import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { User } from '@common/model/user';
import { AuthService } from '@common/services/auth.service';
import { InMemoryQueryService } from '@common/services/inmemory.query.service';
import { Brands } from '@enuk/model/registration';
import { BrandCommunity } from '@lang/uk/model/brand-community';
import { BrandService } from '@lang/uk/services/brand.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent implements OnDestroy {
    public brandCode$: Observable<string>;
    public brandCommunities$: Observable<{ code: string; name: string }[]>;
    public paperRegistration = false;
    public user$: Observable<User>;
    protected destroyed$ = new Subject<void>();

    Brands: typeof Brands = Brands;

    constructor(
        private authService: AuthService,
        private brandService: BrandService,
        private queryService: InMemoryQueryService,
        private router: Router,
    ) {
        this.brandCode$ = this.brandService.getActive().pipe(
            takeUntil(this.destroyed$),
            filter((brand) => !!brand),
            map((brand) => brand.code),
        );
        this.brandCommunities$ = this.authService.user$.pipe(
            takeUntil(this.destroyed$),
            switchMap((_user) =>
                this.brandCode$.pipe(switchMap((brandCode) => this.brandService.getCommunities(brandCode))),
            ),
            map((brandCommunities) =>
                brandCommunities.concat({
                    code: null,
                    name: 'My council is not listed',
                }),
            ),
        );
        this.user$ = this.authService.user$;
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    navigateToPage(brandCommunity: BrandCommunity): void {
        if (brandCommunity.code === null) {
            this.brandCode$
                .pipe(take(1))
                .subscribe((brandCode) => this.navigateToPage({ code: brandCode, name: '' }));
            return;
        }

        // We're redirecting to the same app on a different domain so we can use our own routing rules
        let urlTree: UrlTree;
        if (this.paperRegistration) {
            urlTree = this.router.createUrlTree([brandCommunity.code, 'register'], {
                queryParams: { paper: true },
            });
        } else {
            urlTree = this.router.createUrlTree([brandCommunity.code, 'home']);
        }

        if (!brandCommunity.brandCode) {
            this.router.navigateByUrl(urlTree);
        } else {
            this.brandService.getByCode(brandCommunity.brandCode).subscribe((brand) => {
                // This is only correct for parent brands (since sub-brands might link deeper under a parent brand URL)
                if (!brand || !brand.url || brand.parentBrand) {
                    this.router.navigateByUrl(urlTree);
                    return;
                }
                window.location.href = `${brand.url}${urlTree.toString()}`;
            });
        }
    }

    public searchCouncil(needle: string, item: { code: string; name: string }): boolean {
        // Exclude the item with the 'null' value from search (='My council is not listed')
        return (
            item &&
            item.name &&
            (item.name.toLowerCase().replace(/\W/g, '').indexOf(needle.toLowerCase().replace(/\W/g, '')) >
                -1 ||
                item.code === null)
        );
    }
}
