import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'faqcontent' })
export class FaqContentPipe implements PipeTransform {
    transform(value: string): string {

        if (!value) { return null; }

        const result = value.replace(/<h1>[^<]*<\/h1>/, '');
        return result;
    }
}
