import { Auction } from './auction';
import { CommunityParticipation } from './community-participation';

export class Community {
    id: string;
    code: string;
    name: string;
    contactTo: string;
    postAuctionRegistration: PostAuctionRegistrationMode;
    targetAuction?: Auction;
    participation?: CommunityParticipation;
    preventInterestedIndividuals: boolean;
    contactPhone: string;
    allowCommunicationBySnailMail: boolean;

    constructor(dto?: any) {
        if (dto) {
            Object.assign(this, dto);
        }
    }
}

export enum PostAuctionRegistrationMode {
    Nobody = 'Nobody',
    Everybody = 'Everybody',
    MembersAndPreviousParticipants = 'MembersAndPreviousParticipants',
    PreviousParticipantsOnly = 'PreviousParticipantsOnly',
}
