import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'faqheader' })
export class FaqHeaderPipe implements PipeTransform {
    transform(value: string): string {

        if (!value) { return null; }

        const result = value.match(/<h1>([^<]*)<\/h1>/);
        if (result && result.length > 1) {
            return result[1];
        } else {
            return 'Header';
        }
    }
}
