<div class="card" *ngIf="(isLoading$ | async) !== true && brandName && communityPhone && brandCode">
    <div class="card-body-big">
        <div class="d-flex flex-lg-row flex-column justify-content-between">
            <div class="d-flex flex-row" style="flex-grow: 1">
                <img
                    class="img-fluid mr-5 d-none d-lg-block"
                    style="max-height: 100px"
                    [attr.src]="'helpdesk-icon' | cmsImageSrc | async"
                />
                <p
                    *ngIf="inRegisterFlow$ | async"
                    [innerHTML]="
                        'helpdesk-description-blanks'
                            | cmsText
                                : [
                                      {
                                          brandName: brandName,
                                          brandCode: brandCode,
                                          communityPhone: communityPhone,
                                          communityCode: communityCode,
                                          helpDeskOpeningHours: helpDeskOpeningHours
                                      }
                                  ]
                            | async
                    "
                ></p>
                <p
                    *ngIf="(inRegisterFlow$ | async) !== true"
                    [innerHTML]="
                        'helpdesk-description'
                            | cmsText
                                : [
                                      {
                                          brandName: brandName,
                                          brandCode: brandCode,
                                          communityPhone: communityPhone,
                                          communityCode: communityCode,
                                          helpDeskOpeningHours: helpDeskOpeningHours
                                      }
                                  ]
                            | async
                    "
                ></p>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-lg-start align-items-center">
                <img
                    *ngIf="brandCode !== 'ichoosr'"
                    class="img-fluid mr-5 w-lg-50 w-25"
                    style="max-height: 100px"
                    [attr.src]="brandCode + '-brand-logo' | cmsImageSrc | async"
                />

                <div class="d-flex flex-column w-75">
                    <h6 class="h300">Follow us</h6>
                    <ul class="d-flex pl-0 helpdesk-socials" style="list-style-type: none">
                        <li class="mr-3">
                            <a
                                class="text-primary"
                                [href]="'social-facebook-link' | cmsText | async"
                                target="_blank"
                                aria-label="Facebook"
                            >
                                <i class="fa fa-2x fa-facebook-square text-center"></i>
                            </a>
                        </li>
                        <li class="mr-3">
                            <a
                                class="text-primary"
                                [href]="'social-twitter-link' | cmsText | async"
                                target="_blank"
                                aria-label="Twitter"
                            >
                                <i class="fa fa-2x fa-twitter-square"></i>
                            </a>
                        </li>
                        <li class="mr-3" *ngIf="showInstagramIcon">
                            <a
                                class="text-primary"
                                [href]="'social-instagram-link' | cmsText | async"
                                target="_blank"
                                aria-label="Instagram"
                            >
                                <i class="fa fa-2x fa-instagram"></i>
                            </a>
                        </li>
                        <li class="mr-3">
                            <a
                                class="text-primary"
                                [href]="'social-youtube-link' | cmsText | async"
                                target="_blank"
                                aria-label="YouTube"
                            >
                                <i class="fa fa-2x fa-youtube-square"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
