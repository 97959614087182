import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngLet]',
})
export class LetDirective {
    @Input() set ngLet(context: any) {
        this.context.$implicit = this.context.ngLet = context;
        this.updateView();
    }

    public context: any = {};

    constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<NgLetContext>) {}

    public updateView(): void {
        this.vcRef.clear();
        this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }
}

export class NgLetContext<T = unknown> {
    public $implicit: T = null;
    public ngLet: T = null;
}
