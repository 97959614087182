import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AppBase } from '@common/app/app.component.base'; // for jsdoc
import { AnalyticsClickService } from '../services/click.service';

export interface ClickEventArgs {
    /** To specify what selectors are considered for the link click */
    selectors?: {
        whitelist?: string[];
        blacklist?: string[];
    };
    /** Normally, the text is assumed from the HTMLElement, but if needed, custom text can be specified */
    customText?: string;
}

/**
 * {@link AppBase} will handle all clicks on `<a>` and `<button>`.
 * This directive can be used on other selectors that act like internal links.
 */
@Directive({
    selector: '[clickEvent]', // eslint-disable-line @angular-eslint/directive-selector
})
export class ClickEventDirective<TArgs extends ClickEventArgs = ClickEventArgs> extends Directive {
    @Input()
    eventArgs: TArgs;

    constructor(protected service: AnalyticsClickService, private elementRef: ElementRef) {
        super();
    }

    protected get customText(): TArgs['customText'] {
        return this.eventArgs?.customText;
    }

    protected get selectors(): TArgs['selectors'] {
        return { whitelist: null, blacklist: null, ...this.eventArgs?.selectors };
    }

    @HostListener('mouseup', ['$event'])
    protected onMouseUp(event: PointerEvent): boolean {
        if (!this.isLeftOrMidddleClick(event)) return false;

        const clickTarget = event?.target as HTMLElement;
        if (!clickTarget || !this.matchesSelectors(clickTarget)) return false;

        this.sendEvent(clickTarget);
        return true;
    }

    protected sendEvent(clickTarget: HTMLElement): void {
        this.service.sendLinkClickEvent(clickTarget, this.customText);
    }

    private matchesSelectors(target: HTMLElement): boolean {
        const { whitelist, blacklist } = this.selectors;
        const whitelistOk = !whitelist || whitelist.some((selector) => target.matches(selector));
        const blacklistOk = !blacklist || !blacklist.some((selector) => target.matches(selector));
        return whitelistOk && blacklistOk;
    }

    private isLeftOrMidddleClick(event: PointerEvent): boolean {
        return event && (event.button === 0 || event.button === 1);
    }
}
