import {ApplicationInsights, IConfiguration, Snippet} from '@microsoft/applicationinsights-web';
import {Injectable, Inject, Optional} from '@angular/core';
import {APPLICATION_INSIGHTS_CONNECTION_STRING, BUSINESS} from '@common/app/app.config';

// Based on: https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
@Injectable()
export class MonitoringService {
    appInsights: ApplicationInsights;

    public getAIConfigurationSnippet(connectionString: string): Snippet
    {
        return {
            config : {
                connectionString
            }
        } as Snippet;
    }

    constructor(@Inject(BUSINESS) business: string, @Optional() @Inject(APPLICATION_INSIGHTS_CONNECTION_STRING) connectionString: string) {
        if(connectionString) {
            this.appInsights = new ApplicationInsights(this.getAIConfigurationSnippet(connectionString));
        }
        else {
            console.log("MonitoringService: Do not use window.appInsightsInstrumentationKey, it is deprecated. Please use APPLICATION_INSIGHTS_CONNECTION_STRING instead.");

            //Legacy/backward compatible initialization

            // Might be better to make this come from environment.ts, but this has
            // two disadvantages:
            //
            // 1. The legacy setup (where AI is loaded outside angular, in index.html)
            //    sets the key via CI/CD server in index.html, and a consistent approach
            //    has merits.
            // 2. There's no "environment.local.ts" setup in horizons-app, so it'd be
            //    much harder to have an empty key for localhost development, thus
            //    polluting DEMO AI.
            //
            // So for now, we just do things via `window`:
            const instrumentationKey = window['appInsightsInstrumentationKey'];

            if(instrumentationKey) {
                this.appInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey,
                        // If you ever need a LOT of telemetry, you could enable this for a BL:
                        // enableAutoRouteTracking: true // Tracks ALL route changes
                    }
                });
            }
        }

        // Do not bootstrap app insights on localhost, only if the key is available.
        if (this.appInsights) {
            this.appInsights.loadAppInsights();
            this.appInsights.addTelemetryInitializer(envelope => {
                envelope.tags['ai.cloud.role'] = `ichoosr-app-${(business || '').toLowerCase()}`;
            });
        }
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        if (this.appInsights) {
            this.appInsights.trackEvent({name: name}, properties);
        }
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (this.appInsights) {
            this.appInsights.trackMetric({name: name, average: average}, properties);
        }
    }

    logException(exception: Error, severityLevel?: number) {
        if (this.appInsights) {
            this.appInsights.trackException({exception: exception, severityLevel: severityLevel});
        }
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (this.appInsights) {
            this.appInsights.trackTrace({message: message}, properties);
        }
    }

    logPageView() {
        if (this.appInsights) {
            this.appInsights.trackPageView();
        }
    }
}
