<div class="row">
    <div class="col-sm-7">
        <label appOptional>{{ 'publicity-channel-label' | cmsText | async }}</label>
        <select
            class="form-select"
            [name]="id"
            [id]="id"
            [ngModel]="publicityChannel"
            (ngModelChange)="setPublicityChannel($event)"
            [disabled]="!enabled"
        >
            <option *ngFor="let channel of availableChannels" [ngValue]="channel.value">
                {{ channel.label | cmsText | async }}
            </option>
        </select>
    </div>
</div>
