import { Community } from '@common/model/community';
import { RegistrationBase } from '@common/model/registration.base';
import { User } from '@common/model/user';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProcessEvent } from '../events/process.event';
import { AnalyticsState } from '../model/analytics.state';
import { getFlowPhase } from '../model/flow-phase';
import { Enricher } from './enricher';

export class ProcessEventEnricher implements Enricher<ProcessEvent> {
    constructor(
        private registration$: Observable<RegistrationBase>,
        private community$: Observable<Community>,
        _user$: Observable<User> = null, //unused for now
    ) {}

    enrich$(event: ProcessEvent, state?: AnalyticsState): Observable<ProcessEvent> {
        return combineLatest([this.registration$, this.community$]).pipe(
            map(([registration, community]) => {
                if (this.shouldIgnoreEvent(registration, event)) {
                    return null;
                }

                return {
                    ...event,
                    process_flow_start: state?.process_flow_start,
                    process_flow_phase: getFlowPhase(registration, community),
                };
            }),
        );
    }

    private shouldIgnoreEvent(registration: RegistrationBase, event: ProcessEvent) {
        //process_start should be ignored if there is a registration with an email already
        return registration?.email && event.event === 'process_start';
    }
}
