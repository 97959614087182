import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-terms-and-conditions',
    template: `
    <div class="container">
  <h2 class="mt-5 mb-3">{{ 'terms-and-conditions-title' | cmsText | async }}</h2>
  <p [innerHtml]="'terms-and-conditions-text' | cmsText | async"></p>
</div>
  `,
})
export class TermsAndConditionsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
