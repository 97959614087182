import { MpasDetails } from './mpas-details';

export class Address {
    postcode: string;
    buildingNumber: string;
    buildingName: string;
    subBuilding: string;
    town: string;
    county: string;
    organisation: string;
    dependentThoroughfare?: string;
    thoroughfare?: string;
    dependentLocality?: string;
    doubleDependentLocality?: string;
    regionId: number;
    mpans?: string[];

    public static equalsPostalData(a: Address, b: Address): boolean {
        return (
            (a.buildingName || '') === (b.buildingName || '') &&
            (a.buildingNumber || '') === (b.buildingNumber || '') &&
            (a.county || '') === (b.county || '') &&
            (a.dependentLocality || '') === (b.dependentLocality || '') &&
            (a.dependentThoroughfare || '') === (b.dependentThoroughfare || '') &&
            (a.doubleDependentLocality || '') === (b.doubleDependentLocality || '') &&
            (a.organisation || '') === (b.organisation || '') &&
            (a.postcode || '') === (b.postcode || '') &&
            (a.subBuilding || '') === (b.subBuilding || '') &&
            (a.thoroughfare || '') === (b.thoroughfare || '') &&
            (a.town || '') === (b.town || '')
        );
    }

    public static emptyAddress(): Address {
        return {
            postcode: '',
            buildingNumber: '',
            buildingName: '',
            subBuilding: '',
            town: '',
            county: '',
            organisation: '',
            dependentThoroughfare: '',
            thoroughfare: '',
            dependentLocality: '',
            doubleDependentLocality: '',
            regionId: 0,
        } as Address;
    }
}

export class AddressWithMpasDetails extends Address {
    mpasDetails?: MpasDetails[];
}

export class SearchableAddress {
    value: AddressWithMpasDetails;
    label: string;
    constructor(address: Address) {
        this.value = address;
        this.label =
            `${address.organisation ? address.organisation : ''} ` +
            `${address.buildingName ? address.buildingName : ''} ` +
            `${address.subBuilding ? address.subBuilding : ''} ` +
            `${address.buildingNumber ? address.buildingNumber : ''} ` +
            `${address.dependentThoroughfare ? address.dependentThoroughfare : ''} ` +
            `${address.thoroughfare ? address.thoroughfare : ''} ` +
            `${address.doubleDependentLocality ? address.doubleDependentLocality : ''} ` +
            `${address.dependentLocality ? address.dependentLocality : ''} ` +
            `${address.town ? address.town : ''} ` +
            `${address.county ? address.county : ''} ` +
            `${address.postcode ? address.postcode : ''}`;
    }
}
