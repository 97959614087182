import { Pipe, PipeTransform } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { ContentService } from '../services/content.service';
import { map, switchMap } from 'rxjs/operators';

@Pipe({ name: 'cmsImageSrc' })
export class CmsImageSrcPipe implements PipeTransform {
    constructor(private contentService: ContentService) {}

    transform(key: string | string[]): Observable<string> {
        if (Array.isArray(key)) {
            const exists$ = [];
            const keys: string[] = key;

            keys.forEach((key) =>
                exists$.push(this.contentService.valueExists(key)),
            );

            return combineLatest(exists$).pipe(
                switchMap((exists: boolean[]) => {
                    const index = exists.findIndex((item) => item);
                    if (this.contentService.showLinksToCms || index == -1) {
                        return of(`{${keys.join(',')}}`);
                    } else {
                        return this.getValue(keys[index]);
                    }
                }),
            );
        }

        return this.getValue(key);
    }

    private getValue(key: string): Observable<string> {
        return this.contentService.get(key).pipe(
            map((content) => {
                if (content?.value) {
                    if (this.isString(content.value)) {
                        // old image cms / keep here for legacy / Remove in future
                        return content.value;
                    } else if (content?.value?.src) {
                        return content.value.src;
                    }
                } else if (content) {
                    return '';
                } else {
                    return `{{${key}}}`;
                }
            }),
        );
    }

    private isString(object: any): boolean {
        if (typeof object === 'object') {
            return false;
        }
        return true;
    }
}
