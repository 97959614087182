/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AnalyticsState } from '@common/analytics/model/analytics.state';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { Community } from '@common/model/community';
import { RegistrationBase } from '@common/model/registration.base';
import { CommunityServiceBase } from '@common/services/community.service.base';
import { IFetchRegistration } from '@common/services/registration.service.base';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AnalyticsSolverBase } from './analytics.solver.base';

export class AnalyticsResolverProvider {
    constructor(
        public registrationFetcher: IFetchRegistration<RegistrationBase>,
        public communityService: CommunityServiceBase<Community>,
        public analitycsSolver: AnalyticsSolverBase<RegistrationBase>,
    ) {}
}

@Injectable({ providedIn: 'root' })
export class AnalyticsResolver  {
    constructor(private provider: AnalyticsResolverProvider, private analyticsService: AnalyticsService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        _: RouterStateSnapshot,
    ): Observable<AnalyticsState> | Promise<AnalyticsState> | AnalyticsState {
        const { registrationFetcher, communityService, analitycsSolver } = this.provider;

        const id = route.paramMap.get('id');
        const communityCode = communityService.getCommunityCodeOrFallback(route.params);

        return registrationFetcher.getAny(id, communityCode).pipe(
            map((reg) => analitycsSolver.solveState(route, reg)),
            tap((state) => (this.analyticsService.state = state)),
        );
    }
}
