<div class="d-none d-print-block">
    <img
        [attr.src]="registration?.community?.code + '-community-logo' | cmsImageSrc | async"
        [attr.alt]="registration?.community?.code + '-community-logo' | cmsImageAlt | async"
        height="70"
        style="max-width: 300px"
    />
    <img class="float-end" src="assets/logo-ichoosr-partner.svg" height="70" alt="ichoosr partner logo" />

    <!-- these are needed for adding some space 
        (mt class does not seem to do anything in print) -->
    <h2>&nbsp;</h2>
</div>

<div class="row d-print-none">
    <div *ngIf="!registration.subscriptionComplete && !registration.auction?.finished" class="col-sm-12">
        <p class="alert alert-info">
            {{ 'status-subscription-incomplete' | cmsText | async }}
        </p>
    </div>
    <ng-container
        *ngIf="
            registration.subscriptionComplete &&
            !registration.decisionComplete &&
            !registration.auction?.finished
        "
    >
        <div *ngIf="emailConfirmed; else subscriptionComplete" class="col-sm-12">
            <p class="alert alert-success">
                {{ 'email-confirmation-success' | cmsText | async }}
            </p>
        </div>
        <ng-template #subscriptionComplete>
            <div class="col-sm-12">
                <p class="alert alert-success">
                    {{ 'status-subscription-complete' | cmsText | async }}
                </p>
            </div>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="registration.decisionComplete">
        <div *ngIf="registration.proposalAccepted" class="col-sm-12">
            <p class="alert alert-success">
                {{ 'status-proposal-accepted' | cmsText | async }}
            </p>
        </div>
        <div *ngIf="!registration.proposalAccepted && !registration.excluded" class="col-sm-12">
            <p *ngIf="!registration.copiedToNextAuction" class="alert alert-warning">
                {{ 'status-proposal-declined' | cmsText | async }}
            </p>
            <p *ngIf="registration.copiedToNextAuction" class="alert alert-success">
                {{ 'status-proposal-declined-participate-in-next-auction' | cmsText | async }}
            </p>
        </div>
        <div *ngIf="registration.excluded" class="col-sm-12">
            <p
                *ngIf="!registration.copiedToNextAuction"
                class="alert alert-warning"
                [innerHtml]="
                    (registration.excludedReason
                        ? 'status-proposal-excluded-' + excludedReasonKebab
                        : 'status-proposal-excluded'
                    )
                        | cmsText
                        | async
                "
            ></p>
            <p *ngIf="registration.copiedToNextAuction" class="alert alert-success">
                {{ 'status-proposal-excluded-participate-in-next-auction' | cmsText | async }}
            </p>
        </div>
    </ng-container>
</div>

<ng-container *ngIf="nextStep !== steps.None && !registration.auction?.finished">
    <div class="row">
        <div class="col-sm-12 px-4">
            <h5 class="pb-2">{{ 'overview-next-steps-title' | cmsText | async }}</h5>
            <ng-container [ngSwitch]="nextStep">
                <div *ngSwitchCase="steps.CompleteRegistration">
                    <p [innerHTML]="'status-next-steps-complete-registration' | cmsText | async"></p>
                </div>
                <ng-container *ngSwitchCase="steps.ConfirmRegistration">
                    <div *ngIf="registration.email">
                        <p [innerHTML]="'status-next-steps-confirm-registration' | cmsText | async"></p>
                    </div>
                    <div *ngIf="!registration.email">
                        <p [innerHTML]="'status-next-steps-paper-registration' | cmsText | async"></p>
                    </div>
                </ng-container>
                <div *ngSwitchCase="steps.WaitForAuction">
                    <p
                        [innerHTML]="
                            (!this.registration.communicationBySnailMail
                                ? 'status-next-steps-wait-for-auction'
                                : 'status-next-steps-wait-for-auction-paper'
                            )
                                | cmsText
                                | async
                        "
                    ></p>
                    <app-survey-banner
                        id="post-registration-survey"
                        cmsPrefix="survey"
                        cmsLink="post-registration-survey-link"
                        [cmsParams]="{ registrationNumber: registration.number }"
                        [isMultiverse]="false"
                    >
                    </app-survey-banner>
                </div>
                <div *ngSwitchCase="steps.MakeDecision">
                    <p [innerHTML]="'status-next-steps-make-decision' | cmsText | async"></p>
                </div>
                <div *ngSwitchCase="steps.WaitForContactWithSupplier">
                    <p [innerHTML]="'status-next-steps-wait-for-contact-with-supplier' | cmsText | async"></p>
                </div>
                <div *ngSwitchCase="steps.AfterProposalAccepted">
                    <p
                        class="no-margin-cms-list"
                        [innerHTML]="defaultProposalAcceptedText | cmsText | async"
                    ></p>
                    <p
                        [innerHTML]="
                            'status-next-steps-proposal-accepted-' +
                                (this.registration.proposal.winningTariffSupplierName | kebabCase)
                                | cmsText
                                | async
                        "
                    ></p>
                </div>
                <div *ngSwitchCase="steps.None">
                    <p [innerHTML]="'status-next-steps-none' | cmsText | async"></p>
                </div>
            </ng-container>
        </div>
    </div>
    <hr class="mt-2 mb-4" />
</ng-container>
<ng-container
    *ngIf="
        ((registration.decisionComplete || registration.auction?.finished) &&
            !registration.proposalAccepted &&
            !registration.copiedToNextAuction) ||
        (!registration.decisionComplete && registration.auction?.finished && registration.copiedToNextAuction)
    "
>
    <div class="row">
        <div class="col-sm-12 px-4">
            <p
                [innerHTML]="
                    (registration.decisionComplete
                        ? registration.excluded
                            ? 'status-next-steps-excluded-participate-in-next-auction'
                            : 'status-next-steps-declined-participate-in-next-auction'
                        : 'status-next-steps-registration-expired'
                    )
                        | cmsText : [cmsParams]
                        | async
                "
            ></p>
            <div class="text-center">
                <button
                    type="button"
                    id="participate-in-next-auction-button"
                    (click)="wantToParticipateInNextAuction()"
                    class="btn btn-primary px-5 d-print-none"
                >
                    {{ 'participate-in-next-auction-button' | cmsText | async }}
                </button>
            </div>
        </div>
    </div>
    <hr class="my-4" />
</ng-container>

<div *ngIf="registration.decisionComplete && registration.proposalAccepted">
    <div class="row">
        <div class="col-sm px-4">
            <h5 class="mb-4">{{ 'overview-new-energy-details-title' | cmsText | async }}</h5>
            <div class="row">
                <div class="col-sm px-4">
                    <p>
                        Supplier:
                        <span class="float-end">
                            {{ registration.proposal.winningTariffSupplierName }}
                        </span>
                        <br />

                        Contract type:
                        <span class="float-end">
                            {{ registration.product.contract }}
                        </span>
                        <br />

                        Tariff name:
                        <span class="float-end">
                            {{ registration.proposal.winningTariff.electricityTariffName }}
                        </span>
                        <br />

                        <ng-container
                            *ngIf="
                                registration.proposal.winningTariff?.electricityPaymentMethod !==
                                PaymentMethod.PrePayment
                            "
                        >
                            Contract duration:
                            <span class="float-end">
                                {{ 'overview-new-energy-details-duration-standard' | cmsText | async }}
                            </span>
                            <br />
                        </ng-container>
                    </p>

                    <button
                        class="btn btn-sm btn-primary d-print-none"
                        (click)="modalService.open(offerDetailsPopup, { size: 'lg' })"
                        ms-2
                    >
                        Show offer details
                    </button>
                    <button
                        class="btn btn-sm btn-primary ms-2 d-print-none"
                        (click)="modalService.open(calculationDetailsPopup, { size: 'lg' })"
                        ms-2
                    >
                        View calculation
                    </button>
                </div>
            </div>
        </div>
    </div>
    <hr class="mt-4 mb-4" />
    <app-survey-banner
        id="proposal-accepted-survey"
        cmsPrefix="survey"
        [cmsLink]="cmsProposalAcceptedSurveyKey | async"
        [cmsParams]="{ registrationNumber: registration.number }"
        [isMultiverse]="false"
    >
    </app-survey-banner>
</div>

<div *ngIf="registration.decisionComplete && !registration.proposalAccepted">
    <app-survey-banner
        id="proposal-declined-survey"
        cmsPrefix="survey"
        [cmsLink]="cmsProposalDeclinedSurveyKey | async"
        [cmsParams]="{ registrationNumber: registration.number }"
        [isMultiverse]="false"
    >
    </app-survey-banner>
</div>

<div class="d-print-none" *ngIf="miniFaqCategory">
    <div class="row">
        <div class="col-sm-12 px-4">
            <h5 class="mb-4">{{ 'faq-title' | cmsText | async }}</h5>
            <app-mini-faq #miniFaq [category]="miniFaqCategory"></app-mini-faq>
        </div>
    </div>
    <hr class="mt-4 mb-4" />
</div>

<ng-container *ngIf="registration.decisionComplete && registration.proposalAccepted">
    <ng-container *ngTemplateOutlet="energyDetails"></ng-container>
</ng-container>

<ng-container *ngIf="registration.subscriptionComplete">
    <div class="row">
        <div class="col-sm-12 px-4">
            <app-collapsible-card
                titleKey="{{
                    !showBankDetails ? 'personal-details-title' : 'personal-and-bank-details-title'
                }}"
                [isCollapsed]="nextStep !== steps.WaitForAuction"
            >
                <app-register-personal-details-overview
                    [registration]="registration"
                    [readonly]="isRegistrationReadonly"
                    [showBankDetails]="showBankDetails"
                    (edit)="onNavigateToTab('person')"
                >
                </app-register-personal-details-overview>
            </app-collapsible-card>
        </div>
    </div>
    <hr class="mt-4 mb-4" />
</ng-container>

<ng-container
    *ngIf="
        registration.subscriptionComplete &&
        (!registration.decisionComplete || !registration.proposalAccepted)
    "
>
    <ng-container *ngTemplateOutlet="energyDetails"></ng-container>
</ng-container>

<div class="row">
    <div class="col-sm-12 px-4">
        <p
            *ngIf="registration.contact.agreesToConditions && registration.contact.agreesToPrivacyPolicy"
            [innerHTML]="'agreed-to-tc-and-pp-label' | cmsText | async"
        ></p>
        <button class="d-print-none float-end btn btn-secondary" (click)="print()">
            <i class="fa fa-fw fa-print"></i>Print
        </button>
    </div>
</div>

<div
    [innerHTML]="
        'overview-important-dates'
            | cmsText
                : [
                      {
                          auctionDate: registration?.auction?.date | date : 'fullDate',
                          dateWhenOneCanRequestLetter: dateWhenOneCanRequestLetter | date : 'fullDate'
                      }
                  ]
            | async
    "
    class="d-none d-print-block alert alert-primary"
    role="alert"
    *ngIf="
        (!registration.decisionComplete || registration.proposalAccepted) &&
        (contentService.valueExists('overview-important-dates') | async)
    "
></div>
<div class="d-none d-print-block" *ngIf="miniFaqCategory">
    <hr class="my-4" />
    <div class="row">
        <div class="col-sm-12 px-4">
            <h5 class="mb-4">{{ 'faq-title' | cmsText | async }}</h5>
            <app-mini-faq #miniFaq [category]="miniFaqCategory"></app-mini-faq>
        </div>
    </div>
    <hr class="mt-4 mb-4" />
</div>

<ng-template #energyDetails>
    <div class="row">
        <div class="col-sm-12 px-4">
            <app-collapsible-card
                titleKey="energy-details-title"
                [isCollapsed]="registration?.decisionComplete"
            >
                <app-register-energy-details-overview
                    [registration]="registration"
                    [readonly]="isRegistrationReadonly"
                    (edit)="onNavigateToTab('product')"
                >
                </app-register-energy-details-overview>
            </app-collapsible-card>
        </div>
    </div>
    <hr class="mt-4 mb-4" />
</ng-template>

<ng-template #offerDetailsPopup let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Offer Details</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-proposal-information
            [registration]="registration"
            [paymentMethod]="registration.decision.paymentMethod"
        >
        </app-proposal-information>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Back to overview</button>
    </div>
</ng-template>

<ng-template #calculationDetailsPopup let-modal>
    <div class="modal-header">
        <p class="h5 modal-title">{{ 'tariff-details-title' | cmsText | async }}</p>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-tarrif-comparison [registration]="registration"></app-tarrif-comparison>
    </div>
    <div class="modal-footer">
        <div class="col-sm-12 text-start">
            <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">
                Back to overview
            </button>
        </div>
    </div>
</ng-template>
