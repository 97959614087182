<label [for]="id" class="d-block form-label">
    {{ cmsLabel | cmsText | async }}
    <i *ngIf="isLoading && !readonly" class="fa fa-spinner fa-pulse ms-2"></i>
</label>
<div class="row">
    <div class="col-md-8">
        <select
            class="form-select"
            [name]="id"
            [id]="id"
            [disabled]="!enabled || readonly || isLoading"
            [ngModel]="paymentMethod"
            (ngModelChange)="setPaymentMethod($event)"
        >
            <option [ngValue]="null" disabled="true">
                {{ !isLoading ? 'Select your payment method...' : 'Loading...' }}
            </option>
            <option *ngFor="let kvPair of getPaymentMethodOptions()" [ngValue]="kvPair.optionValue">
                {{ kvPair.cmsKey | cmsText | async }}
            </option>
        </select>
    </div>
</div>
