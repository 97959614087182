import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from '@common/util';
import { Observable, ReplaySubject, of, BehaviorSubject } from 'rxjs';
import {
    switchMap,
    map,
    distinctUntilChanged,
    catchError,
    tap,
} from 'rxjs/operators';

import {
    CMS_URL,
    CMS_PRODUCT,
    CMS_LOCALE,
    CMS_REGION,
    CMS_ALLOW_LINKS,
} from '../app/app.config';
import { Community } from '@common/model/community';
import { CommunityService } from './community.service';
import { Content, Kind } from '../model/content';
import { LanguageService } from './language.service';
import { ContentFallbackServiceBase } from './content-fallback.service.base';
import { CmsProcessor } from './cms-processor';

@Injectable()
export class ContentService {
    loaded$ = new BehaviorSubject<boolean>(false);
    loading$ = new BehaviorSubject<boolean>(false);
    showPreview = false;
    showLinksToCms = false;
    cmsEditorUrl = 'https://backoffice.ichoosr.com/content/';
    visibleCmsKeys = new ReplaySubject<{
        key: string;
        url: string;
        val: string;
    }>();
    locale: string;

    protected content = new ReplaySubject<Content[]>(1);
    protected url: string;

    private visibleCmsKeysCache = [];

    constructor(
        protected http: HttpClient,
        @Inject(CMS_URL) private baseUrl: string,
        @Inject(CMS_PRODUCT) product: string,
        @Inject(CMS_REGION) region: string,
        @Inject(CMS_LOCALE) protected defaultLocale: string,
        @Inject(CMS_ALLOW_LINKS) public cmsAllowLinks: boolean,
        protected communityService: CommunityService,
        @Optional() protected languageService: LanguageService,
        @Optional() fallbackService: ContentFallbackServiceBase,
    ) {
        this.url =
            this.baseUrl +
            'values' +
            '?client=app&product=' +
            product +
            '&region=' +
            region;
        this.content.subscribe(() => {
            this.loaded$.next(true);
            this.loading$.next(false);
        });
        if (fallbackService) {
            this.content.next(fallbackService.getContentFallback());
        }
        this.load();
    }

    load(auctionCode?: string, noAuction = false): void {
        this.load$(auctionCode, noAuction).subscribe();
    }

    load$(auctionCode?: string, noAuction = false): Observable<void> {
        return this.communityService.community$.pipe(
            catchError(() => of(new Community())),
            tap(() => this.loading$.next(true)),
            map((community) =>
                this.getContentUrl(community, auctionCode, noAuction),
            ),
            distinctUntilChanged(),
            switchMap((url) =>
                this.http.get<Content[]>(url).pipe(
                    tap((content) => this.content.next(content)),
                    catchError(() => []),
                ),
            ),
        );
    }

    get(key: string): Observable<Content> {
        return this.content.pipe(
            map((contents) => {
                return contents.find((value) => value.key === key);
            }),
        );
    }

    filter(predicate: (key: string) => boolean): Observable<Content[]> {
        return this.content.pipe(
            map((contents) => {
                return contents.filter((value) => predicate(value.key));
            }),
        );
    }

    value(key: string): Observable<string> {
        return this.content.pipe(
            map((contents) => {
                const content = contents.find((value) => value.key === key);
                if (content) {
                    if (content.kind === Kind.Image) {
                        return content.value.src;
                    } else if (content.kind === Kind.FaqCategory) {
                        return content.value.title
                            ? content.value.title
                            : content.value;
                    } else if (content.kind === Kind.ItemsList) {
                        return content.value.title
                            ? content.value.title
                            : content.value;
                    }

                    return content.value;
                } else {
                    return `{{${key}}}`;
                }
            }),
        );
    }

    valueExists(key: string): Observable<boolean> {
        return this.content.pipe(
            map((contents) => {
                const content = contents.find((value) => value.key === key);
                if (content && content.value !== '') {
                    return true;
                }
                return false;
            }),
        );
    }

    registerVisibleCmsKey(previewKey: {
        key: string;
        url: string;
        val: string;
    }): void {
        if (!this.visibleCmsKeysCache.some((k) => k === previewKey.key)) {
            this.visibleCmsKeysCache.push(previewKey.key);
            this.visibleCmsKeys.next(previewKey);
        }
    }

    getFileShareUrl(route: string): Observable<string> {
        return this.http.get(
            `${this.baseUrl}content/fileshare/file-url?route=${route}`,
            { responseType: 'text' },
        );
    }

    private getContentUrl(
        community: Community,
        auctionCode: string,
        noAuction: boolean,
    ): string {
        let url = this.url;

        if (this.locale) {
            url = url + '&locale=' + this.locale;
        } else {
            if (this.languageService) {
                url =
                    url + '&locale=' + this.languageService.currentLanguageCode;
            } else {
                url = url + '&locale=' + this.defaultLocale;
            }
        }

        if (auctionCode && noAuction === false) {
            url += `&auction=${auctionCode}`;
        } else if (
            community &&
            community.targetAuction &&
            noAuction === false
        ) {
            url += `&auction=${community.targetAuction.code}`;
        }
        if (!isNullOrUndefined(community.code)) {
            url += '&community=' + community.code;
        }
        if (this.showPreview) {
            url += '&preview=true';
        }

        return url;
    }

    transform(key: string, params?: any[]): Observable<string> {
        return this.get(key).pipe(
            map((content) => {
                if (content) {
                    // Make sure the content is seen as a string
                    content.value = `${content.value}`;

                    //  No pipe parameters
                    if (params === undefined) {
                        return content.value;
                    }

                    if (params && content.value) {
                        const regex =
                            /({{|%7B%7B)\s*([\w\.\-]+)\s*(}}|%7D%7D)/g;
                        const match = content.value.match(regex);

                        const tokensToReplace = match ? match.length : 0;
                        const processor = new CmsProcessor(params);

                        //  No token to replace or empty parameters list
                        if (tokensToReplace === 0 || params.length < 1) {
                            return content.value;
                        }
                        // Tokens and parameters exist
                        if (tokensToReplace > 0 && params.length > 0) {
                            const result = content.value.replace(
                                regex,
                                processor.contentReplacer.bind(processor),
                            );
                            return result;
                        }
                    }
                } else {
                    return `{{${key}}}`;
                }
            }),
            map((value) => {
                if (this.showLinksToCms) {
                    this.addToCmsBar(key, value);
                    return `{{${key}}}`;
                }
                return value;
            }),
        );
    }

    private addToCmsBar(key: string, val: string) {
        const url = this.cmsEditorUrl + key;
        this.registerVisibleCmsKey({ key: key, url: url, val: val });
    }
}
