import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@common/app/app.config';
import { RegistrationBase } from '@common/model/registration.base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class PotentialDuplicatesResult {
    potentialDuplicatesCount: number;
}

@Injectable()
export class DuplicateRegistrationsService {
    private url: string;

    constructor(private http: HttpClient, @Inject(API_URL) baseUrl: string) {
        this.url = baseUrl + 'registrations/';
    }

    hasPotentialDuplicates(findDuplicates: FindDuplicate): Observable<boolean> {
        return this.http
            .post<PotentialDuplicatesResult>(
                this.url + 'potential-duplicates',
                findDuplicates,
            )
            .pipe(map((result) => result.potentialDuplicatesCount !== 0));
    }

    sendLinksToDuplicates(findDuplicates: FindDuplicate): Observable<any> {
        return this.http.post<any>(
            this.url + 'send-links-to-duplicates',
            findDuplicates,
        );
    }
}

export class FindDuplicate {
    registration: RegistrationBase;
    registrationId?: string;
    searchMethod: DuplicateSearchMethod;
}

export enum DuplicateSearchMethod {
    Full = 'Full',
    EmailOnly = 'EmailOnly',
}
