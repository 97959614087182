/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable, Optional } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { ANALYTICS_CROSS_BUSINESS_URLS } from '@common/app/app.config';
import { ContentService } from '@common/services/content.service';
import { isPropablyFilePath as isProbablyFilePath } from '@common/util';
import { EMPTY, of } from 'rxjs';
import { filter, take, map, timeoutWith, catchError } from 'rxjs/operators';
import { DownloadEvent } from '../events/download-event';
import {
    ExternalLinkClickEvent,
    InternalLinkClickEvent,
    CrossBusinessLinkClickEvent,
    LinkClickEvent,
} from '../events/link-click.event';
import { ProposalInteractionEvent } from '../events/proposal-interaction.event';
import { TooltipClickEvent } from '../events/tooltip-click.event';
import { ProposalInteractionType } from '../model/proposal-interaction-type';
import { AnalyticsService } from './analytics.service';
import { WINDOW } from '@common/services/window.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsClickService {
    constructor(
        private analyticsService: AnalyticsService,
        private router: Router,
        private contentService: ContentService,
        @Inject(WINDOW) private window: Window,
        @Optional() @Inject(ANALYTICS_CROSS_BUSINESS_URLS) private crossBusinessUrls?: string[],
    ) {}

    public sendInternalLinkClicked(linkTarget: HTMLElement, href: string, customText?: string) {
        if (!linkTarget) return;
        const event = InternalLinkClickEvent.create(linkTarget, href, customText);
        if (event) this.analyticsService.push(event);
    }

    public sendLinkClickEvent(linkTarget: HTMLElement, customText?: string): void {
        const href = this.getHref(linkTarget);

        if (href) {
            const event = this.getClickEvent(href, linkTarget, customText);
            if (event) this.analyticsService.push(event);
            return;
        }

        // if there is no href, it's probably a button with an internal link.
        // internal links will go through the router events.
        this.router.events
            .pipe(
                filter<ResolveEnd>((ev) => ev instanceof ResolveEnd),
                take(1),
                map((ev) => InternalLinkClickEvent.create(linkTarget, ev?.urlAfterRedirects, customText)),
                timeoutWith(1000, EMPTY), //if for some reason the router did not send an event within 1s of clicking, then cancel
            )
            .subscribe((e) => this.analyticsService.push(e));
    }

    public sendProposalInteractionEvent(
        clickTarget: HTMLElement,
        customText?: string,
        interactionType?: ProposalInteractionType,
    ): void {
        const event = ProposalInteractionEvent.create(clickTarget, customText, interactionType);
        this.analyticsService.push(event);
    }

    public sendTooltipClick(cmsKey?: string, fallBackText?: string): void {
        const event = cmsKey
            ? TooltipClickEvent.create({ tooltip_key: cmsKey })
            : TooltipClickEvent.create({ tooltip_item: fallBackText });

        this.analyticsService.push(event);
    }

    private getHref(el: HTMLElement): string {
        if (!el.hasAttribute('href')) return null;
        const href = el.getAttribute('href');
        if (!href || href.startsWith('#') || href.startsWith('javascript:void')) return null;
        return href;
    }

    private getClickEvent(
        href: string,
        linkTarget: HTMLElement,
        customText: string,
    ): LinkClickEvent | DownloadEvent {
        if (!href) return null;

        const hrefWithoutProtocol = href.replace(/^https?:\/\//, '');
        const isCrossBusiness = this.crossBusinessUrls?.some((url) => hrefWithoutProtocol.startsWith(url));

        switch (true) {
            case isProbablyFilePath(href):
                return DownloadEvent.create(linkTarget, customText);

            case isCrossBusiness:
                return CrossBusinessLinkClickEvent.create(linkTarget, customText);

            case !href.startsWith('http') || hrefWithoutProtocol.startsWith(this.window.location.hostname):
                return InternalLinkClickEvent.create(linkTarget, href, customText);

            default:
                return ExternalLinkClickEvent.create(linkTarget, customText);
        }
    }
}
