/* eslint-disable @typescript-eslint/naming-convention */
import { getAnyTextFromElement } from '@common/util';
import { AnalyticsEvent, AnalyticsEventTemplate } from './analytics.event';

type Template = AnalyticsEventTemplate<TooltipClickEvent>;

export class TooltipClickEvent implements AnalyticsEvent {
    event = 'tooltip_click' as const;

    tooltip_key: string;
    tooltip_item: string;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }

    public static create(template: Template = {}): TooltipClickEvent {
        return new this(template);
    }
}
