export enum PaymentStatus {
    New = 'New',
    Successful = 'Successful',
    Failed = 'Failed',
    Pending = 'Pending',
    Cancelled = 'Cancelled',
    Awaiting = 'Awaiting'
}

export enum StartPaymentStatus {
    Ok = 'Ok',
    Failed = 'Failed'
}

export class StartPaymentRequest {
    registrationId: string;
    business: string;
    paymentMethod: string;
}

export class StartPaymentResponse {
    status: StartPaymentStatus;
    paymentId: string;
    buckarooPayload: any;
    buckarooUrl: string;
    message: string;
}

export class PaymentStatusResponse {
    status: PaymentStatus;
    buckarooPaymentStatus: string;
    statusCodeDetail: string;
    statusCodeMessage: string;
}

export class PaymentTransferDetailsResponse {
    paymentReference: string;
    iban: string;
    bic: string;
    accountHolderName: string;
    amount: number;
}
