/* eslint-disable @typescript-eslint/naming-convention */
import { FlowStart } from '@common/analytics/model/flow-start';
import { ProposalFlowSource } from './proposal-flow-source';

export class AnalyticsState {
    process_flow_start: FlowStart;
    proposal_flow_source: ProposalFlowSource;

    constructor(template: Partial<AnalyticsState> = {}) {
        Object.assign(this, template);
    }
}
