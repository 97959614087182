import { Community } from '@common/model/community';

export class UkCommunity extends Community {
    promoting: string[];
    hasDataSharingAgreement: boolean;

    constructor(dto?: any) {
        super(dto);
    }
}
