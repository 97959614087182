/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import { CommunityParticipation } from '@common/model/community-participation';
import { RegistrationBase } from '@common/model/registration.base';
import { CommunityService } from '@common/services/community.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
} from 'rxjs/operators';

export abstract class ParticipationAssetsService {
    public abstract get communityParticipation$(): Observable<CommunityParticipation>;
    public abstract get communityIsPromoting$(): Observable<boolean>;
    public abstract get communityLogoUrl$(): Observable<string>;
    public abstract get communityLogoAlt$(): Observable<string>;
    public abstract get communityHeroImageUrl$(): Observable<string>;
    public abstract get communityHeroImageAlt$(): Observable<string>;
    public abstract get brandLogoUrl$(): Observable<string>;
    public abstract get brandLogoAlt$(): Observable<string>;
    public abstract get baseBrandLogoUrl$(): Observable<string>;
    public abstract get baseBrandLogoAlt$(): Observable<string>;
    public abstract registrationLoaded(registration: RegistrationBase): void;
}

@Injectable({
    providedIn: 'root',
})
export class I4pParticipationAssetsService extends ParticipationAssetsService {
    private registrationParticipation$: Subject<CommunityParticipation> = new BehaviorSubject<CommunityParticipation>(null);
    private activeParticipation$: Observable<CommunityParticipation>;

    constructor(private communityService: CommunityService) {
        super();

        this.activeParticipation$ = this.registrationParticipation$.pipe(
            switchMap((communityParticipation) => {
                if (communityParticipation) {
                    return of(communityParticipation);
                }

                return this.communityService.community$.pipe(
                    distinctUntilChanged((lhs, rhs) => lhs?.id === rhs?.id),
                    map((community) => community?.participation || ({} as CommunityParticipation)),
                );
            }),
            shareReplay(1),
        );
    }

    public get communityParticipation$(): Observable<CommunityParticipation> {
        return this.activeParticipation$;
    }

    public get communityIsPromoting$(): Observable<boolean> {
        return this.activeParticipation$.pipe(
            map((communityParticipation) => communityParticipation.isPromoting),
        );
    }

    public get communityLogoUrl$(): Observable<string> {
        return this.activeParticipation$.pipe(
            map((communityParticipation) => communityParticipation.logoImageUri),
        );
    }

    public get communityLogoAlt$(): Observable<string> {
        return of(null);
    }

    public get communityHeroImageUrl$(): Observable<string> {
        return this.activeParticipation$.pipe(
            map((communityParticipation) => communityParticipation.heroImageUri),
        );
    }

    public get communityHeroImageAlt$(): Observable<string> {
        return this.activeParticipation$.pipe(
            map((communityParticipation) => communityParticipation.heroImageAlt),
        );
    }

    public get brandLogoUrl$(): Observable<string> {
        return this.activeParticipation$.pipe(
            map((communityParticipation) => communityParticipation.brandParticipation.logoImageUri),
        );
    }

    public get brandLogoAlt$(): Observable<string> {
        return this.activeParticipation$.pipe(
            map((communityParticipation) => communityParticipation.brandParticipation.logoImageAlt),
        );
    }

    public get baseBrandLogoUrl$(): Observable<string> {
        return this.activeParticipation$.pipe(
            map((communityParticipation) => communityParticipation.brandParticipation.logoImageUri),
        );
    }

    public get baseBrandLogoAlt$(): Observable<string> {
        return this.activeParticipation$.pipe(
            map((communityParticipation) => communityParticipation.brandParticipation.logoImageAlt),
        );
    }

    public registrationLoaded(registration: RegistrationBase): void {
        if (registration?.communityParticipation) {
            this.registrationParticipation$.next(registration.communityParticipation);
        }
    }
}
