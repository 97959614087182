import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { KebabCasePipe } from '@common/pipes/kebab-case.pipe';
import { PaymentMethod } from '@enuk/model/registration';
import { EnergySelectorComponentBase } from '../energy-selector-base/energy-selector-base.component';

@Component({
    selector: 'app-register-energy-payment-method',
    templateUrl: './payment-method.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyPaymentMethodComponent extends EnergySelectorComponentBase {
    @Input() public paymentMethods: PaymentMethod[] = [];
    public paymentMethod: PaymentMethod = null;

    constructor(ngControl: NgControl) {
        super(ngControl);
    }

    public getPaymentMethodOptions(): {
        cmsKey: string;
        optionValue: PaymentMethod;
    }[] {
        const kebabPipe = new KebabCasePipe();
        const paymentMethods = Object.assign([], this.paymentMethods);
        if (this.paymentMethod && paymentMethods.indexOf(this.paymentMethod) === -1) {
            // Make sure the current payment method is always an option (in case of historical data)
            paymentMethods.push(this.paymentMethod);
        }

        return (paymentMethods || []).map((pm) => ({
            cmsKey: `payment-method-${kebabPipe.transform(pm)}`,
            optionValue: pm,
        }));
    }

    public writeValue(paymentMethod: PaymentMethod): void {
        this.paymentMethod = paymentMethod;
    }

    public setPaymentMethod(paymentMethod: PaymentMethod): void {
        this.paymentMethod = paymentMethod;
        this.onChanged.forEach((f) => f(this.paymentMethod));
    }
}
