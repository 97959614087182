<ng-container *ngIf="registration">
    <div class="row">
        <div class="col-sm">
            <p>
                {{ registration.contact.firstName }} {{ registration.contact.lastName }} <br />
                {{ registration.email }}
                <ng-container *ngIf="registration.contact.phone">
                    <br />
                    {{ registration.contact.phone }}
                </ng-container>
                <ng-container *ngIf="registration.contact.mobilePhone">
                    <br />
                    {{ registration.contact.mobilePhone }}
                </ng-container>
            </p>
        </div>
        <div class="col-sm">
            <span *ngFor="let add of registration.contact | formatAddress : { separatePostcode: true }">
                {{ add }} <br />
            </span>
        </div>
    </div>

    <div class="d-none d-print-block">
        <div class="row">
            <div class="col">
                {{ 'registration-number-label' | cmsText | async }}
            </div>
            <div class="col text-end fw-bold">
                {{ registration.number }}
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">{{ 'online-billing-label' | cmsText | async }}:</div>
        <div class="col text-end fw-bold">
            {{ registration.product.onlineBilling ? ('yes' | cmsText | async) : ('no' | cmsText | async) }}
        </div>
    </div>
    <div class="row">
        <div class="col">{{ 'warm-home-discount-label' | cmsText | async }}:</div>
        <div class="col text-end fw-bold">
            {{ registration.product.warmHomeDiscount ? ('yes' | cmsText | async) : ('no' | cmsText | async) }}
        </div>
    </div>
    <div class="row">
        <div class="col">{{ 'priority-service-register-label' | cmsText | async }}:</div>
        <div class="col text-end fw-bold">
            {{
                registration.product.priorityServiceRegister
                    ? ('yes' | cmsText | async)
                    : ('no' | cmsText | async)
            }}
        </div>
    </div>
    <div class="row">
        <div class="col">{{ 'has-changed-supplier-in-last-3-years-label' | cmsText | async }}:</div>
        <div class="col text-end fw-bold">
            {{
                registration.product.hasChangedSupplierInLast3Years
                    ? ('yes' | cmsText | async)
                    : ('no' | cmsText | async)
            }}
        </div>
    </div>

    <div class="mt-3" *ngIf="showBankDetails">
        <strong> {{ 'status-bank-details-label' | cmsText | async }} </strong>
        <div class="row">
            <div class="col">{{ 'first-name-label' | cmsText | async }}:</div>
            <div class="col text-end fw-bold">
                {{ registration.decision.firstName }}
            </div>
        </div>
        <div class="row">
            <div class="col">{{ 'surname-label' | cmsText | async }}:</div>
            <div class="col text-end fw-bold">
                {{ registration.decision.lastName }}
            </div>
        </div>
        <div class="row">
            <div class="col"></div>
            <div class="col text-end fw-bold"></div>
        </div>
        <div class="row">
            <div class="col">{{ 'account-number-label' | cmsText | async }}:</div>
            <div class="col text-end fw-bold">
                {{ maskedAccountNumber }}
            </div>
        </div>
        <div class="row">
            <div class="col">{{ 'sort-code-label' | cmsText | async }}:</div>
            <div class="col text-end fw-bold">
                {{ registration.decision.sortCode }}
            </div>
        </div>
        <div class="row" *ngIf="registration.decision.preferredPaymentDay">
            <div class="col">{{ 'payment-day-label' | cmsText | async }}:</div>
            <div class="col text-end fw-bold">
                {{ registration.decision.preferredPaymentDay }}
            </div>
        </div>
    </div>

    <button
        *ngIf="!readonly"
        id="editPersonalButton"
        class="float-end btn btn-secondary ps-4 pe-4 d-print-none"
        (click)="onEdit()"
    >
        {{ 'edit-button' | cmsText | async }}
    </button>
</ng-container>
