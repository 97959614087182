<ng-container *ngIf="'variant-1' | vwoVariant | async"> </ng-container>

<ng-container *ngIf="'default' | vwoVariant | async">
    <div
        class="navWrapper sticky"
        [ngClass]="{ fullNav: mobileMenuExpanded }"
        *ngIf="(showMenu$ | async) === true"
    >
        <nav class="container d-flex" *ngIf="(isLoading$ | async) !== true">
            <div class="logo" id="menuLogo">
                <app-menu-logo (clickEvent)="onMenuLogoClick()"></app-menu-logo>
            </div>

            <div
                class="menu"
                *ngIf="(showLinks$ | async) === true"
                [ngbCollapse]="isMobileDevice ? !mobileMenuExpanded : null"
                [ngClass]="{
                    'collapse navbar-collapse': isMobileDevice
                }"
                #menu
            >
                <app-nav-menu
                    class="col-12 col-lg-12"
                    cmsMenuKey="menu"
                    [navbarCollapsed]="!mobileMenuExpanded"
                    [isMobileDevice]="isMobileDevice"
                    (itemClick)="onMenuClick()"
                ></app-nav-menu>
                <div class="registerWrapper d-none">
                    <button
                        class="btn btn-primary px-3 btn-md"
                        [routerLink]="'../register'"
                        id="register-button-menu"
                    >
                        <app-content key="register-button-text"></app-content>
                        <i class="fa fa-chevron-right ml-2"></i>
                    </button>
                </div>
                <div class="socialsWrapper">
                    <ul class="d-flex" style="list-style-type: none">
                        <li class="col-3 p-0">
                            <a
                                class="text-primary"
                                [href]="generateBrandedCmsKey('social-facebook-link') | cmsText | async"
                                target="_blank"
                            >
                                <i class="fa fa-facebook-square text-center"></i>
                            </a>
                        </li>
                        <li class="col-3 p-0">
                            <a
                                class="text-primary"
                                [href]="generateBrandedCmsKey('social-twitter-link') | cmsText | async"
                                target="_blank"
                            >
                                <i class="fa fa-twitter-square"></i>
                            </a>
                        </li>
                        <li class="col-3 p-0" *ngIf="showInstagramIcon">
                            <a
                                class="text-primary"
                                [href]="generateBrandedCmsKey('social-instagram-link') | cmsText | async"
                                target="_blank"
                            >
                                <i class="fa fa-instagram"></i>
                            </a>
                        </li>
                        <li class="col-3 p-0">
                            <a
                                class="text-primary"
                                [href]="generateBrandedCmsKey('social-youtube-link') | cmsText | async"
                                target="_blank"
                            >
                                <i class="fa fa-youtube-square"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="menu-button icon" *ngIf="(showLinks$ | async) === true">
                <ng-container>
                    <button class="navbar-toggler" type="button" (click)="showOrHideBody()">
                        <span
                            class="fa"
                            [ngClass]="{ 'fa-bars': !mobileMenuExpanded, 'fa-times': mobileMenuExpanded }"
                        ></span>
                    </button>
                </ng-container>
            </div>
        </nav>
    </div>
</ng-container>
