import { Brands } from '@enuk/model/registration';
import { Brand } from '@lang/uk/model/brand';

export class BrandHelper {
    // Used to shorten CMS keys to more manageable lengths
    // (e.g. 'proposal-declined-survey-bcs-link' vs 'proposal-declined-survey-big-community-switch-link')
    public static getShortCode(brand: Brand): string {
        switch (brand?.parentBrand?.code ?? brand?.code) {
            case Brands.BigCommunitySwitch:
                return 'bcs';
            case Brands.BigLondonEnergySwitch:
                return 'bles';
            case Brands.iChoosr:
                return 'ichoosr';
            default:
                return '';
        }
    }
}
