<p *ngIf="registration.product.electricityPaymentMethod !== PaymentMethod.PrePayment">
    {{ 'tariff-details-info-text' | cmsText | async }}
</p>
<p *ngIf="registration.product.electricityPaymentMethod === PaymentMethod.PrePayment">
    {{ 'tariff-detail-info-text-ppm' | cmsText | async }}
</p>
<table class="table table-bordered table-sm table-responsive-sm">
    <thead>
        <tr>
            <th scope="col" style="width: 33%">Totals</th>
            <th scope="col" style="width: 33%" class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.typeName }}
            </th>
            <th scope="col" style="width: 33%" class="bg-success text-white text-end">
                {{ rightTariff.typeName }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr class="fw-bold">
            <td class="bg-light">Estimated total annual cost</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.totalCost | currency : 'GBP' : 'symbol' : '1.0-0' }} / year
            </td>
            <td class="text-end text-success">
                {{ rightTariff.totalCost | currency : 'GBP' : 'symbol' : '1.0-0' }} / year
            </td>
        </tr>
    </tbody>
</table>

<table class="table table-bordered table-sm table-responsive-sm mt-3">
    <thead>
        <tr>
            <th scope="col" style="width: 33%">Electricity</th>
            <th scope="col" style="width: 33%" class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.typeName }}
            </th>
            <th scope="col" style="width: 33%" class="bg-success text-white text-end">
                {{ rightTariff.typeName }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="bg-light">Supplier</td>
            <td class="text-end" *ngIf="leftTariff">{{ leftTariff?.electricitySupplierName }}</td>
            <td class="text-end text-success">{{ rightTariff.electricitySupplierName }}</td>
        </tr>
        <tr>
            <td class="bg-light">Tariff</td>
            <td class="text-end" *ngIf="leftTariff">{{ leftTariff?.electricityTariffName }}</td>
            <td class="text-end text-success">{{ rightTariff.electricityTariffName }}</td>
        </tr>
        <tr>
            <td class="bg-light">Payment method</td>
            <td class="text-end" *ngIf="leftTariff">{{ leftTariff?.electricityPaymentMethod }}</td>
            <td class="text-end text-success">{{ rightTariff.electricityPaymentMethod }}</td>
        </tr>
        <tr>
            <td *ngIf="!registration.product.hasEco7Meter" class="bg-light">Electricity Price</td>
            <td *ngIf="registration.product.hasEco7Meter" class="bg-light">Electricity Day Price</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.electricityDayPrice * 100 | number : '1.2-2' }} p / kWh
            </td>
            <td class="text-end text-success">
                {{ rightTariff.electricityDayPrice * 100 | number : '1.2-2' }} p / kWh
            </td>
        </tr>
        <tr *ngIf="registration.product.hasEco7Meter">
            <td class="bg-light">Electricity Night Price</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.electricityNightPrice * 100 | number : '1.2-2' }}
                p / kWh
            </td>
            <td class="text-end text-success">
                {{ rightTariff.electricityNightPrice * 100 | number : '1.2-2' }} p / kWh
            </td>
        </tr>
        <tr>
            <td class="bg-light">Standing charge</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.electricityStandingCharge * 100 | number : '1.2-2' }} p / day
            </td>
            <td class="text-end text-success">
                {{ rightTariff.electricityStandingCharge * 100 | number : '1.2-2' }} p / day
            </td>
        </tr>
        <tr *ngIf="leftTariff?.electricityDiscount || rightTariff.electricityDiscount">
            <td class="bg-light">Discounts</td>
            <td *ngIf="leftTariff && leftTariff?.electricityDiscount" class="text-end">
                {{ leftTariff?.electricityDiscount | currency : 'GBP' : 'symbol' : '1.2-2' }} / year
            </td>
            <td *ngIf="!leftTariff?.electricityDiscount" class="text-end">
                {{ 'not-applicable' | cmsText | async }}
            </td>

            <td *ngIf="rightTariff.electricityDiscount" class="text-end text-success">
                {{ rightTariff.electricityDiscount | currency : 'GBP' : 'symbol' : '1.2-2' }} / year
            </td>
            <td *ngIf="!rightTariff.electricityDiscount" class="text-end text-success">
                {{ 'not-applicable' | cmsText | async }}
            </td>
        </tr>
        <tr>
            <td class="bg-light">Annual consumption of electricity</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.electricityConsumption | number : '1.0-2' }} kWh / year
            </td>
            <td class="text-end text-success">
                {{ rightTariff.electricityConsumption | number : '1.0-2' }} kWh / year
            </td>
        </tr>
    </tbody>
    <tfoot class="fw-bold">
        <tr>
            <td>Estimated annual cost of electricity</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.electricityCost | currency : 'GBP' : 'symbol' : '1.0-0' }}
                / year
            </td>
            <td class="text-end text-success">
                {{ rightTariff.electricityCost | currency : 'GBP' : 'symbol' : '1.0-0' }} / year
            </td>
        </tr>
    </tfoot>
</table>

<table
    class="table table-bordered table-sm table-responsive-sm mt-3"
    *ngIf="registration.product.contract != Contracts.Electricity"
>
    <thead>
        <tr>
            <th scope="col" style="width: 33%">Gas</th>
            <th scope="col" style="width: 33%" class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.typeName }}
            </th>
            <th scope="col" style="width: 33%" class="bg-success text-white text-end">
                {{ rightTariff.typeName }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="bg-light">Supplier</td>
            <td class="text-end" *ngIf="leftTariff">{{ leftTariff?.gasSupplierName }}</td>
            <td class="text-end text-success">{{ rightTariff.gasSupplierName }}</td>
        </tr>
        <tr>
            <td class="bg-light">Tariff</td>
            <td class="text-end" *ngIf="leftTariff">{{ leftTariff?.gasTariffName }}</td>
            <td class="text-end text-success">{{ rightTariff.gasTariffName }}</td>
        </tr>
        <tr>
            <td class="bg-light">Payment method</td>
            <td class="text-end" *ngIf="leftTariff">{{ leftTariff?.gasPaymentMethod }}</td>
            <td class="text-end text-success">{{ rightTariff.gasPaymentMethod }}</td>
        </tr>
        <tr>
            <td class="bg-light">Gas</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.gasPrice * 100 | number : '1.2-2' }} p / kWh
            </td>
            <td class="text-end text-success">{{ rightTariff.gasPrice * 100 | number : '1.2-2' }} p / kWh</td>
        </tr>
        <tr>
            <td class="bg-light">Standing charge</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.gasStandingCharge * 100 | number : '1.2-2' }} p / day
            </td>
            <td class="text-end text-success">
                {{ rightTariff.gasStandingCharge * 100 | number : '1.2-2' }} p / day
            </td>
        </tr>
        <tr *ngIf="leftTariff?.gasDiscount || rightTariff.gasDiscount">
            <td class="bg-light">Discounts</td>
            <td *ngIf="leftTariff && leftTariff?.gasDiscount" class="text-end">
                {{ leftTariff?.gasDiscount | currency : 'GBP' : 'symbol' : '1.2-2' }} / year
            </td>
            <td *ngIf="!leftTariff?.gasDiscount" class="text-end">
                {{ 'not-applicable' | cmsText | async }}
            </td>

            <td *ngIf="rightTariff.gasDiscount" class="text-end text-success">
                {{ rightTariff.gasDiscount | currency : 'GBP' : 'symbol' : '1.2-2' }} / year
            </td>
            <td *ngIf="!rightTariff.gasDiscount" class="text-end text-success">
                {{ 'not-applicable' | cmsText | async }}
            </td>
        </tr>
        <tr>
            <td class="bg-light">Annual consumption of gas</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.gasConsumption | number : '1.0-2' }} kWh / year
            </td>
            <td class="text-end text-success">
                {{ rightTariff.gasConsumption | number : '1.0-2' }} kWh / year
            </td>
        </tr>
    </tbody>
    <tfoot class="fw-bold">
        <tr>
            <td>Estimated annual cost of gas</td>
            <td class="text-end" *ngIf="leftTariff">
                {{ leftTariff?.gasCost | currency : 'GBP' : 'symbol' : '1.0-0' }} / year
            </td>
            <td class="text-end text-success">
                {{ rightTariff.gasCost | currency : 'GBP' : 'symbol' : '1.0-0' }} / year
            </td>
        </tr>
    </tfoot>
</table>
