<span *ngIf="kind === 0">{{ value }}</span>
<div *ngIf="kind === 1" [innerHTML]="value"></div>
<img
    *ngIf="kind === 2 && !oldImageValue"
    src="{{ value.src }}"
    class="{{ imgClass || 'img-fluid' }}"
    alt="{{ value.alt }}"
/>
<img
    *ngIf="kind === 2 && oldImageValue"
    src="{{ value }}"
    class="{{ imgClass || 'img-fluid' }}"
    alt="{{ key }}"
/>
