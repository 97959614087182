import { Injectable, Inject } from '@angular/core';
import { HttpUrlComponentCodec } from '@lang/uk/helpers/http-url-component-codec';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { API_URL } from '../../../_common/app/app.config';
import { PaymentMethod } from '../model/registration';
import { FeatureFlagsService } from './feature-flags.service';

export class TariffLookupData {
    name: string;
    fuelType: FuelType;
    contractEndDate?: Date;
    requiresContractEndDate?: boolean;
    economy7?: boolean;

    paperlessBilling: boolean;
    paperBilling: boolean;
    paymentMethods: PaymentMethod[];
}

export class TariffPricesLookupData extends TariffLookupData {
    electricityStandingCharge?: number;
    electricityDayPriceThreshold?: number;
    electricityDayPrice?: number;
    electricityDayPrice2?: number;
    electricityNightPrice?: number;
    electricityDiscount?: number;

    gasStandingCharge?: number;
    gasPriceThreshold?: number;
    gasPrice?: number;
    gasPrice2?: number;
    gasDiscount?: number;
}

export class GetTariffsQuery {
    regionId: number;
    supplierCode: string;
    supplierName: string;
    fuelType: FuelType;
    paymentMethod?: PaymentMethod;
    hasEco7Meter: boolean;
    // paperlessBilling?: boolean;
    renewal?: boolean;
}

export class GetTariffPricesQuery extends GetTariffsQuery {
    tariffName: string;
}

export enum FuelType {
    Electricity = 'Electricity',
    Gas = 'Gas',
    ElectricityAndGas = 'ElectricityAndGas',
}

@Injectable()
export class TariffService {
    protected readonly url: string;

    constructor(
        private http: HttpClient,
        @Inject(API_URL) protected baseUrl: string,
        private featureFlagsService: FeatureFlagsService,
    ) {
        this.url = this.baseUrl + 'tariffs';
    }

    get(query: GetTariffsQuery): Observable<TariffLookupData[]> {
        const headers = new HttpHeaders({
            'X-EnergyClient': this.featureFlagsService.energyClient,
        });
        const params = <
            {
                regionId: string;
                supplierCode: string;
                supplierName: string;
                fuelType: string;
                paymentMethod?: string;
                hasEco7Meter: string;
                renewal?: string;
            }
        >{
            regionId: query.regionId.toString(),
            fuelType: query.fuelType,
            hasEco7Meter: query.hasEco7Meter.toString(),
        };

        if (query.supplierCode) {
            params.supplierCode = query.supplierCode;
        } else if (query.supplierName) {
            params.supplierName = query.supplierName;
        }
        if (query.paymentMethod) {
            params.paymentMethod = query.paymentMethod.toString();
        }
        if (query.renewal) {
            params.renewal = query.renewal.toString();
        }

        return this.http.get<TariffLookupData[]>(this.url, {
            headers,
            params: new HttpParams({
                fromObject: params,
                encoder: new HttpUrlComponentCodec(),
            }),
        });
    }

    getWithPrices(query: GetTariffPricesQuery): Observable<TariffPricesLookupData> {
        const headers = new HttpHeaders({
            'X-EnergyClient': this.featureFlagsService.energyClient,
        });
        const params = <
            {
                regionId: string;
                supplierCode: string;
                supplierName: string;
                fuelType: string;
                paymentMethod?: string;
                hasEco7Meter: string;
                tariffName: string;
            }
        >{
            tariffName: query.tariffName,
            regionId: query.regionId.toString(),
            fuelType: query.fuelType,
            hasEco7Meter: query.hasEco7Meter.toString(),
        };

        if (query.supplierCode) {
            params.supplierCode = query.supplierCode;
        } else if (query.supplierName) {
            params.supplierName = query.supplierName;
        }
        if (query.paymentMethod) {
            params.paymentMethod = query.paymentMethod.toString();
        }

        return this.http.get<TariffPricesLookupData>(`${this.url}/prices`, {
            headers,
            params: new HttpParams({
                fromObject: params,
                encoder: new HttpUrlComponentCodec(),
            }),
        });
    }
}
