import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Community, CommunityType } from '../../../../model/community';
import { PublicityChannel } from '../../../../model/registration';

@Component({
    selector: 'app-person-publicity-channel',
    templateUrl: 'publicity-channel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicityChannelComponent implements ControlValueAccessor {
    private onChanged = [];
    private onTouched = [];
    private publicityChannels = [
        {
            value: PublicityChannel.Unanswered,
            label: 'option-choose-one',
        },
        {
            value: PublicityChannel.Email,
            label: 'publicity-channel-email',
            usedFor: CommunityType.Partner,
        },
        {
            value: PublicityChannel.Website,
            label: 'publicity-channel-website',
            usedFor: CommunityType.Partner,
        },
        {
            value: PublicityChannel.Letter,
            label: 'publicity-channel-letter',
            usedFor: CommunityType.Partner,
        },
        {
            value: PublicityChannel.Magazine,
            label: 'publicity-channel-magazine',
            usedFor: CommunityType.Partner,
        },
        {
            value: PublicityChannel.LetterFromCouncil,
            label: 'publicity-channel-letter-from-council',
            usedFor: CommunityType.Council,
        },
        {
            value: PublicityChannel.LocalNewspaper,
            label: 'publicity-channel-local-newspaper',
            usedFor: CommunityType.Council,
        },
        {
            value: PublicityChannel.CouncilWebsite,
            label: 'publicity-channel-council-website',
            usedFor: CommunityType.Council,
        },
        {
            value: PublicityChannel.CouncilMagazineOrNewspaper,
            label: 'publicity-channel-council-magazine',
            usedFor: CommunityType.Council,
        },
        {
            value: PublicityChannel.CouncilEvent,
            label: 'publicity-channel-council-event',
            usedFor: CommunityType.Council,
        },
        {
            value: PublicityChannel.SearchEngine,
            label: 'publicity-channel-search-engine',
        },
        {
            value: PublicityChannel.WordOfMouth,
            label: 'publicity-channel-word-of-mouth',
        },
        {
            value: PublicityChannel.LeafletOrFlyer,
            label: 'publicity-channel-leaflet-or-flyer',
        },
        {
            value: PublicityChannel.SocialMedia,
            label: 'publicity-channel-social-media',
        },
        {
            value: PublicityChannel.TakenPartBefore,
            label: 'publicity-channel-taken-part-before',
        },
    ];

    public publicityChannel: PublicityChannel;

    @Input() public id: string = null;
    @Input() community: Community;
    @Input() public enabled = true;

    get availableChannels(): { value: PublicityChannel; label: string }[] {
        return this.publicityChannels.filter((c) => !c.usedFor || c.usedFor === this.community.type);
    }

    constructor(private ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    writeValue(publicityChannel: PublicityChannel): void {
        if (!publicityChannel) publicityChannel = PublicityChannel.Unanswered;
        this.publicityChannel = this.publicityChannels.filter(
            (x) => x.value.toLowerCase() === publicityChannel.toLowerCase(),
        )[0].value;
    }
    registerOnChange(fn: any): void {
        this.onChanged.push(fn);
    }
    registerOnTouched(fn: any): void {
        this.onTouched.push(fn);
    }
    setDisabledState?(isDisabled: boolean): void {
        this.enabled = !isDisabled;
    }
    setPublicityChannel(channel: PublicityChannel): void {
        this.publicityChannel = channel;
        this.onChanged.forEach((f) => f(this.publicityChannel));
    }
}
