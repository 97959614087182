import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsEnricherProvider } from '@common/analytics/services/analytics-enricher.service';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { CommandComponent } from '@common/components/command/command.component';
import { AuthService } from '@common/services/auth.service';
import { CommandService } from '@common/services/command.service';
import { ContentService } from '@common/services/content.service';
import { KenalyticsService } from '@common/services/kenalytics.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: '../../../../_common/components/command/command.component.html',
    styleUrls: ['../../../../_common/components/command/command.component.scss'],
})
export class EnukCommandComponent extends CommandComponent implements OnDestroy {
    private authSubscription: Subscription;

    constructor(
        commandService: CommandService,
        route: ActivatedRoute,
        router: Router,
        kenalyticsService: KenalyticsService,
        private authService: AuthService,
        contentService: ContentService,
        analyticsService: AnalyticsService,
        analyticsProvider: AnalyticsEnricherProvider,
    ) {
        super(
            commandService,
            route,
            router,
            kenalyticsService,
            contentService,
            analyticsService,
            analyticsProvider,
        );
        this.authSubscription = this.authService.authEvents$.subscribe((event) => {
            if (event === 'user_changed') {
                this.router.navigate(['user-changed']);
            }
        });
    }

    override ngOnDestroy(): void {
        this.authSubscription.unsubscribe();
    }
}
