import { Directive, ElementRef } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'img' })
export class ImageLazyLoadingDirective extends Directive {
    constructor(private elementRef: ElementRef<HTMLImageElement>) {
        super();

        const img = this.elementRef?.nativeElement;

        // the loading attribute is supported in most browsers (https://caniuse.com/loading-lazy-attr)
        // but it's best to first check anyway
        const supported = 'loading' in HTMLImageElement.prototype;

        if (!img || !supported) return;
        if (img.hasAttribute('loading')) return; //don't change explicitly set loading attributes

        img.setAttribute('loading', 'lazy');
    }
}
