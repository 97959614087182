export class StringHelpers {
    public static convertToKebab(toConvert?: string): string {
        if (!toConvert) return toConvert;
        return toConvert
            .replace(/[^A-Za-z0-9\- ]+/g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();
    }

    public static convertPascalToKebab(toConvert?: string): string {
        if (!toConvert) return toConvert;
        return toConvert
            .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
            .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
            .toLowerCase();
    }

    // Reference: https://stackoverflow.com/a/53952925
    public static convertToPascalCase(value: string): string {
        return `${value}`
            .replace(/[-_]+/g, ' ')
            .replace(/[^\w\s]/g, '')
            .replace(/\s+(.)(\w+)/g, (_$1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
            .replace(/\s/g, '')
            .replace(/\w/, (s) => s.toUpperCase());
    }
}
