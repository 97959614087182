import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ContentService } from '../../services/content.service';
import { Observable, Subscription, of } from 'rxjs';
import { Kind, Content } from '../../model/content';
import { CmsLineBreaker } from '../../infrastructure/cms-line-breaker';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
})
export class ContentComponent implements OnInit, OnDestroy {
    @Input()
    key: string;

    @Input()
    params: any[];

    @Input()
    imgClass: string;

    kind?: Kind;
    value: any;
    private tempValue: string;
    private tokensToReplace = 0;

    oldImageValue: boolean;
    data: Subscription;

    constructor(private contentService: ContentService) {}

    ngOnInit(): void {
        const key = this.key;

        this.data = this.contentService.get(key).subscribe((content) => {
            if (content) {
                this.kind = content.kind;
                if (this.params && content.value) {
                    const regex = /\{\{([\w|\.]+)\}\}/g;
                    this.tempValue = content.value;
                    const match = content.value.match(regex);
                    this.tokensToReplace = match ? match.length : 0;
                    if (this.tokensToReplace === 0) {
                        this.updateValueFromContent(content);
                    } else {
                        content.value.replace(
                            regex,
                            this.contentReplacer.bind(this),
                        );
                    }
                } else {
                    this.updateValueFromContent(content);
                }
            } else {
                this.kind = 0;
                this.value = `{{${key}}}`;
            }

            if (this.contentService.showLinksToCms) {
                this.kind = 0;
                this.addToCmsBar(key, this.value);
                this.value = `{{${key}}}`;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.data) {
            this.data.unsubscribe();
        }
    }

    updateValueFromContent(content: Content) {
        if (content.kind === Kind.Image && !this.isString(content.value)) {
            this.oldImageValue = false;
        } else {
            this.oldImageValue = true;
        }
        this.breakLines(content.value);
    }

    contentReplacer(match, key: string, _offset, _string): void {
        for (const param of this.params) {
            const value = this.resolve(key, param);
            if (value === undefined) {
                continue;
            }

            if (value instanceof Observable) {
                value.subscribe((result) => {
                    this.updateValue(match, result);
                });
                return;
            } else {
                this.updateValue(match, value);
                return;
            }
        }

        this.updateValue(match, `{{${key}}}`);
    }

    private updateValue(match: string, value: string) {
        this.tempValue = this.tempValue.replace(match, value);
        this.tokensToReplace--;
        if (this.tokensToReplace === 0) {
            this.breakLines(this.tempValue);
            this.tempValue = null;
        }
    }

    private resolve(path: string, obj: any): any {
        return path.split('.').reduce(function (prev, curr) {
            return prev ? prev[curr] : undefined;
        }, obj || self);
    }

    private isString(object: any): boolean {
        if (typeof object === 'object') {
            return false;
        }
        return true;
    }

    // input: any because for (kind === Kind.Image && !oldImageValue) it is an object with {src:..., alt:..., } fields
    private breakLines(input: any) {
        if (
            this.kind !== Kind.Image &&
            CmsLineBreaker.containsBreakLineChar(input)
        ) {
            switch (this.kind) {
                case Kind.Text: {
                    this.value = CmsLineBreaker.insertLineBreaks(input);
                    this.kind = Kind.Markdown;
                    break;
                }
                case Kind.Markdown: {
                    this.value = CmsLineBreaker.insertLineBreaks(input);
                    break;
                }
            }
        } else {
            this.value = input;
        }
    }

    private addToCmsBar(key: string, val: string) {
        const url = this.contentService.cmsEditorUrl + key;
        this.contentService.registerVisibleCmsKey({
            key: key,
            url: url,
            val: val,
        });
    }
}
