import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-contact-options',
    templateUrl: './contact-options.component.html',
})
export class ContactOptionsComponent implements OnInit {
    public contactParams = [];

    @Input()
    communityCode: string;

    @Input()
    languageCode: string = null;

    @Input()
    languageCodeAsPrefix: boolean = false;

    @Input()
    contactPhoneCmsKey: string;

    @Input()
    contactPhone: string = null;

    @Input()
    contactPhone2CmsKey: string;

    @Input()
    communityContactPhone$: Observable<string>;

    @Input()
    showTitle = true;

    @Input()
    showIntroduction = false;

    @Input()
    showContactUsLink = true;

    @Input()
    showFaqLink = true;

    @Input()
    communityForumLink: string;

    @Input()
    chatbotLink: string;

    @Input()
    openInNewTab = false;

    @Input()
    faqQueryParams = {};

    @Input()
    contactQueryParams = {};

    @Input()
    showWebinarLink = false;

    @Input()
    webinarLink: string = null;

    @Input()
    useArrow = false;

    @Output()
    analyticsEventEmitter = new EventEmitter<string>();

    get showPhoneLink(): boolean {
        return (
            !!this.contactPhoneCmsKey ||
            !!this.contactPhone ||
            !!this.contactPhone2CmsKey ||
            this.communityContactPhone$ != null
        );
    }

    get faqLink(): Array<string> {
        return this.getLink('faq');
    }

    get contactLink(): Array<string> {
        return this.getLink('contact');
    }

    private getLink(page: string): Array<string> {
        return this.communityCode
            ? this.languageCode
                ? this.languageCodeAsPrefix
                    ? ['/', this.languageCode, this.communityCode, page]
                    : ['/', this.communityCode, this.languageCode, page]
                : ['/', this.communityCode, page]
            : this.languageCode && this.languageCodeAsPrefix
            ? ['/', this.languageCode, page]
            : ['/', page];
    }

    ngOnInit(): void {
        if (this.communityContactPhone$) {
            this.communityContactPhone$.subscribe((cp) => {
                this.contactParams.push({
                    contactPhone: cp,
                });
            });
        }
    }

    analyticsEvent(action: string): void {
        this.analyticsEventEmitter.emit(action);
    }
}
