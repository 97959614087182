<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate appfocusFirstInvalidField>
    <div class="row me-1 ms-1" *ngIf="showSubscriptionBanner()">
        <ngb-alert class="col-12" type="warning" [dismissible]="false">
            {{ 'member-check-failed' | cmsText | async }}
        </ngb-alert>
    </div>

    <div class="row mb-3" formGroupName="contact">
        <div class="col-sm-3">
            <label for="salutation" class="form-label">
                <app-content key="salutation-title"></app-content>
            </label>
            <select class="form-select" id="salutation" name="salutation" formControlName="salutation">
                <option [ngValue]="null" disabled>
                    <app-content key="option-choose-one"></app-content>
                </option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Ms">Ms</option>
                <option value="Mx">Mx</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
                <option value="Rev">Rev</option>
            </select>
            <div *ngIf="salutation.invalid && (salutation.dirty || submitRequested)" class="text-danger">
                <span *ngIf="salutation.errors.required">
                    <app-content key="salutation-error-required"></app-content>
                </span>
            </div>
        </div>
    </div>

    <div class="row mb-3" formGroupName="contact">
        <div class="col-sm-6">
            <label for="firstName" class="form-label">
                <app-content key="first-name-label"></app-content>
                <app-tooltip-enuk
                    cmsKey="first-name-tooltip-body"
                    targetCmsKey="first-name-label"
                ></app-tooltip-enuk>
            </label>
            <input class="form-control" id="firstName" formControlName="firstName" />
            <div *ngIf="firstName.invalid && (firstName.dirty || submitRequested)" class="text-danger">
                <span *ngIf="firstName.errors.required">
                    <app-content key="first-name-error-required"></app-content>
                </span>
                <span *ngIf="firstName.errors.minlength">
                    <app-content key="first-name-error-minlength"></app-content>
                </span>
                <span *ngIf="firstName.errors.maxlength">
                    <app-content key="first-name-error-maxlength"></app-content>
                </span>
                <span *ngIf="firstName.errors.pattern">
                    <app-content key="first-name-error-pattern"></app-content>
                </span>
            </div>
        </div>
        <div class="col-sm-6">
            <label for="lastName" class="form-label">
                <app-content key="surname-label"></app-content>
                <app-tooltip-enuk
                    cmsKey="surname-tooltip-body"
                    targetCmsKey="surname-label"
                ></app-tooltip-enuk>
            </label>
            <input class="form-control" id="lastName" formControlName="lastName" />
            <div *ngIf="lastName.invalid && (lastName.dirty || submitRequested)" class="text-danger">
                <span *ngIf="lastName.errors.required">
                    <app-content key="surname-error-required"></app-content>
                </span>
                <span *ngIf="lastName.errors.maxlength">
                    <app-content key="surname-error-maxlength"></app-content>
                </span>
                <span *ngIf="lastName.errors.pattern">
                    <app-content key="surname-error-pattern"></app-content>
                </span>
            </div>
        </div>
    </div>

    <div formGroupName="contact" class="mb-3">
        <label for="postcode" class="form-label">
            <app-content key="postcode-label"></app-content>
        </label>
        <div>
            <div class="input-group">
                <input
                    class="form-control"
                    id="postcode"
                    name="postcode"
                    type="text"
                    (keydown.enter)="$event.preventDefault()"
                    formControlName="postcode"
                />
            </div>
            <div *ngIf="postcode.invalid && (postcode.dirty || submitRequested)" class="text-danger">
                <span *ngIf="postcode.errors.required">
                    <app-content key="postcode-error-required"></app-content>
                </span>
                <span *ngIf="postcode.errors.pattern">
                    <app-content key="postcode-error-pattern"></app-content>
                </span>
                <div class="row ms-1 me-1 mt-3" *ngIf="postcode.errors.isNorthernIreland">
                    <ngb-alert class="col-12 mb-0" type="danger" [dismissible]="false">
                        {{ 'postcode-error-northern-ireland' | cmsText | async }}
                    </ngb-alert>
                </div>
            </div>
            <div class="text-danger" *ngIf="postcode.valid">
                <span class="text-danger" *ngIf="postCodeError">
                    {{ 'postcode-error-server' | cmsText | async }}
                </span>
                <span class="text-danger" *ngIf="postCodeNotFoundError">
                    {{ 'postcode-not-found-error-server' | cmsText | async }}
                </span>
            </div>
        </div>
        <label for="address" *ngIf="postcode.valid" class="form-label">
            <i class="fa fa-spinner fa-spin fa-2x" *ngIf="postCodeFetching" aria-hidden="true"></i>
            <app-content key="address-label" *ngIf="searchableAddresses"></app-content>
        </label>
        <div>
            <ng-select
                *ngIf="searchableAddresses"
                id="address"
                [items]="searchableAddresses"
                name="address"
                id="address"
                formControlName="address"
                [selectOnTab]="true"
                [searchFn]="searchAddress"
            >
            </ng-select>

            <ng-container *ngIf="address.invalid && (address.dirty || submitRequested)">
                <div *ngIf="address.errors.required" class="text-danger">
                    {{ 'address-error-required' | cmsText | async }}
                </div>
                <div
                    *ngIf="address.errors.notListed"
                    class="alert alert-warning mt-2 no-margin-md-cms"
                    [innerHTML]="'address-error-not-listed' | cmsText | async"
                ></div>
                <div *ngIf="address.errors.mpanInactive" class="text-danger">
                    {{ 'mpan-error-inactive' | cmsText | async }}
                </div>
            </ng-container>
        </div>
    </div>

    <div class="row mb-3" *ngIf="showEmailFields">
        <div class="col-sm-6">
            <label for="email" class="form-label">
                <app-content key="email-label"></app-content>
            </label>
            <input class="form-control" id="email" formControlName="email" type="email" />
            <div *ngIf="email.invalid && (email.touched || submitRequested)" class="text-danger">
                <span *ngIf="email.errors.required">
                    <app-content key="email-error-required"></app-content>
                </span>
                <span *ngIf="!email.errors.required && email.errors.email">
                    <app-content key="email-error-invalid"></app-content>
                </span>
                <span *ngIf="email.errors.maxlength">
                    <app-content key="email-error-maxlength"></app-content>
                </span>
            </div>
        </div>
        <div class="col-sm-6">
            <label for="emailConfirmation" class="form-label">
                <app-content key="email-confirmation-label"></app-content>
            </label>
            <input
                (paste)="(false)"
                class="form-control"
                id="emailConfirmation"
                formControlName="emailConfirmation"
                type="email"
            />

            <div
                *ngIf="emailConfirmation.invalid && (emailConfirmation.dirty || submitRequested)"
                class="text-danger"
            >
                <span *ngIf="emailConfirmation.errors.required">
                    <app-content key="email-confirmation-error-required"></app-content>
                </span>
                <span *ngIf="!emailConfirmation.errors.required && emailConfirmation.errors.sameValueAsEmail">
                    <app-content key="email-confirmation-error-differs"></app-content>
                </span>
            </div>
        </div>
    </div>

    <div class="row mb-3" formGroupName="contact">
        <div class="col-sm-12">
            <label
                class="form-label"
                appOptional
                [message]="'contact-phones-label-super' | cmsText | async"
                >{{ 'contact-phones-label' | cmsText | async }}</label
            >
        </div>
        <p class="ms-3 disclaimer" [innerHTML]="'cell-phone-contact-text' | cmsText | async"></p>
        <div class="col-sm-6 mb-2">
            <label class="normal form-label" for="mobilePhone">
                <app-content key="cell-phone-label"></app-content>
            </label>
            <input class="form-control" id="mobilePhone" formControlName="mobilePhone" />
            <div *ngIf="mobilePhone.invalid && (mobilePhone.dirty || submitRequested)" class="text-danger">
                <span *ngIf="mobilePhone.errors.pattern">
                    <app-content key="cell-phone-error-pattern"></app-content>
                </span>
            </div>
        </div>
        <div class="col-sm-6 mb-2">
            <label class="normal form-label" for="fixedPhone">
                {{ 'phone-label' | cmsText | async }}
            </label>
            <input class="form-control" id="fixedPhone" formControlName="fixedPhone" />
            <ng-container *ngIf="contactForm.invalid">
                <div
                    *ngIf="contactForm.errors && (fixedPhone.dirty || mobilePhone.dirty || submitRequested)"
                    class="text-danger"
                >
                    <span *ngIf="contactForm.errors.phoneRequired">
                        {{ 'phone-error-required' | cmsText | async }}
                    </span>
                </div>
                <div *ngIf="fixedPhone.invalid && (fixedPhone.dirty || submitRequested)" class="text-danger">
                    <span *ngIf="fixedPhone.errors.pattern">
                        {{ 'phone-error-pattern' | cmsText | async }}
                    </span>
                </div>
            </ng-container>
        </div>
        <div
            class="form-check custom-checkbox ms-3"
            *ngIf="mobilePhone.value && mobilePhone.valid"
            id="receiveOfferUpdatesViaSmsSection"
        >
            <input
                type="checkbox"
                class="form-check-input"
                id="receiveOfferUpdatesViaSms"
                formControlName="receiveOfferUpdatesViaSms"
            />
            <label class="form-check-label" for="receiveOfferUpdatesViaSms">
                <span> {{ 'receive-offer-updates-via-sms-label' | cmsText | async }} </span>
            </label>
            <app-tooltip-enuk
                cmsKey="receive-offer-updates-via-sms-tooltip-body"
                targetCmsKey="receive-offer-updates-via-sms-label"
            >
            </app-tooltip-enuk>
        </div>
    </div>

    <div class="row mb-3" formGroupName="contact">
        <div *ngIf="useMembercheck() && communityService.communityCode === 'cssc'" class="col-sm-6">
            <label for="membership" class="form-label">
                <app-content key="membership-label"></app-content>
            </label>
            <div class="input-group">
                <div class="input-group-text bg-light text-dark">Cssc</div>
                <input
                    [(ngModel)]="memberInput"
                    [ngModelOptions]="{ standalone: true }"
                    type="number"
                    class="form-control"
                    (blur)="checkMembership(memberInput)"
                />
                <div *ngIf="!registration.isCommunityMember" class="input-group-text bg-light">
                    <i class="fa fa-times" aria-hidden="true" style="color: red"></i>
                </div>
                <div *ngIf="registration.isCommunityMember" class="input-group-text bg-light">
                    <i class="fa fa-check" aria-hidden="true" style="color: green"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="row ms-1 me-1 mb-3" *ngIf="hasDuplicateRegistrations$ | async">
        <ngb-alert
            class="col-12 mb-0"
            type="warning"
            [dismissible]="false"
            *ngIf="!registration.markedAsDuplicate"
        >
            <i class="fa fa-exclamation-triangle mt-1"></i>
            {{ 'request-send-links-to-duplicates-body' | cmsText | async }}
            <br />
            <button
                class="btn btn-secondary mt-2"
                type="button"
                [disabled]="duplicateLinkRequested"
                (click)="sendLinksToDuplicates()"
            >
                {{ 'request-send-links-to-duplicates-button' | cmsText | async }}
            </button>
        </ngb-alert>
        <ngb-alert
            class="col-12 mb-0"
            type="info"
            [dismissible]="false"
            *ngIf="registration.markedAsDuplicate"
        >
            <i class="fa fa-info-circle mt-1"></i>
            {{ 'status-link-to-duplicates-requested' | cmsText | async }}
        </ngb-alert>
    </div>

    <div class="row ms-1 me-1 mb-3" *ngIf="useMembercheck()">
        <ngb-alert
            class="col-12"
            id="memberCheckFailed"
            *ngIf="registration.isCommunityMember === false"
            type="warning"
            [dismissible]="false"
        >
            {{ 'member-check-failed' | cmsText | async }}
        </ngb-alert>
        <ngb-alert
            class="col-12"
            id="memberCheckOk"
            *ngIf="registration.isCommunityMember && community.type !== CommunityType.Council"
            type="success"
            [dismissible]="false"
        >
            {{ 'member-check-ok' | cmsText | async }}
        </ngb-alert>
    </div>

    <div class="mb-3" formGroupName="contact">
        <div class="row">
            <div class="col-sm-12">
                <label appOptional class="form-label">{{
                    'switching-benefits-label' | cmsText : [cmsSwitchingBenefitParams] | async
                }}</label>
                <app-cms-items-checklist
                    formControlName="switchingBenefits"
                    id="switchingBenefits"
                    cmsPrefix="switching-benefits"
                    [cmsParams]="cmsSwitchingBenefitParams"
                >
                </app-cms-items-checklist>
            </div>
        </div>
    </div>

    <div class="mb-3" formGroupName="contact">
        <app-person-publicity-channel
            [community]="community"
            formControlName="publicityChannel"
            id="publicityChannel"
        >
        </app-person-publicity-channel>
    </div>

    <app-person-can-be-contacted
        [contactForm]="contactForm"
        [community]="community"
        [submitRequested]="submitRequested$"
    >
    </app-person-can-be-contacted>

    <div class="mb-3" formGroupName="contact">
        <div class="form-check custom-checkbox">
            <input
                type="checkbox"
                class="form-check-input"
                id="agreesToConditions"
                formControlName="agreesToConditions"
            />
            <label class="form-check-label" for="agreesToConditions">
                <span [innerHTML]="'terms-conditions-label' | cmsText : [cmsConditionsParams] | async">
                </span>
            </label>
        </div>
        <div
            *ngIf="agreesToConditions.invalid && (agreesToConditions.dirty || submitRequested)"
            class="text-danger"
        >
            <div *ngIf="agreesToConditions.errors.required" id="agreesToConditionsRequired">
                <app-content key="terms-conditions-error-required"></app-content>
            </div>
        </div>
        <div class="form-check custom-checkbox">
            <input
                type="checkbox"
                class="form-check-input"
                id="agreesToPrivacyPolicy"
                formControlName="agreesToPrivacyPolicy"
            />
            <label class="form-check-label" for="agreesToPrivacyPolicy">
                <span [innerHTML]="'privacy-policy-label' | cmsText : [cmsPrivacyParams] | async"> </span>
            </label>
        </div>
        <div
            *ngIf="agreesToPrivacyPolicy.invalid && (agreesToPrivacyPolicy.dirty || submitRequested)"
            class="text-danger"
        >
            <div *ngIf="agreesToPrivacyPolicy.errors.required" id="agreesToPrivacyPolicyRequired">
                <app-content key="privacy-policy-error-required"></app-content>
            </div>
        </div>
    </div>
    <hr class="my-3" />
    <div class="text-end">
        <button
            id="nextButton"
            type="submit"
            class="btn btn-primary px-5"
            [disabled]="submitting && form.valid"
        >
            <i *ngIf="submitting && form.valid" class="fa fa-spinner fa-spin me-1"></i>
            {{ 'save-button' | cmsText | async }}
            <i class="fa fa-chevron-right align-middle ms-2"></i>
        </button>
    </div>
    <!-- <pre>Form value: {{ this.registration | json}}</pre> -->
</form>
