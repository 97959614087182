import { NgModule } from '@angular/core';
import { PlatformLocation, APP_BASE_HREF, CommonModule } from '@angular/common';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ContentComponent } from './components/content/content.component';
import { CommandComponent } from './components/command/command.component';
import { SurveyCtaComponent } from './components/survey-cta/survey-cta.component';
import { FaqHeaderPipe } from './pipes/faqheader.pipe';
import { FaqContentPipe } from './pipes/faqcontent.pipe';
import { CmsTextPipe } from './pipes/cms-text.pipe';
import { CurrencyWithPostfixPipe } from './pipes/currency-with-postfix.pipe';
import { KebabCasePipe } from './pipes/kebab-case.pipe';
import { CmsImageSrcPipe } from './pipes/cms-image-src.pipe';
import { CmsImageAltPipe } from './pipes/cms-image-alt.pipe';
import { ErrorComponent } from './components/error/error.component';
import { CommunityService } from './services/community.service';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { ContactOptionsComponent } from './components/contact-options/contact-options.component';
import { AbsoluteNumberPipe } from './pipes/absolute-number.pipe';
import { CmsKeysBarComponent } from './components/cms-keys-bar/cms-keys-bar.component';
import { FocusFirstInvalidFieldDirective } from './directives/focus-invalid.directive';
import { RouteTransformerDirective } from './directives/route-tranformer.directive';
import { LinkService } from './services/link.service';
import { YouTubePipe } from './pipes/youtube.pipe';
import { HighlightDirective } from './directives/highlight.directive';
import { RouteService } from './infrastructure/params-service';
import { CommandService } from './services/command.service';
import { ParamReplacementPipe } from './pipes/param-replacement-pipe';
import { AppCommonCoreModule } from './app-common-core.module';
import { InputRegexDirective } from './directives/input-regex.directive';
import { DuplicateRegistrationsService } from './services/duplicate-registrations.service';
import { ShufflePipe } from './pipes/shuffle.pipe';
import { AccordionComponent } from './components/accordion/accordion.component';
import { RegisterFlowLocationService } from './services/register-flow-location.service';
import { PendingChangesService } from './services/pending-changes.service';
import { RegisterFlowCalculatorBase } from './services/register-flow-calculator.service.base';
import { MiniFaqComponent } from './components/mini-faq/mini-faq.component';
import { FocusFirstExcludedFieldDirective } from './directives/focus-excluded.directive';
import { ContentTooltipExtComponent } from './components/content-tooltip-ext/content-tooltip-ext.component';
import { RoundToNearestPipe } from './pipes/round-to-nearest.pipe';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import {
    I4pParticipationAssetsService,
    ParticipationAssetsService,
} from './services/participation-assets.service';
import { RegisterFlowLocationAnalyticsPageService } from './services/register-flow-location-analytics-page.service';
import { CmsKeyOptSuffixPipe } from './pipes/cms-key-optional-suffix.pipe';
import { VwoVariantPipe } from './pipes/vwo-variant.pipe';
import { FileShareUrlPipe } from './pipes/file-share-url.pipe';
import { LetDirective } from '@common/directives/ng-let.directive';

@NgModule({
    imports: [AppCommonCoreModule, CommonModule],
    exports: [
        HighlightPipe,
        FaqHeaderPipe,
        FaqContentPipe,
        ParamReplacementPipe,
        CurrencyWithPostfixPipe,
        KebabCasePipe,
        CamelCasePipe,
        RoundToNearestPipe,
        CmsTextPipe,
        CmsImageSrcPipe,
        CmsImageAltPipe,
        CmsKeyOptSuffixPipe,
        YouTubePipe,
        ShufflePipe,
        AbsoluteNumberPipe,
        ContentComponent,
        CommandComponent,
        ErrorComponent,
        CookiePolicyComponent,
        ContactOptionsComponent,
        CmsKeysBarComponent,
        AccordionComponent,
        MiniFaqComponent,
        ContentTooltipExtComponent,
        FocusFirstInvalidFieldDirective,
        FocusFirstExcludedFieldDirective,
        RouteTransformerDirective,
        HighlightDirective,
        InputRegexDirective,
        SurveyCtaComponent,
        VwoVariantPipe,
        FileShareUrlPipe,
        LetDirective,
    ],
    providers: [
        CommunityService,
        RouteService,
        LinkService,
        CommandService,
        DuplicateRegistrationsService,
        RegisterFlowLocationService,
        {
            provide: ParticipationAssetsService,
            useClass: I4pParticipationAssetsService,
        },
        PendingChangesService,
        RegisterFlowCalculatorBase,
        RegisterFlowLocationAnalyticsPageService,
        {
            provide: APP_BASE_HREF,
            useFactory: getBaseHref,
            deps: [PlatformLocation],
        },
    ],
})
export class AppCommonModule {}
export function getBaseHref(platformLocation: PlatformLocation): string {
    return platformLocation.getBaseHrefFromDOM();
}
