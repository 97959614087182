import { Component, OnInit, Input } from '@angular/core';
import { Registration, PaymentMethod, Contracts } from '../../../../model/registration';
import { TariffInformationLabelComponent } from '../tariff-information-label/tariff-information-label.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-proposal-information',
    templateUrl: './proposal-information.component.html',
    styleUrls: ['./proposal-information.component.scss'],
})
export class ProposalInformationComponent implements OnInit {
    @Input()
    registration: Registration;

    @Input()
    paymentMethod: PaymentMethod;

    public get winningTariffSupplierName(): string {
        return this.registration.proposal.winningTariffSupplierName;
    }

    public get wantsToCompareToOwnTariff(): boolean {
        return this.registration.decision.wantsToCompareToOwnTariff;
    }

    public warmHomeDiscountKey: string;

    Contracts: typeof Contracts = Contracts;
    PaymentMethod: typeof PaymentMethod = PaymentMethod;

    constructor(public modalService: NgbModal) {}

    ngOnInit(): void {
        if (
            !this.registration ||
            !this.registration.proposal ||
            !this.registration.proposal.winningTariffSupplierName
        ) {
            throw new Error(
                'Registration.proposal.winningTariffSupplierName cannot be falsy when showing proposal information',
            );
        }
        if (!this.registration.decision) {
            throw new Error('Registration.decision cannot be null when showing proposal information');
        }

        if (this.registration.product.warmHomeDiscount && this.winningTariffSupplierName) {
            const supplierCode = this.winningTariffSupplierName.toLocaleLowerCase().replace(/[^\w]/gi, '-');
            this.warmHomeDiscountKey = `warm-home-discount-${supplierCode}`;
        }
    }

    openTariffComparisonLabel(): void {
        const modalRef = this.modalService.open(TariffInformationLabelComponent, {
            size: 'lg',
            backdrop: 'static',
        });
        const modal = modalRef.componentInstance as TariffInformationLabelComponent;
        modal.registration = this.registration;
    }

    get supplierNameKebabCase(): string {
        return this.registration.proposal.winningTariffSupplierName
            .replace(/[^A-Za-z0-9\- ]+/g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();
    }
}
