import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-survey-banner',
    templateUrl: 'survey-banner.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyBannerComponent {
    @Input() public id: string = null;
    @Input() public cmsPrefix: string = null;
    @Input() public cmsLink: string = null;
    @Input() public cmsParams: { [key: string]: any } = {};
    @Input() isMultiverse: boolean = true;
    @ContentChild('contentTemplate', { static: false })
    public contentTemplate: TemplateRef<any> = null;

    constructor() {}
}


