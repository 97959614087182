import { Injectable } from '@angular/core';

export enum AddressClient {
    Ecoes = 'Ecoes',
    EnergyLinx = 'EnergyLinx',
}

export enum EnergyClient {
    EnergyLinx = 'EnergyLinx',
    EnergyShop = 'EnergyShop',
}

// Temporary services to allow runtime switching between different address and energy data providers using query params
@Injectable()
export class FeatureFlagsService {
    addressClient: string = AddressClient.Ecoes;
    energyClient: string = EnergyClient.EnergyShop;
}
