import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNumberDefault',
})
export class FormatNumberDefaultPipe implements PipeTransform {
    transform(value: number): string {
        if (0 === value) {
            return '0';
        }
        if (!value) {
            return null;
        }

        return new Intl.NumberFormat('en-GB').format(value);
    }
}
