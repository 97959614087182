<ng-container *ngIf="registration">
    <div *ngIf="!registration.product.knowsEnergyConsumption" class="alert alert-primary" role="alert">
        {{ 'status-average-consumption-used-warning' | cmsText | async }}
    </div>

    <ng-container [ngSwitch]="registration.product.contract">
        <div *ngSwitchCase="Contracts.Separate">
            <div class="row">
                <div class="col">{{ 'contract-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.contract }}
                </div>
            </div>

            <h6 class="text-muted fw-bold mt-3">
                {{ 'electricity-title' | cmsText | async }}
            </h6>
            <div class="row">
                <div class="col">{{ 'supplier-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.electricitySupplier }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="
                    registration.product.currentTariff &&
                    registration.product.currentTariff.electricityTariffName
                "
                id="currentElectricityTariff"
            >
                <div class="col">Tariff name:</div>
                <div class="col text-end">
                    {{ registration.product.currentTariff.electricityTariffName }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="
                    registration.product.currentTariff &&
                    registration.product.currentTariff.electricityContractEndDate
                "
                id="currentElectricityContractEndDate"
            >
                <div class="col">Contract end date:</div>
                <div class="col text-end">
                    {{ registration.product.currentTariff.electricityContractEndDate | date }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="registration.product.currentTariff && registration.product.electricityPaymentMethod"
                id="electricityPaymentMethod"
            >
                <div class="col">Payment method:</div>
                <div class="col text-end">
                    {{ registration.product.electricityPaymentMethod }}
                </div>
            </div>
            <div class="row">
                <div class="col">{{ 'consumption-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.electricityConsumption | number : '1.0-2' }}
                    {{ 'unit-kilowatt-hour-per-year' | cmsText | async }}
                </div>
            </div>
            <div class="row">
                <div class="col">{{ 'has-eco-7-meter-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{
                        registration.product.hasEco7Meter
                            ? ('yes' | cmsText | async)
                            : ('no' | cmsText | async)
                    }}
                </div>
            </div>
            <div class="row" *ngIf="registration.product.hasEco7Meter">
                <div class="col">{{ 'night-time-consumption-percentage-label' | cmsText | async }}:</div>
                <div class="col text-end">{{ registration.product.nightTimeConsumptionPercentage }}%</div>
            </div>

            <h6 class="text-muted fw-bold mt-3">
                {{ 'gas-title' | cmsText | async }}
            </h6>
            <div class="row">
                <div class="col">{{ 'supplier-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.gasSupplier }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="registration.product.currentTariff && registration.product.currentTariff.gasTariffName"
                id="currentGasTariff"
            >
                <div class="col">Tariff name:</div>
                <div class="col text-end">
                    {{ registration.product.currentTariff.gasTariffName }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="
                    registration.product.currentTariff &&
                    registration.product.currentTariff.gasContractEndDate
                "
                id="currentGasContractEndDate"
            >
                <div class="col">Contract end date:</div>
                <div class="col text-end">
                    {{ registration.product.currentTariff.gasContractEndDate | date }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="registration.product.currentTariff && registration.product.gasPaymentMethod"
                id="gasPaymentMethod"
            >
                <div class="col">Payment method:</div>
                <div class="col text-end">
                    {{ registration.product.gasPaymentMethod }}
                </div>
            </div>
            <div class="row">
                <div class="col">{{ 'consumption-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.gasConsumption | number : '1.0-2' }}
                    {{ 'unit-kilowatt-hour-per-year' | cmsText | async }}
                </div>
            </div>
        </div>

        <div *ngSwitchCase="Contracts.Dual">
            <div class="row">
                <div class="col">{{ 'supplier-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.dualSupplier }}
                </div>
            </div>
            <div class="row">
                <div class="col">{{ 'contract-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.contract }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="
                    registration.product.currentTariff &&
                    registration.product.currentTariff.electricityTariffName
                "
                id="currentDualFuelTariff"
            >
                <div class="col">Tariff name:</div>
                <div class="col text-end">
                    {{ registration.product.currentTariff.electricityTariffName }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="
                    registration.product.currentTariff &&
                    registration.product.currentTariff.electricityContractEndDate
                "
                id="currentDualFuelContractEndDate"
            >
                <div class="col">Contract end date:</div>
                <div class="col text-end">
                    {{ registration.product.currentTariff.electricityContractEndDate | date }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="registration.product.currentTariff && registration.product.electricityPaymentMethod"
                id="dualPaymentMethod"
            >
                <div class="col">Payment method:</div>
                <div class="col text-end">
                    {{ registration.product.electricityPaymentMethod }}
                </div>
            </div>
            <div class="row">
                <div class="col">Has eco7 meter:</div>
                <div class="col text-end">
                    {{
                        registration.product.hasEco7Meter
                            ? ('yes' | cmsText | async)
                            : ('no' | cmsText | async)
                    }}
                </div>
            </div>
            <div class="row" *ngIf="registration.product.hasEco7Meter">
                <div class="col">{{ 'night-time-consumption-percentage-label' | cmsText | async }}:</div>
                <div class="col text-end">{{ registration.product.nightTimeConsumptionPercentage }}%</div>
            </div>
            <div class="row mt-3">
                <div class="col">{{ 'electricity-consumption-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.electricityConsumption | number : '1.0-2' }}
                    {{ 'unit-kilowatt-hour-per-year' | cmsText | async }}
                </div>
            </div>
            <div class="row">
                <div class="col">{{ 'gas-consumption-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.gasConsumption | number : '1.0-2' }}
                    {{ 'unit-kilowatt-hour-per-year' | cmsText | async }}
                </div>
            </div>
        </div>

        <div *ngSwitchCase="Contracts.Electricity">
            <div class="row">
                <div class="col">{{ 'supplier-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.electricitySupplier }}
                </div>
            </div>
            <div class="row">
                <div class="col">{{ 'contract-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.contract }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="
                    registration.product.currentTariff &&
                    registration.product.currentTariff.electricityTariffName
                "
                id="currentElectricityTariff"
            >
                <div class="col">Tariff name:</div>
                <div class="col text-end">
                    {{ registration.product.currentTariff.electricityTariffName }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="
                    registration.product.currentTariff &&
                    registration.product.currentTariff.electricityContractEndDate
                "
                id="currentElectricityContractEndDate"
            >
                <div class="col">Contract end date:</div>
                <div class="col text-end">
                    {{ registration.product.currentTariff.electricityContractEndDate | date }}
                </div>
            </div>
            <div
                class="row"
                *ngIf="registration.product.currentTariff && registration.product.electricityPaymentMethod"
                id="electricityPaymentMethod"
            >
                <div class="col">Payment method:</div>
                <div class="col text-end">
                    {{ registration.product.electricityPaymentMethod }}
                </div>
            </div>
            <div class="row">
                <div class="col">{{ 'has-eco-7-meter-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{
                        registration.product.hasEco7Meter
                            ? ('yes' | cmsText | async)
                            : ('no' | cmsText | async)
                    }}
                </div>
            </div>
            <div class="row" *ngIf="registration.product.hasEco7Meter">
                <div class="col">{{ 'night-time-consumption-percentage-label' | cmsText | async }}:</div>
                <div class="col text-end">{{ registration.product.nightTimeConsumptionPercentage }}%</div>
            </div>
            <div class="row">
                <div class="col">{{ 'electricity-consumption-label' | cmsText | async }}:</div>
                <div class="col text-end">
                    {{ registration.product.electricityConsumption | number : '1.0-2' }}
                    {{ 'unit-kilowatt-hour-per-year' | cmsText | async }}
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="registration.decisionComplete && registration.proposalAccepted === true" class="mt-3">
        <strong>Your offer</strong>
        <div class="row">
            <div class="col">Supplier:</div>
            <div class="col text-end">
                {{ registration.proposal.winningTariffSupplierName }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">Tariff name:</div>
            <div class="col-8 text-end">
                {{ registration.proposal.winningTariff.electricityTariffName }}
            </div>
        </div>
        <div class="row">
            <div class="col">
                Electricity <span *ngIf="this.registration.product.hasEco7Meter">day</span>price:
            </div>
            <div class="col text-end">
                {{ registration.proposal.winningTariff.electricityDayPrice * 100 | number : '1.2-2' }}
                p / kWh
            </div>
        </div>
        <div class="row" *ngIf="this.registration.product.hasEco7Meter">
            <div class="col">Electricity night price:</div>
            <div class="col text-end">
                {{ registration.proposal.winningTariff.electricityNightPrice * 100 | number : '1.2-2' }}
                p / kWh
            </div>
        </div>
        <div class="row">
            <div class="col">Standing charge:</div>
            <div class="col text-end">
                {{ registration.proposal.winningTariff.electricityStandingCharge * 100 | number : '1.2-2' }}
                p / day
            </div>
        </div>
        <ng-container *ngIf="registration.product.contract !== Contracts.Electricity">
            <div class="row">
                <div class="col">Gas price:</div>
                <div class="col text-end">
                    {{ this.registration.proposal.winningTariff.gasPrice * 100 | number : '1.2-2' }}
                    p / kWh
                </div>
            </div>
            <div class="row">
                <div class="col">Standing charge:</div>
                <div class="col text-end">
                    {{ registration.proposal.winningTariff.gasStandingCharge * 100 | number : '1.2-2' }}
                    p / day
                </div>
            </div>
        </ng-container>
    </div>

    <div
        *ngIf="
            registration.decisionComplete &&
            registration.proposalAccepted === true &&
            registration.decision.wantsToCompareToOwnTariff &&
            !registration.proposal.calculatedPersonalProjection
        "
        class="mt-3"
    >
        <strong>Your current tariff</strong>
        <div class="row">
            <div class="col">
                Electricity <span *ngIf="this.registration.product.hasEco7Meter">day</span>price:
            </div>
            <div class="col text-end">
                {{ registration.product.currentTariff.electricityDayPrice * 100 | number : '1.2-2' }}
                p / kWh
            </div>
        </div>
        <div class="row" *ngIf="this.registration.product.hasEco7Meter">
            <div class="col">Electricity night price:</div>
            <div class="col text-end">
                {{ registration.product.currentTariff.electricityNightPrice * 100 | number : '1.2-2' }}
                p / kWh
            </div>
        </div>
        <div class="row">
            <div class="col">Standing charge:</div>
            <div class="col text-end">
                {{ registration.product.currentTariff.electricityStandingCharge * 100 | number : '1.2-2' }}
                p / day
            </div>
        </div>
        <ng-container *ngIf="registration.product.contract !== Contracts.Electricity">
            <div class="row">
                <div class="col">Gas price:</div>
                <div class="col text-end">
                    {{ this.registration.product.currentTariff.gasPrice * 100 | number : '1.2-2' }}
                    p / kWh
                </div>
            </div>
            <div class="row">
                <div class="col">Standing charge:</div>
                <div class="col text-end">
                    {{ registration.product.currentTariff.gasStandingCharge * 100 | number : '1.2-2' }}
                    p / day
                </div>
            </div>
        </ng-container>
    </div>

    <ng-container
        *ngIf="
            registration.decisionComplete &&
            registration.proposalAccepted === true &&
            registration.decision.wantsToCompareToOwnTariff &&
            registration.proposal.calculatedPersonalProjection
        "
        class="mt-3"
    >
        <div class="mt-3">
            <strong>Your current tariff</strong>
            <div class="row">
                <div class="col">
                    Electricity <span *ngIf="this.registration.product.hasEco7Meter">day</span>price:
                </div>
                <div class="col text-end">
                    {{ registration.product?.currentTariff?.electricityDayPrice * 100 | number : '1.2-2' }}
                    p / kWh
                </div>
            </div>
            <div class="row" *ngIf="this.registration.product.hasEco7Meter">
                <div class="col">Electricity night price:</div>
                <div class="col text-end">
                    {{ registration.product?.currentTariff?.electricityNightPrice * 100 | number : '1.2-2' }}
                    p / kWh
                </div>
            </div>
            <div class="row">
                <div class="col">Standing charge:</div>
                <div class="col text-end">
                    {{
                        registration.product?.currentTariff?.electricityStandingCharge * 100
                            | number : '1.2-2'
                    }}
                    p / day
                </div>
            </div>
            <div class="row">
                <div class="col">Contract end date:</div>
                <div class="col text-end">
                    {{ registration.product?.currentTariff?.electricityContractEndDate | date }}
                </div>
            </div>
            <ng-container *ngIf="registration.product.contract !== Contracts.Electricity">
                <div class="row">
                    <div class="col">Gas price:</div>
                    <div class="col text-end">
                        {{ this.registration.product?.currentTariff?.gasPrice * 100 | number : '1.2-2' }}
                        p / kWh
                    </div>
                </div>
                <div class="row">
                    <div class="col">Standing charge:</div>
                    <div class="col text-end">
                        {{ registration.product?.currentTariff?.gasStandingCharge * 100 | number : '1.2-2' }}
                        p / day
                    </div>
                </div>
                <div class="row">
                    <div class="col">Contract end date:</div>
                    <div class="col text-end">
                        {{ registration.product?.currentTariff?.gasContractEndDate | date }}
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="mt-3">
            <strong class="mt-3">Standard variable tariff</strong>
            <div class="row">
                <div class="col">
                    Electricity <span *ngIf="this.registration.product.hasEco7Meter">day</span>price:
                </div>
                <div class="col text-end">
                    {{
                        registration.proposal.personalProjection?.standardTariff?.electricityDayPrice * 100
                            | number : '1.2-2'
                    }}
                    p / kWh
                </div>
            </div>
            <div class="row" *ngIf="this.registration.product.hasEco7Meter">
                <div class="col">Electricity night price:</div>
                <div class="col text-end">
                    {{
                        registration.proposal.personalProjection?.standardTariff?.electricityNightPrice * 100
                            | number : '1.2-2'
                    }}
                    p / kWh
                </div>
            </div>
            <div class="row">
                <div class="col">Standing charge:</div>
                <div class="col text-end">
                    {{
                        registration.proposal.personalProjection?.standardTariff?.electricityStandingCharge *
                            100 | number : '1.2-2'
                    }}
                    p / day
                </div>
            </div>
            <ng-container *ngIf="registration.product.contract !== Contracts.Electricity">
                <div class="row">
                    <div class="col">Gas price:</div>
                    <div class="col text-end">
                        {{
                            registration.proposal.personalProjection?.standardTariff?.gasPrice * 100
                                | number : '1.2-2'
                        }}
                        p / kWh
                    </div>
                </div>
                <div class="row">
                    <div class="col">Standing charge:</div>
                    <div class="col text-end">
                        {{
                            registration.proposal.personalProjection?.standardTariff?.gasStandingCharge * 100
                                | number : '1.2-2'
                        }}
                        p / day
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <button
        *ngIf="!readonly"
        id="editProductButton"
        class="float-end btn btn-secondary ps-4 pe-4 d-print-none"
        (click)="onEdit()"
    >
        {{ 'edit-button' | cmsText | async }}
    </button>
</ng-container>
