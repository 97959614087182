<ng-template #tooltipContent let-d="dismiss">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="d()"></button>
    </div>
    <div class="modal-body" [innerHtml]="cmsKey | cmsText : [cmsParams] | async"></div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="d()">
            {{ 'tooltip-close-button' | cmsText | async }}
        </button>
    </div>
</ng-template>
<button
    *ngIf="this.isAvailable$ | async"
    type="button"
    class="fa fa-question-circle text-primary tooltip-icon d-print-none"
    tabindex="-1"
    (click)="openModal(tooltipContent)"
></button>
