import { HttpParameterCodec } from '@angular/common/http';

// See https://github.com/angular/angular/issues/11058 and
// proposed fix PR https://github.com/angular/angular/pull/37385
export class HttpUrlComponentCodec implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponentWrapped(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponentWrapped(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

function encodeURIComponentWrapped(value: string): string {
    return value != null ? encodeURIComponent(value).replace(/%20/g, '+') : '';
}
