export class CmsProcessor {
    constructor(private params: any[]) {}

    contentReplacer(_match, _group1, key: string, _offset, _string): string {
        for (const param of this.params) {
            const value = this.resolve(key, param);
            if (value === undefined) {
                continue;
            }
            return value;
        }
        return `{{${key}}}`;
    }
    private resolve(path: string, obj: any): any {
        return path.split('.').reduce(function (prev, curr) {
            return prev ? prev[curr] : undefined;
        }, obj || self);
    }
}
