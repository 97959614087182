import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, DEFAULT_COMMUNITY } from '../app/app.config';
import { Community } from '../model/community';
import { RouteService } from '../infrastructure/params-service';
import { CommunityServiceBase } from '@common/services/community.service.base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CommunityService extends CommunityServiceBase<Community> {
    constructor(
        protected override http: HttpClient,
        @Inject(API_URL) protected override baseUrl: string,
        @Inject(DEFAULT_COMMUNITY) protected override defaultCommunity: string,
        protected override routeService: RouteService,
    ) {
        super(http, baseUrl, defaultCommunity, routeService);
    }

    protected getCommunityInstance(): Community {
        return new Community();
    }

    public generateCommunityLink(link: string, community: Community): string {
        const communityCode = community?.code;

        const addCommunityToLink =
            !!communityCode && !this.communityCode.includes('solar-together');
        if (addCommunityToLink) {
            return `${communityCode}/${link}`;
        } else {
            return link;
        }
    }

    public generateCommunityLink$(
        link: string,
        community$: Observable<Community> = null,
    ): Observable<string> {
        if (!community$) {
            community$ = this.community$;
        }
        return community$.pipe(
            map((community) => this.generateCommunityLink(link, community)),
        );
    }
}
