<section class="fluid-container">
    <div class="container">
        <div class="row mb-3 align-items-center">
            <img
                class="h-25 w-100"
                [attr.src]="'hero-image' | cmsImageSrc | async"
                [attr.alt]="'' | cmsImageAlt | async"
            />
            <div class="position-absolute">
                <h1 class="hero-title">{{ heroCardMessageKey | cmsText | async }}</h1>
            </div>
        </div>
    </div>
</section>

<section class="container">
    <div class="row">
        <div class="col-md-8">
            <form
                *ngIf="showForm"
                class="mt-3"
                [formGroup]="form"
                (ngSubmit)="onSubmit()"
                novalidate
                appfocusFirstInvalidField
            >
                <app-content
                    key="contact-form-summary"
                    [params]="[{ faqUrl: '/' + community?.code + '/faq' }]"
                >
                </app-content>
                <h4 class="mb-3">{{ 'contact-form-title' | cmsText | async }}</h4>
                <div>
                    <div class="row mb-3">
                        <label for="salutation" class="col-sm-3 col-form-label">
                            {{ 'salutation-title' | cmsText | async }}
                        </label>
                        <div class="col-sm-9">
                            <select class="form-select" name="salutation" formControlName="salutation">
                                <option disabled selected value>
                                    {{ 'option-choose-one' | cmsText | async }}
                                </option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Ms">Ms</option>
                                <option value="Mx">Mx</option>
                                <option value="Dr">Dr</option>
                                <option value="Prof">Prof</option>
                                <option value="Rev">Rev</option>
                            </select>
                            <div
                                *ngIf="salutation.invalid && (salutation.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="salutation.errors.required">
                                    {{ 'salutation-error-required' | cmsText | async }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="firstName" class="col-sm-3 col-form-label">
                            {{ 'first-name-label' | cmsText | async }}
                        </label>
                        <div class="col-sm-9">
                            <input class="form-control" id="firstName" formControlName="firstName" />
                            <div
                                *ngIf="firstName.invalid && (firstName.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="firstName.errors.required">
                                    {{ 'first-name-error-required' | cmsText | async }}
                                </span>
                                <span *ngIf="firstName.errors.maxlength">
                                    {{ 'first-name-error-maxlength' | cmsText | async }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="lastName" class="col-form-label col-sm-3">
                            {{ 'surname-label' | cmsText | async }}
                        </label>
                        <div class="col-sm-9">
                            <input class="form-control" id="lastName" formControlName="lastName" />
                            <div
                                *ngIf="lastName.invalid && (lastName.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="lastName.errors.required">
                                    {{ 'surname-error-required' | cmsText | async }}
                                </span>
                                <span *ngIf="lastName.errors.maxlength">
                                    {{ 'surname-error-maxlength' | cmsText | async }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="email" class="col-form-label col-sm-3">
                            {{ 'email-label' | cmsText | async }}
                        </label>
                        <div class="col-sm-9">
                            <input class="form-control" id="email" formControlName="email" />
                            <div
                                *ngIf="email.invalid && (email.touched || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="email.errors.required">
                                    {{ 'email-error-required' | cmsText | async }}
                                </span>
                                <span *ngIf="!email.errors.required && email.errors.pattern">
                                    {{ 'email-error-invalid' | cmsText | async }}
                                </span>
                                <span *ngIf="email.errors.maxlength">
                                    {{ 'email-error-maxlength' | cmsText | async }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="registrationNumber" class="col-form-label col-sm-3">
                            {{ 'registration-number-label' | cmsText | async }}
                        </label>
                        <div class="col-sm-9">
                            <input
                                class="form-control"
                                id="registrationNumber"
                                formControlName="registrationNumber"
                            />
                            <div
                                *ngIf="
                                    registrationNumber.invalid &&
                                    (registrationNumber.dirty || submitRequested)
                                "
                                class="text-danger"
                            >
                                <span *ngIf="registrationNumber.errors.maxlength">
                                    {{ 'registration-number-error-maxlength' | cmsText | async }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="question" class="col-form-label col-sm-3">
                            {{ 'question-label' | cmsText | async }}
                        </label>
                        <div class="col-sm-9">
                            <select class="form-select" name="question" formControlName="question">
                                <option disabled selected value>
                                    {{ 'option-choose-one' | cmsText | async }}
                                </option>
                                <option
                                    *ngFor="let item of questionItems"
                                    [ngValue]="item.id | cmsText | async"
                                >
                                    ..{{ item.id | cmsText | async }}
                                </option>
                            </select>
                            <div
                                *ngIf="question.invalid && (question.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="question.errors.required">
                                    {{ 'question-error-required' | cmsText | async }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="message" class="col-form-label col-sm-3">
                            {{ 'message-label' | cmsText | async }}
                        </label>
                        <div class="col-sm-9">
                            <textarea
                                class="form-control"
                                rows="5"
                                id="message"
                                formControlName="message"
                            ></textarea>
                            <div
                                *ngIf="message.invalid && (message.dirty || submitRequested)"
                                class="text-danger"
                            >
                                <span *ngIf="message.errors.required">
                                    {{ 'message-error-required' | cmsText | async }}
                                </span>
                                <span *ngIf="message.errors.maxlength">
                                    {{ 'message-error-maxlength' | cmsText | async }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-sm-9 offset-sm-3">
                        <div *ngIf="showError" class="alert alert-danger" role="alert">
                            {{ 'submit-contact-form-failed' | cmsText | async }}
                        </div>
                        <button type="submit" class="btn btn-primary px-5 float-end">
                            {{ 'submit-contact-form' | cmsText | async }}
                        </button>
                    </div>
                </div>
            </form>

            <div *ngIf="showSuccess" class="alert alert-success" role="status">
                {{ 'submit-contact-form-succeeded' | cmsText | async }}
            </div>
        </div>

        <div class="col-md-4">
            <button
                class="btn btn-primary btn-lg btn-block"
                [routerLink]="['/' + community?.code + '/register']"
            >
                <app-content key="register-button-text"></app-content>
                &nbsp;<i class="fa fa-chevron-right"></i>
            </button>
            <h4 class="mt-5">
                <app-content key="info-more-information-header"></app-content>
            </h4>
            <hr />
            <ul id="moreInfoList" class="list-group list-group-flush">
                <ng-container *ngFor="let item of infoItems$ | async">
                    <a [routerLink]="'../info/' + item.id" class="list-group-item list-group-item-action">
                        {{ 'info-item-' + item.id + '-header' | cmsText | async }}
                    </a>
                </ng-container>
            </ul>

            <app-contact-options
                [communityCode]="community?.code"
                [showContactUsLink]="false"
                [contactPhone]="community?.contactPhone"
                class="d-flex my-3"
            >
            </app-contact-options>
        </div>
    </div>
</section>
