<ng-container *ngIf="parentBrandCode$ | async; let parentBrandCode">
    <ng-container *ngIf="community$ | async; let community">
        <div class="row">
            <div class="col-md-2 col-xs-12 text-center d-none d-md-block"></div>
            <div class="col-12 col-md-10">
                <div class="row">
                    <div class="col-sm-10 col-9 col-md-12">
                        <div [innerHTML]="'info-item-about-us' | cmsText : [cmsParams] | async"></div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="row"
            id="aboutCommunityRole"
            *ngIf="participationAssetsService.communityIsPromoting$ | async"
        >
            <div class="col-md-2 col-xs-12 text-center d-none d-md-block">
                <img
                    class="ichoosr-sphere img-fluid"
                    [attr.src]="community.code + '-community-logo' | cmsImageSrc | async"
                    [attr.alt]="community.code + '-community-logo' | cmsImageAlt | async"
                    height="80"
                />
            </div>
            <div class="col-12 col-md-10">
                <div class="row">
                    <div class="col-sm-10 col-9 col-md-12">
                        <div
                            [innerHTML]="'info-item-about-us-community' | cmsText : [cmsParams] | async"
                        ></div>
                    </div>
                    <div class="col-sm-2 col-3 d-block d-md-none">
                        <img
                            class="ichoosr-sphere img-fluid"
                            [attr.src]="community.code + '-community-logo' | cmsImageSrc | async"
                            [attr.alt]="community.code + '-community-logo' | cmsImageAlt | async"
                            height="80"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="isBcsOrBlesBrand(parentBrandCode)">
            <div class="col-md-2 col-xs-12 text-center d-none d-md-block">
                <img
                    class="ichoosr-sphere img-fluid"
                    [attr.src]="parentBrandCode + '-brand-logo' | cmsImageSrc | async"
                    [attr.alt]="parentBrandCode + '-brand-logo' | cmsImageAlt | async"
                    height="80"
                />
            </div>
            <div class="col-12 col-md-10">
                <div class="row">
                    <div class="col-sm-10 col-9 col-md-12">
                        <div
                            [innerHTML]="'info-item-about-us-bcs-bles-brand' | cmsText : [cmsParams] | async"
                        ></div>
                    </div>
                    <div class="col-sm-2 col-3 d-block d-md-none">
                        <img
                            class="ichoosr-sphere img-fluid"
                            [attr.src]="parentBrandCode + '-brand-logo' | cmsImageSrc | async"
                            [attr.alt]="parentBrandCode + '-brand-logo' | cmsImageAlt | async"
                            height="80"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2 col-xs-12 text-center d-none d-md-block">
                <img
                    class="ichoosr-sphere img-fluid"
                    [attr.src]="'ichoosr-brand-logo' | cmsImageSrc | async"
                    [attr.alt]="'ichoosr-brand-logo' | cmsImageAlt | async"
                    height="80"
                />
            </div>
            <div class="col-12 col-md-10">
                <div class="row">
                    <div class="col-sm-10 col-9 col-md-12">
                        <div
                            id="infoAllBrands"
                            *ngIf="
                                isBcsOrBlesBrand(parentBrandCode) &&
                                    (participationAssetsService.communityIsPromoting$ | async);
                                else iChoosrBrand
                            "
                            [innerHTML]="
                                'info-item-about-us-bcs-bles-ichoosr-brand' | cmsText : [cmsParams] | async
                            "
                        ></div>
                        <ng-template #iChoosrBrand>
                            <div
                                id="infoIchoosrBrand"
                                [innerHTML]="
                                    'info-item-about-us-ichoosr-brand' | cmsText : [cmsParams] | async
                                "
                            ></div>
                        </ng-template>
                    </div>
                    <div class="col-sm-2 col-3 d-block d-md-none">
                        <img
                            class="ichoosr-sphere img-fluid"
                            [attr.src]="'ichoosr-brand-logo' | cmsImageSrc | async"
                            [attr.alt]="'ichoosr-brand-logo' | cmsImageAlt | async"
                            height="80"
                        />
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
