import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_URL, BRAND_AS_URL_QUERY_PARAMETER } from '@common/app/app.config';
import { RouteService } from '@common/infrastructure/params-service';
import { BrandService } from '@lang/uk/services/brand.service';
import { EnUkCommunityService } from './enuk-community.service';

@Injectable()
export class EnUkBrandService extends BrandService {
    constructor(
        @Inject(API_URL) protected override baseUrl: string,
        protected override http: HttpClient,
        @Inject(BRAND_AS_URL_QUERY_PARAMETER)
        protected brandAsUrlQueryParameter: boolean = true,
        protected routeService: RouteService,
        @Inject(DOCUMENT) protected override document: Document,
        protected communityService: EnUkCommunityService,
        protected override router: Router,
    ) {
        super(baseUrl, http, document, router);

        this.brandsWithoutBrandedCmsKeys.push('ichoosr');
    }
}
