import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../../_common/app/app.config';
import { Observable } from 'rxjs';
import { Supplier } from '../../../_common/model/supplier.base';
import { OfferSupplier } from '@common/model/offersupplier.base';

export class SupplierData extends Supplier {
    isActive: boolean;
    excludedFromRegionsForElectricity?: number[];
    excludedFromRegionsForGas?: number[];
    excludedFromRegionsForDual?: number[];
    ceasedTrading: boolean;
}

@Injectable()
export class SupplierService {
    protected readonly url: string;

    constructor(protected http: HttpClient, @Inject(API_URL) protected baseUrl: string) {
        this.url = this.baseUrl + 'suppliers/';
    }

    getActiveSuppliers(): Observable<SupplierData[]> {
        return this.http.get<SupplierData[]>(this.url + 'active');
    }

    getSupplierByCode(code: string): Observable<SupplierData> {
        return this.http.get<SupplierData>(this.url + 'code/' + code);
    }

    getSupplierOffer(auctionId: string, supplierId: string): Observable<OfferSupplier> {
        const offerSupplierUrl = `${this.url}${supplierId}/offer-supplier-for-auction/${auctionId}`;
        return this.http.get<OfferSupplier>(offerSupplierUrl);
    }
}
