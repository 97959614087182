<div class="d-none d-print-block mb-4">
    <img
        [attr.src]="registration?.community?.code + '-community-logo' | cmsImageSrc | async"
        [attr.alt]="registration?.community?.code + '-community-logo' | cmsImageAlt | async"
        height="70"
        style="max-width: 300px"
    />
    <img class="float-end" src="assets/logo-ichoosr-partner.svg" height="70" alt="ichoosr partner logo" />
</div>

<ng-container *ngIf="registration.proposalMade">
    <div class="row justify-content-center">
        <div class="col px-4">
            <h5>
                {{ 'offer-introduction-title' | cmsText | async }}
            </h5>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-11 col-md-11 col-sm-12">
            <div
                *ngIf="!registration.communicationBySnailMail; else paperIntroductionText"
                class="d-print-none"
                [innerHTML]="'offer-introduction-text' | cmsText : [cmsParams] | async"
            ></div>
            <ng-template #paperIntroductionText>
                <div
                    class="d-print-none"
                    [innerHTML]="'offer-introduction-text-paper' | cmsText : [cmsParams] | async"
                ></div>
            </ng-template>
            <div
                class="d-none d-print-block"
                [innerHTML]="'offer-introduction-text-print' | cmsText : [cmsParams] | async"
            ></div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <button
                id="nextButton"
                type="button"
                class="btn btn-primary px-5 d-print-none"
                (click)="onSubmit()"
                [disabled]="submitting"
            >
                <i *ngIf="submitting" class="fa fa-spinner fa-spin me-1"></i>
                {{ 'offer-introduction-button' | cmsText | async }}
                <i class="fa fa-chevron-right align-middle ms-1"></i>
            </button>
        </div>
    </div>
    <hr class="my-4" />

    <div class="d-print-none">
        <div class="row">
            <div class="col-sm-12 px-4">
                <h5 class="mb-4">{{ 'faq-title' | cmsText | async }}</h5>
                <app-mini-faq #miniFaq category="faq-category-mini-post-auction"></app-mini-faq>
            </div>
        </div>
        <hr class="mt-4 mb-4" />
    </div>
</ng-container>
<div class="row">
    <div class="col-sm-12 px-4">
        <app-collapsible-card titleKey="personal-details-title" [isCollapsed]="false">
            <app-register-personal-details-overview
                [registration]="registration"
                [readonly]="false"
                [showBankDetails]="false"
                (edit)="onNavigateToTab('person')"
            >
            </app-register-personal-details-overview>
        </app-collapsible-card>
    </div>
</div>
<hr class="mt-4 mb-4" />

<div class="row">
    <div class="col-sm-12 px-4">
        <app-collapsible-card titleKey="energy-details-title" [isCollapsed]="false">
            <app-register-energy-details-overview
                [registration]="registration"
                [readonly]="false"
                (edit)="onNavigateToTab('product')"
            >
            </app-register-energy-details-overview>
        </app-collapsible-card>
    </div>
</div>

<div class="d-none d-print-block">
    <hr class="my-4" />
    <div class="row">
        <div class="col-sm-12 px-4">
            <h5 class="mb-4">{{ 'faq-title' | cmsText | async }}</h5>
            <app-mini-faq #miniFaq category="faq-category-mini-post-auction"></app-mini-faq>
        </div>
    </div>
    <hr class="mt-4 mb-4" />
</div>

<div class="row mt-3">
    <div class="col-sm-12 px-4">
        <button class="d-print-none float-end btn btn-secondary" (click)="print()">
            <i class="fa fa-fw fa-print"></i>Print
        </button>
    </div>
</div>
