<div class="modal-header">
    <p class="h5 modal-title">{{ 'proposal-tariff-information-label-title' | cmsText | async }}</p>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table class="table">
            <thead>
                <th></th>
                <th *ngIf="usesElectricity()">{{ 'electricity' | cmsText | async }}</th>
                <th *ngIf="usesGas()">{{ 'gas' | cmsText | async }}</th>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">{{ 'supplier' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">
                        {{ registration.proposal.winningTariffSupplierName }}
                    </td>
                    <td *ngIf="usesGas()">{{ registration.proposal.winningTariffSupplierName }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ 'tariff-name-label' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">
                        {{ registration.proposal.winningTariff.electricityTariffName }}
                    </td>
                    <td *ngIf="usesGas()">{{ registration.proposal.winningTariff.gasTariffName }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ 'tariff-type-label' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">{{ tariffType }}</td>
                    <td *ngIf="usesGas()">{{ tariffType }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ 'payment-method' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">{{ paymentMethod }}</td>
                    <td *ngIf="usesGas()">{{ paymentMethod }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ 'unit-rate' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">
                        {{
                            this.registration.proposal.winningTariff.electricityDayPrice * 100
                                | number : '1.0-2'
                        }}{{ 'price-per-kwh' | cmsText | async }}
                    </td>
                    <td *ngIf="usesGas()">
                        {{ this.registration.proposal.winningTariff.gasPrice * 100 | number : '1.0-2' }}
                        {{ 'price-per-kwh' | cmsText | async }}
                    </td>
                </tr>
                <tr *ngIf="this.registration.product.hasEco7Meter">
                    <th scope="row">{{ 'unit-rate-eco7' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">
                        {{
                            this.registration.proposal.winningTariff.electricityNightPrice * 100
                                | number : '1.0-2'
                        }}{{ 'price-per-kwh' | cmsText | async }}
                    </td>
                    <td *ngIf="usesGas()">{{ 'not-available' | cmsText | async }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ 'standing-charge' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">
                        {{
                            this.registration.proposal.winningTariff.electricityStandingCharge * 100
                                | number : '1.0-2'
                        }}{{ 'price-per-day' | cmsText | async }}
                    </td>
                    <td *ngIf="usesGas()">
                        {{
                            this.registration.proposal.winningTariff.gasStandingCharge * 100
                                | number : '1.0-2'
                        }}{{ 'price-per-day' | cmsText | async }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ 'tariff-end-on-label' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">{{ tariffEnd }}</td>
                    <td *ngIf="usesGas()">{{ tariffEnd }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ 'price-guaranteed-until-label' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">{{ priceGuaranteed }}</td>
                    <td *ngIf="usesGas()">{{ priceGuaranteed }}</td>
                </tr>
                <tr>
                    <th scope="row">
                        {{ 'exit-fees-label' | cmsText | async }}
                    </th>
                    <td *ngIf="usesElectricity()">{{ exitFee }}</td>
                    <td *ngIf="usesGas()">{{ exitFee }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ 'discounts-and-charges-label' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">{{ 'not-applicable' | cmsText | async }}</td>
                    <td *ngIf="usesGas()">{{ 'not-applicable' | cmsText | async }}</td>
                </tr>
                <tr>
                    <th scope="row">{{ 'additional-included-products-services-label' | cmsText | async }}</th>
                    <td *ngIf="usesElectricity()">{{ 'not-applicable' | cmsText | async }}</td>
                    <td *ngIf="usesGas()">{{ 'not-applicable' | cmsText | async }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <app-content key="tariff-information-explanation-text"></app-content>
    <h5>{{ 'what-is-a-kwh-label' | cmsText | async }}</h5>
    <app-content key="what-is-a-kwh-explanation"></app-content>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close(false)">
        {{ 'back-to-proposal-label' | cmsText | async }}
    </button>
</div>
