import { Injectable } from '@angular/core';
import { RegisterFlowLocationService } from '@common/services/register-flow-location.service';
import { StepConfiguration } from '../model/menu-configuration';
import { Registration } from '../model/registration';
@Injectable()
export class StepperHelperService {
    constructor(private flowLocationService: RegisterFlowLocationService) {}

    public getSteps(neededIds: string[], registration: Registration): StepConfiguration[] {
        return [
            {
                id: 'person',
                title: 'person-header',
                titleParams: [],
                description: 'person-description',
                group: 'person',
                showMenu: false,
            },
            {
                id: 'product',
                title: 'energy-title',
                titleParams: [],
                description: 'product-description',
                group: 'product',
                showMenu: false,
            },
            {
                id: 'email-confirmation',
                title: 'email-confirmation-title',
                titleParams: [],
                description: 'email-confirmation-description',
                group: 'email-confirmation',
                showMenu: false,
            },
            {
                id: 'offer-introduction',
                title: 'offer-introduction-header',
                titleParams: [],
                description: 'offer-introduction-description',
                group: 'person',
                showMenu: false,
                showStepCounter: false,
                showPrint: true,
                showStepper: false,
            },
            {
                id: 'proposal',
                title: 'personal-offer-title',
                titleParams: [
                    {
                        fullName: registration?.contact?.firstName + ' ' + registration?.contact?.lastName,
                    },
                    {
                        winningSupplier: registration?.proposal?.winningTariffSupplierName,
                    },
                ],
                description: 'proposal-description',
                group: 'person',
                showMenu: false,
                showStepCounter: false,
                showStepper: false,
            },
            {
                id: 'overview',
                title: 'overview-header',
                titleParams: [],
                description: 'overview-description',
                group: 'person',
                showStepCounter: false,
                showPrint: true,
                showStepper: false,
            },
        ].filter((s) => neededIds == null || neededIds.includes(s.id));
    }

    get selectedStepId(): string {
        return this.flowLocationService.activeStep;
    }

    getSelectedStep(neededIds: string[], registration: Registration): StepConfiguration {
        const neededSteps = this.getSteps(neededIds, registration);
        return !!this.selectedStepId && neededSteps.some((x) => x.id === this.selectedStepId)
            ? neededSteps.filter((x) => x.id === this.selectedStepId)[0]
            : null;
    }

    getStepCount(neededIds: string[]): number {
        return [...new Set(this.getSteps(neededIds, null).map((s) => s.group))].length;
    }

    getStepNumber(neededIds: string[]): number {
        const stepsByGroup = this.getSteps(neededIds, null).reduce(
            (sbg, s) => ({
                ...sbg,
                [s.group]: [...(sbg[s.group] || []), s.id],
            }),
            {},
        );
        return (
            (<string[][]>Object.keys(stepsByGroup).map((x) => stepsByGroup[x])).findIndex((group) =>
                group.includes(this.selectedStepId),
            ) + 1
        );
    }

    getStepIsVisible(stepId: string): boolean {
        return this.getSteps(null, null).filter((step) => step.id === stepId)[0]?.showStepper ?? true;
    }

    getCurrentStepIsVisible(): boolean {
        return this.getSelectedStep(null, null)?.showStepper ?? true;
    }
}
