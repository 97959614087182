import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BrandService } from '@lang/uk/services/brand.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class CommunityOrSubBrandRedirectGuard {
    constructor(private router: Router, private brandService: BrandService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
        const communityCode: string = route.params.community;
        return this.brandService.getActive().pipe(
            take(1),
            map((brand) => {
                const redirectUrl = this.router.createUrlTree(
                    [communityCode, BrandService.isSubBrand(brand, communityCode) ? 'landing' : 'home'],
                    { queryParams: route.queryParams },
                );
                return redirectUrl.toString() === state.url ? true : redirectUrl;
            }),
        );
    }
}
