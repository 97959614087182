<div class="card">
    <div class="card-header" style="cursor: pointer; user-select: none" (click)="isCollapsed = !isCollapsed">
        <span>{{ titleKey | cmsText | async }}</span>
        <div class="float-end">
            <i
                class="fa fa-chevron-circle-up m-1"
                [ngClass]="{ 'fa-chevron-circle-down': isCollapsed, 'fa-chevron-circle-up': !isCollapsed }"
            ></i>
        </div>
    </div>
    <div class="card-body" [ngbCollapse]="isCollapsed">
        <ng-content></ng-content>
    </div>
</div>
