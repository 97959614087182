import { HttpErrorResponse } from '@angular/common/http';
export interface ApiRequest<T> {
    readonly isLoading: boolean;
    readonly data?: T;
    readonly error?: Error | HttpErrorResponse;
}

export const requestSetState = <T>(
    isLoading: boolean,
    data?: T,
    error?: HttpErrorResponse | Error,
): ApiRequest<T> => ({ isLoading, data, error });

export const requestInitialState = <T>(data?: T): ApiRequest<T> => requestSetState(false, data);
export const requestLoadingState = <T>(data?: T): ApiRequest<T> => requestSetState(true, data);
export const requestLoadedState = <T>(data?: T): ApiRequest<T> => requestSetState(false, data);
export const requestErrorState = <T>(error: HttpErrorResponse | Error, data?: T): ApiRequest<T> =>
    requestSetState(false, data, error);
