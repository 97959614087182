import { AfterViewInit, Component } from '@angular/core';
import { VwoService } from '@common/services/vwo.service';
import { RegisterStep } from '../../../model/register-step.enum';
@Component({
    selector: 'app-register-email-confirmation',
    templateUrl: './email-confirmation.component.html',
})
export class RegisterEmailConfirmationComponent implements AfterViewInit {
    constructor(private vwoService: VwoService) {}

    ngAfterViewInit(): void {
        this.vwoService.push(RegisterStep.Proposal);
    }
}
