import { Select } from '@common/util';

const pagetypes = [
    'home',
    'faq',
    'contact',
    'register',
    'proposal',
    'info',
    'policies',
    'landing',
    'tell-a-friend',
    'existing-registration',
    'error',
    'rollover',
    'confirm-email',
    'send-mail',
    'unsubscribe-from-group',
    'announcement',
    'contact-voorkeuren',
    'preferences',
    'tariff-comparison',
    'user-changed',
    'seminar',
    'unauthorized',
    'blog',
] as const;

export type PageType = typeof pagetypes[number];

const isPageType = (segment: string): segment is PageType => pagetypes.includes(segment as any);

const hasKeyword = (str: string, keywords: string[]) => {
    if (!str) return false;
    return keywords.some((x) => str.includes(x));
};

const isPolicyPage = (segment: string): segment is Select<'policies', PageType> => {
    return hasKeyword(segment, [
        'policy',
        'policies',
        'privacy',
        'terms',
        'conditions',
        'cookie',
        'cookies',
        'disclaimer',
    ]);
};

const isProposalPage = (segment: string): segment is Select<'proposal', PageType> => {
    return hasKeyword(segment, ['proposal', 'contract', 'offer']);
};

/** Determines the most fitting page type for the given path */
export const getPageType = (path: string): PageType => {
    const segments = path?.split('/').filter((x) => x);

    const pageType: PageType = segments.filter(isPageType).pop();

    if (!pageType && segments.some(isProposalPage)) return 'proposal';
    if (!pageType && segments.some(isPolicyPage)) return 'policies';

    return pageType;
};
