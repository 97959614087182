import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TariffLookupData } from '@enuk/services/tariff.service';
import { EnergySelectorComponentBase } from '../energy-selector-base/energy-selector-base.component';

@Component({
    selector: 'app-register-energy-tariff-select',
    templateUrl: './tariff-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyTariffSelectComponent extends EnergySelectorComponentBase {
    @Input() public tariffs: TariffLookupData[] = [];
    public tariffName: string = null;

    constructor(ngControl: NgControl) {
        super(ngControl);
    }

    public writeValue(tariffName: string): void {
        this.tariffName = tariffName;
    }

    public setTariff(tariffName: string): void {
        this.tariffName = tariffName;
        this.onChanged.forEach((f) => f(this.tariffName));
    }

    public search(term: string, item: TariffLookupData): boolean {
        return item.name.toLowerCase().replace(/\W/g, '').indexOf(term.replace(/\W/g, '').toLowerCase()) > -1;
    }
}
