export type ProcessStepName =
    | 'product choice'
    | 'personal details'
    | 'personal details - minimal'
    | 'personal details - address'
    | 'product details'
    | `personal details ${number} | ${string}` //substeps
    | `product details ${number} | ${string}` //substeps
    | `personal details ${number} - ${string}` //substeps
    | `product details ${number} - ${string}` //substeps
    | 'registration completed'
    | 'proposal'
    | 'decided'
    | 'interested individual confirmed'
    | 'offer-introduction'
    | 'welcome'
    | 'contract details'
    | 'confirm contract'
    | 'overview'
    | 'overview - completed registration'
    | 'email confirmed'
    | 'confirmation'
    | 'confirm email';

export interface ProcessStep {
    name: ProcessStepName;
    number: number;
}

export const mapToSteps = (names: ProcessStepName[]): ProcessStep[] =>
    names.map<ProcessStep>((name, i) => ({ name, number: i + 1 }));
