import { AppConfig } from '@enuk/model/app-config';

export const environment: AppConfig = {
    production: true,
    apiUri: null,
    identityUri: null,
    customDomainIdentityUris: [],
    googleApiKey: '',
    allowLinksToCms: true,
    analyticsUri: null,
    canAccessLandingPage: true,
    brandAsUrlQueryParameter: true,
    defaultBrand: null,
    participantSecurity: true,
    useProjectsAssets: true,
    aiConnectionString: null,
};
