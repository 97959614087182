import { Component, OnInit, Input } from '@angular/core';
import { Registration, PaymentMethod, Contracts } from '../../../../model/registration';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export class TariffInformationLabel {
    supplier: string;
    tariffName: string;
    tariffType: string;
    paymentMethod: string;
    unitRate: string;
    standingCharge: string;
    tariffEndDate: string;
    priceGuarantyEndDate: string;
    exitFees: string;
    discountsAndCharges: string;
    additionalProducts: string;
}

@Component({
    selector: 'app-tariff-information-label',
    templateUrl: './tariff-information-label.component.html',
    styleUrls: ['./tariff-information-label.component.scss'],
})
export class TariffInformationLabelComponent implements OnInit {
    @Input()
    registration: Registration;

    Contracts: typeof Contracts = Contracts;

    paymentMethod: string;
    tariffType: string;
    exitFee: string;
    priceGuaranteed: string;
    tariffEnd: string;
    PaymentMethods: typeof PaymentMethod = PaymentMethod;
    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {
        this.paymentMethod =
            this.registration.product.hasPrepaymentMeter === true ? 'Prepayment' : 'Monthly Direct Debit';
        this.tariffType = this.registration.product.hasPrepaymentMeter === true ? 'Variable' : 'Fixed';
        if (this.registration.proposal.winningTariffSupplierName === 'Octopus Energy') {
            this.exitFee = 'No exit fees';
        } else if (this.registration.proposal.winningTariffSupplierName === 'So Energy') {
            this.exitFee = '£75.00';
        } else {
            this.exitFee = this.registration.product.hasPrepaymentMeter === true ? 'No exit fees' : '£30.00';
        }
        this.priceGuaranteed =
            this.registration.product.hasPrepaymentMeter === true
                ? 'Not applicable'
                : this.registration.proposal.winningTariffSupplierName === 'Shell Energy'
                ? '31st August 2022 '
                : '12 months from supply start date';
        this.tariffEnd =
            this.registration.product.hasPrepaymentMeter === true
                ? 'This plan has no end date'
                : this.registration.proposal.winningTariffSupplierName === 'Shell Energy'
                ? '31st August 2022 '
                : '12 months from supply start date';
    }

    usesElectricity(): boolean {
        return !!this.registration.product.contract;
    }

    usesGas(): boolean {
        const contract = this.registration.product.contract;
        return contract === Contracts.Dual || contract === Contracts.Separate;
    }
}
