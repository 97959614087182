export class User {
    name: string;
    role: string;
    email: string;

    constructor(userInfo: any) {
        this.name = userInfo.preferred_username || 'Unknown User';
        this.role = userInfo.role;
        this.email = userInfo.email;
    }
}
