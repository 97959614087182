import { Component } from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    templateUrl: 'user-changed.component.html',
})
export class UserChangedComponent {
    get jumbotronStyles$(): Observable<any> {
        return this.contentService.value('hero-image-small').pipe(
            map((heroImageUrl) => {
                return { 'background-image': 'url("' + heroImageUrl + '")' };
            }),
        );
    }

    constructor(private contentService: ContentService) {}
}
