import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class ParticipantStatusGuard {
    constructor(private router: Router) {}
    canActivate(
        next: ActivatedRouteSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const participantStatus = next.queryParamMap.get('redirect');
        if (participantStatus === 'unauthorized') {
            const communityCode = next.params.community;
            return this.router.createUrlTree(['/', communityCode, 'unauthorized']);
        }

        return true;
    }
}
