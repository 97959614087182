import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRoute,
    NavigationEnd,
    Params,
    ParamMap,
} from '@angular/router';
import { filter, map, shareReplay, mergeMap } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';

/*
 * This encapsulates the logic needed to access route parameters outside of a component context
 */
@Injectable()
export class RouteService {
    public aggregatedParams: Observable<Params>;
    public params: Observable<Params>;
    public queryParams: Observable<Params>;
    public activeRoute: Observable<ActivatedRoute>;
    public paramMap: Observable<ParamMap>;

    constructor(private router: Router, private route: ActivatedRoute) {
        const navigationEvent = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            shareReplay(1),
        );

        this.activeRoute = navigationEvent.pipe(
            map(() => this.getCurrentActiveRoute()),
        );

        this.aggregatedParams = navigationEvent.pipe(
            mergeMap(() => this.getAggregatedParams()),
        );

        this.params = navigationEvent.pipe(
            mergeMap(() => this.getCurrentActiveRoute().params),
        );

        this.queryParams = navigationEvent.pipe(
            mergeMap(() => this.getCurrentActiveRoute().queryParams),
        );

        this.paramMap = navigationEvent.pipe(
            mergeMap(() => this.getCurrentActiveRoute().paramMap),
        );
    }

    private getCurrentActiveRoute() {
        let r = this.route;
        while (r.firstChild) {
            r = r.firstChild;
        }
        return r;
    }

    private getAggregatedParams(): Observable<Params> {
        let r = this.route;

        const allParams: Observable<Params>[] = [];
        while (r.firstChild) {
            allParams.push(r.params);
            r = r.firstChild;
        }

        return combineLatest(allParams).pipe(
            map((params) =>
                params.reduce((prev, current) => ({ ...prev, ...current }), {}),
            ),
        );
    }
}
