<div class="text-center mt-4">
    <h1 *ngIf="!id">
        {{ 'route-error-general-title' | cmsText | async }}
    </h1>
    <h1 *ngIf="id == 'region'">
        {{ 'route-error-region-title' | cmsText | async }}
    </h1>
    <h1 *ngIf="id == 'community'">
        {{ 'route-error-community-title' | cmsText | async }}
    </h1>

    <p [innerHTML]="'route-error-general-text' | cmsText | async" *ngIf="!id">
    </p>
    <p [innerHTML]="'route-error-region-text' | cmsText | async" *ngIf="id == 'region'">
    </p>
    <p [innerHTML]="'route-error-community-text' | cmsText | async" *ngIf="id == 'community'">
    </p>
</div>
