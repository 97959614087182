import { Injectable } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { I4pParticipationAssetsService } from '@common/services/participation-assets.service';
import { BrandService } from '@lang/uk/services/brand.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EnUkParticipationAssetsService extends I4pParticipationAssetsService {
    constructor(communityService: CommunityService, private brandService: BrandService) {
        super(communityService);
    }

    public override get brandLogoUrl$(): Observable<string> {
        return this.brandService.activeBrand$.pipe(
            map((brandParticipation) => {
                return brandParticipation.logoImageUri;
            }),
        );
    }

    public override get brandLogoAlt$(): Observable<string> {
        return this.brandService.activeBrand$.pipe(
            map((brandParticipation) => {
                return brandParticipation.logoImageAlt;
            }),
        );
    }

    public override get baseBrandLogoUrl$(): Observable<string> {
        return this.brandService.activeBrand$.pipe(
            map((brandParticipation) => {
                return (brandParticipation.parentBrand ?? brandParticipation).logoImageUri;
            }),
        );
    }

    public override get baseBrandLogoAlt$(): Observable<string> {
        return this.brandService.activeBrand$.pipe(
            map((brandParticipation) => {
                return (brandParticipation.parentBrand ?? brandParticipation).logoImageAlt;
            }),
        );
    }
}
