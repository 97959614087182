import { RegistrationBase } from '@common/model/registration.base';
import { TariffPricesLookupData } from '@enuk/services/tariff.service';
import * as moment from 'moment';
import { DecisionAddress } from './decisionAddress';

export class Contact {
    salutation: string;
    firstName: string;
    lastName: string;
    postcode: string;
    buildingNumber: string;
    buildingName: string;
    subBuilding: string;
    town: string;
    county: string;
    organisation: string;
    dependentThoroughfare?: string;
    thoroughfare?: string;
    dependentLocality?: string;
    doubleDependentLocality?: string;
    regionId: number;
    mpan?: string;
    mprn?: string;
    phone: string;
    mobilePhone: string;
    agreesToConditions?: boolean;
    agreesToPrivacyPolicy?: boolean;
    switchingBenefits?: string[];
    publicityChannel?: PublicityChannel;
    canBeContactedByLetter?: boolean;
    canBeContactedByEmail?: boolean;
    canBeContactedByPhone?: boolean;
    receiveOfferUpdatesViaSms?: boolean;
}

export class Tariff {
    electricitySupplierCode: string;
    electricitySupplierName: string;
    electricityTariffName: string;
    electricityPaymentMethod?: PaymentMethod;
    electricityContractEndDate?: Date;
    electricityEconomy7?: boolean;
    electricityPaperlessBilling?: boolean;

    electricityStandingCharge?: number;
    electricityDayPriceThreshold?: number;
    electricityDayPrice?: number;
    electricityDayPrice2?: number;
    electricityNightPrice?: number;
    electricityDiscount?: number;

    gasSupplierCode: string;
    gasSupplierName: string;
    gasTariffName: string;
    gasPaymentMethod?: PaymentMethod;
    gasContractEndDate?: Date;
    gasPaperlessBilling?: boolean;

    gasStandingCharge?: number;
    gasPriceThreshold?: number;
    gasPrice?: number;
    gasPrice2?: number;
    gasDiscount?: number;

    public setElectricityTariff(
        tariffPrice: TariffPricesLookupData,
        hasEco7Meter: boolean,
        contractEndDate: Date,
        paymentMethod: PaymentMethod,
    ): void {
        this.electricityEconomy7 = hasEco7Meter;

        this.electricityTariffName = tariffPrice.name;
        this.electricityStandingCharge = tariffPrice.electricityStandingCharge;
        this.electricityDayPriceThreshold = tariffPrice.electricityDayPriceThreshold;
        this.electricityDayPrice = tariffPrice.electricityDayPrice;
        this.electricityDayPrice2 = tariffPrice.electricityDayPrice2;
        this.electricityNightPrice = tariffPrice.electricityNightPrice;
        this.electricityDiscount = tariffPrice.electricityDiscount;
        if (contractEndDate && tariffPrice.requiresContractEndDate) {
            this.electricityContractEndDate = contractEndDate;
        } else if (!tariffPrice.requiresContractEndDate && tariffPrice.contractEndDate) {
            const date = moment.utc(tariffPrice.contractEndDate, 'YYYY-MM-DD').toDate();
            this.electricityContractEndDate = new Date(
                Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
            );
        }

        this.electricityPaymentMethod = paymentMethod;
    }

    public setGasTariff(
        tariffPrice: TariffPricesLookupData,
        contractEndDate: Date,
        paymentMethod: PaymentMethod,
    ): void {
        this.gasTariffName = tariffPrice.name;
        this.gasStandingCharge = tariffPrice.gasStandingCharge;
        this.gasPriceThreshold = tariffPrice.gasPriceThreshold;
        this.gasPrice = tariffPrice.gasPrice;
        this.gasPrice2 = tariffPrice.gasPrice2;
        this.gasDiscount = tariffPrice.gasDiscount;
        if (contractEndDate && tariffPrice.requiresContractEndDate) {
            this.gasContractEndDate = contractEndDate;
        } else if (!tariffPrice.requiresContractEndDate && tariffPrice.contractEndDate) {
            const date = moment.utc(tariffPrice.contractEndDate, 'YYYY-MM-DD').toDate();
            this.gasContractEndDate = new Date(
                Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
            );
        }

        this.gasPaymentMethod = paymentMethod;
    }
}

export class Product {
    contract?: Contracts;
    electricitySupplierCode: string;
    electricitySupplier: string;
    actualElectricitySupplier: string;
    gasSupplierCode: string;
    gasSupplier: string;
    actualGasSupplier: string;
    dualSupplierCode: string;
    dualSupplier: string;

    knowsEnergyConsumption?: boolean;
    consumptionProfile?: ConsumptionProfiles;
    electricityConsumption?: number;
    gasConsumption?: number;

    onlineBilling?: boolean;
    warmHomeDiscount?: boolean;
    priorityServiceRegister?: boolean;
    hasPrepaymentMeter?: boolean;
    hasEco7Meter?: boolean;
    nightTimeConsumptionPercentage?: number;
    hasChangedSupplierInLast3Years: boolean;
    currentTariff?: Tariff;
    renewalTariff?: Tariff;
    electricityPaymentMethod?: PaymentMethod;
    gasPaymentMethod?: PaymentMethod;

    constructor() {
        this.currentTariff = new Tariff();
        this.renewalTariff = new Tariff();
    }
}

export class PersonalProjection {
    totalCost?: number;
    saving?: number;
    currentTariffElectricityCost?: number;
    currentTariffGasCost?: number;
    currentTariffTotalCost?: number;
    daysOnCurrentElectricityTariff?: number;
    daysOnCurrentGasTariff?: number;
    standardTariffElectricityCost?: number;
    standardTariffGasCost?: number;
    standardTariffTotalCost?: number;
    daysOnStandardElectricityTariff?: number;
    daysOnStandardGasTariff?: number;
    standardTariff: Tariff;
    constructor() {
        this.standardTariff = new Tariff();
    }
}

export class Proposal {
    winningTariffSupplierName?: string;
    winningTariffSupplierId?: string;
    winningTariff?: Tariff;
    winningTariffElectricityCost?: number;
    winningTariffGasCost?: number;
    winningTariffTotalCost?: number;

    standardTariff?: Tariff;
    standardTariffGasCost?: number;
    standardTariffElectricityCost?: number;
    standardTariffTotalCost?: number;
    standardTariffSaving?: number;

    currentTariffElectricityCost?: number;
    currentTariffGasCost?: number;
    currentTariffTotalCost?: number;
    currentTariffSaving?: number;

    renewalTariffElectricityCost?: number;
    renewalTariffGasCost?: number;
    renewalTariffTotalCost?: number;
    renewalTariffSaving?: number;

    calculatedPersonalProjection?: boolean;
    personalProjection: PersonalProjection;
    waivePeriodStartDate?: Date;

    constructor() {
        this.standardTariff = new Tariff();
        this.winningTariff = new Tariff();
        this.personalProjection = new PersonalProjection();
    }
}

export class Decision {
    salutation?: string;
    wantsToCompareToOwnTariff?: boolean;
    wantsToCompareToRenewalTariff?: boolean;
    selectedComparison?: SelectedComparison;
    paymentMethod?: PaymentMethod;
    birthDate?: Date;
    hasSmartMeter?: boolean;

    employmentStatus?: EmploymentStatus;
    residentialStatus?: ResidentialStatus;
    currentAddressYears?: number;
    currentAddressMonths?: number;
    previousAddressYears?: number;
    previousAddressMonths?: number;
    previousPostcode?: string;
    previousBuildingNumber?: string;
    previousBuildingName?: string;
    previousSubBuilding?: string;
    previousTown?: string;
    previousCounty?: string;
    previousOrganisation?: string;
    previousDependentThoroughfare?: string;
    previousThoroughfare?: string;
    previousDependentLocality?: string;
    previousDoubleDependentLocality?: string;
    additionalRequirements: string[];
    agreesToSupplierSharingInformationWithNetworkOperator?: boolean;
    agreesToSupplierEmailingAccountManagementReminders?: boolean;
    agreesToSupplierEmailingAccountChanges?: boolean;
    supplierContactMethods: SupplierContactMethod[];
    agreesToSupplierCreditCheck?: boolean;
    waitTillWaiveDateToSwitch?: boolean;

    hasSeparateBillingAddress?: boolean;
    allowsPublicity?: boolean;
    agreesToSupplierConditions?: boolean;
    agreesToSupplierCommunicationThirdParty?: boolean;

    firstName?: string;
    lastName?: string;
    accountNumber?: string;
    sortCode?: string;
    preferredPaymentDay?: number;
    authorizedDirectDebit?: boolean;
}

export class Registration extends RegistrationBase {
    contact: Contact;
    product: Product;
    proposal: Proposal;
    decision: Decision;
    previousAddresses: DecisionAddress[];
    isCommunityMember: boolean;
    communityMemberInfo: string;
    billingAddress: DecisionAddress;
    excluded?: boolean;
    excludedReason?: ExcludedReason;

    constructor() {
        super();
        this.contact = new Contact();
        this.product = new Product();
        this.proposal = new Proposal();
        this.decision = new Decision();
        this.billingAddress = new DecisionAddress();
    }

    static clone(registration: Registration) {
        return new RegistrationCopy(registration);
    }
}

export class RegistrationCopy extends Registration {
    constructor(dto: any = {}) {
        super();
        Object.assign(this, dto);
        this.contact = new ContactCopy(dto?.contact);
        this.decision = new DecisionCopy(dto?.decision);
    }
}

export class DecisionCopy extends Decision {
    constructor(dto: any = {}) {
        super();
        Object.assign(this, dto);
    }
}
export class ContactCopy extends Contact {
    constructor(dto: any = {}) {
        super();
        Object.assign(this, dto);
    }
}

export enum Contracts {
    Dual = 'Dual',
    Separate = 'Separate',
    Electricity = 'Electricity',
}

export enum ConsumptionProfiles {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
}

export enum PaymentMethod {
    PrePayment = 'PrePayment',
    MonthlyDirectDebit = 'MonthlyDirectDebit',
    QuarterlyDirectDebit = 'QuarterlyDirectDebit',
    Other = 'Other',
    StandingOrder = 'StandingOrder',
    QuarterlyCashOrCheque = 'QuarterlyCashOrCheque',
    AdvancePayment = 'AdvancePayment',
    MonthlyCard = 'MonthlyCard',
    MonthlyDebitOrCreditCard = 'MonthlyDebitOrCreditCard',
    MonthlyVariableDirectDebit = 'MonthlyVariableDirectDebit',
    QuarterlyEqualPayments = 'QuarterlyEqualPayments',
    MonthlyCashOrCheque = 'MonthlyCashOrCheque',
    RegularCashPayments = 'RegularCashPayments',
    QuarterlyDebitCard = 'QuarterlyDebitCard',
    AnnualAdvanceDirectDebitPayment = 'AnnualAdvanceDirectDebitPayment',
    PayAsYouGo = 'PayAsYouGo',
    PayOnReceiptOfBill = 'PayOnReceiptOfBill',
}

export enum EmploymentStatus {
    EmployedFullTime = 'EmployedFullTime',
    EmployedPartTime = 'EmployedPartTime',
    SelfEmployed = 'SelfEmployed',
    Unemployed = 'Unemployed',
    Retired = 'Retired',
    Homemaker = 'Homemaker',
    Student = 'Student',
}

export enum ResidentialStatus {
    HomeOwner = 'HomeOwner',
    Tenant = 'Tenant',
    Landlord = 'Landlord',
}

export enum SupplierContactMethod {
    Email = 'Email',
    Post = 'Post',
    Phone = 'Phone',
    Sms = 'Sms',
}

export enum PublicityChannel {
    Unanswered = 'Unanswered',
    Email = 'Email',
    Website = 'Website',
    SocialMedia = 'SocialMedia',
    Letter = 'Letter',
    Magazine = 'Magazine',
    LocalNewspaper = 'LocalNewspaper',
    LeafletOrFlyer = 'LeafletOrFlyer',
    LetterFromCouncil = 'LetterFromCouncil',
    WordOfMouth = 'WordOfMouth',
    CouncilWebsite = 'CouncilWebsite',
    CouncilMagazineOrNewspaper = 'CouncilMagazineOrNewspaper',
    CouncilEvent = 'CouncilEvent',
    Roadshow = 'Roadshow',
    SearchEngine = 'SearchEngine',
    TakenPartBefore = 'TakenPartBefore',
}

export enum Brands {
    BigCommunitySwitch = 'big-community-switch',
    BigLondonEnergySwitch = 'big-london-energy-switch',
    Norfolk = 'norfolk',
    Kent = 'kent',
    Essex = 'essex',
    Warwickshire = 'warwickshire',
    iChoosr = 'ichoosr',
}

export enum SelectedComparison {
    Renewal = 'Renewal',
    Standard = 'Standard',
    Current = 'Current',
}

export enum AdditionalRequirementCategory {
    SensoryImpairment = 'Sensory Impairment',
    MedicalEquipment = 'Need for medical equipment',
    PhysiscalMovementImpairement = 'Physical/movement impairment',
    FamilySituation = 'Family situation',
    MentalHealth = 'Mental Health',
    Dementia = 'Dementia',
    Communication = 'Communication',
}

export enum AdditionalRequirmentKeys {
    SensoryImpairmentBlind = 'SensoryImpairmentBlind',
    SensoryImpairmentPartiallySighted = 'SensoryImpairmentPartiallySighted',
    SensoryImpairmentHearingOrSpeech = 'SensoryImpairmentHearingOrSpeech',
    SensoryImpairmentPoorSenseOfSmell = 'SensoryImpairmentPoorSenseOfSmell',
    SensoryImpairmentHearingImpairement = 'SensoryImpairmentHearingImpairement',
    SensoryImpairmentSpeechImpairement = 'SensoryImpairmentSpeechImpairement',
    MedicalEquipmentDialysis = 'MedicalEquipmentDialysis',
    MedicalEquipmentOxygenConcentrator = 'MedicalEquipmentOxygenConcentrator',
    MedicalEquipmentOxygenUse = 'MedicalEquipmentOxygenUse',
    MedicalEquipmentNebuliserAndApnoeaMonitor = 'MedicalEquipmentNebuliserAndApnoeaMonitor',
    MedicalEquipmentHearthLungAndVentilator = 'MedicalEquipmentHearthLungAndVentilator',
    MedicalEquipmentStairliftHoistElectricBed = 'MedicalEquipmentStairliftHoistElectricBed',
    MedicalEquipmentMDEElectricShowering = 'MedicalEquipmentMDEElectricShowering',
    MedicalEquipmentMedicineRefrigeration = 'MedicalEquipmentMedicineRefrigeration',
    MedicalEquipmentCarelineTelecareSystem = 'MedicalEquipmentCarelineTelecareSystem',
    MedicalEquipmentWaterDependent = 'MedicalEquipmentWaterDependent',
    PhysiscalMovementImpairementRestrictedHandMovement = 'PhysiscalMovementImpairementRestrictedHandMovement',
    PhysiscalMovementImpairementChronicSeriousIllness = 'PhysiscalMovementImpairementChronicSeriousIllness',
    PhysiscalMovementImpairementPhysicalImpairment = 'PhysiscalMovementImpairementPhysicalImpairment',
    PhysiscalMovementImpairementUnableToAnswerDoor = 'PhysiscalMovementImpairementUnableToAnswerDoor',
    PhysiscalMovementImpairementAdditionalPresencePreferred = 'PhysiscalMovementImpairementAdditionalPresencePreferred',
    PhysiscalMovementImpairementTempLifeChanges = 'PhysiscalMovementImpairementTempLifeChanges',
    PhysiscalMovementImpairementTempPostHospitalRecovery = 'PhysiscalMovementImpairementTempPostHospitalRecovery',
    FamilySituationPensionableAge = 'FamilySituationPensionableAge',
    FamilySituationYoungChildren = 'FamilySituationYoungChildren',
    FamilySituationTempYoungAdultHouseholder = 'FamilySituationTempYoungAdultHouseholder',
    MentalHealth = 'MentalHealth',
    MentalHealthDevelopmentalCondition = 'MentalHealthDevelopmentalCondition',
    Dementia = 'Dementia',
    CommunicationUnableToCommunicateInEnglish = 'CommunicationUnableToCommunicateInEnglish',
}

export enum ExcludedReason {
    None = 'None',
    OfferExcluded = 'OfferExcluded',
}
