import { Injectable, Inject } from '@angular/core';
import { HttpUrlComponentCodec } from '@lang/uk/helpers/http-url-component-codec';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { API_URL } from '../../../_common/app/app.config';
import { Address } from '../model/address';
import { FeatureFlagsService } from './feature-flags.service';

@Injectable()
export class AddressesService {
    protected readonly url: string;

    constructor(
        private http: HttpClient,
        @Inject(API_URL) protected baseUrl: string,
        private featureFlagsServices: FeatureFlagsService,
    ) {
        this.url = this.baseUrl + 'addresses';
    }

    get(postcode: string): Observable<Address[]> {
        // ToDo @Vanessa: post-EnergyShop clean-up
        const headers = new HttpHeaders({
            'X-AddressClient': this.featureFlagsServices.addressClient,
        });
        return this.http.get<Address[]>(this.url, {
            headers,
            params: new HttpParams({
                fromObject: { postcode },
                encoder: new HttpUrlComponentCodec(),
            }),
        });
    }
}
