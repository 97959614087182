import { KeyValue } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { Address } from '../../../../model/address';
import { DecisionAddress } from '../../../../model/decisionAddress';
import { AddressesService } from '../../../../services/addresses.service';
import { PostcodeValidator } from '../../../../validators/postcode.validator';

enum ServerLookup {
    Idle,
    Busy,
    Error,
    NotFound,
}

@Component({
    selector: 'app-decision-previous-address',
    templateUrl: './previous-address.component.html',
    styleUrls: [],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DecisionPreviousAddressComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DecisionPreviousAddressComponent),
            multi: true,
        },
    ],
})
export class DecisionPreviousAddressComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public decisionAddress: Partial<DecisionAddress> = {};
    @Input() public submitRequested = false;

    @ViewChild('form') public form: NgForm;
    public currentPostcode: string = null;
    public currentAddress: Partial<Address> = null;
    public currentYears?: number = null;
    public currentMonths?: number = null;
    public addressYears: KeyValue<number, string>[] = [
        { key: 0, value: '0' },
        { key: 1, value: '1' },
        { key: 2, value: '2' },
        { key: 3, value: '3' },
        { key: 4, value: '4' },
        { key: 5, value: '5+' },
    ];
    public addressMonths = Array.from({ length: 11 }, (_, i) => i + 1);
    public addressServiceLookup: ServerLookup = ServerLookup.Idle;
    public ServerLookup = ServerLookup;
    public postcodeAddresses: Address[] = [];
    public enabled = true;
    private onChanged = [];
    private onTouched = [];
    private destroyed$ = new Subject<void>();
    private postcode$ = new Subject<string>();

    public static isValidMonth(control: AbstractControl): ValidationErrors | null {
        return control.value == null ? { required: true } : null;
    }

    constructor(private addressesService: AddressesService) {}

    public ngOnInit(): void {
        this.postcode$
            .pipe(
                takeUntil(this.destroyed$),
                filter((postcode) => postcode && PostcodeValidator.isValidPostcode(postcode)),
                map((postcode) => postcode.replace(/ /g, '')),
                distinctUntilChanged(),
                debounceTime(300),
                switchMap((query) => {
                    this.addressServiceLookup = ServerLookup.Busy;
                    this.postcodeAddresses = [];

                    return this.addressesService.get(query).pipe(
                        tap(() => (this.addressServiceLookup = ServerLookup.Idle)),
                        catchError((error: HttpErrorResponse) => {
                            switch (error.status) {
                                case 404:
                                    this.addressServiceLookup = ServerLookup.NotFound;
                                    break;
                                default:
                                    this.addressServiceLookup = ServerLookup.Error;
                                    break;
                            }
                            return of([]);
                        }),
                    );
                }),
            )
            .subscribe((addresses) => {
                this.postcodeAddresses = addresses;
                if (this.decisionAddress) {
                    const theOne = this.postcodeAddresses.find((address) =>
                        Address.equalsPostalData(address, this.decisionAddress as Address),
                    );
                    if (theOne) {
                        this.currentAddress = theOne;
                    }
                }
            });
    }

    public ngAfterViewInit(): void {
        this.form.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(100)).subscribe(() => {
            this.sanitize();

            Object.keys(Address.emptyAddress()).forEach((key) => {
                this.decisionAddress[key] = this.currentAddress ? this.currentAddress[key] : null;
            });
            this.decisionAddress.addressYears = this.currentYears;
            this.decisionAddress.addressMonths = this.currentMonths;

            this.onChanged.forEach((f) => f(this.decisionAddress));
        });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public registerOnChange(fn: any): void {
        this.onChanged.push(fn);
    }

    public registerOnTouched(fn: any): void {
        this.onTouched.push(fn);
    }

    public writeValue(decisionAddress: any): void {
        this.decisionAddress = decisionAddress || {};

        this.currentPostcode = this.decisionAddress.postcode;
        this.currentAddress = null; // Will be set from decisionAddress once the postcode addresses load
        this.currentYears = this.decisionAddress.addressYears;
        this.currentMonths = this.decisionAddress.addressMonths;
        this.sanitize();
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.enabled = !isDisabled;
    }

    public onPostcodeChange(postcode: string): void {
        this.postcode$.next(postcode);
    }

    public sanitize(): void {
        if (this.currentYears === 0 && this.currentMonths === 0) {
            this.currentMonths = null;
        } else if (this.currentYears >= 5) {
            this.currentMonths = 0;
        }
    }

    validate(_c: AbstractControl): ValidationErrors | null {
        if (!this.form) return null;

        return !this.form.valid
            ? {
                  invalidForm: {
                      valid: false,
                      message: 'Previous addresses are invalid',
                  },
              }
            : null;
    }
}
