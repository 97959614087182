/* eslint-disable @typescript-eslint/naming-convention */
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { convertToParamMap, ParamMap, Params } from '@angular/router';
import { PageViewEvent } from '@common/analytics/events/pageview.event';
import { RegistrationStatus } from '@common/analytics/model/registration-status';
import { Page } from '@common/model/data-layer';
import {
    RegistrationBase,
    RegistrationNumber,
} from '@common/model/registration.base';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AnalyticsService } from '../analytics/services/analytics.service';
import { API_URL } from '../app/app.config';
import { AuctionService } from './auction.service';
import { CommunityService } from './community.service';
import { GoogleAnalyticsService } from './google-analytics.service';

export enum Command {
    ConfirmEmail = 'confirm-email',
    Rollover = 'rollover',
    SendMail = 'send-mail',
    UnsubscribeFromGroup = 'unsubscribe-from-group',
}

export interface CommandResult {
    command: Command;
    registrationId: string;
}

@Injectable()
export class CommandService {
    executedCommand: CommandResult;

    constructor(
        private http: HttpClient,
        @Inject(API_URL) private baseUrl: string,
        private analytics: GoogleAnalyticsService,
        private communityService: CommunityService,
        private analyticsService: AnalyticsService,
        @Inject(APP_BASE_HREF) private baseHref: string,
    ) {}

    processCommandWithParams(
        command: Command,
        id: string,
        params: Params,
    ): Observable<CommandResult> {
        const queryParams = convertToParamMap(params);
        return this.processCommand(command, id, queryParams, false);
    }

    processCommand(
        command: Command,
        id: string,
        queryParams: ParamMap,
        logPageView = true,
    ): Observable<CommandResult> {
        if (this.executedCommand && this.executedCommand.command === command) {
            // do not execute a command twice (e.g., when the user hits the back button)
            return of(this.executedCommand);
        }

        let response: Observable<string>;

        if (command === Command.ConfirmEmail) {
            response = this.ConfirmEmail(id, queryParams.get('token'));
        } else if (command === Command.Rollover) {
            response = this.Rollover(id);
            logPageView = false; //we will send the pageview manually after the rollover completes
        } else if (command === Command.SendMail) {
            response = this.SendMail(id, queryParams.get('trigger'));
        } else if (command === Command.UnsubscribeFromGroup) {
            response = this.unsubscribeFromGroup(
                id,
                queryParams.get('unsubscribeGroup'),
            );
        }

        if (logPageView) {
            this.logPageView(command);
        }

        return response.pipe(
            map((newId) => {
                this.executedCommand = {
                    command,
                    registrationId: newId,
                };
                return this.executedCommand;
            }),
        );
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static forRegistration<T extends RegistrationBase>(
        registration: T,
        { path }: Page | { path: string },
    ): PageViewEvent {
        if (!registration) return PageViewEvent.create({ path });

        return new PageViewEvent({
            page_path: path,
            registration_number: registration.number as RegistrationNumber,
            registration_status:
                registration.status.toLowerCase() as RegistrationStatus,
            community: registration.community?.code,
            auction_code: AuctionService.getAnyAuction(
                registration as RegistrationBase,
                registration.community,
            )?.code,
        });
    }

    logPageView<T extends RegistrationBase = RegistrationBase>(
        command: Command,
        registration: T = null,
    ): void {
        const path = `/${this.communityService.communityCode}/do/${command}`;

        //GA3
        this.analytics.pageView({ path });

        //GA4
        const event = CommandService.forRegistration(registration, { path });
        this.analyticsService.push(event);
    }

    public extractCommandAndId(paramMap: ParamMap): {
        command: Command;
        id: string;
    } {
        const id = paramMap.get('id');
        if (!id) {
            throw new Error("Parameter 'id' is missing.");
        }

        const mayBeCommand = paramMap.get('command');

        let command: Command;
        switch (mayBeCommand) {
            case 'confirm':
                command = Command.ConfirmEmail;
                break;
            case 'rollover':
                command = Command.Rollover;
                break;
            case 'send-mail':
                command = Command.SendMail;
                break;
            case 'unsubscribe':
                command = Command.UnsubscribeFromGroup;
                break;
            default:
                throw new Error('Unknown command: ' + mayBeCommand);
        }

        return { command, id };
    }

    private Rollover(id: string): Observable<string> {
        return this.SendCommand(
            'rollover',
            id,
            'participate-in-current-auction',
        );
    }

    private SendMail(id: string, trigger: string): Observable<string> {
        if (trigger === 'tell-a-friend') {
            return this.SendCommand('send-mail', id, 'send-mail', {
                trigger: 'TellAFriend',
            });
        }

        if (trigger === 'ean-completed') {
            return this.SendCommand('send-mail', id, 'send-mail', {
                trigger: 'EanCompleted',
            });
        }

        throw new Error('Unknown trigger: ' + trigger);
    }

    private ConfirmEmail(id: string, token: string): Observable<string> {
        return this.SendCommand('confirm-email', id, 'confirm-email', {
            token,
        });
    }

    unsubscribeFromGroup(
        id: string,
        _unsubscribeGroup: string,
    ): Observable<string> {
        return of(id);
    }

    private SendCommand(
        command: string,
        id: string,
        path: string,
        body?: any,
    ): Observable<string> {
        const request = this.http
            .post<string>(`${this.baseUrl}registrations/${id}/${path}`, body)
            .pipe(
                shareReplay(1),
                map((newId) => newId || id), // if the command resulted in returning a (new) id, we continue with that one
                tap(
                    (registrationId) => {
                        this.notify(command, true, registrationId);
                    },
                    () => {
                        this.notify(command, false);
                    },
                ),
            );

        return request;
    }

    private notify(command: string, success: boolean, registrationId?: string) {
        this.analytics.event(
            { path: `/${this.communityService.communityCode}/do/${command}` },
            {
                category: 'command',
                action: command,
                label: success ? 'success' : 'failed',
            },
            registrationId ? { id: registrationId } : null,
        );
    }
}
