/* eslint-disable @typescript-eslint/naming-convention */
import { AnalyticsEvent, AnalyticsEventTemplate, ProcessEventName } from './analytics.event';
import { FlowPhase } from '@common/analytics/model/flow-phase';
import { FlowStart } from '@common/analytics/model/flow-start';
import { mapToSteps, ProcessStep, ProcessStepName } from '../model/process-step';

/** Event parameters that can be calculated from common */
export type CommonProcessEventParams = Pick<ProcessEvent, 'process_flow_start' | 'process_flow_phase'>;

type Template = Omit<AnalyticsEventTemplate<ProcessEvent>, keyof CommonProcessEventParams>;

export class ProcessEvent implements AnalyticsEvent {
    event: ProcessEventName;

    process_flow_phase: FlowPhase;
    process_flow_start: FlowStart;
    process_stepname: ProcessStepName;
    process_stepnumber: number;
    process_substep?: string;

    constructor(name: ProcessEventName, template: Template = {}) {
        this.event = name;
        Object.assign(this, template);
    }

    static create(...args: ConstructorParameters<typeof ProcessEvent>): ProcessEvent {
        return new ProcessEvent(...args);
    }

    static fromObject(e: AnalyticsEvent): ProcessEvent {
        return new ProcessEvent(e.event as ProcessEventName, e as unknown);
    }

    static getEventsFromStepName(stepName: ProcessStepName, allStepNames: ProcessStepName[]): ProcessEvent[] {
        if (!stepName || !allStepNames) return [];

        const steps = mapToSteps(allStepNames);
        const step = steps?.find(({ name }) => name === stepName);

        if (!step) return [];

        const eventName = this.getEventName(step, steps.length);

        const baseEvent = ProcessEvent.create(eventName, {
            process_stepname: step.name,
            process_stepnumber: step.number,
        });

        if (eventName === 'process_interaction') {
            return [baseEvent];
        } else {
            // the process_start and process_finished events always need a simultaneous process_interaction event
            return [baseEvent, ProcessEvent.fromObject({ ...baseEvent, event: 'process_interaction' })];
        }
    }

    private static getEventName(step: ProcessStep, amountOfSteps: number): ProcessEventName {
        switch (step.number) {
            case 1:
                return 'process_start';
            case amountOfSteps:
                return 'process_finished';
            default:
                return 'process_interaction';
        }
    }
}
