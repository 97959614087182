import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactComponentBase } from '@common/components/contact/contact.component.base';
import { InfoItem } from '@common/components/info/info.component.base';
import { ControlWithErrors } from '@common/infrastructure/form-tools';
import { ContactFormBase } from '@common/model/contact-form.base';
import { CommunityService } from '@common/services/community.service';
import { ContactService } from '@common/services/contact.service';
import { ContentService } from '@common/services/content.service';
import { GoogleAnalyticsService } from '@common/services/google-analytics.service';
import { Registration } from '@enuk/model/registration';
import { InfoItemService } from '@enuk/services/info-item.service';
import { RegistrationService } from '@enuk/services/registration.service';
import { BrandService } from '@lang/uk/services/brand.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { ContactFormSubmitEvent } from '@common/analytics/events/contactform-submit.event';

const formGroup = {
    salutation: ['', [Validators.required]],
    firstName: ['', [Validators.required, Validators.maxLength(100)]],
    lastName: ['', [Validators.required, Validators.maxLength(100)]],
};

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
})
export class ContactComponent
    extends ContactComponentBase<ContactFormBase, RegistrationService, Registration>
    implements OnInit
{
    infoItems$: Observable<InfoItem[]> = this.infoItemService.moreInformationItems$;

    questionItems = [
        { id: 'contact-subject-change-data' },
        { id: 'contact-subject-registration' },
        { id: 'contact-subject-proposal' },
        { id: 'contact-subject-new-contract' },
        { id: 'contact-subject-unsubscribe' },
        { id: 'contact-subject-callback-prepayment' },
        { id: 'contact-subject-other' },
    ];
    override registration: Registration;
    currentUrl: string; //Added for testing convenience, so that router.url doesn't have to be mocked.

    public get firstName(): ControlWithErrors {
        return this.form.get('firstName');
    }
    public get lastName(): ControlWithErrors {
        return this.form.get('lastName');
    }
    public get salutation(): ControlWithErrors {
        return this.form.get('salutation');
    }

    constructor(
        protected override content: ContentService,
        protected override communityService: CommunityService,
        protected override contactService: ContactService<ContactFormBase>,
        protected override analytics: GoogleAnalyticsService,
        protected override registrationService: RegistrationService,
        protected infoItemService: InfoItemService,
        fb: UntypedFormBuilder,
        protected brandService: BrandService,
        protected router: Router,
        private analyticsService: AnalyticsService,
    ) {
        super(content, communityService, contactService, analytics, fb, formGroup, registrationService);
        this.currentUrl = this.router.url;
    }

    prepareContactFormBase(): ContactFormBase {
        const form = new ContactFormBase();
        form.senderName = this.firstName.value + ' ' + this.lastName.value;
        return form;
    }

    override prefillRegistration(): void {
        super.prefillRegistration();
        this.firstName.setValue(this.registration.contact.firstName);
        this.lastName.setValue(this.registration.contact.lastName);
        this.email.setValue(this.registration.email);
        this.salutation.setValue(this.registration.contact.salutation);
    }

    override ngOnInit(): void {
        super.ngOnInit();

        //Overwrite community by brand community for url {{domainname}}/contact
        if (this.currentUrl.startsWith('/contact')) {
            this.getCommunityFromBrand();
        }
    }

    getCommunityFromBrand(): void {
        this.brandService
            .getActive()
            .pipe(
                filter((brand) => !!brand),
                switchMap((activeBrand) => {
                    let communityCodeLookup;
                    if (activeBrand.parentBrand) {
                        //If active brand has a parent brand, use this as the community instead
                        communityCodeLookup = activeBrand.parentBrand.code;
                    } else {
                        communityCodeLookup = activeBrand.code;
                    }
                    return this.communityService.fetchCommunity({ community: communityCodeLookup });
                }),
                catchError(() => {
                    return of(null);
                }),
            )
            .subscribe((activeCommunity) => {
                this.community = activeCommunity;
            });
    }

    // eslint-disable-next-line prettier/prettier
    override sendAnalyticsEvent(): void {
        const event = ContactFormSubmitEvent.fromForm(this.form);
        this.analyticsService.push(event);
    }
}
