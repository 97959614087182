import { CmsToGoogleAnalyticsEventDirective } from './directives/cms-ga-event.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ContentComponent } from './components/content/content.component';
import { CommandComponent } from './components/command/command.component';
import { FaqHeaderPipe } from './pipes/faqheader.pipe';
import { FaqContentPipe } from './pipes/faqcontent.pipe';
import { RouterModule } from '@angular/router';
import { CmsTextPipe } from './pipes/cms-text.pipe';
import { CurrencyWithPostfixPipe } from './pipes/currency-with-postfix.pipe';
import { CmsImageSrcPipe } from './pipes/cms-image-src.pipe';
import { CmsImageAltPipe } from './pipes/cms-image-alt.pipe';
import { ErrorComponent } from './components/error/error.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { ContactOptionsComponent } from './components/contact-options/contact-options.component';
import { AbsoluteNumberPipe } from './pipes/absolute-number.pipe';
import { CmsKeysBarComponent } from './components/cms-keys-bar/cms-keys-bar.component';
import { FocusFirstInvalidFieldDirective } from './directives/focus-invalid.directive';
import { RouteTransformerDirective } from './directives/route-tranformer.directive';
import { YouTubePipe } from './pipes/youtube.pipe';
import { HighlightDirective } from './directives/highlight.directive';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { ParamReplacementPipe } from './pipes/param-replacement-pipe';
import { InputRegexDirective } from './directives/input-regex.directive';
import { GoogleAnalyticsEventDirective } from './directives/ga-event.directive';
import { ShufflePipe } from './pipes/shuffle.pipe';
import { AccordionComponent } from './components/accordion/accordion.component';
import { YoutubeMovieDirective } from './directives/youtube.movie.directive';
import { MiniFaqComponent } from './components/mini-faq/mini-faq.component';
import { ImageViewerDirective } from './directives/imageviewer.directive';
import { FocusFirstExcludedFieldDirective } from './directives/focus-excluded.directive';
import { KebabCasePipe } from './pipes/kebab-case.pipe';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { RoundToNearestPipe } from './pipes/round-to-nearest.pipe';
import { JpTextPipe } from '@common/pipes/jp-text.pipe';
import { ContentTooltipExtComponent } from './components/content-tooltip-ext/content-tooltip-ext.component';
import { EncodeURIComponentPipe } from './pipes/encode-url-component.pipe';
import { SocialnetworkIconsComponent } from './components/socialnetwork-icons/socialnetwork-icons.component';
import { FitChildrenHeightDirective } from './directives/fit-children-height.directive';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { VwoCapturerComponent } from './components/vwo/vwo-capturer.component';
import { VwoVariantPipe } from './pipes/vwo-variant.pipe';
import { SurveyCtaComponent } from './components/survey-cta/survey-cta.component';
import { CmsKeyOptSuffixPipe } from './pipes/cms-key-optional-suffix.pipe';
import { FeedbackSurveyComponent } from '@common/components/feedback-survey/feedback-survey.component';
import { ClickEventDirective } from './analytics/directives/click-event.directive';
import { ProposalInteractionEventDirective } from './analytics/directives/proposal-interaction-event.directive';
import { FileShareUrlPipe } from './pipes/file-share-url.pipe';
import { ImageLazyLoadingDirective } from './directives/image-lazy-loading.directive';
import { ContainsYoutubeDirective } from './directives/contains-youtube.directive';
import { ShadowDomEncapsulateComponent } from './components/shadow-dom-encapsulate/shadow-dom-encapsulate.component';
import { LetDirective } from '@common/directives/ng-let.directive';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
    ],
    declarations: [
        HighlightPipe,
        FaqHeaderPipe,
        FaqContentPipe,
        AbsoluteNumberPipe,
        EncodeURIComponentPipe,
        ParamReplacementPipe,
        CurrencyWithPostfixPipe,
        KebabCasePipe,
        CamelCasePipe,
        RoundToNearestPipe,
        CmsTextPipe,
        CmsImageSrcPipe,
        CmsImageAltPipe,
        CmsKeyOptSuffixPipe,
        YouTubePipe,
        ShufflePipe,
        ContentComponent,
        CommandComponent,
        ErrorComponent,
        MiniFaqComponent,
        CookiePolicyComponent,
        ContactOptionsComponent,
        CmsKeysBarComponent,
        AccordionComponent,
        FocusFirstInvalidFieldDirective,
        FocusFirstExcludedFieldDirective,
        RouteTransformerDirective,
        HighlightDirective,
        InputRegexDirective,
        GoogleAnalyticsEventDirective,
        ClickEventDirective,
        ProposalInteractionEventDirective,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        DisclaimerComponent,
        YoutubeMovieDirective,
        ContainsYoutubeDirective,
        ImageViewerDirective,
        JpTextPipe,
        ContentTooltipExtComponent,
        TooltipComponent,
        SocialnetworkIconsComponent,
        CmsToGoogleAnalyticsEventDirective,
        FitChildrenHeightDirective,
        VwoCapturerComponent,
        VwoVariantPipe,
        SurveyCtaComponent,
        FeedbackSurveyComponent,
        FileShareUrlPipe,
        ImageLazyLoadingDirective,
        ShadowDomEncapsulateComponent,
        LetDirective,
    ],
    exports: [
        HighlightPipe,
        FaqHeaderPipe,
        FaqContentPipe,
        ParamReplacementPipe,
        CurrencyWithPostfixPipe,
        KebabCasePipe,
        CamelCasePipe,
        RoundToNearestPipe,
        CmsTextPipe,
        CmsImageSrcPipe,
        CmsImageAltPipe,
        CmsKeyOptSuffixPipe,
        YouTubePipe,
        AbsoluteNumberPipe,
        EncodeURIComponentPipe,
        ShufflePipe,
        ContentComponent,
        CommandComponent,
        ErrorComponent,
        MiniFaqComponent,
        CookiePolicyComponent,
        ContactOptionsComponent,
        CmsKeysBarComponent,
        AccordionComponent,
        FocusFirstInvalidFieldDirective,
        FocusFirstExcludedFieldDirective,
        RouteTransformerDirective,
        HighlightDirective,
        InputRegexDirective,
        GoogleAnalyticsEventDirective,
        ClickEventDirective,
        ProposalInteractionEventDirective,
        YoutubeMovieDirective,
        ContainsYoutubeDirective,
        ImageViewerDirective,
        JpTextPipe,
        ContentTooltipExtComponent,
        TooltipComponent,
        SocialnetworkIconsComponent,
        CmsToGoogleAnalyticsEventDirective,
        FitChildrenHeightDirective,
        VwoCapturerComponent,
        VwoVariantPipe,
        SurveyCtaComponent,
        FeedbackSurveyComponent,
        FileShareUrlPipe,
        ImageLazyLoadingDirective,
        ShadowDomEncapsulateComponent,
        LetDirective,
    ],
})
export class AppCommonCoreModule {}
