/* eslint-disable @typescript-eslint/naming-convention */
import {
    AfterViewInit,
    Directive,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    Subscription,
    Observable,
    of,
    Subject,
    combineLatest,
    merge,
    BehaviorSubject,
    forkJoin,
} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../services/content.service';
import {
    map,
    debounceTime,
    distinctUntilChanged,
    tap,
    filter,
    take,
    mergeMap,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { NgbAccordion, NgbPanel } from '@ng-bootstrap/ng-bootstrap';
import { CommunityService } from '@common/services/community.service';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { FaqImpressionEvent } from '@common/analytics/events/faq-impression.event';
import { domChanges$, isInViewport$ } from '@common/util/dom';
import { WINDOW } from '@common/services/window.service';

export class FaqCategory {
    itemKeys: string[];

    get key(): string {
        return this.phase
            ? `${this.prefix}-${this.phase}-${this.id}`
            : `${this.prefix}-${this.id}`;
    }

    constructor(
        public id: string,
        public phase: string = null,
        public communityCodes = null,
        public prefix: string = 'faq-category',
        public excludedCommunityCodes = null,
    ) {}

    public getHeader(content: ContentService): Observable<string> {
        return content.value(this.key);
    }

    public getItemKeys(content: ContentService): Observable<string[]> {
        return content.get(this.key).pipe(
            map((category) => category?.value?.itemKeys ?? []),
            tap((items) => (this.itemKeys = items)),
        );
    }
}

export class FaqItem {
    content: string = null;

    static getKey(id: string): string {
        return `faq-${id}`;
    }

    get key(): string {
        return FaqItem.getKey(this.id);
    }

    constructor(
        public id: string,
        public categoryIds: string[],
        public phaseIds: string[] = null,
        public communityCodes: string[] = null,
    ) {}

    public getContent(content: ContentService): Observable<string> {
        return content.value(this.key);
    }

    public loadContent(content: ContentService): void {
        this.getContent(content).subscribe((result) => (this.content = result));
    }

    public hasCategory(categoryId: string): boolean {
        return this.categoryIds?.includes(categoryId);
    }

    public hasPhase(phaseId: string): boolean {
        return this.phaseIds?.includes(phaseId);
    }

    public hasCommunityCode(communityCode: string): boolean {
        return this.communityCodes?.includes(communityCode);
    }
}

@Directive()
export abstract class FaqComponentBase
    implements OnInit, OnDestroy, AfterViewInit
{
    routeParams: Subscription;

    @ViewChild('accordion') protected accordion: NgbAccordion;

    public faqParams = [];
    protected searchTerm$ = new Subject<string>();
    protected searchInstant$ = new BehaviorSubject<string>('');
    allCategories: FaqCategory[];
    categories: FaqCategory[];
    items: FaqItem[];
    currentItems$: Observable<FaqItem[]>;

    activeIds: string[] = [];
    activeIdsFromQuery: string[] = [];
    categoryId: string;
    public searchTerm = '';
    protected phaseId$: Observable<string> = of(null);

    get showRegisterFooter(): string {
        return this.communityService.communityCode;
    }

    get jumbotronStyles$(): Observable<any> {
        return this.content.value('hero-image').pipe(
            map((heroImageUrl) => {
                return { 'background-image': `url("${heroImageUrl}")` };
            }),
        );
    }

    /** @deprecated Use async currentItems$ for phase filtered results and other new features. */
    get currentItems(): FaqItem[] {
        return this.items.filter((item) =>
            item.categoryIds.includes(this.categoryId),
        );
    }

    get communityCode(): string {
        return this.communityService.communityCode;
    }

    constructor(
        protected route: ActivatedRoute,
        public content: ContentService,
        protected communityService: CommunityService,
        protected analyticsService: AnalyticsService,
        @Inject(WINDOW) private window: Window,
        categories: FaqCategory[],
        items?: FaqItem[],
    ) {
        this.allCategories = categories;
        this.items = items ? items : [];
    }

    get categoryDisabled(): boolean {
        if (!this.searchTerm) return false;
        return this.searchTerm.length > 0;
    }

    get body(): HTMLElement {
        return this.window?.document?.body;
    }

    ngOnInit(): void {
        this.phaseId$
            .pipe(
                map((phase) =>
                    this.allCategories
                        .filter((item) => !item.phase || item.phase === phase)
                        .filter(
                            (item) =>
                                !item.communityCodes ||
                                item.communityCodes.includes(
                                    this.communityService.communityCode,
                                ),
                        )
                        .filter(
                            (item) =>
                                !item.excludedCommunityCodes ||
                                !item.excludedCommunityCodes.includes(
                                    this.communityService.communityCode,
                                ),
                        ),
                ),
                filter((categories) => categories.length > 0),
                mergeMap((categories) => {
                    this.categories = categories;
                    return forkJoin(
                        categories.map((category) => this.getItems(category)),
                    );
                }),
                switchMap(() =>
                    this.route.paramMap.pipe(
                        map(
                            (params) =>
                                params.get('id') || this.categories[0].id,
                        ),
                    ),
                ),
            )
            .subscribe((faqId) => {
                const faqItem = this.items.find((item) => item.id === faqId);
                if (faqItem) {
                    this.activeIdsFromQuery = [faqItem.id];
                    this.categoryId = faqItem.categoryIds[0];
                    this.items.unshift(
                        this.items.splice(
                            this.items.findIndex((item) => item.id === faqId),
                            1,
                        )[0],
                    );
                } else {
                    const category = this.categories.find(
                        (item) => item.id === faqId,
                    );

                    this.categoryId = category?.id ?? this.categories[0].id;
                }

                this.searchInstant$.next('');
            });

        const searchTerm$ = this.searchTerm$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
        );

        this.currentItems$ = combineLatest([
            merge(this.searchInstant$, searchTerm$),
            this.phaseId$,
        ]).pipe(
            map(([_, phase]) => {
                if (!phase) return this.items;
                return this.items.filter(
                    (item) => !item.phaseIds || item.hasPhase(phase),
                );
            }),
            map((items) => {
                const code = this.communityService.communityCode;
                if (!code) return items;
                return items.filter(
                    (item) =>
                        !item.communityCodes || item.hasCommunityCode(code),
                );
            }),
            tap((items) => {
                items.forEach((item) => item.loadContent(this.content));
            }),
            map((items) => {
                if (!this.searchTerm) {
                    this.activeIds = this.activeIdsFromQuery;
                    const category = this.categories.find(
                        (cat) => cat.id === this.categoryId,
                    );
                    items = items.filter((item) =>
                        item.hasCategory(this.categoryId),
                    );

                    if (!category) return items;

                    category.itemKeys.forEach((key, index) => {
                        const itemIndex = items.findIndex(
                            (i) => i.id === key.replace('faq-', ''),
                        );
                        if (itemIndex !== index) {
                            [items[itemIndex], items[index]] = [
                                items[index],
                                items[itemIndex],
                            ];
                        }
                    });

                    return items;
                } else {
                    items = items.filter((item) =>
                        item.content
                            .toLowerCase()
                            .includes(this.searchTerm.toLocaleLowerCase()),
                    );
                    this.activeIds = items.map((item) => item.id);
                    return items;
                }
            }),
        );
    }

    getItems(category: FaqCategory): Observable<string[]> {
        return category.getItemKeys(this.content).pipe(
            tap((items) => {
                if (!items) return;
                items.forEach((item) => {
                    const itemId = item.replace('faq-', '');
                    if (this.items.map((i) => i.id).includes(itemId)) {
                        const existingItem =
                            this.items[
                                this.items.findIndex((i) => i.id === itemId)
                            ];
                        if (!existingItem.hasCategory(category.id)) {
                            existingItem.categoryIds.push(category.id);
                        }
                    } else {
                        this.items.push(new FaqItem(itemId, [category.id]));
                    }
                });
            }),
            take(1),
        );
    }

    public search(event: Event): void {
        const term = (event.target as HTMLInputElement).value;
        this.searchTerm$.next(term);
    }

    public categoryChange(_value: unknown): void {
        this.searchInstant$.next('');
        this.accordion.collapseAll();
    }

    destroyed$: Subject<void> = new Subject<void>();

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    /* Analytics */

    openPanels$: Observable<NgbPanel[]> = domChanges$(this.body).pipe(
        takeUntil(this.destroyed$),
        debounceTime(50),
        map(() => this.accordion?.panels.filter((p) => p.isOpen) ?? []),
        distinctUntilChanged(),
    );

    eventsSentIds: Set<string> = new Set();

    ngAfterViewInit(): void {
        this.openPanels$.subscribe((panels) => {
            panels
                .map((p) => p.panelDiv)
                .filter((el) => !this.eventsSentIds.has(el.id))
                .forEach((el) => this.sendAnalyticsEventWhenInViewport(el));
        });
    }

    private sendAnalyticsEventWhenInViewport(el: Element): void {
        isInViewport$(el, { threshold: 0.5 }) // 50% of the faq panel should be visible to trigger
            .pipe(filter(Boolean), take(1)) //we just need one true value before completing
            .subscribe(() => {
                this.sendAnalyticsEvent(el.id);
                this.eventsSentIds.add(el.id);
            });
    }

    private sendAnalyticsEvent(id: string): void {
        const event = FaqImpressionEvent.create({
            faq_cms_key: FaqItem.getKey(id),
            search_term: this.searchTerm,
        });

        this.analyticsService.push(event);
    }
}
