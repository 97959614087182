import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageViewEvent } from '@common/analytics/events/pageview.event';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { Community } from '@common/model/community';
import { RegistrationBase } from '@common/model/registration.base';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CommunityService } from './community.service';
import { ContentService } from './content.service';
import { PendingChangesService } from './pending-changes.service';
import { FlowData } from './register-flow-calculator.service.base';
import { RegisterFlowLocationAnalyticsPageService } from './register-flow-location-analytics-page.service';

@Injectable()
export class RegisterFlowLocationService {
    private _activeStep: string;
    private _startedWithStep: string;
    private _stepHistory: string[] = [];
    private _enteredFlowFromLink: boolean;
    private _goToSucceeded = new Subject<string>();
    private _previousRegistrationStatus: string;
    private _registration: RegistrationBase;
    private _isPaperFlow: boolean;

    public constructor(
        private contentService: ContentService,
        private communityService: CommunityService,
        private pendingChangesService: PendingChangesService,
        private pageService: RegisterFlowLocationAnalyticsPageService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private analyticsService: AnalyticsService,
        @Inject(APP_BASE_HREF) private baseHref: string,
    ) {}

    get activeStep(): string {
        return this._activeStep;
    }

    get startedWithStep(): string {
        return this._startedWithStep;
    }

    get enteredFlowFromLink(): boolean {
        return this._enteredFlowFromLink;
    }

    get goToSucceeded$(): Observable<string> {
        return this._goToSucceeded.asObservable();
    }

    get isPaperFlow(): boolean {
        return this._isPaperFlow;
    }

    set isPaperFlow(paperFlow: boolean) {
        this._isPaperFlow = paperFlow;
    }

    startWithStep(step: string): void {
        this._startedWithStep = step;
    }

    enterFlowFromLink(): void {
        this._enteredFlowFromLink = true;
    }

    registrationLoaded(registration: RegistrationBase): void {
        this._previousRegistrationStatus = registration.status;
        this._registration = registration;
    }

    goTo(step: string): void {
        const previousStep = this._activeStep;
        this.contentService
            .value('prevent-loosing-changes-message')
            .pipe(take(1))
            .subscribe((preventLoosingChangesMessage) => {
                if (
                    !this.pendingChangesService.pendingChanges ||
                    confirm(preventLoosingChangesMessage)
                ) {
                    this._activeStep = step;
                    this._stepHistory.push(step);
                }
                this.logToAnalytics(
                    this._isPaperFlow ? 'paper' : step,
                    previousStep,
                );
                this._previousRegistrationStatus = this._registration.status;
                this._goToSucceeded.next();
            });
    }

    /** Simulates a {@link goTo} so analytics can consider something a separate page,
     * while the app itself uses still the normal steps */
    pseudoGoTo(step: string): void {
        const previousStep = this._activeStep;

        this.logToAnalytics(this._isPaperFlow ? 'paper' : step, previousStep);
        this._previousRegistrationStatus = this._registration.status;

        this._activeStep = step;
        this._goToSucceeded.next();
        this._activeStep = previousStep;
    }

    hasVisitedPage(page: string): boolean {
        return this._stepHistory.some((s) => s === page);
    }

    private logToAnalytics(step: string, previousStep: string) {
        const flowData: FlowData = {
            registration: this._registration,
            goToStep: step,
            previousStep,
            startedWithStep: this.startedWithStep,
            enteredFlowFromLink: this.enteredFlowFromLink,
            queryParamMap: this.route.snapshot.queryParamMap,
            previousRegistrationStatus: this._previousRegistrationStatus,
        };
        combineLatest([
            this.authService.identity$,
            this.communityService.community$,
        ])
            .pipe(first())
            .subscribe(([_, community]) => {
                this.sendAnalyticsEvent(flowData, community);
            });
    }

    private sendAnalyticsEvent(flowData: FlowData, community: Community) {
        const event = PageViewEvent.create(
            this.pageService.getPage(community?.code, flowData?.goToStep),
        );
        this.analyticsService.push(event);
    }
}
