import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { SupplierData, SupplierService } from '../../../../services/supplier.service';
import { combineLatest, of, Subject, zip } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { ContentService } from '@common/services/content.service';

@Component({
    selector: 'app-ceased-trading',
    templateUrl: './ceased-trading.component.html',
    styleUrls: ['./ceased-trading.component.scss'],
})
export class CeasedTradingComponent implements OnChanges, OnDestroy {
    private destroyed$: Subject<void> = new Subject<void>();

    public ceasedSupplierCmsKeys: { name: string; cmskey: string }[];

    @Input() supplierCodes: string[];

    constructor(private supplierService: SupplierService, private contentService: ContentService) {}

    ngOnChanges(): void {
        if (this.supplierCodes) {
            const suppliers$ = this.supplierCodes
                .filter((supplier, i) => supplier && this.supplierCodes.indexOf(supplier) === i)
                .map((supplier) =>
                    combineLatest([
                        this.supplierService.getSupplierByCode(supplier),
                        this.contentService.valueExists(`supplier-ceased-trading-${supplier}`),
                    ]),
                );

            zip(...suppliers$)
                .pipe(
                    catchError(() => of(<SupplierData[]>[])),
                    map((suppliers) =>
                        (suppliers as [SupplierData, boolean][]).filter(
                            (supplier) => supplier[0].ceasedTrading,
                        ),
                    ),
                    map((suppliers) =>
                        suppliers.map((supplier) => {
                            return {
                                name: supplier[0].name,
                                cmskey: supplier[1]
                                    ? `supplier-ceased-trading-${supplier[0].code}`
                                    : `supplier-ceased-trading-general`,
                            };
                        }),
                    ),
                    takeUntil(this.destroyed$),
                )
                .subscribe((cmsKeys) => {
                    this.ceasedSupplierCmsKeys = cmsKeys;
                });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
