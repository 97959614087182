import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'kebabCase' })
export class KebabCasePipe implements PipeTransform {

    transform(value: string) {

        if (!value) { return null; }

        value = value
            .match(/[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
            .filter(Boolean)
            .map(x => x.toLowerCase())
            .join('-');

        return value;
    }
}
