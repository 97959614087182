import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { ParticipationAssetsService } from '@common/services/participation-assets.service';
import { RegistrationService } from '@enuk/services/registration.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    participantCount: Observable<number>;
    communityName: string;
    public heroImageUrl: string = null;
    private destroyed$ = new Subject();

    constructor(
        private service: RegistrationService,
        public communityService: CommunityService,
        public participationAssetsService: ParticipationAssetsService,
        public contentService: ContentService,
    ) {}

    ngOnInit(): void {
        this.communityService.community$.pipe(takeUntil(this.destroyed$)).subscribe((community) => {
            this.communityName = community.name;
            this.participantCount = community.targetAuction
                ? this.service.countSubscriptions(community.targetAuction.id)
                : this.service.count();
        });

        // We should be subscribing in the template but lack of OnPush is making that impossible at the moment
        this.participationAssetsService.communityParticipation$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((communityParticipation) => {
                this.heroImageUrl = communityParticipation.heroImageUri;
            });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
