
import { Observable } from 'rxjs';
import { ContentService } from '../services/content.service';
import { Injectable } from '@angular/core';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {

    private preventLoosingChangesMessage: string;

    constructor(
        private contentService: ContentService) {
        this.contentService.value('prevent-loosing-changes-message').subscribe(value => {
            this.preventLoosingChangesMessage = value;
        });
    }

    canDeactivate(component: ComponentCanDeactivate): any {
        if (component === null) {
            return true;
        }
        return component.canDeactivate() ?
            true : confirm(this.preventLoosingChangesMessage);
    }
}
