import { Injectable } from '@angular/core';
import { KenalyticsService } from '@common/services/kenalytics.service';

export enum WhatToTest {
    DefaultComparison = 'DefaultComparison',
    NoComparison = 'NoComparison',
    Nothing = 'Nothing',
}

@Injectable({
    providedIn: 'root',
})
export class ComparisonAbTestService {
    private partnerCommunities = [
        'computerquote',
        'cssc',
        'ichoosr',
        'john-lewis',
        'kerala-link',
        'morning-star',
        'our-property',
        'petrolprices',
        'unite-the-union',
        'usdaw',
        'wrkit',
    ];

    public test(id: string, registrationNumber: string, communityCode: string): WhatToTest {
        if (communityCode && this.partnerCommunities.indexOf(communityCode) < 0) {
            return WhatToTest.Nothing;
        }
        const whatToTest =
            this.getRegistrationNumber(registrationNumber) % 2 === 0
                ? WhatToTest.DefaultComparison
                : WhatToTest.NoComparison;

        setTimeout(() => {
            // when 2 consequitive events arrive within 0.5 seconds of eachother, the second event will be dropped
            // this is a precaution against evil agents who might wish to flood the analytics api.
            this.analytics.postAbTest(id, 'enuk-partners-show-comparison-to-renewal-proposal', whatToTest);
        }, 500);

        return whatToTest;
    }

    public getRegistrationNumber(registrationNumber: string): number {
        return +registrationNumber.substr(4);
    }

    constructor(private analytics: KenalyticsService) {}
}
