import { Directive, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive()
export abstract class EnergySelectorComponentBase implements ControlValueAccessor {
    @Input() public id: string = null;
    @Input() public cmsLabel: string = null;
    @Input() public enabled = true;
    @Input() public readonly = false;
    @Input() public isLoading = false;
    protected onChanged = [];
    protected onTouched = [];

    constructor(private ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    public registerOnChange(fn: any): void {
        this.onChanged.push(fn);
    }

    public registerOnTouched(fn: any): void {
        this.onTouched.push(fn);
    }

    public abstract writeValue(value: any): void;

    public setDisabledState?(isDisabled: boolean): void {
        this.enabled = !isDisabled;
    }
}
