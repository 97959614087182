/* eslint-disable @typescript-eslint/naming-convention */

import { AnalyticsEvent, AnalyticsEventTemplate, EventName } from '@common/analytics/events/analytics.event';
import { FlowPhase } from '@common/analytics/model/flow-phase';
import { OverviewMode } from '@common/analytics/model/overview-mode';
import { PageType } from '@common/analytics/model/page-type';
import { RegistrationStatus } from '@common/analytics/model/registration-status';
import { Page } from '@common/model/data-layer';
import { RegistrationBase, RegistrationNumber } from '@common/model/registration.base';
import { AuctionService } from '@common/services/auction.service';

type Template = AnalyticsEventTemplate<PageViewEvent>;

export class PageViewEvent implements AnalyticsEvent {
    event: EventName = 'page_view';
    page_path: string;
    page_type: PageType;
    single_page_path: string;
    auction_code: string;
    process_flow_phase: FlowPhase;
    overview_mode: OverviewMode;
    community: string;
    registration_status: RegistrationStatus;
    registration_number: RegistrationNumber;
    user_consent_level: boolean;
    user_internal_traffic: boolean;
    product: string;
    business_line: string;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }

    static create({ path }: Page | { path: string }): PageViewEvent {
        return new PageViewEvent({ page_path: path }); //will be enriched
    }

    
}
