import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ControlWithErrors } from '@common/infrastructure/form-tools';
import { ValidationHelper } from '@enuk/helpers/validation-helper';
import { Contracts, PaymentMethod, Registration, Tariff } from '@enuk/model/registration';
import { SupplierData, SupplierService } from '@enuk/services/supplier.service';
import {
    FuelType,
    GetTariffPricesQuery,
    GetTariffsQuery,
    TariffLookupData,
    TariffPricesLookupData,
    TariffService,
} from '@enuk/services/tariff.service';
import { prepaymentValidator } from '@enuk/validators/prepayment.validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-tariff-lookup-modal',
    templateUrl: './tariff.lookup.modal.component.html',
})
export class TariffLookupModalComponent implements OnDestroy {
    @Input() public registration: Registration;
    @Input() public forRenewalTariff: boolean;

    public Contracts: typeof Contracts = Contracts;
    public FuelType: typeof FuelType = FuelType;

    public form: UntypedFormGroup;
    public activeSuppliers$: Observable<SupplierData[]> = null;
    protected destroyed$: Subject<void> = new Subject<void>();

    public dualPaymentMethods: PaymentMethod[] = []; // All payment methods supported by the selected dual fuel supplier
    public dualTariffsAll: TariffLookupData[] = []; // All of the current dual fuel supplier's tariffs (Eco7 filtered)
    public dualTariffs: TariffLookupData[] = []; // The current supplier's tariffs, filtered by the current payment method
    public selectedDualTariff: TariffPricesLookupData;
    public electricityPaymentMethods: PaymentMethod[] = []; // All payment methods supported by the selected electricity supplier
    public electricityTariffsAll: TariffLookupData[] = []; // All of the current electricity supplier's tariffs (Eco7 filtered)
    public electricityTariffs: TariffLookupData[] = []; // The current supplier's tariffs, filtered by the current payment method
    public selectedElectricityTariff: TariffPricesLookupData;
    public gasPaymentMethods: PaymentMethod[] = []; // All payment methods supported by the selected gas supplier
    public gasTariffsAll: TariffLookupData[] = []; // All of the current gas supplier's tariffs
    public gasTariffs: TariffLookupData[] = []; // The current supplier's tariffs, filtered by the current payment method
    public selectedGasTariff: TariffPricesLookupData;
    public errorGetTariffs = false;
    public errorNoTariffsFound = false;

    public get isDual(): boolean {
        return this.registration && this.registration.product.contract === Contracts.Dual;
    }
    public get isElectricity(): boolean {
        return this.registration && this.registration.product.contract === Contracts.Electricity;
    }
    public get isSeparate(): boolean {
        return this.registration && this.registration.product.contract === Contracts.Separate;
    }
    public get hasGas(): boolean {
        return this.isDual || this.isSeparate;
    }

    public get dualSupplierData(): ControlWithErrors {
        return this.form.get('dualSupplierData');
    }
    public get dualPaymentMethod(): ControlWithErrors {
        return this.form.get('dualPaymentMethod');
    }
    public get dualTariffName(): ControlWithErrors {
        return this.form.get('dualTariffName');
    }

    public get electricitySupplierData(): ControlWithErrors {
        return this.form.get('electricitySupplierData');
    }
    public get electricityPaymentMethod(): ControlWithErrors {
        return this.form.get('electricityPaymentMethod');
    }
    public get electricityTariffName(): ControlWithErrors {
        return this.form.get('electricityTariffName');
    }

    public get gasSupplierData(): ControlWithErrors {
        return this.form.get('gasSupplierData');
    }
    public get gasPaymentMethod(): ControlWithErrors {
        return this.form.get('gasPaymentMethod');
    }
    public get gasTariffName(): ControlWithErrors {
        return this.form.get('gasTariffName');
    }

    constructor(
        public modal: NgbActiveModal,
        private formBuilder: UntypedFormBuilder,
        private supplierService: SupplierService,
        private tariffService: TariffService,
    ) {
        const fnGetContract = () =>
            this.registration && this.registration.product ? this.registration.product.contract : null;
        this.form = this.formBuilder.group(
            {
                dualSupplierData: ['', ValidationHelper.requiredForDualContract(fnGetContract)],
                dualPaymentMethod: ['', ValidationHelper.requiredForDualContract(fnGetContract)],
                dualTariffName: ['', ValidationHelper.requiredForDualContract(fnGetContract)],

                electricitySupplierData: [
                    '',
                    ValidationHelper.requiredForElectricityOrSeparateContract(fnGetContract),
                ],
                electricityPaymentMethod: [
                    '',
                    ValidationHelper.requiredForElectricityOrSeparateContract(fnGetContract),
                ],
                electricityTariffName: [
                    '',
                    ValidationHelper.requiredForElectricityOrSeparateContract(fnGetContract),
                ],

                gasSupplierData: ['', ValidationHelper.requiredForSeparateContract(fnGetContract)],
                gasPaymentMethod: ['', ValidationHelper.requiredForSeparateContract(fnGetContract)],
                gasTariffName: ['', ValidationHelper.requiredForSeparateContract(fnGetContract)],
            },
            { validator: prepaymentValidator },
        );

        this.activeSuppliers$ = this.supplierService.getActiveSuppliers();

        // Fetch all tariffs as soon as a supplier is picked
        this.dualSupplierData.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged(),
                tap(() => {
                    if (this.dualSupplierData.dirty) {
                        this.dualTariffsAll = [];
                        this.dualPaymentMethods = [];
                        this.dualPaymentMethod.reset(); // Triggers reset of tariff name
                    }
                }),
                switchMap((supplierData) => {
                    if (!supplierData) {
                        // Supplier isn't set - we're simply clearing all dual fuel related fields
                        return of(<TariffLookupData[]>[]);
                    }

                    this.errorGetTariffs = false;
                    return this.getTariffs(supplierData.code, FuelType.ElectricityAndGas).pipe(
                        catchError((_error) => {
                            this.errorGetTariffs = true;
                            return of(<TariffLookupData[]>[]);
                        }),
                    );
                }),
            )
            .subscribe((results) => {
                this.dualTariffsAll = results;
                const paymentMethod: PaymentMethod = this.dualPaymentMethod.value;
                this.dualTariffs = paymentMethod
                    ? this.dualTariffsAll.filter(
                          (t) => t.paymentMethods && t.paymentMethods.indexOf(paymentMethod) !== -1,
                      )
                    : [];

                const dualPaymentMethods = results
                    .map((result) => result.paymentMethods)
                    .reduce((lhs, rhs) => lhs.concat(rhs), []);
                this.dualPaymentMethods = dualPaymentMethods.filter(
                    (pm, idx) => dualPaymentMethods.indexOf(pm) === idx,
                );
            });

        this.electricitySupplierData.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged(),
                tap(() => {
                    if (this.electricitySupplierData.dirty) {
                        this.electricityTariffsAll = [];
                        this.electricityPaymentMethods = [];
                        this.electricityPaymentMethod.reset(); // Triggers reset of tariff name
                    }
                }),
                switchMap((supplierData) => {
                    if (!supplierData) {
                        // Supplier isn't set - we're simply clearing all electricity related fields
                        return of(<TariffLookupData[]>[]);
                    }

                    this.errorGetTariffs = false;
                    return this.getTariffs(supplierData.code, FuelType.Electricity).pipe(
                        catchError((_error) => {
                            this.errorGetTariffs = true;
                            return of(<TariffLookupData[]>[]);
                        }),
                    );
                }),
            )
            .subscribe((results) => {
                this.electricityTariffsAll = results;
                const paymentMethod: PaymentMethod = this.electricityPaymentMethod.value;
                this.electricityTariffs = paymentMethod
                    ? this.electricityTariffsAll.filter(
                          (t) => t.paymentMethods && t.paymentMethods.indexOf(paymentMethod) !== -1,
                      )
                    : [];

                const electricityPaymentMethods = results
                    .map((result) => result.paymentMethods)
                    .reduce((lhs, rhs) => lhs.concat(rhs), []);
                this.electricityPaymentMethods = electricityPaymentMethods.filter(
                    (pm, idx) => electricityPaymentMethods.indexOf(pm) === idx,
                );
            });

        this.gasSupplierData.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged(),
                tap(() => {
                    if (this.gasSupplierData.dirty) {
                        this.gasTariffsAll = [];
                        this.gasPaymentMethods = [];
                        this.gasPaymentMethod.reset(); // Triggers reset of tariff name, contract end date, etc below
                    }
                }),
                switchMap((supplierData) => {
                    if (!supplierData) {
                        // Supplier isn't set - we're simply clearing all gas related fields
                        return of(<TariffLookupData[]>[]);
                    }

                    this.errorGetTariffs = false;
                    return this.getTariffs(supplierData.code, FuelType.Gas).pipe(
                        catchError((_error) => {
                            this.errorGetTariffs = true;
                            return of(<TariffLookupData[]>[]);
                        }),
                    );
                }),
            )
            .subscribe((results) => {
                this.gasTariffsAll = results;
                const paymentMethod: PaymentMethod = this.gasPaymentMethod.value;
                this.gasTariffs = paymentMethod
                    ? this.gasTariffsAll.filter(
                          (t) => t.paymentMethods && t.paymentMethods.indexOf(paymentMethod) !== -1,
                      )
                    : [];

                const gasPaymentMethods = results
                    .map((result) => result.paymentMethods)
                    .reduce((lhs, rhs) => lhs.concat(rhs), []);
                this.gasPaymentMethods = gasPaymentMethods.filter(
                    (pm, idx) => gasPaymentMethods.indexOf(pm) === idx,
                );
            });

        // Clear the tariff related fields whenever the payment method changes
        this.dualPaymentMethod.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
            .subscribe((paymentMethod: PaymentMethod) => {
                if (this.dualPaymentMethod.dirty || !paymentMethod) {
                    this.dualTariffName.reset();
                    this.selectedDualTariff = null;
                }
                this.dualTariffs = paymentMethod
                    ? this.dualTariffsAll.filter(
                          (t) => t.paymentMethods && t.paymentMethods.indexOf(paymentMethod) !== -1,
                      )
                    : [];
            });

        this.electricityPaymentMethod.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
            .subscribe((paymentMethod: PaymentMethod) => {
                if (this.electricityPaymentMethod.dirty || !paymentMethod) {
                    this.electricityTariffName.reset();
                    this.selectedElectricityTariff = null;
                }
                this.electricityTariffs = paymentMethod
                    ? this.electricityTariffsAll.filter(
                          (t) => t.paymentMethods && t.paymentMethods.indexOf(paymentMethod) !== -1,
                      )
                    : null;
            });

        this.gasPaymentMethod.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
            .subscribe((paymentMethod: PaymentMethod) => {
                if (this.gasPaymentMethod.dirty || !paymentMethod) {
                    this.gasTariffName.reset();
                    this.selectedGasTariff = null;
                }
                this.gasTariffs = paymentMethod
                    ? this.gasTariffsAll.filter(
                          (t) => t.paymentMethods && t.paymentMethods.indexOf(paymentMethod) !== -1,
                      )
                    : null;
            });

        // Tariff selection
        this.dualTariffName.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                filter((tariffName) => tariffName !== null),
                distinctUntilChanged(),
                switchMap((tariffName) => {
                    const supplierData: SupplierData = this.dualSupplierData.value;
                    return this.getTariffWithPrices(
                        supplierData.code,
                        tariffName,
                        FuelType.ElectricityAndGas,
                        this.dualPaymentMethod.value,
                    ).pipe(filter((tariff) => !!tariff));
                }),
            )
            .subscribe((tariff) => {
                this.selectedDualTariff = tariff;
            });

        this.electricityTariffName.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                filter((tariffName) => tariffName !== null),
                distinctUntilChanged(),
                switchMap((tariffName) => {
                    const supplierData: SupplierData = this.electricitySupplierData.value;
                    return this.getTariffWithPrices(
                        supplierData.code,
                        tariffName,
                        FuelType.Electricity,
                        this.electricityPaymentMethod.value,
                    ).pipe(filter((tariff) => !!tariff));
                }),
            )
            .subscribe((tariff) => {
                this.selectedElectricityTariff = tariff;
            });

        this.gasTariffName.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                filter((tariffName) => tariffName !== null),
                distinctUntilChanged(),
                switchMap((tariffName) => {
                    const supplierData: SupplierData = this.gasSupplierData.value;
                    return this.getTariffWithPrices(
                        supplierData.code,
                        tariffName,
                        FuelType.Gas,
                        this.gasPaymentMethod.value,
                    ).pipe(filter((tariff) => !!tariff));
                }),
            )
            .subscribe((tariff) => {
                this.selectedGasTariff = tariff;
            });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public resetForm(tariff: Tariff): void {
        this.form.reset();
        switch (this.registration.product.contract) {
            case Contracts.Dual:
                if (tariff) {
                    this.dualSupplierData.setValue({
                        code: tariff.electricitySupplierCode,
                        name: tariff.electricitySupplierName,
                    });
                    this.dualPaymentMethod.setValue(tariff.electricityPaymentMethod);
                    this.dualTariffName.setValue(tariff.electricityTariffName);
                } else {
                    // NOTE: not all registrations in the february-2021 auction will have the current tariff supplier code/name property yet
                    this.dualSupplierData.setValue({
                        code: this.registration.product.dualSupplierCode,
                        name: this.registration.product.dualSupplier,
                    });
                    this.dualPaymentMethod.setValue(this.registration.product.electricityPaymentMethod);
                }
                break;

            case Contracts.Separate:
                if (tariff) {
                    this.electricitySupplierData.setValue({
                        code: tariff.electricitySupplierCode,
                        name: tariff.electricitySupplierName,
                    });
                    this.electricityPaymentMethod.setValue(tariff.electricityPaymentMethod);
                    this.electricityTariffName.setValue(tariff.electricityTariffName);
                    this.gasSupplierData.setValue({
                        code: tariff.gasSupplierCode,
                        name: tariff.gasSupplierName,
                    });
                    this.gasPaymentMethod.setValue(tariff.gasPaymentMethod);
                    this.gasTariffName.setValue(tariff.gasTariffName);
                } else {
                    // NOTE: not all registrations in the february-2021 auction will have the current tariff supplier code/name property yet
                    this.electricitySupplierData.setValue({
                        code: this.registration.product.electricitySupplierCode,
                        name: this.registration.product.electricitySupplier,
                    });
                    this.electricityPaymentMethod.setValue(
                        this.registration.product.electricityPaymentMethod,
                    );
                    this.gasSupplierData.setValue({
                        code: this.registration.product.gasSupplierCode,
                        name: this.registration.product.gasSupplier,
                    });
                    this.gasPaymentMethod.setValue(this.registration.product.gasPaymentMethod);
                }

                break;

            case Contracts.Electricity:
                if (tariff) {
                    this.electricitySupplierData.setValue({
                        code: tariff.electricitySupplierCode,
                        name: tariff.electricitySupplierName,
                    });
                    this.electricityPaymentMethod.setValue(tariff.electricityPaymentMethod);
                    this.electricityTariffName.setValue(tariff.electricityTariffName);
                } else {
                    this.electricitySupplierData.setValue({
                        code: this.registration.product.electricitySupplierCode,
                        name: this.registration.product.electricitySupplier,
                    });
                    this.electricityPaymentMethod.setValue(
                        this.registration.product.electricityPaymentMethod,
                    );
                }
                break;
        }
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            return;
        }

        let electricitySupplierData: Partial<SupplierData> = {};
        let gasSupplierData: Partial<SupplierData> = {};
        let electricityPaymentMethod: PaymentMethod = null;
        let gasPaymentMethod: PaymentMethod = null;
        let electricityTariff: Partial<TariffPricesLookupData> = {};
        let gasTariff: Partial<TariffPricesLookupData> = {};
        switch (this.registration.product.contract) {
            case Contracts.Dual:
                electricitySupplierData = gasSupplierData = this.dualSupplierData.value || {};
                electricityPaymentMethod = gasPaymentMethod = this.dualPaymentMethod.value;
                electricityTariff = gasTariff = this.selectedDualTariff || {};
                break;
            case Contracts.Separate:
                electricitySupplierData = this.electricitySupplierData.value || {};
                electricityPaymentMethod = this.electricityPaymentMethod.value;
                electricityTariff = this.selectedElectricityTariff || {};
                gasSupplierData = this.gasSupplierData.value || {};
                gasPaymentMethod = this.gasPaymentMethod.value;
                gasTariff = this.selectedGasTariff || {};
                break;
            case Contracts.Electricity:
                electricitySupplierData = this.electricitySupplierData.value || {};
                electricityPaymentMethod = this.electricityPaymentMethod.value;
                electricityTariff = this.selectedElectricityTariff || {};
                break;
        }

        const productTariff: Tariff = new Tariff();
        if (this.forRenewalTariff) {
            this.registration.product.renewalTariff = productTariff;
            this.registration.decision.wantsToCompareToRenewalTariff = true;
            this.registration.decision.wantsToCompareToOwnTariff = false;
        } else {
            // TODO: this isn't fully implemented (and not currently used) - for example contract end date checks are missing
            throw new Error();
        }

        productTariff.electricitySupplierCode = electricitySupplierData.code;
        productTariff.electricitySupplierName = electricitySupplierData.name;
        productTariff.electricityPaymentMethod = electricityPaymentMethod;
        productTariff.electricityTariffName = electricityTariff.name;
        productTariff.electricityEconomy7 = this.registration.product.hasEco7Meter;
        productTariff.electricityPaperlessBilling = electricityTariff.paperlessBilling;
        productTariff.electricityStandingCharge = electricityTariff.electricityStandingCharge;
        productTariff.electricityDayPriceThreshold = electricityTariff.electricityDayPriceThreshold;
        productTariff.electricityDayPrice = electricityTariff.electricityDayPrice;
        productTariff.electricityDayPrice2 = electricityTariff.electricityDayPrice2;
        productTariff.electricityNightPrice = electricityTariff.electricityNightPrice;
        productTariff.electricityDiscount = electricityTariff.electricityDiscount;

        productTariff.gasSupplierCode = gasSupplierData.code;
        productTariff.gasSupplierName = gasSupplierData.name;
        productTariff.gasPaymentMethod = gasPaymentMethod;
        productTariff.gasTariffName = gasTariff.name;
        productTariff.gasPaperlessBilling = gasTariff.paperlessBilling;
        productTariff.gasStandingCharge = gasTariff.gasStandingCharge;
        productTariff.gasPrice = gasTariff.gasPrice;
        productTariff.gasDiscount = gasTariff.gasDiscount;

        this.modal.close(true);
    }

    private getTariffs(
        supplierCode: string,
        fuelType: FuelType,
        paymentMethod?: PaymentMethod,
    ): Observable<TariffLookupData[]> {
        if (supplierCode == null || fuelType == null) {
            return of([]);
        }

        const query = new GetTariffsQuery();
        query.regionId = this.registration.contact.regionId;
        query.supplierCode = supplierCode;
        query.fuelType = fuelType;
        if (FuelType.Gas !== fuelType) {
            query.hasEco7Meter = this.registration.product.hasEco7Meter === true;
        }
        if (paymentMethod !== null) {
            query.paymentMethod = paymentMethod;
        }
        query.renewal = this.forRenewalTariff;

        return this.tariffService.get(query);
    }

    private getTariffWithPrices(
        supplierCode: string,
        tariffName: string,
        fuelType: FuelType,
        paymentMethod: PaymentMethod,
    ): Observable<TariffPricesLookupData> {
        if (supplierCode == null || fuelType == null) {
            return of(null);
        }

        const query = new GetTariffPricesQuery();
        query.regionId = this.registration.contact.regionId;
        query.supplierCode = supplierCode;
        query.fuelType = fuelType;
        if (FuelType.Gas !== fuelType) {
            query.hasEco7Meter = this.registration.product.hasEco7Meter === true;
        }
        query.paymentMethod = paymentMethod;
        query.tariffName = tariffName;

        return this.tariffService.getWithPrices(query);
    }

    public searchItem(term: string, item: any): boolean {
        return (
            (<string>item.name)
                .toLowerCase()
                .replace(/\W/g, '')
                .indexOf(term.replace(/\W/g, '').toLowerCase()) > -1
        );
    }
}
