import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../../services/registration.service';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ContentService } from '@common/services/content.service';
import { CommunityService } from '@common/services/community.service';

@Component({
    templateUrl: 'unauthorized.component.html',
})
export class UnauthorizedComponent {
    sendLinkSuccess = false;
    sendLinkError = false;
    sendingLink = false;

    get communityCode(): string {
        return this.communityService.communityCode;
    }

    get jumbotronStyles$(): Observable<any> {
        return this.content.value('hero-image-small').pipe(
            map((heroImageUrl) => {
                return { 'background-image': 'url("' + heroImageUrl + '")' };
            }),
        );
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private registrationService: RegistrationService,
        private communityService: CommunityService,
        private content: ContentService,
    ) {}

    requestParticipantLink(): void {
        const id = this.activatedRoute.snapshot.params.id;
        this.sendLinkError = false;
        this.sendingLink = true;
        this.registrationService
            .sendLinkToRegistration(id)
            .pipe(finalize(() => (this.sendingLink = false)))
            .subscribe(
                () => (this.sendLinkSuccess = true),
                () => (this.sendLinkError = true),
            );
    }
}
