<section *ngIf="isCommunityPage$ | async" id="register-now-banner" class="fluid-container mt-5">
    <div class="mt-4 py-5 bg-highlight-two text-center">
        <h2 class="col-sm-12">
            {{ 'sign-up-title' | cmsText | async }}
        </h2>
        <div class="col-12">
            <button class="btn btn-primary px-5 mt-3"
                [routerLink]="['/', communityCode$ | async, 'register']"
                id="homeRegisterBtn2">
                <app-content key="register-button-text" id="register-button3"></app-content>
                <i class="fa fa-chevron-right ml-1"></i>
            </button>
        </div>
    </div>
</section>