<button
    *ngIf="isCommunityPage$ | async"
    id="register-now-button"
    class="btn btn-filled"
    [ngClass]="{ 'btn-lg': ButtonSize.Large === buttonSize, 'btn-sm': ButtonSize.Small === buttonSize }"
    [routerLink]="['/', communityCode$ | async, 'register']"
>
    {{ 'register-button-text' | cmsText | async }}
    <i class="ms-7 fa fa-chevron-right"></i>
</button>
