<div id="canBeContactedForm" [formGroup]="contactForm" *ngIf="showCanBeContacted">
    <div class="form-check custom-checkbox">
        <input
            type="checkbox"
            class="form-check-input"
            id="canBeContacted"
            formControlName="canBeContacted"
        />
        <label appOptional class="form-check-label" for="canBeContacted">
            <span
                [innerHTML]="
                    'can-be-contacted-by-label' | cmsText : [{ communityName: community.name }] | async
                "
            ></span>
            <app-tooltip-enuk
                cmsKey="can-be-contacted-by-tooltip-body"
                targetCmsKey="can-be-contacted-by-label"
                [cmsParams]="{ communityName: community.name }"
            >
            </app-tooltip-enuk>
        </label>
    </div>
    <div class="ms-4" *ngIf="canBeContacted.value">
        <span
            [innerHTML]="
                'can-be-contacted-by-methods-label' | cmsText : [{ communityName: community.name }] | async
            "
        ></span>
        <div class="form-check custom-checkbox">
            <input
                type="checkbox"
                class="form-check-input"
                id="canBeContactedByEmail"
                formControlName="canBeContactedByEmail"
            />
            <label class="form-check-label" for="canBeContactedByEmail">
                <span [innerHTML]="'can-be-contacted-by-email-label' | cmsText | async"></span>
            </label>
        </div>
        <div class="form-check custom-checkbox">
            <input
                type="checkbox"
                class="form-check-input"
                id="canBeContactedByLetter"
                formControlName="canBeContactedByLetter"
            />
            <label class="form-check-label" for="canBeContactedByLetter">
                <span [innerHTML]="'can-be-contacted-by-letter-label' | cmsText | async"></span>
            </label>
        </div>
        <div class="form-check custom-checkbox">
            <input
                type="checkbox"
                class="form-check-input"
                id="canBeContactedByPhone"
                formControlName="canBeContactedByPhone"
            />
            <label class="form-check-label" for="canBeContactedByPhone">
                <span [innerHTML]="'can-be-contacted-by-phone-label' | cmsText | async"></span>
            </label>
        </div>
    </div>
</div>
