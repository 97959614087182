/* eslint-disable @typescript-eslint/naming-convention */

import { UntypedFormGroup } from '@angular/forms';
import { AnalyticsEvent, AnalyticsEventTemplate, EventName } from '@common/analytics/events/analytics.event';
import { RegistrationNumber } from '@common/model/registration.base';

type Template = AnalyticsEventTemplate<ContactFormSubmitEvent>;

export class ContactFormSubmitEvent implements AnalyticsEvent {
    event: EventName = 'contactform_submit';
    form_subject: string;
    registration_number: RegistrationNumber;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }

    static fromForm(form: UntypedFormGroup): ContactFormSubmitEvent {
        const formValue = (key: string) => form.get(key)?.value;

        return new this({
            form_subject: formValue('question'),
            registration_number: formValue('registrationNumber'),
        });
    }
}
