import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Registration } from '../model/registration';
import { RegistrationServiceBase } from '../../../_common/services/registration.service.base';
import { API_URL, BUSINESS } from '../../../_common/app/app.config';
import { CommunityService } from '../../../_common/services/community.service';
import { from, Observable, of, throwError } from 'rxjs';
import { AuthService } from '@common/services/auth.service';
import { catchError, flatMap, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'enuk/environments/environment';

export class BankAccountValidatorResponse {
    valid?: boolean;
    responseReceived: boolean;
}

@Injectable()
export class RegistrationService extends RegistrationServiceBase<Registration> {
    constructor(
        @Inject(BUSINESS) business: string,
        http: HttpClient,
        @Inject(API_URL) baseUrl: string,
        communityService: CommunityService,
        auth: AuthService,
        private router: Router,
    ) {
        super(business, http, baseUrl, communityService, auth);
    }

    validateBankAccount(
        registration: Registration,
        accountNumber: string,
        sortCode: string,
    ): Observable<BankAccountValidatorResponse> {
        const params = new HttpParams().set('accountNumber', accountNumber).set('sortCode', sortCode);

        return this.http.get<BankAccountValidatorResponse>(`${this.url}/${registration.id}/bank-validate`, {
            params,
        });
    }

    public excludeAndParticipateInNextAction(registration: Registration): Observable<string> {
        const url = `${this.url}/${registration.id}/exclude-and-participate-in-next-auction`;
        return this.http.post<string>(url, null);
    }

    override get(id: string): Observable<Registration> {
        return super.get(id).pipe(
            catchError((e) => {
                const unauthorizedStatuses = [401, 403, 404];

                if (unauthorizedStatuses.includes(e?.status)) {
                    return this.communityService.communityCode$.pipe(
                        take(1),
                        flatMap((communityCode) =>
                            from(
                                environment.participantSecurity &&
                                    this.router.navigate([communityCode, 'unauthorized', id]),
                            ),
                        ),
                        map(() => new Registration()),
                    );
                }

                return throwError(e);
            }),
        );
    }

    override add(registration: Registration): Observable<string> {
        return this.http
            .post<string>(this.url, registration, {
                observe: 'response',
            })
            .pipe(
                switchMap((response) => {
                    const registrationId = response.body;

                    if (!environment.participantSecurity) {
                        return registrationId;
                    }

                    return this.auth.loginAsParticipant(response).pipe(switchMap((_) => of(registrationId)));
                }),
            );
    }

    sendLinkToRegistration(registrationId: string): Observable<void> {
        const url = `${this.url}/${registrationId}/send-participant-link`;
        return this.http.post<void>(url, {});
    }
}
