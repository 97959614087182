import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { BUSINESS } from '@common/app/app.config';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { InMemoryQueryService } from '@common/services/inmemory.query.service';
import { Brand } from '@lang/uk/model/brand';
import { BrandService } from '@lang/uk/services/brand.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
@Component({
    selector: 'app-helpdesk',
    templateUrl: 'helpdesk.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpdeskComponent implements OnInit {
    @Input() alwaysShowFromBrand: boolean = false;

    public facebookLink: string;
    public twitterLink: string;
    public instagramLink: string;
    public youtubeLink: string;

    private activeBrand: Brand;
    brandCode: string;
    brandName: string;
    communityCode: string;
    communityPhone: string;
    helpDeskOpeningHours: string;
    logoImageUri: string;
    isLoading$ = new BehaviorSubject<boolean>(true);
    inRegisterFlow$ = new BehaviorSubject<boolean>(false);
    public showInstagramIcon: boolean;

    constructor(
        private brandService: BrandService,
        private communityService: CommunityService,
        private contentService: ContentService,
        private queryService: InMemoryQueryService,
        @Inject(BUSINESS) business: string,
    ) {
        this.showInstagramIcon = business == 'enuk';
    }

    ngOnInit(): void {
        this.applyQueryServiceParams();
        combineLatest([
            this.brandService.getActive(),
            this.communityService.community$,
            this.contentService.get('contact-phone-label'),
        ])
            .pipe(
                tap(([activeBrand, community, contactPhoneLabel]) => {
                    this.activeBrand = activeBrand;
                    this.brandCode = activeBrand.parentBrand?.code ?? activeBrand.code;
                    this.brandName = activeBrand.parentBrand?.name ?? activeBrand.name;
                    this.helpDeskOpeningHours = contactPhoneLabel.value.replace(/<\/?[^>]+(>|$)/g, '');

                    if (this.alwaysShowFromBrand) {
                        this.communityCode = activeBrand?.code;
                        this.communityPhone = activeBrand?.contactPhone;
                        this.logoImageUri = activeBrand?.logoImageUri;
                    } else if (!community.code) {
                        //Empty string because of redirects to /contact & /faq instead of /communityCode/contact & /communityCode/faq
                        this.communityCode = '';
                        this.communityPhone = activeBrand?.contactPhone;
                    } else {
                        this.communityCode = community.code;
                        this.communityPhone = community.contactPhone;
                    }
                }),
                switchMap((_) => {
                    return combineLatest([
                        this.contentService.get(this.generateBrandedCmsKey('social-facebook-link')),
                        this.contentService.get(this.generateBrandedCmsKey('social-twitter-link')),
                        this.contentService.get(this.generateBrandedCmsKey('social-instagram-link')),
                        this.contentService.get(this.generateBrandedCmsKey('social-youtube-link')),
                    ]);
                }),
            )
            .subscribe(([facebookLink, twitterLink, instagramLink, youtubeLink]) => {
                this.facebookLink = facebookLink?.value;
                this.twitterLink = twitterLink?.value;
                this.instagramLink = instagramLink?.value;
                this.youtubeLink = youtubeLink?.value;
                this.isLoading$.next(false);
            });
    }

    applyQueryServiceParams(): void {
        this.queryService.getObservable$('app-helpdesk').subscribe((e) => {
            if (e.name === 'inRegisterFlow' && e.value !== null) {
                this.inRegisterFlow$.next(e.value);
            }
        });
    }

    public generateBrandedCmsKey(cmsKey: string): string {
        return this.brandService.generateBrandedCmsKey(this.activeBrand, cmsKey);
    }
}
