<div class="row mb-3 testimonial-card">
    <div class="col-lg-3 col-xs-12 text-center my-auto" [ngClass]="{ 'order-lg-12': cmsIndex % 2 }">
        <img
            class="img-fluid rounded"
            [attr.src]="'info-testimonial-' + cmsIndex + '-image' | cmsImageSrc | async"
            [attr.alt]="'info-testimonial-' + cmsIndex + '-image' | cmsImageSrc | async"
        />
    </div>
    <div class="col-lg-9 col-xs-12 border rounded p-3">
        <blockquote>
            <div [innerHTML]="'info-testimonial-' + cmsIndex + '-text' | cmsText | async"></div>
            <div *ngIf="!showLinksToCms; else showLinkToRatingCms">
                <div *ngIf="rating$ | async; let rating">
                    <ng-container *ngFor="let _ of [].constructor(5); let idxStar = index">
                        <ng-container [ngSwitch]="getShowStar(idxStar, rating)">
                            <i *ngSwitchCase="1" class="fa fa-star" aria-hidden="true"></i>
                            <i *ngSwitchCase="0.5" class="fa fa-star-half-o" aria-hidden="true"></i>
                            <i *ngSwitchCase="0" class="fa fa-star-o" aria-hidden="true"></i>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <ng-template #showLinkToRatingCms>
                {{ rating$ | async }}
            </ng-template>
            <footer class="blockquote-footer mt-3 text-end">
                {{ 'info-testimonial-' + cmsIndex + '-footer' | cmsText | async }}
            </footer>
        </blockquote>
    </div>
</div>
