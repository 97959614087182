import { Component } from '@angular/core';
import { ContentService } from '@common/services/content.service';
@Component({
    selector: 'app-past-suppliers',
    templateUrl: './past-suppliers.component.html',
})
export class PastSuppliersComponent {
    pastSupplierLogos: string[] = [];

    constructor(public contentService: ContentService) {
        contentService.get('past-winning-suppliers-logo-items').subscribe((content) => {
            if (content) {
                this.pastSupplierLogos = content.value.itemKeys;
            }
        });
    }
}
