import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CouncilRouteGuard {
    path: import('@angular/router').ActivatedRouteSnapshot[];
    route: import('@angular/router').ActivatedRouteSnapshot;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    canActivate(): boolean {
        // Partner communities do not do branding/landing pages
        // All partner communities live under app.ichoosr.com
        if (
            this.document.location.pathname.includes('/landing') &&
            this.document.location.search.includes('brand=')
        ) {
            return true;
        }
        return this.document.location.hostname !== 'app.ichoosr.com';
    }
}
