<div class="fluid-container under-menu py-5 hero-image-small" [ngStyle]="jumbotronStyles$ | async">
    <div class="container hero-image-container">
        <div class="row">
            <div class="col-lg-7 col-12 my-auto d-none d-lg-block">
                <div class="hero-title-background">
                    <h1 [innerHTML]="'user-changed-header' | cmsText | async"></h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="fluid-container bg-highlight-one pt-2 pb-5">
    <div class="container">
        <div class="card shadow border-0">
            <div class="card-body">
                <div [innerHTML]="'user-changed-body' | cmsText | async"></div>
            </div>
        </div>
    </div>
</div>
