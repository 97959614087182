import { OnInit, ElementRef, ViewChild, Directive } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ContentService } from '../../services/content.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
    ControlWithErrors,
    applyFormValue,
} from '../../infrastructure/form-tools';
import { ContactService } from '../../services/contact.service';
import { CommunityService } from '../../services/community.service';
import { Community } from '../../model/community';
import { ContactFormBase } from '../../model/contact-form.base';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { emailExpression } from '../../forms/custom-validators';
import { map, filter, first } from 'rxjs/operators';
import { RegistrationServiceBase } from '@common/services/registration.service.base';
import { RegistrationBase } from '@common/model/registration.base';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';
import { HttpEventType } from '@angular/common/http';

export enum ContactFormStatus {
    Ready = 'Ready',
    Pending = 'Pending',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
}

@Directive()
export abstract class ContactComponentBase<
    TContactForm extends ContactFormBase,
    TRegistrationService extends RegistrationServiceBase<TRegistration>,
    TRegistration extends RegistrationBase,
> implements OnInit {
    routeParams: Subscription;
    form: UntypedFormGroup;
    status: ContactFormStatus = ContactFormStatus.Ready;
    community: Community = null;
    registration: TRegistration;

    public uploader: FileUploader;

    maximumUploadSize = 0;
    maximumNumberOfAttachments = 0;
    allowedUploadMimeTypes = [];

    get communityCode() {
        return this.communityService.communityCode;
    }

    get jumbotronStyles$(): Observable<Object> {
        return this.content.value('hero-image').pipe(
            map((heroImageUrl) => {
                return { 'background-image': 'url("' + heroImageUrl + '")' };
            }),
        );
    }

    get contactPhoneNumberLink$(): Observable<string> {
        return this.content.value('contact-phone-number').pipe(
            map((phoneNumberText) => {
                return (
                    "<a href='tel:" +
                    phoneNumberText +
                    "'>" +
                    phoneNumberText +
                    '</a>'
                );
            }),
        );
    }

    get heroCardMessageKey() {
        if (this.status === ContactFormStatus.Succeeded) {
            return 'hero-card-message-contact-success';
        } else {
            return 'hero-card-message-contact';
        }
    }
    get showForm() {
        return (
            this.status !== ContactFormStatus.Succeeded &&
            this.community !== null
        );
    }
    get showError() {
        return this.status === ContactFormStatus.Failed;
    }
    get showSuccess() {
        return this.status === ContactFormStatus.Succeeded;
    }
    get submitRequested() {
        return this.status === ContactFormStatus.Pending;
    }

    get email(): ControlWithErrors {
        return this.form.get('email');
    }
    get question(): ControlWithErrors {
        return this.form.get('question');
    }
    get message(): ControlWithErrors {
        return this.form.get('message');
    }
    get registrationNumber(): ControlWithErrors {
        return this.form.get('registrationNumber');
    }
    get uploadFile(): ControlWithErrors {
        return this.form.get('uploadFile');
    }
    private filesToUpload: Map<string, Blob>;

    @ViewChild('uploadFileElement') uploadFileElement: ElementRef;

    constructor(
        protected content: ContentService,
        protected communityService: CommunityService,
        protected contactService: ContactService<TContactForm>,
        protected analytics: GoogleAnalyticsService,
        private fb: UntypedFormBuilder,
        private formGroup: { [key: string]: any },
        protected registrationService: TRegistrationService,
    ) {
        const baseFormGroup = {
            email: [
                '',
                [
                    Validators.required,
                    Validators.pattern(emailExpression),
                    Validators.maxLength(500),
                ],
            ],
            question: ['', [Validators.required]],
            message: ['', [Validators.required]],
            registrationNumber: ['', [Validators.maxLength(20)]],
            uploadFile: [null],
        };

        const combinedFormGroup = {};
        Object.assign(combinedFormGroup, baseFormGroup);
        Object.assign(combinedFormGroup, this.formGroup);

        this.form = this.fb.group(combinedFormGroup);
    }

    ngOnInit() {
        this.communityService.community$.subscribe(
            (community) => (this.community = community),
        );

        this.registrationService
            .loadFromSessionStorage()
            .pipe(filter((registration) => !!registration))
            .subscribe((registration) => {
                this.registration = registration;
                this.prefillRegistration();
            });

        this.uploader = new FileUploader({
            url: '',
            maxFileSize: this.maximumUploadSize,
            allowedMimeType: this.allowedUploadMimeTypes,
        });
        this.filesToUpload = new Map<string, Blob>();

        this.uploader.onWhenAddingFileFailed = (
            _item: FileLikeObject,
            _f: any,
            _options: any,
        ) => {
            this.uploadFile.setErrors({ invalidFile: true });
        };

        this.uploader.onAfterAddingFile = (_item: FileItem) => {
            this.uploadFile.setErrors(null);
        };
    }

    protected abstract prepareContactFormBase(): TContactForm;

    protected prefillRegistration() {
        this.registrationNumber.setValue(this.registration.number);
    }

    onFileChange(files: File[]) {
        if (files && files.length) {
            for (const file of files) {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => {
                    if (
                        this.allowedUploadMimeTypes.indexOf(file.type) > -1 &&
                        file.size < this.maximumUploadSize
                    ) {
                        this.filesToUpload.set(
                            file.name,
                            this.dataURItoBlob(reader.result as string),
                        );
                    }
                };
            }
        }
    }

    removeFileFromQueue(file: FileItem) {
        this.uploadFile.setErrors(null);
        this.uploader.removeFromQueue(file);
    }

    onSubmit() {
        this.status = ContactFormStatus.Pending;

        if (this.form.valid) {
            const contactForm = this.prepareContactFormBase();
            applyFormValue(this.form, contactForm, []);
            contactForm.communityId = this.community.id;

            const formData = new FormData();

            Object.keys(contactForm).forEach((key) => {
                const value = contactForm[key];
                formData.append(key, value ? value : null); // = form.value[key] === '' ? null : form.value[key];
            });

            this.filesToUpload.forEach((file: Blob, key: string) => {
                formData.append('file', file, key);
            });

            this.contactService
                .sendFormData(formData)
                .pipe(
                    first(
                        (event) =>
                            event.type === HttpEventType.ResponseHeader ||
                            event.type === HttpEventType.Response,
                    ),
                )
                .subscribe(
                    (_result) => {
                        this.status = ContactFormStatus.Succeeded;
                        this.analytics.event(
                            {
                                path: `/${this.communityService.communityCode}/contact`,
                            },
                            {
                                category: 'contact',
                                action: 'messageSubmitted',
                                label: this.question.value,
                            },
                        );

                        this.sendAnalyticsEvent();
                    },
                    (_error) => {
                        this.status = ContactFormStatus.Failed;
                    },
                );
        }
    }

    sendAnalyticsEvent(): void { } // no-op. To be overridden in subclasses

    dataURItoBlob(dataURI) {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = decodeURI(dataURI.split(',')[1]);
        }
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const array = [];
        for (let i = 0; i < byteString.length; i++) {
            array.push(byteString.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: mimeString });
    }
}
