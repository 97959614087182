import { Community as CommunityBase } from '@common/model/community';
import { Brand } from '@lang/uk/model/brand';

export enum CommunityType {
    Council = 'Council',
    Partner = 'Partner',
}

export class Community extends CommunityBase {
    type: CommunityType;

    hasDataSharingAgreement: boolean;

    showOfflineInformation: boolean;
}
