<section class="fluid-container">
    <div class="container">
        <div class="row mb-3 align-items-center">
            <img
                class="h-25 w-100"
                [attr.src]="'hero-image' | cmsImageSrc | async"
                [attr.alt]="'' | cmsImageAlt | async"
            />
            <div class="position-absolute">
                <h1 class="hero-title">{{ activeItemHeader$ | async }}</h1>
            </div>
        </div>
    </div>
</section>

<section class="container" routeTransformer>
    <div class="row mt-4">
        <div class="col-md-8">
            <ng-container *ngIf="activeItem$ | async as activeItem">
                <div
                    *ngIf="activeItem.useCmsForContent; else customInfoItems"
                    [innerHTML]="
                        'info-item-' + activeItem.id + '-text' | cmsText : [cmsParams$ | async] | async
                    "
                ></div>

                <ng-template #customInfoItems>
                    <ng-container [ngSwitch]="activeItem.id">
                        <div class="container-fluid" *ngSwitchCase="InfoItemIds.AboutUs">
                            <app-info-about-us></app-info-about-us>
                        </div>

                        <div class="container-fluid" *ngSwitchCase="InfoItemIds.Testimonials">
                            <ng-container *ngFor="let item of getNumberedArray(6)">
                                <app-info-testimonial [cmsIndex]="item"></app-info-testimonial>
                            </ng-container>
                        </div>

                        <div class="container-fluid" *ngSwitchCase="InfoItemIds.HowDoesItWork">
                            <div class="row">
                                <div
                                    class="col"
                                    [innerHTML]="
                                        'info-item-how-it-works-intro'
                                            | cmsText : [cmsParams$ | async]
                                            | async
                                    "
                                ></div>
                            </div>

                            <div class="row mb-3 border rounded p-3" *ngFor="let item of getNumberedArray(5)">
                                <div class="col-md-2 col-xs-12 text-center d-none d-md-block">
                                    <img
                                        class="ichoosr-sphere img-fluid"
                                        [attr.src]="
                                            'how-it-works-bullet-' + item + '-img' | cmsImageSrc | async
                                        "
                                        [attr.alt]="
                                            'how-it-works-bullet-' + item + '-img' | cmsImageAlt | async
                                        "
                                        height="80"
                                    />
                                </div>
                                <div class="col-12 col-md-10">
                                    <div class="row">
                                        <div class="col-sm-2 col-3 d-block d-md-none">
                                            <img
                                                class="ichoosr-sphere img-fluid"
                                                [attr.src]="
                                                    'how-it-works-bullet-' + item + '-img'
                                                        | cmsImageSrc
                                                        | async
                                                "
                                                [attr.alt]="
                                                    'how-it-works-bullet-' + item + '-img'
                                                        | cmsImageAlt
                                                        | async
                                                "
                                                height="80"
                                            />
                                        </div>
                                        <div class="col-sm-10 col-9 col-md-12">
                                            <div class="mb-2">
                                                <strong>{{
                                                    'info-item-how-it-works-' + item + '-title'
                                                        | cmsText
                                                        | async
                                                }}</strong>
                                            </div>
                                            <div
                                                [innerHTML]="
                                                    'info-item-how-it-works-' + item
                                                        | cmsText : [cmsParams$ | async]
                                                        | async
                                                "
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div
                                    clas="col"
                                    [innerHTML]="
                                        'info-item-how-it-works-outro'
                                            | cmsText : [cmsParams$ | async]
                                            | async
                                    "
                                ></div>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </ng-container>
        </div>

        <div class="col-md-4">
            <app-register-now-button [buttonSize]="ButtonSize.Large"></app-register-now-button>
            <h4>
                <app-content key="info-more-information-header"></app-content>
            </h4>
            <hr />
            <ul id="moreInfoList" class="list-group list-group-flush">
                <ng-container *ngFor="let item of infoItems$ | async">
                    <a
                        [routerLink]="'../' + item.id"
                        [routerLinkActive]="['active']"
                        class="list-group-item list-group-item-action"
                    >
                        {{ 'info-item-' + item.id + '-header' | cmsText | async }}
                    </a>
                </ng-container>
            </ul>

            <app-contact-options
                [communityCode]="community?.code"
                [contactPhone]="community?.contactPhone"
                class="d-flex my-3"
            >
            </app-contact-options>
        </div>
    </div>
</section>

<app-register-now-banner></app-register-now-banner>
