import { Injectable, Optional } from "@angular/core";
import { KenalyticsService } from '@common/services/kenalytics.service';
import { Page } from "@common/model/data-layer";

@Injectable()
export class RegisterFlowLocationAnalyticsPageOptions {
    includeUtmCodes = false;
}


@Injectable()
export class RegisterFlowLocationAnalyticsPageService {

    constructor(
        @Optional() private options: RegisterFlowLocationAnalyticsPageOptions,
        private kenalyticsService: KenalyticsService
    ) { }

    getPage(communityCode: string, step: string): Page {
        const page = { path: `/${communityCode}/register/${step}` };

        if (this.options?.includeUtmCodes) {
            this.appendUTMCodes(page);
        }

        return page;
    }

    appendUTMCodes(page: Page): void {
        const utmData = this.kenalyticsService.loadFromSessionStorage();

        if (utmData) {
            page.path += page.path.includes("?") ? "&" : "?";
            for (const key in utmData) {
                page.path += `${key}=${utmData[key]}&`
            }
            page.path = page.path.slice(0, -1);
        }
    }
}
