import { Component, Input, OnDestroy } from '@angular/core';
import { User } from '@common/model/user';
import { AuthService } from '@common/services/auth.service';
import { Registration } from '@enuk/model/registration';
import { RegistrationService } from '@enuk/services/registration.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-registration-user-card',
    templateUrl: 'registration-user-card.component.html',
})
export class RegistrationUserCardComponent implements OnDestroy {
    @Input() registrationNumber: string;
    user: User;

    destroyed$ = new Subject();

    get isBackofficeUser(): boolean {
        return this.user && this.user.role !== 'participant';
    }

    get isParticipant(): boolean {
        return this.user && this.user.role === 'participant';
    }

    constructor(private registrationService: RegistrationService, private authService: AuthService) {
        authService.identity$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((identity) => (this.user = identity));
    }

    logout(): void {
        this.registrationService.saveToSessionStorage(new Registration());
        this.authService.logout();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
