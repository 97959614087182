import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { VwoStoreService } from '@common/services/vwo-store.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Pipe({ name: 'vwoVariant' })
export class VwoVariantPipe implements PipeTransform, OnDestroy {
    private _destroyed$: Subject<void> = new Subject<void>();

    constructor(private vwoService: VwoStoreService) {}

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    transform(variantName: string): Observable<boolean> {
        return this.vwoService.distinctVariantName$.pipe(
            takeUntil(this._destroyed$),
            map((name) => name === variantName),
        );
    }
}
