export enum MenuMode {
    Show = 'show',
    Hide = 'hide',
    Step = 'step',
}

export interface StepConfiguration {
    id: string;
    title: string;
    titleParams: any[];
    description: string;
    group: string;
    showMenu?: boolean;
    showStepCounter?: boolean;
    showPrint?: boolean;
    showStepper?: boolean;
}
