import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ContentService } from '@common/services/content.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { StringHelpers } from '../../helpers/string-helpers';

type CallbackFunction<T> = (value: T) => void;
export interface DropdownItem {
    label: string;
    value: string;
}

@Component({
    selector: 'app-cms-items-checklist',
    templateUrl: 'cms-items-checklist.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsItemsCheckListComponent implements OnChanges, ControlValueAccessor {
    @Input() public id: string = null;
    @Input() public enabled = true;
    @Input() public cmsPrefix = '';
    @Input() public cmsParams: { [key: string]: any } = {};
    public options$: Observable<DropdownItem[]> = of([]);
    private onChanged = [];
    private onTouched = [];
    private selectedValues: string[] = null;
    private writeValue$: Subject<string[]> = new BehaviorSubject<string[]>(null);

    get cmsItemsKey(): string {
        return `${this.cmsPrefix}-items`;
    }

    constructor(
        public contentService: ContentService,
        private ngControl: NgControl,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.ngControl.valueAccessor = this;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.cmsPrefix && changes.cmsPrefix.currentValue !== changes.cmsPrefix.previousValue) {
            this.options$ = this.getInfoItems(this.cmsItemsKey);
            this.changeDetectorRef.markForCheck();
        }
    }

    public writeValue(values: string[]): void {
        this.selectedValues = values;
        this.writeValue$.next(values);
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: CallbackFunction<string[]>): void {
        this.onChanged.push(fn);
    }

    public registerOnTouched(fn: CallbackFunction<string[]>): void {
        this.onTouched.push(fn);
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.enabled = !isDisabled;
    }

    public isSelected(value: string): boolean {
        return this.selectedValues && -1 !== this.selectedValues.indexOf(value);
    }

    public onSelected(event: Event): void {
        const currentTarget = event.currentTarget as HTMLInputElement;
        if (currentTarget.checked) {
            this.selectedValues = this.selectedValues ?? [];
            if (-1 === this.selectedValues.indexOf(currentTarget.value)) {
                this.selectedValues.push(currentTarget.value);
            }
        } else {
            this.selectedValues = this.selectedValues.filter((value) => value !== currentTarget.value);
        }
        this.onChanged.forEach((f) => f(this.selectedValues));
    }

    protected getInfoItems(itemsListKey: string): Observable<DropdownItem[]> {
        const itemsList$ = this.contentService.get(itemsListKey).pipe(
            filter((cmsContent) => cmsContent && cmsContent.value),
            map((cmsContent) => cmsContent.value.itemKeys),
            map((itemKeys: string[]) =>
                itemKeys.map((itemValue) => ({
                    label: `${this.cmsPrefix}-${itemValue}`,
                    value: StringHelpers.convertToPascalCase(itemValue),
                })),
            ),
        );

        return this.writeValue$.pipe(
            distinctUntilChanged(),
            withLatestFrom(itemsList$),
            map(([selectedValues, dropdownItems]: [string[], DropdownItem[]]) => {
                const missingValues = selectedValues
                    ? selectedValues.filter(
                          (value) => dropdownItems.find((item) => item.value === value) == null,
                      )
                    : [];
                return missingValues.length
                    ? dropdownItems.concat(
                          missingValues.map((value) => ({
                              label: `${this.cmsPrefix}-${StringHelpers.convertPascalToKebab(value)}`,
                              value,
                          })),
                      )
                    : dropdownItems;
            }),
        );
    }
}
