import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { RegistrationBase } from '../model/registration.base';
import { CommunityService } from './community.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Community } from '../model/community';
import { Auction } from '../model/auction';
import { AuthService } from './auth.service';
import { CancelRegistration } from '@common/model/cancel-registration';

/**An interface that defines the ways a registration can be fetched */
export interface IFetchRegistration<R extends RegistrationBase> {
    get(id: string): Observable<R>;
    loadFromSessionStorage(): Observable<R>;
    loadOnceFromSessionStorage(communityCode: string): R;
    getAny(id: string, communityCode: string): Observable<R>;
}

export abstract class RegistrationServiceBase<T extends RegistrationBase>
    implements IFetchRegistration<T>
{
    protected readonly url: string;
    public participantMinimum = 1037;
    public snailMail = false;

    constructor(
        protected business: string,
        protected http: HttpClient,
        protected baseUrl: string,
        protected communityService: CommunityService,
        protected auth: AuthService,
    ) {
        this.url = this.baseUrl + 'registrations';
    }

    public static getAuctionForRegistration(
        community: Community,
        registration: RegistrationBase,
    ): Auction {
        if (!registration.auction) {
            return community.targetAuction;
        }

        return registration.auction;
    }

    public static winningOffersAvailableForRegistration(
        community: Community,
        registration: RegistrationBase,
    ): boolean {
        const auction = this.getAuctionForRegistration(community, registration);

        if (auction) {
            return auction.autoMakeProposals || registration.proposalMade;
        }

        return false;
    }

    count(communityId?: string): Observable<number> {
        let url = this.url + '/count';
        if (communityId) {
            url += '?communityId=' + communityId;
        }
        return this.http
            .get<number>(url)
            .pipe(
                map((count) =>
                    count < this.participantMinimum
                        ? this.participantMinimum
                        : count,
                ),
            );
    }

    countSubscriptions(auctionId: string): Observable<number> {
        return this.http.get<number>(
            this.url + '/count/subscriptions?auctionId=' + auctionId,
        );
    }

    countAcceptants(auctionId: string): Observable<number> {
        return this.http.get<number>(
            this.url + '/count/acceptants?auctionId=' + auctionId,
        );
    }

    get(id: string): Observable<T> {
        return this.http.get<T>(this.url + '/' + id);
    }

    /** Will get the registration by id, and if not available, will try to get it from session storage */
    getAny(id?: string, communityCode?: string): Observable<T> {
        return id
            ? this.http
                  .get<T>(this.url + '/' + id)
                  .pipe(catchError((_) => of(null)))
            : of(this.loadOnceFromSessionStorage(communityCode));
    }

    getByNumber(nr: number): Observable<T> {
        return this.http.get<T>(this.url + '/by-number/' + nr);
    }

    add(registration: T): Observable<string> {
        return this.http.post<string>(this.url, registration);
    }

    update(registration: T): Observable<void> {
        return this.http.put<void>(
            this.url + '/' + registration.id,
            registration,
        );
    }

    cancelAsUser(
        registration: CancelRegistration,
        id: string,
    ): Observable<void> {
        return this.http.post<void>(
            `${this.url}/${id}/cancel-as-user`,
            registration,
        );
    }

    saveToSessionStorage(registration: T): void {
        this.getSessionStorageKey().subscribe((key) =>
            sessionStorage.setItem(key, JSON.stringify(registration)),
        );
    }

    loadFromSessionStorage(): Observable<T> {
        return this.getSessionStorageKey().pipe(
            map((key) => JSON.parse(sessionStorage.getItem(key))),
        );
    }

    loadOnceFromSessionStorage(communityCode: string): T {
        if (!communityCode) return null;
        const key = `registration-${this.business}-${communityCode}`;
        return JSON.parse(sessionStorage.getItem(key));
    }

    removeFromSessionStorage(): Observable<boolean> {
        return this.getSessionStorageKey().pipe(
            tap((key) => sessionStorage.removeItem(key)),
            map((_key) => true),
        );
    }

    protected getSessionStorageKey(): Observable<string> {
        return this.communityService.communityCode$.pipe(
            map(
                (communityCode) =>
                    `registration-${this.business}-${communityCode}`,
            ),
        );
    }

    public participateInNextAction(registration: T): Observable<string> {
        const url = `${this.url}/${registration.id}/participate-in-next-auction`;
        return this.http.post<string>(url, null);
    }
}
