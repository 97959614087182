import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnInit,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import {
    Registration,
    PaymentMethod,
    Contracts,
    EmploymentStatus,
    ResidentialStatus,
    SupplierContactMethod,
    SelectedComparison,
} from '../../../model/registration';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    AbstractControl,
    ValidatorFn,
    ValidationErrors,
    UntypedFormArray,
    UntypedFormControl,
} from '@angular/forms';
import {
    ControlWithErrors,
    makeFormModel,
    applyFormValue,
} from '../../../../../_common/infrastructure/form-tools';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TariffLookupModalComponent } from './tariff.lookup.modal.component';
import {
    filter,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    map,
    catchError,
    takeUntil,
} from 'rxjs/operators';
import { Address } from '../../../model/address';
import { AddressesService } from '../../../services/addresses.service';
import { RegistrationService } from '../../../services/registration.service';
import { CustomValidators } from '../../../validators/custom-validators';
import { KeyValue } from '@angular/common';
import { Observable, of, merge, Subject } from 'rxjs';
import * as moment from 'moment';
import { ChatService } from '@common/services/chat.service';
import { KenalyticsService } from '../../../../../_common/services/kenalytics.service';
import { ComparisonService, Comparisons } from '../../../services/comparison.service';
import { ComparisonAbTestService, WhatToTest } from '../../../services/comparison-ab-test.service';
import { bankAccountValidator } from '../../../validators/bankAccount.validator';
import { AuctionHelper } from '../../../helpers/auction-helper';
import { MpasService } from '../../../services/mpas.service';
import {
    defaultSalutations,
    requirements,
    SupplierSetting,
    supplierSettings,
} from './proposal.component.config';
import { DecisionAddress } from '../../../model/decisionAddress';
import { VwoService } from '@common/services/vwo.service';
import { RegisterStep } from '../../../model/register-step.enum';
import { StringHelpers } from '@lang/uk/helpers/string-helpers';
import { Router } from '@angular/router';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { ErrorEvent } from '@common/analytics/events/error.event';
import { SupplierService } from '@enuk/services/supplier.service';

@Component({
    selector: 'app-register-proposal',
    templateUrl: './proposal.component.html',
    styleUrls: ['./proposal.component.scss'],
})
export class ProposalComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    get paymentMethod(): PaymentMethod {
        return this.registration.product.hasPrepaymentMeter
            ? PaymentMethod.PrePayment
            : PaymentMethod.MonthlyDirectDebit;
    }
    public get missingMpan(): boolean {
        return !this.registration.contact.mpan;
    }
    public get missingMprn(): boolean {
        return (
            this.registration.product.contract !== Contracts.Electricity && !this.registration.contact.mprn
        );
    }

    public get showBankDetailsWarningToEonCustomers(): boolean {
        return (
            (this.registration.product.dualSupplier === 'E.ON' ||
                this.registration.product.electricitySupplier === 'E.ON' ||
                this.registration.product.gasSupplier === 'E.ON') &&
            this.registration.proposal.winningTariffSupplierName === 'E.ON'
        );
    }

    get contactFormGroup(): UntypedFormGroup {
        return this.form.get('contactFormGroup') as UntypedFormGroup;
    }
    get mpan(): ControlWithErrors {
        return this.form.get('contactFormGroup').get('mpan');
    }
    get mprn(): ControlWithErrors {
        return this.form.get('contactFormGroup').get('mprn');
    }

    get generalFormGroup(): UntypedFormGroup {
        return this.form.get('generalFormGroup') as UntypedFormGroup;
    }
    get birthDate(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('birthDate');
    }
    get supplierAccountNumber(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('supplierAccountNumber');
    }
    get hasSmartMeter(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('hasSmartMeter');
    }
    get employmentStatus(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('employmentStatus');
    }
    get residentialStatus(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('residentialStatus');
    }
    get currentAddressYears(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('currentAddressYears');
    }
    get currentAddressMonths(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('currentAddressMonths');
    }
    get previousAddresses(): UntypedFormArray {
        return <UntypedFormArray>this.form.get('generalFormGroup').get('previousAddresses');
    }
    get salutation(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('salutation');
    }

    get hasSeparateBillingAddress(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('hasSeparateBillingAddress');
    }
    get billingPostcode(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('billingPostcode');
    }
    get billingAddress(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('billingAddress');
    }

    get additionalRequirementsCategorized(): UntypedFormArray {
        return <UntypedFormArray>this.form.get('generalFormGroup').get('additionalRequirementsCategorized');
    }
    get additionalRequirements(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('additionalRequirements');
    }
    get requirementsCategories(): {
        category: string;
        requirementKey: string;
        requirementLabel: string;
    }[][] {
        const groups = requirements
            .filter(
                (x) =>
                    !x.exclude.some((used) => used === this.registration.proposal.winningTariffSupplierName),
            )
            .reduce(
                (all, req) => ({
                    ...all,
                    [req.category]: [...(all[req.category] || []), req],
                }),
                {},
            );
        return Object.keys(groups).map((x) => groups[x]);
    }
    get requirementsFlat(): KeyValue<string, string>[] {
        return requirements
            .filter(
                (x) =>
                    !x.exclude.some((used) => used === this.registration.proposal.winningTariffSupplierName),
            )
            .sort((a, b) => (a.flatOrder > b.flatOrder ? 1 : -1))
            .map<KeyValue<string, string>>((x) => ({
                key: x.requirementKey,
                value: x.requirementLabel,
            }));
    }
    get SupplierSetting(): SupplierSetting {
        return supplierSettings.filter(
            (s) => s.name === this.registration.proposal.winningTariffSupplierName,
        )[0];
    }

    get agreesToSupplierSharingInformationWithNetworkOperator(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('agreesToSupplierSharingInformationWithNetworkOperator');
    }
    get supplierContactMethod(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('supplierContactMethod');
    }
    get supplierContactMethods(): UntypedFormArray {
        return <UntypedFormArray>this.form.get('generalFormGroup').get('supplierContactMethods');
    }
    get allowedSalutations(): string[] {
        const setting = this.SupplierSetting;
        if ((setting?.disallowedTitles?.length ?? 0) === 0) {
            return defaultSalutations;
        }
        return defaultSalutations.filter((x) => !setting.disallowedTitles.some((d) => d === x));
    }
    get optionalSalutations(): string[] {
        const setting = this.SupplierSetting;
        if ((setting?.disallowedTitles?.length ?? 0) === 0) {
            return null;
        }
        return this.allowedSalutations.some((x) => this.registration.contact.salutation === x)
            ? null
            : this.allowedSalutations;
    }

    get allowsPublicity(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('allowsPublicity');
    }
    get agreesToSupplierConditions(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('agreesToSupplierConditions');
    }
    get agreesToSupplierCreditCheck(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('agreesToSupplierCreditCheck');
    }
    get waitTillWaiveDateToSwitch(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('waitTillWaiveDateToSwitch');
    }
    get agreesToSupplierCommunicationThirdParty(): ControlWithErrors {
        return this.form.get('generalFormGroup').get('agreesToSupplierCommunicationThirdParty');
    }

    get bankFormGroup(): UntypedFormGroup {
        return this.form.get('bankFormGroup') as UntypedFormGroup;
    }
    get bankAccountFormGroup(): UntypedFormGroup {
        return this.form.get('bankAccountFormGroup') as UntypedFormGroup;
    }
    get firstName(): ControlWithErrors {
        return this.form.get('bankFormGroup').get('firstName');
    }
    get lastName(): ControlWithErrors {
        return this.form.get('bankFormGroup').get('lastName');
    }
    get accountNumber(): ControlWithErrors {
        return this.form.get('bankAccountFormGroup').get('accountNumber');
    }
    get sortCode(): ControlWithErrors {
        return this.form.get('bankAccountFormGroup').get('sortCode');
    }
    get preferredPaymentDay(): ControlWithErrors {
        return this.form.get('bankFormGroup').get('preferredPaymentDay');
    }
    get authorizedDirectDebit(): ControlWithErrors {
        return this.form.get('bankFormGroup').get('authorizedDirectDebit');
    }

    get comparisonTariffName(): string {
        if (this.comparisonSituation === this.ComparisonSituations.WithCurrent) {
            return 'Your current tariff';
        }
        if (this.comparisonSituation === this.ComparisonSituations.WithStandard) {
            return 'Standard variable tariff';
        }
        if (this.comparisonSituation === this.ComparisonSituations.DefaultAndRenewal) {
            if (!this.registration.decision.selectedComparison) {
                return 'Compare with:';
            }
            return this.registration.decision.wantsToCompareToRenewalTariff
                ? 'Your renewal tariff'
                : 'Standard variable tariff';
        }
        return '';
    }

    get comparisonTariffTotalCost(): number | null {
        if (this.comparisonSituation === this.ComparisonSituations.WithCurrent) {
            return this.registration.proposal.currentTariffTotalCost;
        }
        if (this.comparisonSituation === this.ComparisonSituations.WithStandard) {
            return this.registration.proposal.standardTariffTotalCost;
        }
        if (this.comparisonSituation === this.ComparisonSituations.DefaultAndRenewal) {
            return this.registration.decision.wantsToCompareToRenewalTariff
                ? this.registration.proposal.renewalTariffTotalCost
                : this.registration.proposal.standardTariffTotalCost;
        }
        if (this.comparisonSituation === this.ComparisonSituations.None) {
            return null;
        }

        return null;
    }

    get comparisonTariffSaving(): number | null {
        if (this.comparisonSituation === this.ComparisonSituations.WithCurrent) {
            return this.registration.proposal.currentTariffSaving;
        }
        if (this.comparisonSituation === this.ComparisonSituations.WithStandard) {
            return this.registration.proposal.standardTariffSaving;
        }
        if (this.comparisonSituation === this.ComparisonSituations.DefaultAndRenewal) {
            return this.registration.decision.wantsToCompareToRenewalTariff
                ? this.registration.proposal.renewalTariffSaving
                : this.registration.proposal.standardTariffSaving;
        }
        if (this.comparisonSituation === this.ComparisonSituations.None) {
            return null;
        }

        return null;
    }

    get comparisonTarriffIsProfit(): boolean {
        window['vwoIsSaving'] = 'unavailable';
        const saving = this.comparisonTariffSaving;
        if (!saving) {
            return null;
        }
        window['vwoIsSaving'] = Math.round(saving) > 0 ? 'saving' : 'not_saving';
        return Math.round(saving) > 0;
    }

    get isExistingCustomer(): boolean {
        switch (this.registration.product.contract) {
            case Contracts.Dual:
            case Contracts.Electricity:
                return (
                    this.registration.product.electricitySupplier ===
                    this.registration.proposal.winningTariffSupplierName
                );
            case Contracts.Separate:
                return (
                    this.registration.product.electricitySupplier ===
                        this.registration.proposal.winningTariffSupplierName &&
                    this.registration.product.gasSupplier ===
                        this.registration.proposal.winningTariffSupplierName
                );
            default:
                throw new Error('Unknown contract type');
        }
    }

    get isExistingCustomerBasedOnActualSuppliers(): boolean {
        if (
            !this.registration.product?.contract ||
            !this.registration.product?.actualElectricitySupplier ||
            !this.registration.proposal?.winningTariffSupplierName ||
            this.paymentMethod === PaymentMethod.PrePayment
        ) {
            return false;
        }

        switch (this.registration.product.contract) {
            case Contracts.Dual:
            case Contracts.Electricity:
                return (
                    this.registration.product?.actualElectricitySupplier ===
                    this.registration.proposal?.winningTariffSupplierName
                );
            case Contracts.Separate:
                return (
                    this.registration.product?.actualElectricitySupplier ===
                        this.registration.proposal?.winningTariffSupplierName ||
                    this.registration.product?.actualGasSupplier ===
                        this.registration.proposal?.winningTariffSupplierName
                );
            default:
                throw new Error('Unknown contract type');
        }
    }

    get today(): NgbDateStruct {
        const d = new Date();
        return {
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: d.getDate(),
        };
    }

    get startDate(): NgbDateStruct {
        if (this.birthDate.value) {
            return this.birthDate.value;
        }
        return { year: 1990, month: 1, day: 1 };
    }

    get showWaiveDateCheckbox(): boolean {
        if (!this.registration.proposal.waivePeriodStartDate) {
            return false;
        }
        const now = moment.utc(moment.now());
        const waivePeriodStartDate = moment.utc(
            this.registration.proposal.waivePeriodStartDate,
            moment.ISO_8601,
        );
        if (
            waivePeriodStartDate.isAfter(now) &&
            waivePeriodStartDate.isBefore(AuctionHelper.getPublicClosureDate(this.registration.auction))
        ) {
            return true;
        }
        return false;
    }

    get showWaiveDateInfoText(): boolean {
        if (!this.registration.proposal.waivePeriodStartDate) {
            return false;
        }
        const waivePeriodStartDate = moment.utc(
            this.registration.proposal.waivePeriodStartDate,
            moment.ISO_8601,
        );
        if (waivePeriodStartDate.isAfter(AuctionHelper.getPublicClosureDate(this.registration.auction))) {
            return true;
        }
        return false;
    }

    get prettyWaiveDate(): string {
        return moment(this.registration.proposal.waivePeriodStartDate).format('MMM Do YYYY');
    }

    get supplierNameKebabCase(): string {
        return StringHelpers.convertToKebab(this.registration.proposal.winningTariffSupplierName);
    }

    constructor(
        private fb: UntypedFormBuilder,
        private addressesService: AddressesService,
        private registrationService: RegistrationService,
        public modalService: NgbModal,
        private chat: ChatService,
        private kenalyticsService: KenalyticsService,
        private comparisonService: ComparisonService,
        private comparisonAbTestService: ComparisonAbTestService,
        private mpasService: MpasService,
        private supplierService: SupplierService,
        private vwoService: VwoService,
        private router: Router,
        private analyticsService: AnalyticsService,
    ) {
        this.form = this.fb.group({
            contactFormGroup: this.fb.group({
                mpan: [''],
                mprn: [''],
            }),
            generalFormGroup: this.fb.group({
                salutation: ['', this.salutationValidator()],
                birthDate: [
                    '',
                    [Validators.required, this.birthDateMinAgeValidator(), this.birthDateMaxAgeValidator()],
                ],
                supplierAccountNumber: ['', [Validators.maxLength(15)]],
                hasSmartMeter: [],
                employmentStatus: ['', this.requiredForEon()],
                residentialStatus: ['', this.requiredForEon()],
                currentAddressYears: ['', this.requiredForShell()],
                currentAddressMonths: [
                    '',
                    [this.requiredWhenCurrentAddressYearsIsTooLowAndSupplier(3, 'Shell Energy')],
                ],
                previousAddresses: this.fb.array([]),

                additionalRequirements: ['', []],
                agreesToSupplierSharingInformationWithNetworkOperator: ['', []],
                supplierContactMethod: ['', []],
                supplierContactMethods: this.fb.array(
                    this.contactMethods.map(() => this.fb.control(false)),
                    this.requiredWhenAllowsPublicityForSuppliers(['Shell Energy']),
                ),

                hasSeparateBillingAddress: [false, []],
                billingPostcode: [
                    '',
                    [this.requiredWhenhasSeparateBillingAddress(), this.postcodeValidator()],
                ],
                billingAddress: ['', this.requiredWhenhasSeparateBillingAddress()],

                allowsPublicity: [false],
                agreesToSupplierConditions: [false, Validators.requiredTrue],
                agreesToSupplierCreditCheck: [false, this.requiredWhenHasSeparateCreditCheckAgreement()],
                waitTillWaiveDateToSwitch: [],
                agreesToSupplierCommunicationThirdParty: [false],
            }),
            bankFormGroup: this.fb.group({
                firstName: ['', [this.requiredForMonthlyDirectDebit(), Validators.maxLength(100)]],
                lastName: ['', [this.requiredForMonthlyDirectDebit(), Validators.maxLength(100)]],
                preferredPaymentDay: ['', this.requiredForMonthlyDirectDebitWhenAskingPreferredPaymentDate()],
                authorizedDirectDebit: [false, this.requiredToBeTrueForMonthlyDirectDebit()],
            }),
            bankAccountFormGroup: this.fb.group(
                {
                    accountNumber: ['', [this.requiredForMonthlyDirectDebit(), Validators.minLength(8)]],
                    sortCode: ['', [this.requiredForMonthlyDirectDebit(), Validators.minLength(6)]],
                },
                { asyncValidators: bankAccountValidator.bind(this) },
            ),
        });

        this.form.valueChanges.subscribe(() => {
            this.pendingChangesUpdate.emit(this.form.dirty);
        });
        this.postCodeValueChanges(this.billingPostcode.valueChanges).subscribe(
            (addresses) => {
                this.addresses = addresses;
                if (this.entityBillingAddress) {
                    const theOne = this.addresses.find((address) =>
                        Address.equalsPostalData(address, this.entityBillingAddress),
                    );
                    if (theOne) {
                        this.billingAddress.setValue(theOne);
                    }
                }
            },
            (_error) => {
                this.billingPostcodeError = true;
            },
        );

        this.billingAddress.valueChanges
            .pipe(filter((billingAddress) => billingAddress))
            .subscribe((billingAddress) => this.addBillingAddress(billingAddress));

        this.currentAddressYears.valueChanges.subscribe(() => {
            this.currentAddressMonths.updateValueAndValidity();
        });

        this.allowsPublicity.valueChanges.subscribe(() => {
            this.supplierContactMethod.updateValueAndValidity();
        });

        this.hasSeparateBillingAddress.valueChanges.subscribe(() => {
            this.billingAddress.updateValueAndValidity();
            this.billingPostcode.updateValueAndValidity();
        });

        merge(
            this.currentAddressYears.valueChanges,
            this.currentAddressMonths.valueChanges,
            this.previousAddresses.valueChanges,
        )
            .pipe(
                debounceTime(100),
                filter(
                    () =>
                        this.minRequiredAddressHistoryYears > 0 &&
                        this.currentAddressYears.valid &&
                        this.currentAddressMonths.valid,
                ),
            )
            .subscribe(() => {
                const decisionAddresses: DecisionAddress[] = this.previousAddresses.controls.map(
                    (control) => control.value,
                );

                // Calculate the combined address history (in years)
                let addressHistoryYears =
                    (this.currentAddressYears.value || 0) + (this.currentAddressMonths.value || 0) / 12;
                for (let idx = 0; idx < decisionAddresses.length; idx++) {
                    if (addressHistoryYears >= this.minRequiredAddressHistoryYears) {
                        // Remove excess addresses if we have more than we need
                        while (this.previousAddresses.length > idx) {
                            this.previousAddresses.removeAt(idx);
                        }
                        return;
                    }
                    addressHistoryYears +=
                        (decisionAddresses[idx].addressYears || 0) +
                        (decisionAddresses[idx].addressMonths || 0) / 12;
                }

                if (
                    addressHistoryYears < this.minRequiredAddressHistoryYears &&
                    this.previousAddresses.length < this.maxPreviousAddresses
                ) {
                    // Add a new control if needed (but never more than maxPreviousAddresses)
                    const lastDecisionAddress: Partial<DecisionAddress> = decisionAddresses.pop();
                    if (lastDecisionAddress == null || lastDecisionAddress.addressYears != null) {
                        this.previousAddresses.push(
                            this.fb.control({
                                order: this.previousAddresses.length + 1,
                            }),
                        );
                    }
                }
            });
    }

    public get showBankQuestions(): boolean {
        return (
            this.paymentMethod === PaymentMethod.MonthlyDirectDebit &&
            (this.registration.proposal.winningTariffSupplierName !== 'Shell Energy' ||
                (this.registration.proposal.winningTariffSupplierName === 'Shell Energy' &&
                    !this.isExistingCustomerBasedOnActualSuppliers))
        );
    }

    @Input()
    registration: Registration;

    @Input()
    submitting: boolean;

    @Output()
    refreshRequested = new EventEmitter<Registration>();

    @Output()
    updateRequested = new EventEmitter<Registration>();

    @Output()
    submitted = new EventEmitter<Registration>();

    @Output()
    pendingChangesUpdate = new EventEmitter<boolean>();

    @Output()
    navigateToTab = new EventEmitter<string>();

    PaymentMethod: typeof PaymentMethod = PaymentMethod;

    private destroyed$ = new Subject<void>();
    form: UntypedFormGroup;
    excluded = false;
    submitRequested = false;
    showDecision = false;
    hasCustomSmartMeterWarning = false;
    showPreferredPaymentDay = true;
    hasSeparateCreditCheckAgreement = false;
    hasExistingCustomerCheck = false;
    showRequirements = false;
    requirementsUseCategory = false;
    showPossibleLossOfFunctionalityWhenSmartMeter = true;
    showCurrentAddressYears = false;
    minRequiredAddressHistoryYears = 0;
    maxPreviousAddresses = 0;
    requirePublicityConsent = true;
    requireThirdPartyCommunicationConsent = true;

    addresses: Address[];
    entityBillingAddress: Address;
    entityPreviousAddress: Address;
    billingPostcodeError = false;
    previousPostCodeError = false;

    paymentDays = Array.apply(0, Array(28)).map((_, index) => index + 1);

    public supplierLogoUri$: Observable<string>;

    employmentStatuses: KeyValue<EmploymentStatus, string>[] = [
        { key: EmploymentStatus.EmployedFullTime, value: 'Employed Full Time' },
        { key: EmploymentStatus.EmployedPartTime, value: 'Employed Part Time' },
        { key: EmploymentStatus.SelfEmployed, value: 'Self Employed' },
        { key: EmploymentStatus.Unemployed, value: 'Unemployed' },
        { key: EmploymentStatus.Retired, value: 'Retired' },
        { key: EmploymentStatus.Homemaker, value: 'Homemaker' },
        { key: EmploymentStatus.Student, value: 'Student' },
    ];
    residentialStatuses: KeyValue<ResidentialStatus, string>[] = [
        { key: ResidentialStatus.HomeOwner, value: 'Home owner' },
        { key: ResidentialStatus.Tenant, value: 'Tenant' },
        { key: ResidentialStatus.Landlord, value: 'Landlord' },
    ];
    addressYears: KeyValue<number, string>[] = [
        { key: 0, value: '0' },
        { key: 1, value: '1' },
        { key: 2, value: '2' },
        { key: 3, value: '3' },
        { key: 4, value: '4' },
        { key: 5, value: '5+' },
    ];
    addressMonths = Array.apply(0, Array(11)).map((_, index) => index + 1);

    contactMethods: KeyValue<SupplierContactMethod, string>[] = [
        { key: SupplierContactMethod.Email, value: 'Email' },
        // Not needed for Shell { key: SupplierContactMethod.Post, value: 'Letter' },
        { key: SupplierContactMethod.Phone, value: 'Phone' },
        { key: SupplierContactMethod.Sms, value: 'SMS (text message)' },
    ];

    ComparisonSituations: typeof Comparisons = Comparisons;
    comparisonSituation: Comparisons;

    WhatToTest: typeof WhatToTest = WhatToTest;
    comparisonAbTest: WhatToTest;

    Contracts: typeof Contracts = Contracts;
    public mpansLookup: string[] = [];

    public selectedSuppliers: string[];

    public contactMethodsForSupplier(supplier: string): KeyValue<SupplierContactMethod, string>[] {
        switch (supplier) {
            case 'Shell Energy':
                return this.contactMethods.filter((i) => i.key !== SupplierContactMethod.Post);
            default:
                return this.contactMethods;
        }
    }

    addYearsFromToday(years: number, lowerDate?: boolean): NgbDateStruct {
        const d = new Date();
        d.setFullYear(d.getFullYear() + years);
        return {
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: lowerDate ? d.getDate() + 1 : d.getDate(),
        };
    }

    postCodeValueChanges(valueChanges: Observable<any>): Observable<Address[]> {
        return valueChanges.pipe(
            map((query) => (query ? query.replace(/ /g, '') : null)),
            filter((query) => query && this.truthyPostcode(query)),
            distinctUntilChanged(),
            debounceTime(300),
            switchMap((query) => {
                this.billingPostcodeError = false;
                return this.addressesService.get(query);
            }),
        );
    }

    requiredWhen(predicate: () => boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.form && predicate() && control.value === null ? { required: true } : null;
        };
    }

    requiredForMonthlyDirectDebit(): ValidatorFn {
        return this.requiredWhen(
            () =>
                !this.registration.product.hasPrepaymentMeter &&
                (this.registration.proposal.winningTariffSupplierName !== 'Shell Energy' ||
                    (this.registration.proposal.winningTariffSupplierName === 'Shell Energy' &&
                        !this.isExistingCustomerBasedOnActualSuppliers)),
        );
    }

    requiredForMonthlyDirectDebitWhenAskingPreferredPaymentDate(): ValidatorFn {
        return this.requiredWhen(() => {
            return (
                this.showPreferredPaymentDay &&
                !this.registration.product.hasPrepaymentMeter &&
                !this.registration.communicationBySnailMail &&
                (this.registration.proposal.winningTariffSupplierName !== 'Shell Energy' ||
                    (this.registration.proposal.winningTariffSupplierName === 'Shell Energy' &&
                        !this.isExistingCustomerBasedOnActualSuppliers))
            );
        });
    }

    requiredToBeTrueForMonthlyDirectDebit(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.form &&
                !this.registration.product.hasPrepaymentMeter &&
                !this.isExistingCustomerBasedOnActualSuppliers &&
                control.value !== true
                ? { required: true }
                : null;
        };
    }

    private requiredWhenCurrentAddressYearsIsTooLowAndSupplier(limit: number = 3, supplierName: string) {
        return this.requiredWhen(
            () =>
                this.currentAddressYears &&
                this.currentAddressYears.value < limit &&
                this.registration.proposal.winningTariffSupplierName === supplierName &&
                !this.isExistingCustomerBasedOnActualSuppliers,
        );
    }

    private requiredForEon() {
        return this.requiredWhen(() => this.registration.proposal.winningTariffSupplierName === 'E.ON');
    }

    private requiredForShell() {
        return this.requiredWhen(
            () =>
                this.registration.proposal.winningTariffSupplierName === 'Shell Energy' &&
                !this.isExistingCustomerBasedOnActualSuppliers,
        );
    }

    private requiredWhenAllowsPublicityForSuppliers(suppliers: string[]): ValidatorFn {
        return (): ValidationErrors | null => {
            return this.form &&
                this.allowsPublicity.value === true &&
                suppliers.includes(this.registration.proposal.winningTariffSupplierName) &&
                !this.generalFormGroup.controls['supplierContactMethods'].value.some(
                    (selected?: boolean) => selected === true,
                )
                ? { required: true }
                : null;
        };
    }

    private requiredWhenhasSeparateBillingAddress() {
        return this.requiredWhen(
            () => this.hasSeparateBillingAddress && this.hasSeparateBillingAddress.value === true,
        );
    }

    private requiredWhenHasSeparateCreditCheckAgreement() {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.form && this.hasSeparateCreditCheckAgreement && control.value !== true
                ? { required: true }
                : null;
        };
    }

    private postcodeValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            return !this.truthyPostcode(control.value) ? { pattern: { value: control.value } } : null;
        };
    }

    private truthyPostcode(postcode: string): boolean {
        const formatted = postcode.replace(/ /g, '');
        return (
            formatted.length >= 5 &&
            formatted.length <= 7 &&
            formatted.match(/[0-9][a-zA-Z][a-zA-Z]$/) !== null
        );
    }

    private birthDateMaxAgeValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            return this.birthDateMaxAge(control.value) ? { tooOld: { value: control.value } } : null;
        };
    }

    private birthDateMinAgeValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            return this.birthDateMinAge(control.value) ? { tooYoung: { value: control.value } } : null;
        };
    }

    private birthDateMaxAge(birthDate: any): boolean {
        const birthDateMoment = moment.utc(
            `${birthDate.year}-${birthDate.month}-${birthDate.day}`,
            'YYYY-MM-DD',
        );
        return moment.utc(moment.now()).subtract(120, 'y').isSameOrAfter(birthDateMoment);
    }

    private birthDateMinAge(birthDate: any): boolean {
        const birthDateMoment = moment.utc(
            `${birthDate.year}-${birthDate.month}-${birthDate.day}`,
            'YYYY-MM-DD',
        );
        return moment.utc(moment.now()).subtract(18, 'y').isSameOrBefore(birthDateMoment);
    }

    private addBillingAddress(address: Address) {
        const theOne = this.addresses
            ? this.addresses.find((entry) => Address.equalsPostalData(entry, address))
            : null;
        if (theOne) {
            Object.keys(Address.emptyAddress()).forEach((key) => {
                this.registration.billingAddress[key] = address[key];
            });
        }
    }

    private salutationValidator(): ValidatorFn {
        return this.requiredWhen(
            () =>
                this.optionalSalutations !== null &&
                !this.allowedSalutations.some((x) => this.registration.contact.salutation === x),
        );
    }

    acceptOffer(targetElement: any): void {
        this.registration.proposalAccepted = true;
        setTimeout(() => {
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }, 100);
        this.showDecision = true;
    }

    openDeclinePopup(content: any): void {
        this.modalService.open(content);
    }

    // TODO: this wasn't fully implemented (and isn't currently used) - for example contract end date checks are missing
    // compareToOwnTariff() {
    //    const modalRef = this.modalService.open(TariffLookupModalComponent, { size: 'lg', backdrop: 'static' });
    //    const modal = modalRef.componentInstance as TariffLookupModalComponent;
    //
    //    modal.registration = this.registration;
    //    modal.resetForm(this.currentTariffModalData);
    //
    //    modalRef.result
    //        .then(
    //            (result: boolean) => {
    //                if (result) {
    //                    this.save();
    //                    this.currentTariffModalData = modal.model;
    //                }
    //            },
    //            result => { }
    //        );
    // }

    compareToStandardTariff(): void {
        this.registration.decision.wantsToCompareToOwnTariff = false;
        this.registration.decision.wantsToCompareToRenewalTariff = false;
        this.registration.decision.selectedComparison = SelectedComparison.Standard;
        this.save();
    }

    compareToRenewalTariff(): void {
        const modalRef = this.modalService.open(TariffLookupModalComponent, {
            size: 'lg',
            backdrop: 'static',
        });
        const modal = modalRef.componentInstance as TariffLookupModalComponent;

        modal.registration = this.registration;
        modal.forRenewalTariff = true;
        modal.resetForm(this.registration.product.renewalTariff);

        modalRef.result.then(
            (result: boolean) => {
                if (result) {
                    this.registration.decision.selectedComparison = SelectedComparison.Standard;
                    this.save();
                }
            },
            (_result) => {},
        );
    }

    canSwitchWithoutExitFee(): boolean {
        if (this.registration.proposal.waivePeriodStartDate) {
            const waivePeriodStartDate = moment.utc(
                this.registration.proposal.waivePeriodStartDate,
                moment.ISO_8601,
            );
            if (
                waivePeriodStartDate.isBefore(AuctionHelper.getPublicClosureDate(this.registration.auction))
            ) {
                return true;
            }
            return false;
        }
        return false;
    }

    potentialExitFee(): boolean {
        return !this.canSwitchWithoutExitFee();
    }

    save(): void {
        this.updateRequested.emit(this.registration);
    }

    ngOnChanges(): void {
        // keeping this in untill the auction is live.
        const formModel = {
            contactFormGroup: null,
            generalFormGroup: null,
            bankFormGroup: null,
            bankAccountFormGroup: null,
        };

        formModel.contactFormGroup = makeFormModel(
            this.form.controls['contactFormGroup'] as UntypedFormGroup,
            this.registration.contact,
            [],
        );
        formModel.generalFormGroup = makeFormModel(
            this.form.controls['generalFormGroup'] as UntypedFormGroup,
            this.registration.decision,
            [],
        );
        formModel.bankFormGroup = makeFormModel(
            this.form.controls['bankFormGroup'] as UntypedFormGroup,
            this.registration.decision,
            [],
        );
        formModel.bankAccountFormGroup = makeFormModel(
            this.form.controls['bankAccountFormGroup'] as UntypedFormGroup,
            this.registration.decision,
            [],
        );

        formModel.bankAccountFormGroup.authorizedDirectDebit = false;
        formModel.generalFormGroup.agreesToSupplierConditions = false;
        formModel.generalFormGroup.agreesToSupplierCreditCheck = false;
        formModel.generalFormGroup.allowsPublicity = false;
        formModel.generalFormGroup.waitTillWaiveDateToSwitch = true;
        formModel.generalFormGroup.hasSeparateBillingAddress = false;

        switch (this.registration.proposal.winningTariffSupplierName) {
            case 'E.ON':
                this.showPreferredPaymentDay = true;
                this.hasSeparateCreditCheckAgreement = false;
                this.hasExistingCustomerCheck = true;
                this.showRequirements = true;
                this.requirementsUseCategory = true;
                this.showPossibleLossOfFunctionalityWhenSmartMeter = true;
                this.showCurrentAddressYears = true;
                this.minRequiredAddressHistoryYears = 3;
                this.maxPreviousAddresses = 1;
                break;

            case 'Omni Energy':
                this.hasCustomSmartMeterWarning = true;
                this.showPreferredPaymentDay = false;
                this.hasSeparateCreditCheckAgreement = false;
                this.hasExistingCustomerCheck = false;
                this.showRequirements = true;
                this.requirementsUseCategory = false;
                this.showPossibleLossOfFunctionalityWhenSmartMeter = false;
                this.showCurrentAddressYears = false;
                this.minRequiredAddressHistoryYears = 0;
                this.maxPreviousAddresses = 0;
                this.requirePublicityConsent = false;

                this.hasSmartMeter.valueChanges
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((hasSmartMeter) => {
                        this.excluded = hasSmartMeter;
                    });
                break;

            case 'So Energy':
                this.showPreferredPaymentDay = false;
                this.hasSeparateCreditCheckAgreement = false;
                this.hasExistingCustomerCheck = false;
                this.requireThirdPartyCommunicationConsent = false;
                break;
            case 'SSE':
                formModel.generalFormGroup.waitTillWaiveDateToSwitch = false;
                this.showPreferredPaymentDay = false;
                this.hasSeparateCreditCheckAgreement = true;
                this.hasExistingCustomerCheck = false;
                break;
            case 'Together Energy':
                this.showRequirements = true;
                break;
            case 'Shell Energy':
                this.hasCustomSmartMeterWarning = true;
                this.showPossibleLossOfFunctionalityWhenSmartMeter = true;
                this.showCurrentAddressYears = true;
                this.minRequiredAddressHistoryYears = 3;
                this.maxPreviousAddresses = 2;
                this.requireThirdPartyCommunicationConsent = !this.isExistingCustomerBasedOnActualSuppliers;
                break;
        }

        if (this.missingMpan) {
            this.mpasService
                .getDetailsFromAddress(Object.assign(new Address(), this.registration.contact))
                .pipe(catchError(() => of([])))
                .subscribe((results) => {
                    this.mpansLookup = (results || [])
                        .filter((result) => result.mpanActive)
                        .map((result) => result.mpan);
                });
            this.mpan.setValidators([Validators.required, CustomValidators.mpan()]);
        }
        if (this.missingMprn) {
            this.mprn.setValidators([Validators.required, CustomValidators.mprn()]);
        }

        this.form.reset(formModel);

        if (this.registration.id) {
            const theOne = Object.assign(new Address(), this.registration.decision);
            this.entityBillingAddress = theOne;
            this.billingPostcode.setValue(
                this.registration.billingAddress.postcode ? this.registration.billingAddress.postcode : null,
            );
        }
    }

    ngOnInit(): void {
        this.chat.likeIts1999('proposal');
        this.comparisonSituation = this.comparisonService.WhichComparisonToDisplay(this.registration);
        this.comparisonAbTest = this.comparisonAbTestService.test(
            this.registration.id,
            this.registration.number,
            this.registration.community.code,
        );
        this.kenalyticsService.postPageView(this.registration.id, 'register/proposal');

        this.selectedSuppliers = [
            this.registration.product.electricitySupplierCode,
            this.registration.product.gasSupplierCode,
            this.registration.product.dualSupplierCode,
        ];

        this.generalFormGroup.addControl(
            'additionalRequirementsCategorized',
            this.fb.array(
                this.requirementsCategories.map((x: any) =>
                    this.fb.array(x.map(() => this.fb.control(false))),
                ),
            ),
        );

        this.supplierLogoUri$ = this.supplierService
            .getSupplierOffer(this.registration.auctionId, this.registration.proposal.winningTariffSupplierId)
            .pipe(map((s) => s.logoImageUri));
    }

    ngAfterViewInit(): void {
        this.vwoService.push(RegisterStep.Proposal);
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public abstractControlToFormControl(control: AbstractControl): UntypedFormControl {
        return control as UntypedFormControl;
    }

    declineOffer(modal: any): void {
        if (modal) {
            modal.close('decline close');
        }

        this.registration.proposalAccepted = false;
        this.registration.decisionComplete = true;
        this.submitted.emit(this.registration);
    }

    submit(): void {
        this.form.updateValueAndValidity();
        this.submitRequested = true;

        const events = ErrorEvent.fromForm(this.form);
        events.forEach((e) => this.analyticsService.push(e));

        if (this.form.valid) {
            applyFormValue(this.contactFormGroup, this.registration.contact, []);
            applyFormValue(this.generalFormGroup, this.registration.decision, [
                'previousAddress',
                'previousPostCode',
                'supplierContactMethod',
                'billingAddress',
                'billingPostcode',
                'supplierContactMethods',
                'additionalRequirements',
            ]);

            if (this.showWaiveDateCheckbox) {
                this.registration.decision.waitTillWaiveDateToSwitch = this.waitTillWaiveDateToSwitch.value;
            } else {
                this.registration.decision.waitTillWaiveDateToSwitch = null;
            }

            this.registration.previousAddresses = this.previousAddresses.value;

            if (this.paymentMethod === PaymentMethod.MonthlyDirectDebit) {
                applyFormValue(this.bankFormGroup, this.registration.decision, []);
                applyFormValue(this.bankAccountFormGroup, this.registration.decision, []);
            }

            if (this.registration.decision.allowsPublicity === null) {
                this.registration.decision.allowsPublicity = false;
            }

            if (!this.registration.decision.allowsPublicity) {
                this.registration.decision.supplierContactMethods = [];
            } else if (this.generalFormGroup.value['supplierContactMethod']) {
                this.registration.decision.supplierContactMethods = [this.supplierContactMethod.value];
            } else {
                this.registration.decision.supplierContactMethods = this.supplierContactMethods.value
                    .map((selected: boolean, i: number) => (selected ? this.contactMethods[i].key : null))
                    .filter((selected: any) => selected);
            }

            if (this.registration.product.priorityServiceRegister && this.showRequirements) {
                if (this.requirementsUseCategory) {
                    this.registration.decision.additionalRequirements =
                        this.additionalRequirementsCategorized.value
                            .map((group: any[], groupIndex: number) =>
                                group.map((selected: boolean, i: number) =>
                                    selected
                                        ? this.requirementsCategories[groupIndex][i].requirementKey
                                        : null,
                                ),
                            )
                            .flat(1)
                            .filter((requirement: string) => requirement !== null);
                } else {
                    this.registration.decision.additionalRequirements = [this.additionalRequirements.value];
                }
            }

            this.registration.decision.paymentMethod = this.paymentMethod;
            // Credit check agreement is tied up with conditions for winning supplier
            if (!this.hasSeparateCreditCheckAgreement) {
                this.registration.decision.agreesToSupplierCreditCheck =
                    this.registration.decision.agreesToSupplierConditions;
            }

            if (this.birthDate.value) {
                this.registration.decision.birthDate = new Date(
                    Date.UTC(
                        this.birthDate.value.year,
                        this.birthDate.value.month - 1,
                        this.birthDate.value.day,
                    ),
                );
            }
            this.registration.decisionComplete = true;

            this.submitted.emit(this.registration);
        }
    }

    onNavigateToTab(tabName: string): void {
        this.navigateToTab.emit(tabName);
    }

    // *HACK: remove after may-2021
    wantToParticipateInNextAuction(): void {
        this.registrationService.excludeAndParticipateInNextAction(this.registration).subscribe(() => {
            this.refreshRequested.emit(this.registration);
        });
    }
}
