import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { InMemoryQueryService } from '@common/services/inmemory.query.service';
import { Brand } from '@lang/uk/model/brand';
import { BrandService } from '@lang/uk/services/brand.service';
import { BehaviorSubject } from 'rxjs';
import { BUSINESS } from '@common/app/app.config';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
    private menuElement: ElementRef;
    mobileMenuExpanded = false;
    private activeBrand: Brand;

    isLoading$ = new BehaviorSubject<boolean>(true);
    isMobileDevice = false;
    showMenu$ = new BehaviorSubject<boolean>(true);
    showLinks$ = new BehaviorSubject<boolean>(true);
    public showInstagramIcon: boolean;
    // Because we had issue's with the top navigation on mobile devices
    // we now use 2 different html structures for mobile/desktop
    // some classes of the navigation are deleted for when the application
    // is opened on either mobile or desktop to ensure the correct behavior.

    // for the desktop we use plain bootstrap
    // for mobile we use ngBootstrap

    @HostListener('window:resize', ['$event'])
    @HostListener('fullscreenchange', ['$event'])
    @HostListener('webkitfullscreenchange', ['$event'])
    @HostListener('mozfullscreenchange', ['$event'])
    @HostListener('MSFullscreenChange', ['$event'])
    onResize(): void {
        this.isMobileDevice = window.innerWidth <= 1200 ? true : false;
        setTimeout(() => {
            this.hideMobileClasses();
        }, 500);
    }

    @ViewChild('menu') set menu(menu: ElementRef) {
        if (menu) {
            this.menuElement = menu;
            this.hideMobileClasses();
        }
    }

    constructor(
        private renderer: Renderer2,
        private brandService: BrandService,
        private queryService: InMemoryQueryService,
        private contentService: ContentService,
        @Inject(BUSINESS) business: string,
    ) {
        this.isMobileDevice = window.innerWidth <= 1200;
        this.showInstagramIcon = business == 'enuk';
    }

    ngOnInit(): void {
        this.applyQueryServiceParams();
        this.brandService.getActive().subscribe((activeBrand) => {
            this.activeBrand = activeBrand;
            this.isLoading$.next(false);
        });
    }

    applyQueryServiceParams(): void {
        this.queryService.getObservable$('app-menu').subscribe((e) => {
            if (e.name === 'showMenu' && e.value !== null) {
                this.showMenu$.next(e.value);
            }
            if (e.name === 'reload' && e.value === true) {
                this.contentService.load(null, true);
            }
            if (e.name === 'showMenuLinks' && e.value !== null) {
                this.showLinks$.next(e.value);
            }
        });
    }

    hideMobileClasses(): void {
        if (this.menuElement && !this.isMobileDevice) {
            //When the menu is opened on desktop, delete 'show' & 'collapse' classes which are added by ngBootstrap
            this.renderer.removeClass(this.menuElement.nativeElement, 'show');
            this.renderer.removeClass(this.menuElement.nativeElement, 'collapse');
        }
    }

    public showOrHideBody(): void {
        if (this.menuElement && this.isMobileDevice) {
            this.renderer[this.mobileMenuExpanded ? 'removeClass' : 'addClass'](document.body, 'menu-open');
            this.renderer[this.mobileMenuExpanded ? 'removeClass' : 'addClass'](
                this.menuElement.nativeElement,
                'show',
            );
        }
        this.mobileMenuExpanded = !this.mobileMenuExpanded;
    }

    public onMenuClick(): void {
        this.showOrHideBody();
    }

    public onMenuLogoClick(): void {
        if (this.mobileMenuExpanded) {
            this.showOrHideBody();
        }
    }

    public generateBrandedCmsKey(cmsKey: string): string {
        return this.brandService.generateBrandedCmsKey(this.activeBrand, cmsKey);
    }
}
