import { Component, ElementRef, Input } from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { TooltipComponentBase } from '@common/components/tooltip/tooltip.component.base';
import { AnalyticsClickService } from '@common/analytics/services/click.service';

@Component({
    selector: 'app-tooltip-enuk',
    templateUrl: './tooltip.component.html',
})
export class EnUkTooltipComponent extends TooltipComponentBase {
    @Input()
    targetCmsKey: string;

    @Input()
    cmsKey: string;

    @Input()
    cmsParams: any = null;

    constructor(
        private modalService: NgbModal,
        private contentService: ContentService,
        clickService: AnalyticsClickService,
        ref: ElementRef,
    ) {
        super(clickService, ref);
    }

    openModal(content: any): void {
        this.modalService.open(content, { centered: true }).result.then(
            (_result) => {},
            (_reason) => {},
        );
    }

    get isAvailable$(): Observable<boolean> {
        return this.contentService.valueExists(this.cmsKey);
    }
}
