import { Injectable, Inject } from '@angular/core';
import { CMS_LOCALE, CMS_AVAILABLE_LOCALES } from '../app/app.config';
import { Router } from '@angular/router';
import { RouteService } from '@common/infrastructure/params-service';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { LanguagePreferences } from '../model/language-preferences';

@Injectable()
export class LanguageService {
    private _language: string;

    constructor(
        @Inject(CMS_LOCALE) private defaultLocale: string,
        @Inject(CMS_AVAILABLE_LOCALES) private _availableLocales: string[],
        private router: Router,
        private routeService: RouteService,
    ) {
        const languageCode$ = this.routeService.params.pipe(
            map(
                (p) => this.getLanguageCodeOrFallback(p),
                distinctUntilChanged(),
            ),
        );
        languageCode$.subscribe((language) => (this._language = language));
    }

    get currentLanguageCode(): string {
        if (!this._language) {
            return this.defaultLocale;
        }
        return this._language;
    }

    saveCurrentLanguageCode(code: string): void {
        localStorage.setItem('languageCode', code);
    }

    public setLanguage(
        language: string,
        communityCode?: string,
        languageAsPrefix?: boolean,
    ): void {
        if (!this.availableLocales.some((item) => item === language)) {
            language = this.defaultLocale;
        }

        if (language !== this.currentLanguageCode) {
            const url = communityCode
                ? languageAsPrefix
                    ? '/' + language + '/' + communityCode + '/home/'
                    : '/' + communityCode + '/' + language + '/home/'
                : '/' + language;

            this.router.navigate([url]).then(() => {
                window.location.reload();
            });
        }
    }

    public get availableLocales(): string[] {
        return this._availableLocales;
    }

    private getLanguageCodeOrFallback(params: any): string {
        const languageCode = params.language;
        if (languageCode) {
            return languageCode;
        }
        return this.defaultLocale;
    }

    public convertToLanguagePreference(language: string) {
        switch (language) {
            case 'en':
                return LanguagePreferences.English;
            case 'es':
                return LanguagePreferences.Spanish;
            default:
                return LanguagePreferences.English;
        }
    }

    public setLocale(locale: string) {
        if (locale && this._language !== locale) {
            this._language = locale;
        }
    }
}
