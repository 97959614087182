import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { FlowStart } from '@common/analytics/model/flow-start';
import { AnalyticsSolverBase } from '@common/analytics/resolvers/analytics.solver.base';
import { Registration } from '../model/registration';

@Injectable()
export class AnalyticsSolver extends AnalyticsSolverBase<Registration> {
    public solveProcessFlowStart(route: ActivatedRouteSnapshot, _: Registration): FlowStart {
        if (route?.paramMap?.has('id')) return 'link';
        return 'person';
    }
}
