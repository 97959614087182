import { Auction } from '@common/model/auction';
import { Community } from '@common/model/community';
import * as moment from 'moment';

export class AuctionHelper {
    static readonly daysAuctionPublicClosure = 42;

    public static getPublicClosureDate(auction: Auction): Date {
        if (auction && auction.date) {
            const auctionDate = new Date(auction.date);
            auctionDate.setDate(auctionDate.getDate() + AuctionHelper.daysAuctionPublicClosure);
            return auctionDate;
        }

        // Assume public closure when the registration has no auction
        const today = new Date();
        return new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
    }

    public static showAuctionResultsPage(community: Community): boolean {
        if (!community || !community.targetAuction) {
            return false;
        }

        return (
            moment.utc(community.targetAuction.date).add(2, 'days').isBefore(moment.utc(moment.now())) &&
            community.targetAuction.autoMakeProposals
        );
    }
}
