import { Auction } from './auction';
import { BusinessLine } from './businessline';
import { Community } from './community';
import { CommunityParticipation } from './community-participation';
import { PaymentStatus } from './payment';

export abstract class RegistrationBase {
    id: string;
    profileId: string;
    communityId: string;
    auctionId: string;
    number: string;
    createdUtc: string;
    email: string;
    communicationBySnailMail: boolean;
    subscriptionComplete: boolean;
    emailConfirmed: boolean;
    proposalMade: boolean;
    decisionComplete: boolean;
    cancelled: boolean;
    copiedToNextAuction: boolean;
    status: string;
    proposalAccepted?: boolean;
    decidedUtc?: Date;

    community: Community;
    communityParticipation?: CommunityParticipation;
    auction: Auction;

    paymentStatus?: PaymentStatus;

    // Transient
    markedAsDuplicate: boolean;
}

// TODO use this for number field?
// eslint-disable-next-line prettier/prettier
export type RegistrationNumber = `${BusinessLine}${number}` | undefined;
