import { Injectable } from '@angular/core';
import { Community } from '@common/model/community';
import { Registration } from '@common/model/data-layer';
import { RegistrationBase } from '@common/model/registration.base';
import { AuctionService } from '@common/services/auction.service';
import { getFlowPhase } from '../model/flow-phase';
import { FlowStart } from '../model/flow-start';
import { ProcessStepName } from '../model/process-step';

export type FlowType = 'interestedIndividualFlow' | 'registrationFlow' | 'decisionFlow';
export type FlowSubType = 'person' | 'product';
export type ProcessStepData = Record<FlowType, Record<FlowSubType, ProcessStepName[]>>;

@Injectable()
export abstract class ProcessStepServiceBase {
    protected abstract data: ProcessStepData; //to be provided by derived class

    protected getType<T extends RegistrationBase>(registration: T, community: Community): FlowType {
        if (!registration && !community) return null;

        const auction = AuctionService.getAnyAuction(registration, community);
        if (!auction) return 'interestedIndividualFlow';

        const phase = getFlowPhase(registration, community);
        if (phase === 'registration phase') return 'registrationFlow';
        if (phase === 'decision phase') return 'decisionFlow';

        return null;
    }

    protected getSubType(flowStart: FlowStart): FlowSubType {
        if (flowStart?.includes('product')) return 'product';
        return 'person'; //for person and link
    }

    public getAllSteps<T extends RegistrationBase>(
        registration: T,
        community: Community,
        flowStart: FlowStart,
    ): ProcessStepName[] {
        const type = this.getType(registration, community);
        const subType = this.getSubType(flowStart);
        if (!type || !subType) return [];
        return this.data[type][subType];
    }

    public abstract getActiveStep<T extends RegistrationBase>(
        registration: T,
        ...args: unknown[]
    ): ProcessStepName;
}
