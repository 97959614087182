import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { BUSINESS } from '@common/app/app.config';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { InMemoryQueryService } from '@common/services/inmemory.query.service';
import { BrandService } from '@lang/uk/services/brand.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
@Component({
    selector: 'app-helpdesk-variant-1',
    templateUrl: 'helpdesk-variant-1.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpdeskVariant1Component implements OnInit {
    brandCode: string;
    brandName: string;
    communityCode: string;
    communityPhone: string;
    helpDeskOpeningHours: string;
    isLoading$ = new BehaviorSubject<boolean>(true);
    inRegisterFlow$ = new BehaviorSubject<boolean>(false);
    public showInstagramIcon: boolean;
    constructor(
        private brandService: BrandService,
        private communityService: CommunityService,
        private contentService: ContentService,
        private queryService: InMemoryQueryService,
        @Inject(BUSINESS) business: string,
    ) {
        this.showInstagramIcon = business == 'enuk';
    }

    ngOnInit(): void {
        this.applyQueryServiceParams();
        combineLatest([
            this.brandService.getActive(),
            this.communityService.community$,
            this.contentService.get('contact-phone-label'),
        ]).subscribe(([brand, community, contactPhoneLabel]) => {
            this.brandCode = brand.parentBrand?.code ?? brand.code;
            this.brandName = brand.parentBrand?.name ?? brand.name;
            this.helpDeskOpeningHours = contactPhoneLabel.value.replace(/<\/?[^>]+(>|$)/g, '');
            if (!community.code) {
                //Empty string because of redirects to /contact & /faq instead of /communityCode/contact & /communityCode/faq
                this.communityCode = '';
                this.communityPhone = brand?.contactPhone;
            } else {
                this.communityCode = community.code;
                this.communityPhone = community.contactPhone;
            }
            this.isLoading$.next(false);
        });
    }

    applyQueryServiceParams(): void {
        this.queryService.getObservable$('app-helpdesk').subscribe((e) => {
            if (e.name === 'inRegisterFlow' && e.value !== null) {
                this.inRegisterFlow$.next(e.value);
            }
        });
    }
}
