import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SupplierData } from '../../../../services/supplier.service';
import { FuelType } from '../../../../services/tariff.service';

@Component({
    selector: 'app-register-energy-supplier',
    templateUrl: './supplier.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergySupplierComponent implements OnChanges, ControlValueAccessor {
    @Input() public id: string = null;
    @Input() public cmsLabel: string = null;
    @Input() public cmsPlaceholderLabel = 'energy-contract-supplier-placeholder-label';
    @Input() public suppliers: Observable<SupplierData[]> = null;
    @Input() public fuelType: FuelType = null;
    @Input() public regionId: number = null;
    @Input() public enabled = true;
    public FuelType: typeof FuelType = FuelType;

    public suppliers$: Observable<SupplierData[]> = null;
    public selectedSupplier: SupplierData = null;
    private onChanged = [];
    private onTouched = [];

    constructor(private ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.fuelType &&
            changes.fuelType.previousValue &&
            changes.fuelType.currentValue !== changes.fuelType.previousValue
        ) {
            throw new Error('Fuel type cannot be changed after initialization.');
        } else if (
            changes.regionId &&
            changes.regionId.previousValue &&
            changes.regionId.currentValue !== changes.regionId.previousValue
        ) {
            throw new Error('Region id cannot be changed after initialization.');
        }

        const suppliers$: Observable<SupplierData[]> = changes.suppliers
            ? changes.suppliers.currentValue
            : this.suppliers;
        const fuelType: FuelType = changes.fuelType ? changes.fuelType.currentValue : this.fuelType;
        const regionId: number = changes.regionId ? changes.regionId.currentValue : this.regionId;
        if (suppliers$ && fuelType && regionId) {
            switch (fuelType) {
                case FuelType.ElectricityAndGas:
                    this.suppliers$ = suppliers$.pipe(
                        map((suppliers) =>
                            suppliers.filter(
                                (supplier) =>
                                    !supplier.excludedFromRegionsForDual ||
                                    !supplier.excludedFromRegionsForDual.includes(regionId),
                            ),
                        ),
                    );

                    break;
                case FuelType.Electricity:
                    this.suppliers$ = suppliers$.pipe(
                        map((suppliers) =>
                            suppliers.filter(
                                (supplier) =>
                                    !supplier.excludedFromRegionsForElectricity ||
                                    !supplier.excludedFromRegionsForElectricity.includes(regionId),
                            ),
                        ),
                    );
                    break;
                case FuelType.Gas:
                    this.suppliers$ = suppliers$.pipe(
                        map((suppliers) =>
                            suppliers.filter(
                                (supplier) =>
                                    !supplier.excludedFromRegionsForGas ||
                                    !supplier.excludedFromRegionsForGas.includes(regionId),
                            ),
                        ),
                    );
                    break;
            }
        }
    }

    public registerOnChange(fn: any): void {
        this.onChanged.push(fn);
    }

    public registerOnTouched(fn: any): void {
        this.onTouched.push(fn);
    }

    public writeValue(supplierData: SupplierData): void {
        this.selectedSupplier = supplierData;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.enabled = !isDisabled;
    }

    public setSelectedSupplier(supplier: SupplierData): void {
        this.selectedSupplier = supplier;
        this.onChanged.forEach((f) => f(this.selectedSupplier));
    }

    public searchItem(needle: string, item: { name: string }): boolean {
        return (
            item.name.toLowerCase().replace(/\W/g, '').indexOf(needle.replace(/\W/g, '').toLowerCase()) > -1
        );
    }

    public compareSupplier(lhs: Partial<SupplierData>, rhs: Partial<SupplierData>): boolean {
        return lhs && rhs && lhs.code === rhs.code;
    }
}
