import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Registration } from '../../../../model/registration';

@Component({
    selector: 'app-register-personal-details-overview',
    templateUrl: './personal-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewPersonalDetailsComponent {
    @Input()
    registration: Registration;

    @Input()
    showBankDetails = false;

    @Input()
    readonly = true;

    @Output()
    edit = new EventEmitter<void>();

    constructor() {}

    public get maskedAccountNumber(): string {
        if (
            !this.showBankDetails ||
            !this.registration ||
            !this.registration.decision ||
            !this.registration.decision.accountNumber
        ) {
            return '';
        }
        return this.registration.decision.accountNumber.replace(/(\d{4})(\d{4})/gi, '****$2');
    }

    public onEdit(): void {
        this.edit.emit();
    }
}
