<h3 class="text-center" [innerHTML]="'past-winning-supplier-title' | cmsText | async"></h3>
<div class="row justify-content-center py-3">
    <ng-container *ngFor="let supplierLogo of pastSupplierLogos">
        <div class="col-md-2 col-4 p-3" *ngIf="contentService.valueExists(supplierLogo) | async">
            <img
                class="w-100"
                [attr.src]="supplierLogo | cmsImageSrc | async"
                [attr.alt]="supplierLogo | cmsImageAlt | async"
            />
        </div>
    </ng-container>
</div>
