import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoItem } from '@common/components/info/info.component.base';
import { ContentService } from '@common/services/content.service';
import { InfoItemServiceBase } from '@lang/uk/services/info-item.service';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

// In the UK BLs the info items are retrieved at runtime which exposed a race condition bug in @common's InfoComponentBase class.
//   - Scenario A: this.infoItems is set before the route subscription triggers -> everything works as expected (activeId has a value)
//   - Scenario B: this.infoItems is set after the route subscription triggers -> activeId will not be set on the module's first load
//                 (but will be set on subsequent navigation) resulting in an info page not loading when navigated to directly
// InfoComponentAsyncBase solves this by making everything async.
@Directive()
export abstract class InfoComponentAsyncBase implements OnInit, OnDestroy {
    public activeId$: Observable<string>;
    public activeItem$: Observable<InfoItem>;
    public activeItemHeader$: Observable<string>;
    public activeItemSubHeader$: Observable<string>;
    protected destroyed$ = new Subject<void>();
    public abstract infoItems$: Observable<InfoItem[]>;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected contentService: ContentService,
        protected infoItemService: InfoItemServiceBase,
    ) {}

    public ngOnInit(): void {
        this.activeId$ = this.route.paramMap.pipe(map((params) => params.get('id')));

        this.activeItem$ = combineLatest([this.activeId$, this.infoItems$]).pipe(
            switchMap(([activeId, infoItems]) => {
                const activeItem = infoItems.find(
                    (item) => item.id === activeId || (item.url === activeId && item.url != null),
                );
                if (activeItem) {
                    return of(activeItem);
                } else {
                    // NOTE: it's possible (and allowed) for info items to only appear in the top/bottom sections
                    return this.infoItemService.getInfoItem(activeId);
                }
            }),
        );

        this.activeItemHeader$ = this.activeItem$.pipe(
            switchMap((infoItem) =>
                infoItem ? this.contentService.value('info-item-' + infoItem.id + '-header') : of(''),
            ),
        );

        this.activeItemSubHeader$ = this.activeItem$.pipe(
            switchMap((infoItem) =>
                infoItem ? this.contentService.value('info-item-' + infoItem.id + '-sub-header') : of(''),
            ),
        );

        // Redirect to the first available info item if the user navigated to an non-existent id
        this.activeItem$
            .pipe(
                takeUntil(this.destroyed$),
                first((infoItem) => !infoItem),
                withLatestFrom(this.infoItems$),
            )
            .subscribe(([_, infoItems]) =>
                this.router.navigate([
                    '/' + this.infoItemService.getInfoItemUrlComponents(infoItems[0]).join('/'),
                ]),
            );
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
