<div *ngIf="command === commands.ConfirmEmail" class="text-center mt-4">
    <h3 *ngIf="!error">
        <app-content *ngIf="!success" key="email-confirmation-started"></app-content>
        <app-content *ngIf="success" key="email-confirmation-success"></app-content>
    </h3>
    <a *ngIf="success && id" [routerLink]="'../../../register/' + id"
        [queryParams]="{ confirmEmail: true}" class="btn btn-primary my-4">
        <app-content key="email-confirmation-go-to-registration"></app-content>
    </a>
    <div *ngIf="error">
        <h3 class="text-danger">Error</h3>
        <div class="alert alert-danger">
            <h4>
                <app-content key="email-confirmation-failed"></app-content>
            </h4>
            {{error}}
        </div>
    </div>
</div>

<div *ngIf="command === commands.Rollover" class="text-center mt-4">
    <h3 *ngIf="!error">
        <p *ngIf="!success">{{ 'rollover-started' | cmsText | async }}</p>
        <p *ngIf="success">{{ 'rollover-success' | cmsText | async }}</p>
    </h3>
    <a *ngIf="success && id && contentService.valueExists('rollover-go-to-registration') | async" [routerLink]="'../../../register/' + id" class="btn btn-primary my-4"
        id="goToRegistrationButton">
        {{ 'rollover-go-to-registration' | cmsText | async }}
    </a>
    <div *ngIf="error">
        <h3 class="text-danger">Error</h3>
        <div class="alert alert-danger">
            <h4>{{ 'rollover-failed' | cmsText | async }}</h4>
            {{error}}
        </div>
    </div>
</div>

<div *ngIf="command === commands.SendMail" class="text-center mt-4">
    <h3 *ngIf="!error">
        <app-content *ngIf="!success" key="send-mail-started"></app-content>
        <app-content *ngIf="success" key="send-mail-success"></app-content>
    </h3>
    <a *ngIf="success && id" [routerLink]="'../../../register/' + id" class="btn btn-primary my-4">
        <app-content key="send-mail-go-to-registration"></app-content>
    </a>
    <div *ngIf="error">
        <h3 class="text-danger">Error</h3>
        <div class="alert alert-danger">
            <h4>
                <app-content key="send-mail-failed"></app-content>
            </h4>
            {{error}}
        </div>
    </div>
</div>

<div *ngIf="command === commands.UnsubscribeFromGroup" class="text-center mt-4">
    <h3 *ngIf="!error">
        <app-content *ngIf="!success" key="unsubscribe-started"></app-content>
        <app-content *ngIf="success" key="unsubscribe-success"></app-content>
    </h3>
    <a *ngIf="success && id" [routerLink]="'../../../contact-voorkeuren/' + id"
        class="btn btn-primary my-4">
        <app-content key="go-to-unsubscribe-preferences"></app-content>
    </a>
    <div *ngIf="error">
        <h3 class="text-danger">Error</h3>
        <div class="alert alert-danger">
            <h4>
                <app-content key="unsubscribe-failed"></app-content>
            </h4>
            {{error}}
        </div>
    </div>
</div>

<div *ngIf="!command && !busy" class="text-center mt-4">
    <div *ngIf="error">
        <div class="alert alert-danger">
            <h4>Error</h4>
            {{error}}
        </div>
    </div>
</div>
