import { DistributedTracingModes, Snippet } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { MonitoringService } from '@common/services/monitoring.service';

// Based on @common:monitoring.service.ts, enabling distributed tracing and W3C mode
@Injectable()
export class EnUkMonitoringService extends MonitoringService {
    override getAIConfigurationSnippet(connectionString: string): Snippet {
        const correlationHeaderDomain = environment.apiUri;

        return {
            config: {
                connectionString,
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                enableAutoRouteTracking: true,
                distributedTracingMode: DistributedTracingModes.W3C,
                correlationHeaderDomains: [correlationHeaderDomain],
            },
        };
    }
}
