<form #form="ngForm">
    <div>
        <label>{{ 'previous-address' | cmsText | async }} #{{ decisionAddress.order }}</label>
        <div>
            <label for="postcode"> {{ 'postcode-label' | cmsText | async }}</label>
            <div class="input-group">
                <input
                    class="form-control"
                    type="text"
                    name="postcode"
                    id="postcode"
                    (keydown.enter)="$event.preventDefault()"
                    [(ngModel)]="currentPostcode"
                    (ngModelChange)="onPostcodeChange($event)"
                    #postcodeControl="ngModel"
                    required
                    app-postcode
                />
            </div>
            <div
                *ngIf="postcodeControl.invalid && (postcodeControl.dirty || submitRequested)"
                class="text-danger"
            >
                <span *ngIf="postcodeControl.errors.required">
                    {{ 'postcode-error-required' | cmsText | async }}
                </span>
                <span *ngIf="postcodeControl.errors.pattern">
                    {{ 'postcode-error-pattern' | cmsText | async }}
                </span>
            </div>
            <div
                class="text-danger error-focus ng-invalid"
                *ngIf="addressServiceLookup === ServerLookup.NotFound"
            >
                {{ 'postcode-not-found-error-server' | cmsText | async }}
            </div>
            <div
                class="text-danger error-focus ng-invalid"
                *ngIf="addressServiceLookup === ServerLookup.Error"
            >
                {{ 'postcode-error-server' | cmsText | async }}
            </div>
        </div>
        <i class="fa fa-spinner fa-spin fa-2x" *ngIf="addressServiceLookup === ServerLookup.Busy"></i>

        <div [hidden]="!postcodeControl.valid || !postcodeAddresses?.length">
            <label for="address">
                <span>{{ 'address-label' | cmsText | async }}</span>
            </label>
            <div class="input-group">
                <select
                    class="form-select"
                    name="address"
                    id="address"
                    [(ngModel)]="currentAddress"
                    #addressControl="ngModel"
                    required
                >
                    <option [ngValue]="null">
                        {{ 'address-placeholder' | cmsText | async }}
                    </option>
                    <option *ngFor="let address of postcodeAddresses" [ngValue]="address">
                        {{ address.organisation }} {{ address.buildingName }}
                        {{ address.subBuilding }}
                        {{ address.buildingNumber }} {{ address.dependentThoroughfare }}
                        {{ address.thoroughfare }} {{ address.doubleDependentLocality }}
                        {{ address.dependentLocality }}
                        {{ address.town }} {{ address.county }} {{ address.postcode }}
                    </option>
                </select>
            </div>
            <div class="text-danger">
                <span *ngIf="addressControl.invalid && (addressControl.dirty || submitRequested)">
                    <span *ngIf="addressControl.errors.required">
                        {{ 'address-error-required' | cmsText | async }}
                    </span>
                </span>
            </div>
        </div>
    </div>

    <div>
        <div class="row">
            <div class="col">
                <label for="addressYears">
                    {{ 'decision-previous-address-how-long-label' | cmsText | async }}
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col col-6">
                {{ 'decision-address-years' | cmsText | async }}
                <select
                    class="form-select"
                    name="addressYears"
                    id="addressYears"
                    [(ngModel)]="currentYears"
                    #addressYearsControl="ngModel"
                    required
                >
                    <option [ngValue]="null">
                        {{ 'option-choose-one' | cmsText | async }}
                    </option>
                    <option *ngFor="let item of addressYears" [ngValue]="item.key">
                        {{ item.value }}
                    </option>
                </select>
            </div>
            <div class="col col-6" [hidden]="currentYears == null || currentYears >= 5">
                {{ 'decision-address-months' | cmsText | async }}
                <select
                    class="form-select"
                    name="addressMonths"
                    id="addressMonths"
                    [(ngModel)]="currentMonths"
                    #addressMonthsControl="ngModel"
                    required
                >
                    <option [ngValue]="null">{{ 'option-choose-one' | cmsText | async }}</option>
                    <option [ngValue]="0" [disabled]="currentYears == 0">0</option>
                    <option *ngFor="let item of addressMonths" [ngValue]="item">
                        {{ item }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div
                    *ngIf="addressYearsControl.invalid && (addressYearsControl.dirty || submitRequested)"
                    class="text-danger"
                >
                    <span *ngIf="addressYearsControl.errors.required">
                        {{ 'decision-previous-address-years-error-required' | cmsText | async }}
                    </span>
                </div>
                <div
                    *ngIf="addressMonthsControl.invalid && (addressMonthsControl.dirty || submitRequested)"
                    class="text-danger"
                >
                    <span *ngIf="addressMonthsControl.errors.required">
                        {{ 'decision-previous-address-months-error-required' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</form>
