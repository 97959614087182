import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepperHelperService } from '../../../services/stepper-helper.service';
import { Registration } from '../../../model/registration';
import { StepConfiguration } from '../../../model/menu-configuration';
import { AnalyticsClickService } from '@common/analytics/services/click.service';

@Component({
    selector: 'app-stepper-header',
    templateUrl: './stepper-header.component.html',
})
export class StepperHeaderComponent {
    @Input() neededSteps: string[];
    @Input() registration: Registration;
    @Output() printRequested = new EventEmitter<string>();

    constructor(
        private stepperHelper: StepperHelperService,
        private analyticsClickService: AnalyticsClickService,
    ) {}

    get selectedStep(): StepConfiguration {
        return this.stepperHelper.getSelectedStep(this.neededSteps, this.registration);
    }

    get stepCount(): number {
        return this.stepperHelper.getStepCount(this.neededSteps);
    }

    get stepNumber(): number {
        return this.stepperHelper.getStepNumber(this.neededSteps);
    }

    get showStepCounter(): boolean {
        return this.selectedStep?.showStepCounter ?? true;
    }

    get showPrint(): boolean {
        return this.selectedStep?.showPrint ?? false;
    }

    print(ref?: HTMLElement): void {
        if (this.selectedStep.id === 'proposal') {
            this.analyticsClickService.sendProposalInteractionEvent(ref, null, 'print');
        }
        if (this.selectedStep) {
            this.printRequested.emit(this.selectedStep.id);
        }
    }
}
