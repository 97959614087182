import { Component, OnInit, Input } from '@angular/core';
import { Registration, Contracts } from '../../../model/registration';
import { Comparisons, ComparisonService } from '../../../services/comparison.service';
import { CommunityService } from '../../../../../_common/services/community.service';
import { TarrifComparisonModel } from './tarrifComparison.model';

@Component({
    selector: 'app-tarrif-comparison',
    templateUrl: './tarrif-comparison.component.html',
})
export class TarrifComparisonComponent implements OnInit {
    @Input() registration: Registration;

    comparisonSituation: Comparisons;
    regions = [
        'East England',
        'East Midlands',
        'London',
        'North Wales, Merseyside and Cheshire',
        'West Midlands',
        'North East England',
        'North West England',
        'North Scotland',
        'South Scotland)',
        'South East England',
        'Southern England',
        'South Wales',
        'South West England',
        'Yorkshire',
    ];

    Contracts: typeof Contracts = Contracts;

    get currentTariff(): TarrifComparisonModel {
        const model = new TarrifComparisonModel();
        const currentTariff = this.registration.product.currentTariff;

        model.typeName = 'Current Tariff';
        model.totalCost = this.registration.proposal.currentTariffTotalCost;

        model.electricitySupplierName =
            this.registration.product.contract === Contracts.Dual
                ? this.registration.product.dualSupplier
                : this.registration.product.electricitySupplier;
        model.electricityTariffName = currentTariff.electricityTariffName;
        model.electricityPaymentMethod = currentTariff.electricityPaymentMethod;
        model.electricityDayPriceThreshold = currentTariff.electricityDayPriceThreshold;
        model.electricityDayPrice = currentTariff.electricityDayPrice;
        model.electricityDayPrice2 = currentTariff.electricityDayPrice2;
        model.electricityNightPrice = currentTariff.electricityNightPrice;
        model.electricityStandingCharge = currentTariff.electricityStandingCharge;
        model.electricityDiscount = currentTariff.electricityDiscount;
        model.electricityCost = this.registration.proposal.currentTariffElectricityCost;
        model.electricityConsumption = this.registration.product.electricityConsumption;

        model.gasSupplierName =
            this.registration.product.contract === Contracts.Dual
                ? this.registration.product.dualSupplier
                : this.registration.product.gasSupplier;
        model.gasTariffName = currentTariff.gasTariffName;
        model.gasPaymentMethod = currentTariff.gasPaymentMethod;
        model.gasPriceThreshold = currentTariff.gasPriceThreshold;
        model.gasPrice = currentTariff.gasPrice;
        model.gasPrice2 = currentTariff.gasPrice2;
        model.gasStandingCharge = currentTariff.gasStandingCharge;
        model.gasDiscount = currentTariff.gasDiscount;
        model.gasCost = this.registration.proposal.currentTariffGasCost;
        model.gasConsumption = this.registration.product.gasConsumption;

        return model;
    }

    get winningTariff(): TarrifComparisonModel {
        const model = new TarrifComparisonModel();
        const winningTariff = this.registration.proposal.winningTariff;

        model.typeName = 'Winning Tariff';
        model.totalCost = this.registration.proposal.winningTariffTotalCost;

        model.electricitySupplierName = this.registration.proposal.winningTariffSupplierName;
        model.electricityTariffName = winningTariff.electricityTariffName;
        model.electricityPaymentMethod = winningTariff.electricityPaymentMethod;
        model.electricityDayPriceThreshold = winningTariff.electricityDayPriceThreshold;
        model.electricityDayPrice = winningTariff.electricityDayPrice;
        model.electricityDayPrice2 = winningTariff.electricityDayPrice2;
        model.electricityNightPrice = winningTariff.electricityNightPrice;
        model.electricityStandingCharge = winningTariff.electricityStandingCharge;
        model.electricityDiscount = winningTariff.electricityDiscount;
        model.electricityCost = this.registration.proposal.winningTariffElectricityCost;
        model.electricityConsumption = this.registration.product.electricityConsumption;

        model.gasSupplierName = this.registration.proposal.winningTariffSupplierName;
        model.gasTariffName = winningTariff.gasTariffName;
        model.gasPaymentMethod = winningTariff.gasPaymentMethod;
        model.gasPriceThreshold = winningTariff.gasPriceThreshold;
        model.gasPrice = winningTariff.gasPrice;
        model.gasPrice2 = winningTariff.gasPrice2;
        model.gasStandingCharge = winningTariff.gasStandingCharge;
        model.gasDiscount = winningTariff.gasDiscount;
        model.gasCost = this.registration.proposal.winningTariffGasCost;
        model.gasConsumption = this.registration.product.gasConsumption;

        return model;
    }

    get renewalTariff(): TarrifComparisonModel {
        const model = new TarrifComparisonModel();
        const renewalTariff = this.registration.product.renewalTariff;

        model.typeName = 'Renewal Tariff';
        model.totalCost = this.registration.proposal.renewalTariffTotalCost;

        model.electricitySupplierName =
            this.registration.product.contract === Contracts.Dual
                ? this.registration.product.dualSupplier
                : this.registration.product.electricitySupplier;
        model.electricityTariffName = renewalTariff.electricityTariffName;
        model.electricityPaymentMethod = renewalTariff.electricityPaymentMethod;
        model.electricityDayPriceThreshold = renewalTariff.electricityDayPriceThreshold;
        model.electricityDayPrice = renewalTariff.electricityDayPrice;
        model.electricityDayPrice2 = renewalTariff.electricityDayPrice2;
        model.electricityNightPrice = renewalTariff.electricityNightPrice;
        model.electricityStandingCharge = renewalTariff.electricityStandingCharge;
        model.electricityDiscount = renewalTariff.electricityDiscount;
        model.electricityCost = this.registration.proposal.renewalTariffElectricityCost;
        model.electricityConsumption = this.registration.product.electricityConsumption;

        model.gasSupplierName =
            this.registration.product.contract === Contracts.Dual
                ? this.registration.product.dualSupplier
                : this.registration.product.gasSupplier;
        model.gasTariffName = renewalTariff.gasTariffName;
        model.gasPaymentMethod = renewalTariff.gasPaymentMethod;
        model.gasPriceThreshold = renewalTariff.gasPriceThreshold;
        model.gasPrice = renewalTariff.gasPrice;
        model.gasPrice2 = renewalTariff.gasPrice2;
        model.gasStandingCharge = renewalTariff.gasStandingCharge;
        model.gasDiscount = renewalTariff.gasDiscount;
        model.gasCost = this.registration.proposal.renewalTariffGasCost;
        model.gasConsumption = this.registration.product.gasConsumption;

        return model;
    }

    get standardTariff(): TarrifComparisonModel {
        const model = new TarrifComparisonModel();
        const standardTariff = this.registration.proposal.standardTariff;

        model.typeName = 'Standard Tariff';
        model.totalCost = this.registration.proposal.standardTariffTotalCost;

        model.electricitySupplierName =
            this.registration.product.contract === Contracts.Dual
                ? this.registration.product.dualSupplier
                : this.registration.product.electricitySupplier;
        model.electricityTariffName = standardTariff.electricityTariffName;
        model.electricityPaymentMethod = standardTariff.electricityPaymentMethod;
        model.electricityDayPriceThreshold = standardTariff.electricityDayPriceThreshold;
        model.electricityDayPrice = standardTariff.electricityDayPrice;
        model.electricityDayPrice2 = standardTariff.electricityDayPrice2;
        model.electricityNightPrice = standardTariff.electricityNightPrice;
        model.electricityStandingCharge = standardTariff.electricityStandingCharge;
        model.electricityDiscount = standardTariff.electricityDiscount;
        model.electricityCost = this.registration.proposal.standardTariffElectricityCost;
        model.electricityConsumption = this.registration.product.electricityConsumption;

        model.gasSupplierName =
            this.registration.product.contract === Contracts.Dual
                ? this.registration.product.dualSupplier
                : this.registration.product.electricitySupplier;
        model.gasTariffName = standardTariff.gasTariffName;
        model.gasPaymentMethod = standardTariff.gasPaymentMethod;
        model.gasPriceThreshold = standardTariff.gasPriceThreshold;
        model.gasPrice = standardTariff.gasPrice;
        model.gasPrice2 = standardTariff.gasPrice2;
        model.gasStandingCharge = standardTariff.gasStandingCharge;
        model.gasDiscount = standardTariff.gasDiscount;
        model.gasCost = this.registration.proposal.standardTariffGasCost;
        model.gasConsumption = this.registration.product.gasConsumption;
        return model;
    }

    get comparisonTariff(): TarrifComparisonModel | null {
        if (this.comparisonSituation === Comparisons.WithCurrent) {
            return this.currentTariff;
        }
        if (this.comparisonSituation === Comparisons.WithStandard) {
            return this.standardTariff;
        }
        if (this.comparisonSituation === Comparisons.DefaultAndRenewal) {
            return this.registration.decision.wantsToCompareToRenewalTariff
                ? this.renewalTariff
                : this.standardTariff;
        }

        return null;
    }

    ngOnInit(): void {
        this.comparisonSituation = this.comparisonService.WhichComparisonToDisplay(this.registration);
    }

    constructor(public communityService: CommunityService, private comparisonService: ComparisonService) {}
}
