import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, DEFAULT_COMMUNITY } from '@common/app/app.config';
import { UkCommunity } from '../model/community';
import { RouteService } from '@common/infrastructure/params-service';
import { CommunityServiceBase } from '@common/services/community.service.base';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UkCommunityService extends CommunityServiceBase<UkCommunity> {
    constructor(
        protected override http: HttpClient,
        @Inject(API_URL) protected override baseUrl: string,
        @Inject(DEFAULT_COMMUNITY) protected override defaultCommunity: string,
        protected override routeService: RouteService,
    ) {
        super(http, baseUrl, defaultCommunity, routeService);
    }

    getActive(): Observable<UkCommunity> {
        return this.community$;
    }
    protected getCommunityInstance(): UkCommunity {
        return new UkCommunity();
    }
}
