import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'absoluteNumber',
})
export class AbsoluteNumberPipe implements PipeTransform {
    transform(num: number, ..._args: any[]): number {
        return Math.abs(num);
    }
}
