import { Component, Input } from '@angular/core';
import { Registration, Contracts, PaymentMethod } from '../../../model/registration';
import { TarrifComparisonModel } from './tarrifComparison.model';

@Component({
    selector: 'app-comparison-table',
    templateUrl: './comparison.table.component.html',
})
export class ComparisonTableComponent {
    @Input() leftTariff: TarrifComparisonModel | null;
    @Input() rightTariff: TarrifComparisonModel;
    @Input() registration: Registration;

    Contracts: typeof Contracts = Contracts;
    PaymentMethod: typeof PaymentMethod = PaymentMethod;

    constructor() {}
}
