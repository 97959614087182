import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-register-now-banner',
    templateUrl: './register-now-banner.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterNowBannerComponent implements OnInit {
    public communityCode$: Observable<string> = null;
    public isCommunityPage$: Observable<boolean> = null;

    constructor(private communityService: CommunityService) {}

    ngOnInit(): void {
        this.communityCode$ = this.communityService.communityCode$;
        this.isCommunityPage$ = this.communityService.communityCode$.pipe(
            map((communityCode) => !!communityCode),
        );
    }
}
