import { Injectable, Inject } from '@angular/core';
import { HttpUrlComponentCodec } from '@lang/uk/helpers/http-url-component-codec';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { API_URL } from '../../../_common/app/app.config';
import { FeatureFlagsService } from './feature-flags.service';
import { MpasDetails } from '../model/mpas-details';
import { Address } from '../model/address';

@Injectable()
export class MpasService {
    protected readonly url: string;

    constructor(
        private http: HttpClient,
        @Inject(API_URL) protected baseUrl: string,
        private featureFlagsServices: FeatureFlagsService,
    ) {
        this.url = this.baseUrl + 'mpas';
    }

    getDetails(mpansLower: string[], postcode: string): Observable<MpasDetails[]> {
        const headers = new HttpHeaders({
            'X-AddressClient': this.featureFlagsServices.addressClient,
            'X-EnergyClient': this.featureFlagsServices.energyClient,
        });
        const params = { mpansLower, postcode };
        return this.http.get<MpasDetails[]>(`${this.url}/details`, {
            headers,
            params: new HttpParams({
                fromObject: params,
                encoder: new HttpUrlComponentCodec(),
            }),
        });
    }

    getDetailsFromAddress(address: Address): Observable<MpasDetails[]> {
        const headers = new HttpHeaders({
            'X-AddressClient': this.featureFlagsServices.addressClient,
            'X-EnergyClient': this.featureFlagsServices.energyClient,
        });
        const params = {
            postcode: !!address.postcode ? address.postcode : '',
            buildingNumber: !!address.buildingNumber ? address.buildingNumber : '',
            buildingName: !!address.buildingName ? address.buildingName : '',
            subBuilding: !!address.subBuilding ? address.subBuilding : '',
            town: !!address.town ? address.town : '',
            county: !!address.county ? address.county : '',
            organisation: !!address.organisation ? address.organisation : '',
            dependentThoroughfare: !!address.dependentThoroughfare ? address.dependentThoroughfare : '',
            thoroughfare: !!address.thoroughfare ? address.thoroughfare : '',
            dependentLocality: !!address.dependentLocality ? address.dependentLocality : '',
            doubleDependentLocality: !!address.doubleDependentLocality ? address.doubleDependentLocality : '',
            regionId: address.regionId ? address.regionId.toString() : '',
        };
        return this.http.get<MpasDetails[]>(`${this.url}/details/address`, {
            headers,
            params: new HttpParams({
                fromObject: params,
                encoder: new HttpUrlComponentCodec(),
            }),
        });
    }
}
