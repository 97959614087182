import { OnInit, OnDestroy, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ContentService } from '../../services/content.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { CommunityService } from '../../services/community.service';

export class InfoItem {
    id: string;
    useCmsForContent: boolean;
    subItems: any[];
    url: string;
    communityCodes: string[];

    constructor(id: string, useCmsForContent = true, subItemsCount = 0, url: string = null, communityCodes = null) {
        this.id = id;
        this.useCmsForContent = useCmsForContent;
        this.subItems = Array(subItemsCount);
        this.url = url;
        this.communityCodes = communityCodes;
    }
}

@Directive()
export abstract class InfoComponentBase implements OnInit, OnDestroy {

    routeParams: Subscription;
    infotextHeader: Observable<string> = null;
    infosubtextHeader: Observable<string> = null;
    activeId = '';
    activeItem: InfoItem;
    infoItems: InfoItem[];
    protected destroyed$ = new Subject<void>();

    get jumbotronStyles$(): Observable<Object> {
        return this.content.value('hero-image').pipe(
            map(heroImageUrl => {
                return { 'background-image': 'url(\"' + heroImageUrl + '\")' };
            }));
    }

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected content: ContentService,
        protected analytics: GoogleAnalyticsService,
        protected communityService: CommunityService,
        infoItems: InfoItem[]
    ) {
        this.infoItems = infoItems.filter(item =>
            !item.communityCodes
            || item.communityCodes.includes(this.communityService.communityCode));
    }

    ngOnInit() {
        this.routeParams = this.route.paramMap.pipe(
            map(params => params.get('id')),
            takeUntil(this.destroyed$)
        ).subscribe((id) => {
            this.updateActiveItem(id);
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    protected getInfoUrl(communityCode: string, infoItem: InfoItem): string[] {
        const url: string[] = [];
        if (communityCode) {
            url.push(communityCode);
        }
        url.push('info', infoItem.url ? infoItem.url : infoItem.id);
        return url;
    }

    protected updateActiveItem(id: string): void {
        const infoItem = this.infoItems.find(item => item.id === id || (item.url === id && item.url != null));
        if (infoItem) {
            this.activeItem = infoItem;
            this.activeId = id;
            this.infotextHeader = infoItem ? this.content.value('info-item-' + infoItem.id + '-header') : of('');
            this.infosubtextHeader = infoItem ? this.content.value('info-item-' + infoItem.id + '-sub-header') : of('');
        } else if (this.infoItems.length) {
            this.router.navigate(['/' + this.getInfoUrl(this.communityService.communityCode, this.infoItems[0]).join('/')]);
        }
    }
}
