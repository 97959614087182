/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BUSINESS, ANALYTICS_API_URL } from '../app/app.config';
import { CommunityService } from './community.service';

export class Event {
    business: string;
    registrationId: string;
    eventType: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: object;
}

export class UtmEventData {
    utm_source: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_term?: string;
}

export class PageViewEventData {
    page: string;
    constructor(_page: string) {
        this.page = _page;
    }
}

export class InternalLinkClickData {
    link: string;
    constructor(_link: string) {
        this.link = _link;
    }
}

export enum UtmQueryParameter {
    Source = 'utm_source',
    Campaign = 'utm_campaign',
    Content = 'utm_content',
    Medium = 'utm_medium',
    Term = 'utm_term',
}

@Injectable()
export class KenalyticsService {
    protected readonly url: string;
    protected readonly sessionKey: string;

    private uniqueAbTests = new Set<string>();

    constructor(
        protected http: HttpClient,
        @Inject(ANALYTICS_API_URL) private baseUrl: string,
        @Inject(BUSINESS) protected business: string,
        private communityService: CommunityService,
    ) {
        this.url = `${this.baseUrl}/events`;
        this.sessionKey = `registration-${this.business}-utm-parameters`;
    }

    saveToSessionStorage(
        source: string,
        campaign?: string,
        content?: string,
        medium?: string,
        term?: string,
    ): void {
        const data = new UtmEventData();

        data.utm_source = source;
        if (campaign) {
            data.utm_campaign = campaign;
        }
        if (content) {
            data.utm_content = content;
        }
        if (medium) {
            data.utm_medium = medium;
        }
        if (term) {
            data.utm_term = term;
        }

        sessionStorage.setItem(this.sessionKey, JSON.stringify(data));
    }

    loadFromSessionStorage(): UtmEventData {
        return JSON.parse(sessionStorage.getItem(this.sessionKey));
    }

    postUtmCodes(id: string): void {
        const data = this.loadFromSessionStorage();
        if (data && data.utm_source !== '') {
            const event = new Event();
            event.registrationId = id;
            event.data = this.loadFromSessionStorage();
            event.business = this.business.toUpperCase();
            event.eventType = 'utm-parameters';

            this.http.post<string>(this.url, [event]).subscribe();
        }
    }

    postPageView(id: string, page: string): void {
        const event = new Event();
        event.registrationId = id;
        event.data = new PageViewEventData(
            `/${this.communityService.communityCode}/${page}`,
        );
        event.business = this.business.toUpperCase();
        event.eventType = 'pageView';
        this.http.post<string>(this.url, [event]).subscribe();
    }

    postAbTest(
        id: string,
        test: string,
        result: string,
        sendUniqueTest: boolean = false,
    ): void {
        if (sendUniqueTest) {
            if (this.uniqueAbTests.has(test)) {
                return;
            }
            this.uniqueAbTests.add(test);
        }

        const event = new Event();
        event.registrationId = id;
        event.data = { test, result };
        event.business = this.business.toUpperCase();
        event.eventType = 'abTest';
        this.http.post<string>(this.url, [event]).subscribe();
    }

    postInternalLinkClick(id: string, link: string): void {
        const event = new Event();
        event.registrationId = id;
        event.data = new InternalLinkClickData(link);
        event.business = this.business.toUpperCase();
        event.eventType = 'internalLinkClick';
        this.http.post<string>(this.url, [event]).subscribe();
    }
}
