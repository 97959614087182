import { Community } from '@common/model/community';
import { RegistrationBase, RegistrationNumber } from '@common/model/registration.base';
import { User } from '@common/model/user';
import { AuctionService } from '@common/services/auction.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageViewEvent } from '../events/pageview.event';
import { AnalyticsState } from '../model/analytics.state';
import { getFlowPhase } from '../model/flow-phase';
import { getOverViewMode, OverviewMode } from '../model/overview-mode';
import { getPageType, PageType } from '../model/page-type';
import { RegistrationStatus } from '../model/registration-status';
import { Enricher } from './enricher';

export class PageViewEventEnricher implements Enricher<PageViewEvent> {
    private overviewPages = [
        'overview',
        'offer-introduction',
        'welcome',
        'ii-welcome',
        'declined',
        'payment',
        'paid',
        'closed',
    ];
    constructor(
        private consentLevel: boolean,
        private baseHref: string,
        private currentUrl: string,
        private product: string,
        private business: string,
        private registration$: Observable<RegistrationBase>,
        private community$: Observable<Community>,
        private identity$: Observable<User>,
    ) {}

    private regIdGuid = /\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;

    enrich$(event: PageViewEvent, _state?: AnalyticsState): Observable<PageViewEvent> {
        const page_path = this.getFullPath(this.removeRegistrationId(event.page_path));
        const single_page_path = this.getSinglePagePath(page_path);

        return combineLatest([this.registration$, this.community$, this.identity$]).pipe(
            map((dependencies) => {
                // we want the original event params to override the enriched params, mainly for if we want to supply new registration data,
                // but the page path variables should always be the enriched ones
                const overrides = { page_path, single_page_path };

                return {
                    ...this.getDependentParams(dependencies, page_path),
                    user_consent_level: this.consentLevel,
                    product: this.product,
                    business_line: this.business,
                    ...event,
                    ...overrides,
                };
            }),
        );
    }

    private buildUrl(segments: string[]) {
        return segments.join('/').replace(/\/+/g, '/'); //coerce to a single slash
    }

    private getFullPath(path: string): string {
        return this.buildUrl([this.baseHref, path]);
    }

    private getSinglePagePath(path: string): string {
        const pageNames = path?.split('/').filter((x) => x);
        const firstRelevantIndex = pageNames.findIndex(getPageType);
        if (firstRelevantIndex === -1) return this.baseHref;

        return this.buildUrl([this.baseHref, ...pageNames.slice(firstRelevantIndex)]);
    }

    private getEnrichedPageType(
        path: string,
        registration: RegistrationBase,
        overviewMode: OverviewMode,
    ): PageType {
        const purePageType = getPageType(path);

        if (!purePageType) {
            return this.currentUrl.endsWith(this.baseHref) ? 'landing' : 'home';
        }

        if (registration && ['register', 'proposal'].includes(purePageType)) {
            if (!registration.proposalMade || overviewMode === 'registration complete') return 'register';
            return 'proposal';
        }

        return purePageType;
    }

    private getDependentParams(
        [registration, community, user]: [RegistrationBase, Community, User],
        page_path: string,
    ): Partial<PageViewEvent> {
        const onOverviewPage = this.overviewPages.some((page) => page_path?.endsWith(`/register/${page}`));
        const overviewMode = onOverviewPage ? getOverViewMode(registration) : undefined;

        return {
            page_type: this.getEnrichedPageType(page_path, registration, overviewMode),
            registration_number: registration?.number as RegistrationNumber,
            registration_status: registration?.status?.toLowerCase() as RegistrationStatus,
            community: community?.code,
            user_internal_traffic: !!user && user?.role !== 'participant',
            auction_code: AuctionService.getAnyAuction(registration, community)?.code,
            overview_mode: overviewMode,
            process_flow_phase: getFlowPhase(registration, community),
        };
    }

    private removeRegistrationId(path: string): string {
        return path ? path.replace(this.regIdGuid, '') : path;
    }
}
