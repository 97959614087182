import { Injectable } from '@angular/core';
import { ProcessGoalEventFactory } from '@common/analytics/services/process-goal-event-factory.service';
import { Registration } from '../model/registration';

@Injectable({
    providedIn: 'root',
})
export class EnukProcessGoalEventFactory extends ProcessGoalEventFactory<Registration> {
    constructor() {
        super();
    }

    // Override because ENUK sets an auctionId at the start of the registration
    // so we need another mechanism to determine if the registration becomes created
    protected override shouldTriggerCreatedEvent(
        oldRegistration: Registration,
        newRegistration: Registration,
    ): boolean {
        return (
            newRegistration.status === 'Created' &&
            !!newRegistration.email &&
            !oldRegistration.contact?.postcode &&
            !!newRegistration.contact.postcode
        );
    }
}
