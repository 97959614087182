import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, HostListener } from '@angular/core';

@Component({
    selector: 'app-collapsible-card',
    templateUrl: './collapsible-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleCardComponent {
    @Input()
    public titleKey: string = null;

    @Input()
    public isCollapsed = true;

    @Input()
    public expandOnPrint = true;
    private wasCollapsedOnPrint: boolean = null;

    @HostListener('window:beforeprint') onBeforePrint(): void {
        if (this.expandOnPrint && this.isCollapsed) {
            this.wasCollapsedOnPrint = true;
            this.isCollapsed = false;
            // We need the content to reflow so markForCheck isn't good enough
            this.changeDetectorRef.detectChanges();
        }
    }

    @HostListener('window:afterprint') onAfterPrint(): void {
        if (this.wasCollapsedOnPrint) {
            this.isCollapsed = true;
            this.wasCollapsedOnPrint = null;
        }
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {}
}
