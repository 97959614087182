<section class="fluid-container bg-highlight-one py-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-print-12 mb-3">
                <div class="card shadow border-0">
                    <div class="card-body">
                        <div class="col" *ngIf="currentStepVisible">
                            <div class="container mt-3 d-print-none">
                                <div class="d-flex flex-nowrap pt-3">
                                    <ng-container *ngFor="let item of steps">
                                        <div
                                            *ngIf="stepIsVisible(item.id)"
                                            class="step-item"
                                            [routerLinkActive]="['active']"
                                            (click)="goBackTo(item.id)"
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                class="mt-3"
                                                [ngClass]="{
                                                    'not-clickable':
                                                        isRegistrationReadonly ||
                                                        currentStepNumber <= getStepNumber(item.id)
                                                }"
                                            >
                                                <i
                                                    class="text- fa steps-icon {{ item.icon }}"
                                                    [ngClass]="{
                                                        done: currentStepNumber > getStepNumber(item.id),
                                                        doing: currentStepNumber === getStepNumber(item.id),
                                                        notdone: currentStepNumber < getStepNumber(item.id)
                                                    }"
                                                ></i>
                                                <p
                                                    *ngIf="currentStepNumber > getStepNumber(item.id)"
                                                    class="d-none d-lg-block text-primary"
                                                >
                                                    {{ item.header | cmsText | async }}
                                                </p>
                                                <p
                                                    *ngIf="currentStepNumber === getStepNumber(item.id)"
                                                    class="d-none d-lg-block text-primary"
                                                >
                                                    {{ item.header | cmsText | async }}
                                                </p>
                                                <p
                                                    *ngIf="currentStepNumber < getStepNumber(item.id)"
                                                    class="d-none d-lg-block text-secondary"
                                                >
                                                    {{ item.header | cmsText | async }}
                                                </p>
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="registration.auction?.finished" class="d-print-none">
                            <ngb-alert [dismissible]="false" type="danger">
                                <span [innerHTML]="'auction-finished-warning' | cmsText | async"></span>
                            </ngb-alert>
                        </div>

                        <app-stepper-header
                            [registration]="registration"
                            [neededSteps]="stepIds"
                            (printRequested)="onPrintRequested($event)"
                        >
                        </app-stepper-header>

                        <app-register-person
                            *ngIf="activeStep === 'person' && steps[0].id === 'person'"
                            [submitting]="submitting"
                            [registration]="registration"
                            (pendingChangesUpdate)="pendingChangesUpdate($event)"
                            (submitted)="saveAndContinue($event)"
                        ></app-register-person>

                        <app-register-energy
                            *ngIf="activeStep === 'product'"
                            [registration]="registration"
                            [submitting]="submitting"
                            (submitted)="saveAndContinue($event)"
                            (pendingChangesUpdate)="pendingChangesUpdate($event)"
                            (navigateToTab)="goTo($event)"
                        >
                        </app-register-energy>

                        <app-register-email-confirmation *ngIf="activeStep === 'email-confirmation'">
                        </app-register-email-confirmation>

                        <app-register-offer-introduction
                            *ngIf="activeStep === 'offer-introduction'"
                            [registration]="registration"
                            [submitting]="submitting"
                            (submitted)="saveAndContinue($event)"
                            (navigateToTab)="goTo($event)"
                            [printRequestEvents]="printRequestSubject.asObservable()"
                        >
                        </app-register-offer-introduction>

                        <app-register-proposal
                            *ngIf="activeStep === 'proposal'"
                            [registration]="registration"
                            (submitted)="saveAndContinue($event)"
                            (pendingChangesUpdate)="pendingChangesUpdate($event)"
                            (updateRequested)="saveAndRehydrate($event)"
                            (refreshRequested)="rehydrate($event, true)"
                            (navigateToTab)="goTo($event)"
                        >
                        </app-register-proposal>

                        <app-register-overview
                            *ngIf="activeStep === 'overview'"
                            [registration]="registration"
                            (updateRequested)="saveAndRehydrate($event)"
                            (navigateToTab)="goTo($event)"
                            [printRequestEvents]="printRequestSubject.asObservable()"
                        >
                        </app-register-overview>

                        <div *ngIf="serverValidations?.length || serverError" class="alert alert-danger">
                            <h4>
                                <app-content key="server-error-title"></app-content>
                            </h4>
                            <span>
                                <app-content key="server-error-description"></app-content>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 d-print-none">
                <div *ngIf="showParticipantCount && (participantCount | async) > 500">
                    <div class="text-center">
                        <img
                            class="img-fluid w-25"
                            [attr.src]="'group-icon-img' | cmsImageSrc | async"
                            [attr.alt]="'group-icon-img' | cmsImageAlt | async"
                        />
                        <h4 class="text-primary">
                            {{ participantCount | async | formatNumberDefault }} households
                        </h4>
                        <ng-container *ngIf="registrationIsCommunityTargetAuction; else upcomingAuctionCount">
                            <p *ngIf="!showAcceptantsCount">
                                {{ 'participant-count-title' | cmsText | async }}
                            </p>
                            <p *ngIf="showAcceptantsCount">
                                {{ 'acceptants-count-title' | cmsText | async }}
                            </p>
                        </ng-container>
                        <ng-template #upcomingAuctionCount>
                            <p>
                                {{ 'upcoming-participant-count-title' | cmsText | async }}
                            </p>
                        </ng-template>
                    </div>
                </div>

                <div *ngIf="community">
                    <app-contact-options
                        *ngIf="showContactOptions"
                        [communityCode]="communityCode"
                        [contactPhone]="community.contactPhone"
                        [showIntroduction]="showIntroduction"
                        [openInNewTab]="true"
                        class="d-flex my-3"
                    >
                    </app-contact-options>
                </div>

                <div class="mt-2" *ngIf="registration?.number && enabled">
                    <app-registration-user-card
                        [registrationNumber]="registration.number"
                    ></app-registration-user-card>
                </div>

                <div class="card mt-2" *ngIf="registration?.number && !enabled">
                    <div class="card-body">
                        {{ 'registration-number-label' | cmsText | async }}
                        <strong id="registrationNumber">{{ registration.number }}</strong>
                        <span class="d-none" id="registrationId">{{ registration.id }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-none d-print-block">
            <div class="row mt-2">
                <div class="col" *ngIf="community">
                    <a [href]="'tel:' + community.contactPhone" class="d-block">
                        <i class="fa fa-fw fa-phone" aria-hidden="true"> </i>
                        <span>{{ community.contactPhone }}</span>
                    </a>
                    <span [innerHTML]="'contact-phone-label' | cmsText | async"></span>
                </div>
            </div>
        </div>
    </div>
</section>
