import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { InfoItem } from '@common/components/info/info.component.base';
import { MenuItem } from '@lang/uk/model/menu-item.model';
import { InfoItemServiceBase } from '@lang/uk/services/info-item.service';
import { MenuItemService } from '@lang/uk/services/menu-item.service';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-list-menu',
    templateUrl: './list-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListMenuComponent implements OnChanges {
    @Input() cmsMenuKey: string = null;
    @Input() footer: boolean = false;
    public menuItems$: Observable<MenuItem[]> = of([]);

    public getMenuItemRoute = (menuItem: MenuItem): string[] =>
        this.menuItemService.getMenuItemRoute(menuItem);

    public getInfoItemRoute = (infoItem: InfoItem): string[] =>
        this.infoItemService.getInfoItemUrlComponents(infoItem);

    public trackById = (_: number, menuItem: MenuItem): string => menuItem.id;

    constructor(private menuItemService: MenuItemService, private infoItemService: InfoItemServiceBase) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.cmsMenuKey) {
            this.menuItems$ = changes.cmsMenuKey.currentValue
                ? this.menuItemService.getMenuItems(changes.cmsMenuKey.currentValue)
                : of([]);
        }
    }
}
