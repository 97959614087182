import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[app-postcode][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PostcodeValidator, multi: true }],
})
export class PostcodeValidator implements Validator {
    public static isValidPostcode(postcode: string): boolean {
        postcode = postcode?.replace(/ /g, '') ?? '';
        return (
            postcode.length >= 5 && postcode.length <= 7 && postcode.match(/[0-9][a-zA-Z][a-zA-Z]$/) !== null
        );
    }

    validate(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value) {
            return null;
        }
        return !PostcodeValidator.isValidPostcode(control.value)
            ? { pattern: { value: control.value } }
            : null;
    }
}
