<div
    class="alert alert-danger"
    role="alert"
    *ngIf="
        registration.product.warmHomeDiscount === true &&
        registration.proposal.winningTariffSupplierName === 'Together Energy'
    "
>
    <app-content key="proposal-more-information-warm-home-discount-together-energy"></app-content>
</div>
<ng-container *ngIf="winningTariffSupplierName === 'E.ON'">
    <app-content
        key="proposal-more-information-eon-ppm"
        *ngIf="paymentMethod === PaymentMethod.PrePayment && !wantsToCompareToOwnTariff"
    >
    </app-content>
    <app-content
        key="proposal-more-information-eon"
        *ngIf="paymentMethod === PaymentMethod.MonthlyDirectDebit && !wantsToCompareToOwnTariff"
    >
    </app-content>
</ng-container>

<app-content
    [key]="'proposal-more-information-' + supplierNameKebabCase"
    *ngIf="paymentMethod === PaymentMethod.MonthlyDirectDebit"
>
</app-content>
<app-content
    [key]="'proposal-more-information-' + supplierNameKebabCase + '-ppm'"
    *ngIf="paymentMethod === PaymentMethod.PrePayment"
>
</app-content>

<ul class="ms-3 mt-0">
    <li *ngIf="warmHomeDiscountKey" class="mt-2 warmHouseDiscountInfo">
        <app-content key="{{ warmHomeDiscountKey }}"></app-content>
    </li>
    <li class="mt-2">
        See
        <a
            href="#"
            (click)="$event.preventDefault(); openTariffComparisonLabel()"
            proposalInteractionEvent
            [eventArgs]="{ interactionType: 'popup' }"
            >Tariff Information Label</a
        >.
    </li>
</ul>
