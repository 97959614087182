import { Location } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BUSINESS } from '@common/app/app.config';
import { CommunityParticipation } from '@common/model/community-participation';
import { ParticipationAssetsService } from '@common/services/participation-assets.service';
import { BrandService } from '@lang/uk/services/brand.service';
import { UkCommunityService } from '@lang/uk/services/community.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

enum RouterLinkSuffix {
    Community = 'home',
    Brand = 'landing',
}

export enum ShowCommunityLogoMode {
    always,
    never,
    promoting,
}

@Component({
    selector: 'app-menu-logo',
    templateUrl: './menu-logo.component.html',
})
export class MenuLogoComponent implements OnDestroy, OnInit {
    @Input() showCommunityMode: ShowCommunityLogoMode = ShowCommunityLogoMode.promoting;
    @Input() multiverse: boolean = false;
    @Output() clickEvent = new EventEmitter<void>();

    public communityLogoMode = ShowCommunityLogoMode;
    routerLink = '';

    protected destroyed$ = new Subject<void>();

    // This 'hacky' way of doing things is there because some communities are also subbrands.
    // e.g. essex is both. /essex/info/how-does-it-work is reachable from both the landing page and the home page.
    // From those pages there is no way of knowing whether we should navigate to /landing or /home
    // Session storage is used to catch refreshes

    private _routerLinkPrefix = '';
    set routerLinkPrefix(prefix: string | undefined) {
        this._routerLinkPrefix = prefix;
        this.updateRouterLink();
    }

    get routerLinkPrefix(): string | undefined {
        return this._routerLinkPrefix;
    }

    private _routerLinkSuffix: RouterLinkSuffix = RouterLinkSuffix.Brand;
    private routerLinkSuffixStorageKey = 'menu-logo-router-link-suffix';

    set routerLinkSuffix(suffix: RouterLinkSuffix) {
        sessionStorage.setItem(this.routerLinkSuffixStorageKey, suffix);
        this._routerLinkSuffix = suffix;
        this.updateRouterLink();
    }

    get routerLinkSuffix(): RouterLinkSuffix {
        return this._routerLinkSuffix;
    }

    constructor(
        private communityService: UkCommunityService,
        public participationAssetsService: ParticipationAssetsService,
        location: Location,
    ) {
        const currentPath = location.path();
        if (currentPath.includes(`/${RouterLinkSuffix.Community}`)) {
            this.routerLinkSuffix = RouterLinkSuffix.Community;
        } else if (currentPath.includes(`/${RouterLinkSuffix.Brand}`)) {
            this.routerLinkSuffix = RouterLinkSuffix.Brand;
        } else {
            const storageSuffix = sessionStorage.getItem(this.routerLinkSuffixStorageKey);
            this.routerLinkSuffix =
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                storageSuffix !== null ? <RouterLinkSuffix>storageSuffix : RouterLinkSuffix.Brand;
        }

        location.onUrlChange((u) => {
            if (u.includes(`/${RouterLinkSuffix.Community}`)) {
                this.routerLinkSuffix = RouterLinkSuffix.Community;
            } else if (u.includes(`/${RouterLinkSuffix.Brand}`)) {
                this.routerLinkSuffix = RouterLinkSuffix.Brand;
            }
        });
    }

    ngOnInit(): void {
        this.communityService
            .getActive()
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged((lhsCommunity, rhsCommunity) => {
                    return lhsCommunity?.code === rhsCommunity?.code;
                }),
            )
            .subscribe((activeCommunity) => {
                this.routerLinkPrefix = activeCommunity.code;
            });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private updateRouterLink(): void {
        const prefix = this.routerLinkPrefix && '/' + this.routerLinkPrefix;
        const suffix = this.routerLinkSuffix && '/' + this.routerLinkSuffix;
        this.routerLink = (prefix || '') + (suffix || '');
    }

    public showCommunityLogo(communityParticipation: CommunityParticipation): boolean {
        return (
            this.communityLogoMode.always === this.showCommunityMode ||
            (this.communityLogoMode.promoting === this.showCommunityMode &&
                communityParticipation.isPromoting)
        );
    }
}
