import { ValidationErrors, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { PaymentMethod } from '../model/registration';

export const prepaymentValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const electricityPaymentMethod = control.get('electricityPaymentMethod');
    const gasPaymentMethod = control.get('gasPaymentMethod');

    if (!electricityPaymentMethod.value || !gasPaymentMethod.value) {
        return null;
    }

    if (
        electricityPaymentMethod.value === PaymentMethod.PrePayment &&
        gasPaymentMethod.value !== PaymentMethod.PrePayment
    ) {
        return { bothPrepaymentOrNot: true };
    }
    if (
        electricityPaymentMethod.value !== PaymentMethod.PrePayment &&
        gasPaymentMethod.value === PaymentMethod.PrePayment
    ) {
        return { bothPrepaymentOrNot: true };
    }

    return null;
};
