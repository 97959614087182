import { AbstractControl, UntypedFormGroup, UntypedFormArray, UntypedFormControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export interface ControlWithErrors extends AbstractControl {
    errors: any;
}

export function makeFormModel(form: UntypedFormGroup, data: object, skipControls: string[]): any {
    const formModel: any = {};

    Object.keys(form.controls).forEach(key => {
        if (skipControls.indexOf(key) === -1) {
            if (form.controls[key] instanceof UntypedFormGroup) {
                formModel[key] = makeFormModel(form.controls[key] as UntypedFormGroup, data[key], skipControls);
            } else {
                formModel[key] = data[key];
            }
        }
    });

    return formModel;
}

export function applyFormValue(form: UntypedFormGroup, data: object, skipControls: string[]) {
    Object.keys(form.controls).forEach(key => {
        if (!skipControls || skipControls.indexOf(key) === -1) {
            if (form.controls[key] instanceof UntypedFormGroup) {
                applyFormValue(form.controls[key] as UntypedFormGroup, data[key], skipControls);
            } else if (form.controls[key] instanceof UntypedFormArray) {
                // We use a form-array for checkboxes and Angular will
                // only save the boolean state of the checkboxes, not the values attached to them.
                // Here we try to get the resolvedValue first and fallback to
                // default behaviour if it's not available.
                data[key] = (form.controls[key] as any).resolvedValue;
                if (data[key] === null) {
                    data[key] = form.value[key] === '' ? null : form.value[key];
                }
            } else {
                data[key] = form.value[key] === '' ? null : form.value[key];
            }
        }
    });
}

export function requiredWhen(form: UntypedFormGroup, predicate: (() => boolean)): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return (form && predicate() && !control.value) ? { 'required': true } : null;
    };
}

export const getInvalidFormControls = (
    form: UntypedFormGroup | UntypedFormArray,
): Record<string, AbstractControl> =>
    Object.entries(form.controls)
        .filter(([_, c]) => c?.invalid)
        .reduce((acc, [key, control]) => {
            return control instanceof UntypedFormGroup || control instanceof UntypedFormArray
                ? { ...acc, ...getInvalidFormControls(control) }
                : { ...acc, [key]: control };
        }, {});


export function validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            validateAllFormFields(control);
        }
    });
}

export function shouldShowErrorsFor(field: ControlWithErrors) {
    return field.invalid && field.touched;
}

export interface FormGroupWithSubmit extends UntypedFormGroup {
    onSubmit: () => void;
}
