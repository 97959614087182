<div class="fluid-container under-menu py-5 hero-image-small" [ngStyle]="jumbotronStyles$ | async">
    <div class="container hero-image-container">
        <div class="row">
            <div class="col-lg-7 col-12 my-auto d-none d-lg-block">
                <div class="hero-title-background">
                    <h1 [innerHTML]="'notfound-header' | cmsText | async"></h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="fluid-container bg-highlight-one pt-2 pb-5">
    <div class="container">
        <div class="card shadow border-0">
            <div class="card-body">
                <div [innerHTML]="'registration-not-found-intro' | cmsText | async"></div>
                <div class="col-12 text-center">
                    <button
                        class="btn btn-primary mt-3 px-5"
                        (click)="requestParticipantLink()"
                        *ngIf="!sendLinkSuccess"
                        [disabled]="sendingLink"
                    >
                        <i class="fa fa-envelope me-2"></i>
                        {{ 'send-link-to-registration-btn' | cmsText | async }}
                        <i *ngIf="sendingLink" class="fa fa-spinner fa-spin ms-2 me-1"></i>
                    </button>
                    <div class="alert alert-success mt-2" *ngIf="sendLinkSuccess">
                        {{ 'send-participant-link-success' | cmsText | async }}
                    </div>
                    <div
                        class="alert alert-danger mt-1 d-flex align-items-stretch order-last excluded-scroll-selector"
                        *ngIf="sendLinkError"
                    >
                        <i class="fa fa-exclamation-circle me-3 mt-1"></i>
                        <div>
                            <span
                                class="d-block"
                                [innerHTML]="'send-participant-link-error' | cmsText | async"
                            ></span>
                            <ng-content></ng-content>
                        </div>
                    </div>
                </div>
                <div [innerHTML]="'registration-not-found-outro' | cmsText | async" class="mt-3"></div>
                <div>
                    <p>
                        <a [routerLink]="['/', 'contact']">
                            {{ 'registration-not-found-contact-link-text' | cmsText | async }}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
