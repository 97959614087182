import { Observable, of } from 'rxjs';
import { ProposalInteractionEvent } from '../events/proposal-interaction.event';
import { AnalyticsState } from '../model/analytics.state';
import { Enricher } from './enricher';

export class ProposalInteractionEventEnricher implements Enricher<ProposalInteractionEvent> {
    constructor(private product: string) {}
    enrich$(event: ProposalInteractionEvent, _state?: AnalyticsState): Observable<ProposalInteractionEvent> {
        return of({ ...event, process_product: this.product });
    }
}
