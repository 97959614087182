import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {
    CommandService,
    Command,
    CommandResult,
} from '../../services/command.service';
import { Subscription } from 'rxjs';
import { KenalyticsService } from '@common/services/kenalytics.service';
import { ContentService } from '@common/services/content.service';
import {
    CreatedRegistrationEvent,
    EmailConfirmedEvent,
} from '@common/analytics/events/process-goal.event';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { IFetchRegistration } from '@common/services/registration.service.base';
import { RegistrationBase } from '@common/model/registration.base';
import { AnalyticsEnricherProvider } from '@common/analytics/services/analytics-enricher.service';

@Component({
    selector: 'app-command',
    templateUrl: './command.component.html',
    styleUrls: ['./command.component.scss'],
})
export class CommandComponent implements OnInit, OnDestroy {
    commands = Command;
    command: Command;
    id: string;

    busy = true;
    success = false;
    error: string;
    process: Subscription;
    registrationService: IFetchRegistration<RegistrationBase>;

    constructor(
        private commandService: CommandService,
        private route: ActivatedRoute,
        protected router: Router,
        private kenalyticsService: KenalyticsService,
        public contentService: ContentService,
        private analyticsService: AnalyticsService,
        analyticsProvider: AnalyticsEnricherProvider,
    ) {
        this.registrationService = analyticsProvider?.registrationService;
    }

    ngOnInit(): void {
        try {
            const { command, id } = this.commandService.extractCommandAndId(
                this.route.snapshot.paramMap,
            );

            this.command = command;
            this.id = id;

            this.process = this.commandService
                .processCommand(
                    this.command,
                    this.id,
                    this.route.snapshot.queryParamMap,
                )
                .subscribe(
                    (result) => this.handleResult(result),
                    (error) => this.handleError(error),
                );
        } catch (error) {
            this.handleError(error);
        }
    }

    private handleResult(result: CommandResult): void {
        this.success = true;
        this.id = result.registrationId;
        this.busy = false;

        switch (this.command) {
            case Command.ConfirmEmail:
                this.handleConfirmEmail(result);
                break;
            case Command.Rollover:
                this.handleRollover(result);
                break;
        }
    }

    private handleError(error: any) {
        this.busy = false;
        if (error instanceof HttpErrorResponse) {
            this.error = error.status + ': ' + error.error;
        } else {
            this.error = error.message;
        }
    }

    private handleRollover(result: CommandResult) {
        this.kenalyticsService.postUtmCodes(result.registrationId);

        // Goal event
        const event = CreatedRegistrationEvent.create({ rollover: true });
        if (event?.event) this.analyticsService.push(event);

        this.registrationService
            ?.get(result.registrationId)
            .subscribe((registration) => {
                this.commandService.logPageView(result.command, registration);
            });
    }

    private handleConfirmEmail(result: CommandResult) {
        const event = EmailConfirmedEvent.create();
        this.analyticsService.push(event);

        this.router.navigate(['../../../register', result.registrationId], {
            relativeTo: this.route,
            queryParams: { confirmEmail: true },
        });
    }

    ngOnDestroy(): void {
        if (this.process) {
            this.process.unsubscribe();
        }
    }
}
