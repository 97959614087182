import { Injectable } from '@angular/core';
import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import {
    Observable,
    ReplaySubject,
    from,
    of,
    Subject,
    BehaviorSubject,
    combineLatest,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { User } from '../model/user';
import { HttpClient, HttpResponse } from '@angular/common/http';

type AuthEvent = 'user_changed';

@Injectable()
export class AuthService {
    // Legacy for BLs that don't support Participant Logins:
    private userSubject$ = new ReplaySubject<User>();
    public user$: Observable<User> = this.userSubject$;

    private profileId: string;
    private participantKey: string;
    private identitySubject$ = new ReplaySubject<User>();
    public identity$: Observable<User> = this.identitySubject$;

    private authEventsSubject$ = new Subject<AuthEvent>();
    public authEvents$: Observable<AuthEvent> = this.authEventsSubject$;

    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    public accessToken: string;

    /**
     * Publishes `true` if and only if (a) all the asynchronous initial
     * login calls have completed or errorred, and (b) the user ended up
     * being authenticated.
     *
     * In essence, it combines:
     *
     * - the latest known state of whether the user is authorized
     * - whether the ajax calls for initial log in have all been done
     */
    public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
        this.isAuthenticated$,
        this.isDoneLoading$,
    ]).pipe(map((values) => values.every((b) => b)));

    constructor(private oAuthService: OAuthService, private http: HttpClient) {
        this.setupOAuthEventListeners();

        this.oAuthService
            .loadDiscoveryDocument()
            .then(() => this.trySilentRefresh()) // Do this even when hasValidAccessToken, because it might be stale
            .then(() => {
                if (!this.oAuthService.hasValidAccessToken()) {
                    this.isAuthenticatedSubject$.next(false);
                    this.isDoneLoadingSubject$.next(true);
                } else {
                    // If at this point we have a valid access token, then the
                    // 'token_received' event will have been published and our
                    // listeners will have made `tryLoadUserInfo` do all this
                    // finalization work.
                }
            })
            .catch(() => {
                this.isAuthenticatedSubject$.next(false);
                this.isDoneLoadingSubject$.next(true);
                this.userSubject$.next(null)
            });

        // TODO: Move this to above loadDiscoveryDocument
        // This is the news advice for the angular-oauth2-oidc library, but
        // too impactful to change *while* doing Participant Login changes too.
        this.oAuthService.setupAutomaticSilentRefresh();
    }

    private setupOAuthEventListeners() {
        this.oAuthService.events.subscribe((evt) => {
            switch (evt.type) {
                case 'token_received':
                    this.tryLoadUserInfo();
                    this.accessToken = this.oAuthService.getAccessToken();
                    break;
                case 'session_terminated':
                case 'session_error':
                case 'token_validation_error':
                    this.identitySubject$.next(null);
                    this.isAuthenticatedSubject$.next(false);
                    this.isDoneLoadingSubject$.next(true);
                    this.userSubject$.next(null); // Legacy for BLs that don't support Participant Logins

                    // Unfortunately the OAuth2 library doesn't nicely expose the fact that the user
                    // somehow "changed" (for example by logging into another Identity in a different
                    // tab, thus causing silent refreshes to return tokens for another user than was
                    // currently known). We have to 'sniff' this from the error message for now, and
                    // publish it to anyone who's interested (e.g. a RegisterEffectsService).
                    if (
                        evt instanceof OAuthErrorEvent &&
                        typeof (evt as OAuthErrorEvent).reason === 'string' &&
                        String((evt as OAuthErrorEvent).reason).includes(
                            'token for another user',
                        )
                    ) {
                        this.authEventsSubject$.next('user_changed');
                    }

                    break;
            }
        });
    }

    trySilentRefresh(): Promise<void> {
        return this.oAuthService
            .silentRefresh()
            .then(() => Promise.resolve())
            .catch((_) => {
                this.identitySubject$.next(null);

                // Legacy for BLs that don't support Participant Logins:
                this.userSubject$.next(null);
            });
    }

    private tryLoadUserInfo(): Promise<void> {
        if (this.oAuthService.hasValidAccessToken()) {
            return this.oAuthService
                .loadUserProfile()
                .then((userProfile: Record<string, unknown>) => {
                    const user = new User(userProfile?.info);
                    this.identitySubject$.next(user);
                    this.isAuthenticatedSubject$.next(true);
                    this.isDoneLoadingSubject$.next(true);

                    // Legacy for BLs that don't support Participant Logins:
                    if (user.role !== 'participant') {
                        this.userSubject$.next(user);
                    } else {
                        this.userSubject$.next(null);
                    }
                });
        }

        return Promise.resolve().then((_) => {
            this.isAuthenticatedSubject$.next(false);
            this.isDoneLoadingSubject$.next(true);
        });
    }

    hasValidToken(): boolean {
        const accessToken = this.oAuthService.getAccessToken();
        return accessToken ? true : false;
    }

    loginAsParticipant(resp: HttpResponse<any>): Observable<any> {
        const newProfileId = resp.headers.get('X-Profile-Id');
        const newKey = resp.headers.get('X-Participant-Key');
        if (!newProfileId || !newKey || this.participantKey === newKey) {
            return of(null);
        }

        this.profileId = newProfileId;
        this.participantKey = newKey;

        this.oAuthService.logOut(true);

        return this.http
            .get(
                `${this.oAuthService.issuer}/participant/login?profileId=${this.profileId}&key=${this.participantKey}`,
                { responseType: 'text', withCredentials: true },
            )
            .pipe(switchMap((_) => from(this.oAuthService.silentRefresh())));
    }

    logout(): void {
        this.oAuthService.logOut();
    }
}
