import { Pipe, PipeTransform } from '@angular/core';
import { CmsProcessor } from '../services/cms-processor';

@Pipe({ name: 'paramReplacement' })
export class ParamReplacementPipe implements PipeTransform {
    constructor() {}

    transform(input: string, params: any[]): string {
        if (params === undefined) {
            return input;
        }

        if (params && input) {
            const regex = /({{|%7B%7B)\s*([\w\.]+)\s*(}}|%7D%7D)/g;
            const match = input.match(regex);
            const tokensToReplace = match ? match.length : 0;
            const processor = new CmsProcessor(params);

            //  No token to replace or empty parameters list
            if (tokensToReplace === 0 || params.length < 1) {
                return input;
            }

            // Tokens and parameters exist
            if (tokensToReplace > 0 && params.length > 0) {
                const result = input.replace(
                    regex,
                    processor.contentReplacer.bind(processor),
                );
                return result;
            }
        }

        return '';
    }
}
