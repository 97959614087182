import { ValidationErrors, AbstractControl } from '@angular/forms';
import { first, map, catchError } from 'rxjs/operators';
import { BankAccountValidatorResponse } from '../services/registration.service';
import { of, Observable } from 'rxjs';
export function bankAccountValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    const accountNumber = control.get('accountNumber');
    const sortCode = control.get('sortCode');
    if (!accountNumber.value || !sortCode.value) {
        return of(null);
    }

    return this.registrationService
        .validateBankAccount(this.registration, accountNumber.value, sortCode.value)
        .pipe(
            first((response) => response !== null),
            map((response: BankAccountValidatorResponse) =>
                response && (response.valid || !response.responseReceived)
                    ? null
                    : { bankAccountIsInvalid: true },
            ),
            catchError(() => null),
        );
}
