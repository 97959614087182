import {
    Community,
    PostAuctionRegistrationMode,
} from '@common/model/community';
import { Observable, of, ReplaySubject } from 'rxjs';
import {
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
    take,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { API_URL, DEFAULT_COMMUNITY } from '@common/app/app.config';
import { RouteService } from '@common/infrastructure/params-service';
import { Params } from '@angular/router';
import { isNullOrUndefined } from '@common/util';

export abstract class CommunityServiceBase<T extends Community> {
    private _communityCode: string;
    private isValid: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    public community$: Observable<T>;
    public communityCode$: Observable<string>;

    public get communityIsValid() {
        return this.isValid.asObservable();
    }

    get communityCode(): string {
        return this._communityCode;
    }

    get communityContactPhone$(): Observable<string> {
        return this.community$.pipe(
            map((community) => {
                return community.contactPhone;
            }),
        );
    }

    get postAuctionRegistration$(): Observable<boolean> {
        return this.community$.pipe(
            map((community) => {
                if (!community || !community.targetAuction) {
                    return false;
                }
                const currentDate = new Date();
                const auctionDate =
                    community.targetAuction.date instanceof Date
                        ? community.targetAuction.date
                        : new Date(community.targetAuction.date);

                return (
                    community.postAuctionRegistration ===
                        PostAuctionRegistrationMode.Everybody &&
                    auctionDate < currentDate &&
                    community.targetAuction.openForNewRegistrations
                );
            }),
        );
    }

    get autoMakeProposals$(): Observable<boolean> {
        return this.community$.pipe(
            map((community) => {
                if (!community || !community.targetAuction) {
                    return false;
                }
                return community.targetAuction.autoMakeProposals;
            }),
        );
    }

    get preventInterestedIndividualsSubscriptions$(): Observable<boolean> {
        return this.community$.pipe(
            map((community) => {
                if (!community) {
                    return false;
                }
                return community.preventInterestedIndividuals;
            }),
        );
    }

    get currentAuctionOpenForNewRegistrations$(): Observable<boolean> {
        return this.community$.pipe(
            map((community) => {
                if (!community || !community.targetAuction) {
                    return false;
                }
                return community.targetAuction.openForNewRegistrations;
            }),
        );
    }

    get currentAuctionOpenForDecisions$(): Observable<boolean> {
        return this.community$.pipe(
            map((community) => {
                if (!community || !community.targetAuction) {
                    return false;
                }
                const currentDate = new Date();
                const auctionDate =
                    community.targetAuction.date instanceof Date
                        ? community.targetAuction.date
                        : new Date(community.targetAuction.date);

                return (
                    auctionDate < currentDate &&
                    community.targetAuction.autoMakeProposals
                );
            }),
        );
    }

    get currentAuctionFinished$(): Observable<boolean> {
        return this.community$.pipe(
            map((community) => {
                if (!community || !community.targetAuction) {
                    return true;
                }
                const currentDate = new Date();
                const auctionDate =
                    community.targetAuction.date instanceof Date
                        ? community.targetAuction.date
                        : new Date(community.targetAuction.date);

                return (
                    auctionDate < currentDate &&
                    community.targetAuction.finished
                );
            }),
        );
    }

    protected constructor(
        protected http: HttpClient,
        @Inject(API_URL) protected baseUrl: string,
        @Inject(DEFAULT_COMMUNITY) protected defaultCommunity: string,
        protected routeService: RouteService,
    ) {
        this.communityCode$ = this.routeService.aggregatedParams.pipe(
            map((p: Params) => this.getCommunityCodeOrFallback(p)),
            distinctUntilChanged(),
        );
        this.community$ = this.communityCode$.pipe(
            switchMap((code) => this.getCommunity(code)),
            shareReplay(1),
        );

        this.communityCode$.subscribe((code) => (this._communityCode = code));
        this.community$.subscribe(
            (result) => this.isValid.next(!isNullOrUndefined(result.code)),
            (_error) => this.isValid.next(false),
        );
    }

    public checkMembership(
        communityId: string,
        code: string,
    ): Observable<boolean> {
        return this.http.get<boolean>(
            `${this.baseUrl}communities/${communityId}/member?info=${code}`,
        );
    }

    public fetchCommunity(params: Params): Observable<T> {
        return this.getCommunity(this.getCommunityCodeOrFallback(params));
    }

    // Finds the community code by checking for an explicit community code in the route
    // In absense of that, uses the convention where region- and community code are equivalent
    // As a last resort tries the defaultcommunity as defined in the appsettings
    // Returns null ifall else fails
    public getCommunityCodeOrFallback(params: Params): string {
        const communityCode = isNullOrUndefined(params.community)
            ? null
            : params.community.toLowerCase();
        const regionCode = isNullOrUndefined(params.region)
            ? null
            : params.region.toLowerCase();

        if (communityCode && communityCode[0] !== '?') {
            return communityCode;
        }
        if (regionCode) {
            return regionCode;
        }
        if (this.defaultCommunity) {
            return this.defaultCommunity;
        }

        return null;
    }

    protected abstract getCommunityInstance(): T;

    public getCommunity(code: string): Observable<T> {
        if (isNullOrUndefined(code)) {
            return of(this.getCommunityInstance());
        }

        const url = `${this.baseUrl}communities/code/${code}`;

        return this.http.get<T>(url).pipe(take(1));
    }
}
