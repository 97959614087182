import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable()
export class VwoStoreService {
    variantName$: ReplaySubject<string>;
    distinctVariantName$: Observable<string>;

    constructor() {
        this.variantName$ = new ReplaySubject<string>(1);
        this.distinctVariantName$ = this.variantName$.pipe(
            filter((name) => name !== 'undefined'),
            distinctUntilChanged(),
        );
    }
}
