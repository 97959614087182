import { Flow } from '@common/model/data-layer';
import { RegistrationBase } from '@common/model/registration.base';
import { ParamMap } from '@angular/router';
import { Injectable } from '@angular/core';

export class FlowData {
    goToStep: string;
    previousStep?: string;
    startedWithStep?: string;
    enteredFlowFromLink: boolean;
    registration?: RegistrationBase;
    previousRegistrationStatus?: string;
    queryParamMap: ParamMap;
}

@Injectable()
export class RegisterFlowCalculatorBase {
    flow(data: FlowData): Flow {
        let start = data.startedWithStep;
        if (!start) {
            start = data.enteredFlowFromLink
                ? 'link'
                : data.startedWithStep
                ? data.startedWithStep
                : 'person';
        }

        if (data.queryParamMap.get('confirmEmail') === 'true') {
            return { start, action: 'confirmEmail' };
        }
        if (data.previousRegistrationStatus === data.registration?.status) {
            return { start, action: undefined };
        }
        switch (data.registration.status) {
            case 'Created':
                return { start, action: 'subscriptionCreated' };
            case 'Subscribed':
                return { start, action: 'subscriptionCompleted' }; // ToDo @Bart: remove once API upgraded
            case 'Completed':
                return { start, action: 'subscriptionCompleted' };
            case 'Undecided':
                return { start, action: 'subscriptionCompleted' };
            case 'Accepted':
                return { start, action: 'proposalAccepted' };
            case 'Declined':
                return { start, action: 'proposalDeclined' };
            default:
                return { start, action: undefined };
        }
    }
}
