import { Inject, Injectable, Optional } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CANONICAL_COMMUNITY } from '@common/app/app.config';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { LanguageService } from '@common/services/language.service';
import { LinkService } from '@common/services/link.service';
import { SeoService } from '@common/services/seo.service';
import { BrandService } from '@lang/uk/services/brand.service';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EnukSeoService extends SeoService {
    constructor(
        meta: Meta,
        content: ContentService,
        communityService: CommunityService,
        title: Title,
        @Inject(CANONICAL_COMMUNITY) canonicalCommunity: string,
        linkService: LinkService,
        @Optional() languageService: LanguageService,
        private brandService: BrandService,
    ) {
        super(meta, content, communityService, title, canonicalCommunity, linkService, languageService);
    }

    override generateCanonicalTag(urlArray: string[]): void {
        this.brandService
            .getActive()
            .pipe(first())
            .subscribe((brand) => {
                super.generateCanonicalTag(urlArray, brand ? brand.code : 'ichoosr');
            });
    }
}
