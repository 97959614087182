export enum RegisterStep {
    Person = 'person',
    Product = 'product',
    EmailConfirmation = 'email-confirmation',
    OfferIntroduction = 'offer-introduction',
    Proposal = 'proposal',
    Overview = 'overview',
    OverViewProposalAccepted = 'overview-proposal-accepted',
    OverviewCompleteRegistration = 'overview-complete-registration',
    OverviewConfirmRegistration = 'overview-confirm-registration',
    OverviewWaitForAuction = 'overview-wait-for-auction',
}
