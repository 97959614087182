import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { APP_BASE_HREF, Location } from '@angular/common';
import { AppBase } from '@common/app/app.component.base';
import { GoogleAnalyticsService } from '@common/services/google-analytics.service';
import { Title } from '@angular/platform-browser';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { SeoService } from '@common/services/seo.service';
import { KenalyticsService } from '@common/services/kenalytics.service';
import { FeatureFlagsService } from './services/feature-flags.service';
import { filter, map, throttleTime } from 'rxjs/operators';
import { MenuMode } from './model/menu-configuration';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { WINDOW } from '@common/services/window.service';
import { AnalyticsClickService } from '@common/analytics/services/click.service';

declare var tagManagerEvent: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent extends AppBase implements OnInit {
    public menuMode: string;
    public showLogo: boolean;
    protected override get useGenericCommunityServiceErrorHandling(): boolean {
        return false;
    }

    constructor(
        router: Router,
        location: Location,
        analytics: GoogleAnalyticsService,
        titleService: Title,
        communityService: CommunityService,
        route: ActivatedRoute,
        contentService: ContentService,
        seoService: SeoService,
        kenalyticsService: KenalyticsService,
        private featureFlagsService: FeatureFlagsService,
        analyticsService: AnalyticsService,
        @Inject(WINDOW) window: Window,
        @Inject(APP_BASE_HREF) baseHref: string,
        clickService: AnalyticsClickService,
    ) {
        super(
            router,
            location,
            analytics,
            titleService,
            communityService,
            route,
            contentService,
            seoService,
            kenalyticsService,
            analyticsService,
            window,
            baseHref,
            clickService,
        );
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.communityService.community$.subscribe(
            (_community) => {},
            (error) => {
                if (error.status === 404) {
                    this.router.navigate(['landing']);
                } else {
                    this.router.navigate([this.communityService.communityCode, 'error']);
                }
            },
        );

        this.route.queryParamMap.subscribe((params) => {
            const addressClient = params.get('addressClient');
            if (addressClient != null) {
                this.featureFlagsService.addressClient = addressClient;
            }

            const energyClient = params.get('energyClient');
            if (energyClient != null) {
                this.featureFlagsService.energyClient = energyClient;
            }
        });

        this.router.events
            .pipe(
                filter((e) => e instanceof ActivationEnd),
                throttleTime(0),
                map((e: ActivationEnd) => e.snapshot.data),
            )
            .subscribe((data) => {
                this.menuMode = data?.menuMode ? <string>data.menuMode : MenuMode.Show;
                this.showLogo = data.showLogo ?? true;
            });
    }
}
