import { Injectable, Inject } from '@angular/core';
import { WINDOW } from './window.service';


export interface IVwoService {
    push(path: string);
}

@Injectable(
    { providedIn: 'root' }
)
export class VwoService implements IVwoService {

    constructor(@Inject(WINDOW) private window: Window) { }

    push(path: string) {
        const href = this.window.location.href;
        const url = href.split('?')[0];
        (<any>this.window).VWO = (<any>window).VWO || [];
        (<any>this.window).VWO.push(['activate', {
            virtualPageUrl: `${url}/${path}`
        }]);
    }
}
