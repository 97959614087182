/* eslint-disable @typescript-eslint/naming-convention */
import { ActivatedRouteSnapshot } from '@angular/router';
import { AnalyticsState } from '@common/analytics/model/analytics.state';
import { FlowStart } from '@common/analytics/model/flow-start';
import { ProposalFlowSource } from '@common/analytics/model/proposal-flow-source';
import { RegistrationBase } from '@common/model/registration.base';

export abstract class AnalyticsSolverBase<T extends RegistrationBase> {
    /**
     * Will be called by the resolver and itself call
     * - abstract methods if the state value is really business specific
     * - protected methods if the state value is (mostly) common. This can still be overridden if needed
     */
    public solveState(routeSnapshot: ActivatedRouteSnapshot, registration: T): AnalyticsState {
        return new AnalyticsState({
            process_flow_start: this.solveProcessFlowStart(routeSnapshot, registration),
            proposal_flow_source: this.solveProcessProposalFlowSource(routeSnapshot, registration),
        });
    }

    protected abstract solveProcessFlowStart(route: ActivatedRouteSnapshot, registration: T): FlowStart;

    protected solveProcessProposalFlowSource(
        route: ActivatedRouteSnapshot,
        registration: T,
    ): ProposalFlowSource {
        if (!registration || !registration.proposalMade || registration.decisionComplete) return undefined;
        return route?.paramMap?.has('id') ? 'link' : 'direct';
    }
}
