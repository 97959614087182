/* eslint-disable @typescript-eslint/naming-convention */
import { getAnyTextFromElement } from '@common/util';
import { ProposalInteractionType } from '../model/proposal-interaction-type';
import { AnalyticsEvent, AnalyticsEventTemplate } from './analytics.event';

type Template = AnalyticsEventTemplate<ProposalInteractionEvent>;

export class ProposalInteractionEvent implements AnalyticsEvent {
    event = 'proposal_interaction' as const;

    process_product: string;
    interaction_type: ProposalInteractionType;
    click_text: string;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }

    static create(
        linkElement?: HTMLElement,
        customText?: string,
        interactionType?: ProposalInteractionType,
    ): ProposalInteractionEvent {
        return new ProposalInteractionEvent({
            click_text: customText ?? getAnyTextFromElement(linkElement),
            interaction_type: interactionType,
        });
    }
}
