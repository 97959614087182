import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '@common/app/app.config';
import { Auction } from '../model/auction';
import {AuctionServiceBase} from "@common/services/auction-base.service";

@Injectable({
    providedIn: 'root',
})
export class AuctionService extends AuctionServiceBase<Auction> {
    constructor(
        protected override http: HttpClient,
        @Inject(API_URL) protected override baseUrl: string,
    ) {
        super(http, baseUrl);
    }
}
