import { NgModule } from '@angular/core';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './components/menu/menu.component';
import { AppCommonCoreModule } from '@common/app-common-core.module';
import { RouterModule } from '@angular/router';
import { MenuLogoComponent } from './components/menu-logo/menu-logo.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VwoCapturerComponent } from '@common/components/vwo/vwo-capturer.component';
import { ProposalInteractionEventDirective } from '@common/analytics/directives/proposal-interaction-event.directive';
import { MvModule } from '@ichoosr/multiverse';
import { MvMiniFaqComponent } from './components/mv-mini-faq/mv-mini-faq.component';
import { TrustpilotReviewComponent } from './components/trustpilot/trustpilot-review/trustpilot-review.component';

@NgModule({
    imports: [
        AppCommonCoreModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        RouterModule,
        NgbModule,
        MvModule,
    ],
    declarations: [
        NavMenuComponent,
        MenuComponent,
        MenuLogoComponent,
        MvMiniFaqComponent,
        TrustpilotReviewComponent,
    ],
    exports: [
        NavMenuComponent,
        MenuComponent,
        MenuLogoComponent,
        VwoCapturerComponent,
        ProposalInteractionEventDirective,
        MvMiniFaqComponent,
        TrustpilotReviewComponent,
    ],
    providers: [],
})
export class UkLangModule {}
