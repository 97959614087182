import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from '@enuk/model/app-config';
import { APP_CONFIG } from '@enuk/services/app-config.service';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

const baseElement = document.querySelector('base');
const baseHref = (baseElement?.getAttribute('href') || '/').replace(/\/$/, '');

fetch(`${baseHref}/assets/runtime-config.json?v=${new Date().getTime()}`)
    .then((response) => response.json())
    .then((config: AppConfig) => {
        if (config.production) {
            enableProdMode();
        }

        platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    })
    .catch(() => {
        const roots = document.getElementsByTagName('app-root');
        if (roots && roots.length) {
            var root = roots[0];
            let element = document.createElement('p');
            element.className = 'container text-center';
            element.innerHTML = 'Unknown network error: Please refresh your browser to reload!';
            root.appendChild(element);
        }
    });
