import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { MonitoringService } from '@common/services/monitoring.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    constructor(private monitoringService: MonitoringService) {
        super();
    }

    override handleError(error: Error) {
        if (!environment.production) {
            console.error('Intercepted AppInsights error:', error);
        }

        this.monitoringService.logException(error);
    }
}
