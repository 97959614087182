import { Community } from '@common/model/community';
import { RegistrationBase } from '@common/model/registration.base';
import { AuctionService } from '@common/services/auction.service';

export type FlowPhase = 'registration phase' | 'decision phase' | 'no active auction' | '';

export const getFlowPhase = (registration: RegistrationBase, community: Community): FlowPhase => {
    const auction = AuctionService.getAnyAuction(registration, community);

    if (!auction && !community) return undefined;
    if (!auction || auction.finished) return 'no active auction';
    if (auction.autoMakeProposals || registration?.proposalMade) return 'decision phase';
    if (auction.openForNewRegistrations) return 'registration phase';

    return 'no active auction';
};
