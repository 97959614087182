<form [formGroup]="form" class="mt-3" (ngSubmit)="onSubmit()" novalidate appfocusFirstInvalidField>
    <div class="modal-header">
        <p class="h5 modal-title">{{ 'proposal-compare-current-title' | cmsText | async }}</p>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-0">
        <div class="row">
            <div class="col col-12 col-lg-6">
                <div class="row">
                    <div class="col">
                        <label class="form-label">{{
                            'proposal-compare-current-electricity-consumption' | cmsText | async
                        }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-8">
                        <div class="input-group">
                            <p class="fw-bold">{{ registration?.product.electricityConsumption }} kWh/year</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="hasGas" class="col col-12 col-lg-6">
                <div class="row">
                    <div class="col">
                        <label class="form-label">{{
                            'proposal-compare-current-gas-consumption' | cmsText | async
                        }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-8">
                        <div class="input-group">
                            <p class="fw-bold">{{ registration?.product.gasConsumption }} kWh/year</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-header pt-0">
        <p class="h5 modal-title">{{ 'proposal-compare-questions-title' | cmsText | async }}</p>
    </div>
    <div class="modal-body">
        <div *ngIf="isDual">
            <div>
                <app-register-energy-supplier
                    formControlName="dualSupplierData"
                    id="dualSupplierData"
                    cmsLabel="proposal-compare-current-dual-supplier"
                    [suppliers]="activeSuppliers$"
                    [fuelType]="FuelType.ElectricityAndGas"
                    [regionId]="registration.contact.regionId"
                >
                </app-register-energy-supplier>
                <div *ngIf="dualSupplierData.invalid && dualSupplierData.dirty" class="text-danger">
                    <span *ngIf="dualSupplierData.errors.required">
                        {{ 'energy-contract-dual-supplier-error-required' | cmsText | async }}
                    </span>
                </div>
            </div>

            <div>
                <app-register-energy-payment-method
                    formControlName="dualPaymentMethod"
                    id="dualPaymentMethod"
                    cmsLabel="proposal-compare-current-dual-payment-method"
                    [readonly]="!dualSupplierData.value"
                    [paymentMethods]="dualPaymentMethods"
                >
                </app-register-energy-payment-method>
                <div *ngIf="dualPaymentMethod.invalid && dualPaymentMethod.dirty" class="text-danger">
                    <span *ngIf="dualPaymentMethod.errors.required"> Payment method is required. </span>
                </div>
            </div>

            <div>
                <label for="dualTariff" class="d-block form-label">
                    {{ 'energy-contract-dual-tariff-label' | cmsText | async }}
                </label>
                <div class="row">
                    <div class="col-md-8">
                        <ng-select
                            id="dualTariffName"
                            formControlName="dualTariffName"
                            [readonly]="!dualPaymentMethod.value"
                            [items]="dualTariffs"
                            [searchFn]="searchItem"
                            bindValue="name"
                            bindLabel="name"
                            [selectOnTab]="true"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                <span [innerHTML]="item.name"></span>
                                <span *ngIf="item.paperBilling && !item.paperlessBilling">
                                    (Paper billing)</span
                                >
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <div [innerHTML]="item.name"></div>
                                <span *ngIf="item.paperBilling && !item.paperlessBilling">
                                    (Paper billing)</span
                                >
                            </ng-template>
                        </ng-select>
                        <div *ngIf="dualTariffName.invalid && dualTariffName.dirty" class="text-danger">
                            <span *ngIf="dualTariffName.errors.required">
                                Dual fuel tariff plan is required.
                            </span>
                        </div>
                        <div class="text-danger" *ngIf="errorGetTariffs">
                            {{ 'tariff-error-server' | cmsText | async }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isElectricity || isSeparate">
            <div>
                <app-register-energy-supplier
                    formControlName="electricitySupplierData"
                    id="electricitySupplierData"
                    cmsLabel="proposal-compare-current-electricity-supplier"
                    [suppliers]="activeSuppliers$"
                    [fuelType]="FuelType.Electricity"
                    [regionId]="registration.contact.regionId"
                >
                </app-register-energy-supplier>
                <div
                    *ngIf="electricitySupplierData.invalid && electricitySupplierData.dirty"
                    class="text-danger"
                >
                    <span *ngIf="electricitySupplierData.errors.required">
                        {{ 'energy-contract-electricity-supplier-error-required' | cmsText | async }}
                    </span>
                </div>
            </div>

            <div>
                <app-register-energy-payment-method
                    formControlName="electricityPaymentMethod"
                    id="electricityPaymentMethod"
                    cmsLabel="proposal-compare-current-electricity-payment-method"
                    [readonly]="!electricitySupplierData.value"
                    [paymentMethods]="electricityPaymentMethods"
                >
                </app-register-energy-payment-method>
                <div
                    *ngIf="electricityPaymentMethod.invalid && electricityPaymentMethod.dirty"
                    class="text-danger"
                >
                    <span *ngIf="electricityPaymentMethod.errors.required">
                        Payment method is required.
                    </span>
                </div>
                <div
                    *ngIf="form.errors?.bothPrepaymentOrNot && (form.touched || form.dirty)"
                    class="cross-validation-error-message text-danger error-focus ng-invalid"
                >
                    {{ 'energy-contract-separate-error-mismatched-payment-method' | cmsText | async }}
                </div>
            </div>

            <div>
                <label for="electricityTariff" class="d-block form-label">
                    {{ 'energy-contract-electricity-tariff-label' | cmsText | async }}
                </label>
                <div class="row">
                    <div class="col-md-8">
                        <ng-select
                            id="electricityTariffName"
                            formControlName="electricityTariffName"
                            [readonly]="!electricityPaymentMethod.value"
                            [items]="electricityTariffs"
                            [searchFn]="searchItem"
                            bindValue="name"
                            bindLabel="name"
                            [selectOnTab]="true"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                <span [innerHTML]="item.name"></span>
                                <span *ngIf="item.paperBilling && !item.paperlessBilling">
                                    (Paper billing)</span
                                >
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <div [innerHTML]="item.name"></div>
                                <span *ngIf="item.paperBilling && !item.paperlessBilling">
                                    (Paper billing)</span
                                >
                            </ng-template>
                        </ng-select>
                        <div
                            *ngIf="electricityTariffName.invalid && electricityTariffName.dirty"
                            class="text-danger"
                        >
                            <span *ngIf="electricityTariffName.errors.required">
                                Electricity tariff plan is required.
                            </span>
                        </div>
                        <div class="text-danger" *ngIf="errorGetTariffs">
                            {{ 'tariff-error-server' | cmsText | async }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isSeparate">
            <div>
                <app-register-energy-supplier
                    formControlName="gasSupplierData"
                    id="gasSupplierData"
                    [suppliers]="activeSuppliers$"
                    cmsLabel="proposal-compare-current-gas-supplier"
                    [fuelType]="FuelType.Gas"
                    [regionId]="registration?.contact?.regionId"
                >
                </app-register-energy-supplier>
                <div *ngIf="gasSupplierData.invalid && gasSupplierData.dirty" class="text-danger">
                    <span *ngIf="gasSupplierData.errors.required">
                        {{ 'energy-contract-gas-supplier-error-required' | cmsText | async }}
                    </span>
                </div>
            </div>

            <div>
                <app-register-energy-payment-method
                    formControlName="gasPaymentMethod"
                    id="gasPaymentMethod"
                    cmsLabel="proposal-compare-current-gas-payment-method"
                    [readonly]="!gasSupplierData.value"
                    [paymentMethods]="gasPaymentMethods"
                >
                </app-register-energy-payment-method>
                <div *ngIf="gasPaymentMethod.invalid && gasPaymentMethod.dirty" class="text-danger">
                    <span *ngIf="gasPaymentMethod.errors.required"> Payment method is required. </span>
                </div>
                <div
                    *ngIf="form.errors?.bothPrepaymentOrNot && (form.touched || form.dirty)"
                    class="cross-validation-error-message text-danger error-focus ng-invalid"
                >
                    {{ 'energy-contract-separate-error-mismatched-payment-method' | cmsText | async }}
                </div>
            </div>

            <div>
                <label for="gasTariff" class="d-block form-label">
                    {{ 'energy-contract-gas-tariff-label' | cmsText | async }}
                </label>
                <div class="row">
                    <div class="col-md-8">
                        <ng-select
                            id="gasTariffName"
                            formControlName="gasTariffName"
                            [readonly]="!gasPaymentMethod.value"
                            [items]="gasTariffs"
                            [searchFn]="searchItem"
                            bindValue="name"
                            bindLabel="name"
                            [selectOnTab]="true"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                <span [innerHTML]="item.name"></span>
                                <span *ngIf="item.paperBilling && !item.paperlessBilling">
                                    (Paper billing)</span
                                >
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <div [innerHTML]="item.name"></div>
                                <span *ngIf="item.paperBilling && !item.paperlessBilling">
                                    (Paper billing)</span
                                >
                            </ng-template>
                        </ng-select>
                        <div *ngIf="gasTariffName.invalid && gasTariffName.dirty" class="text-danger">
                            <span *ngIf="gasTariffName.errors.required"> gas tariff plan is required. </span>
                        </div>
                        <div class="text-danger" *ngIf="errorGetTariffs">
                            {{ 'tariff-error-server' | cmsText | async }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">
            {{ 'proposal-compare-close-button' | cmsText | async }}
        </button>
        <button type="submit" class="btn btn-success btn-block ws-normal" [disabled]="form.invalid">
            {{ 'proposal-compare-calculate-button' | cmsText | async }}
        </button>
    </div>
</form>
