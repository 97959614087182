import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AppBase } from '@common/app/app.component.base'; // for jsdoc
import { ProposalInteractionType } from '../model/proposal-interaction-type';
import { ClickEventArgs, ClickEventDirective } from './click-event.directive';

interface ProposalInteractionEventArgs extends ClickEventArgs {
    interactionType?: ProposalInteractionType;
}

/**
 * {@link AppBase} will handle all clicks on `<a>` and `<button>`.
 * This directive can be used on other selectors that act like internal links.
 */
@Directive({
    selector: '[proposalInteractionEvent]', // eslint-disable-line @angular-eslint/directive-selector
})
export class ProposalInteractionEventDirective extends ClickEventDirective<ProposalInteractionEventArgs> {

    private get interactionType(): ProposalInteractionType {
        return this.eventArgs?.interactionType;
    }

    override sendEvent(clickTarget: HTMLElement): void {
        this.service.sendProposalInteractionEvent(clickTarget, this.customText, this.interactionType);
    }
}
