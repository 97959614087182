import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy-policy',
    template: `
    <div class="container">
  <h2 class="mt-5 mb-3">{{ 'privacy-policy-title' | cmsText | async }}</h2>
  <p [innerHtml]="'privacy-policy-text' | cmsText | async"></p>
</div>
  `,
})
export class PrivacyPolicyComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
