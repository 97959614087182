import { FILE_URL_REGEX, getAnyTextFromElement, getFileName } from '@common/util';
import { AnalyticsEvent, AnalyticsEventTemplate } from './analytics.event';

type Template = AnalyticsEventTemplate<DownloadEvent>;

export class DownloadEvent implements AnalyticsEvent {
    event = 'download' as const;

    download_file_path: string;
    download_file_name: string;
    click_text: string;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }

    static create(linkElement: HTMLElement, customText?: string): DownloadEvent {
        const path = linkElement.getAttribute('href');

        return new DownloadEvent({
            download_file_path: path,
            click_text: customText ?? getAnyTextFromElement(linkElement),
            download_file_name: getFileName(path),
        });
    }
}
