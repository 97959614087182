import { Directive, ElementRef, HostListener } from '@angular/core';
import { AnalyticsClickService } from '@common/analytics/services/click.service';

@Directive()
export abstract class TooltipComponentBase {
    /** The CMS key of the item the tooltip is explaining. */
    abstract targetCmsKey: string;
    protected element: HTMLElement;

    constructor(private clickService: AnalyticsClickService, ref: ElementRef) {
        this.element = ref.nativeElement as HTMLElement;
    }

    @HostListener('click')
    onClick(): true {
        try {
            const fallBackText = this.element?.parentElement?.innerText;
            this.clickService.sendTooltipClick(this.targetCmsKey, fallBackText);
        } catch {}
        return true; //never prevent default
    }
}
