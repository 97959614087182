import { Injectable } from '@angular/core';
import { Registration, Contracts } from '../model/registration';
import * as moment from 'moment';
import { AuctionHelper } from '../helpers/auction-helper';
export enum Comparisons {
    DefaultAndRenewal = 'DefaultAndRenewal',
    WithCurrent = 'WithCurrent',
    WithStandard = 'WithStandard',
    None = 'None',
}
@Injectable({
    providedIn: 'root',
})
export class ComparisonService {
    constructor() {}

    public WhichComparisonToDisplay(registration: Registration | null): Comparisons {
        if (
            registration.product.contract === Contracts.Separate ||
            ((registration.product.dualSupplier === 'Utilita' ||
                registration.product.electricitySupplier === 'Utilita') &&
                registration.auction.code === 'may-2021')
        ) {
            return Comparisons.None;
        }

        const currentTariff = registration.product.currentTariff;
        const waivePeriodStartDate = moment.utc(
            registration.proposal.waivePeriodStartDate || null,
            moment.ISO_8601,
        );

        if (!currentTariff || !waivePeriodStartDate.isValid()) {
            return Comparisons.WithStandard;
        }

        const decisionEndDate = AuctionHelper.getPublicClosureDate(registration.auction);

        if (waivePeriodStartDate) {
            // no exit fee, waive period start date before auction is finished
            // but also, 'yes, exit fee!' in case your contract has finished. But same thing...
            if (waivePeriodStartDate.isBefore(decisionEndDate)) {
                return Comparisons.DefaultAndRenewal;
            }
            // potential exit fee, waive period start date after auction is finished
            return Comparisons.WithCurrent;
        }

        return null;
    }
}
