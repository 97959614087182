import { Injectable, Inject, Optional } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContentService } from './content.service';
import { CommunityService } from './community.service';
import { CANONICAL_COMMUNITY } from '../app/app.config';
import { LinkService } from './link.service';
import { LanguageService } from './language.service';
import { isNullOrUndefined } from '@common/util';

@Injectable({ providedIn: 'root' })
export class SeoService {
    communityName: string;
    ogTitle: string;
    ogDescription: string;
    ogImage: string;
    tagTypes: any;
    appendCommunityToTitle = true;

    constructor(
        public meta: Meta,
        public content: ContentService,
        protected communityService: CommunityService,
        private title: Title,
        @Inject(CANONICAL_COMMUNITY) protected canonicalCommunity: string,
        protected linkService: LinkService,
        @Optional() protected languageService: LanguageService,
    ) {
        this.tagTypes = {
            title: this.ogTitle,
            description: this.ogDescription,
            image: this.ogImage,
        };
    }

    generateMetaTagsAndTitle(urlArray: string[]): void {
        let urlPosition = 2;
        if (!this.communityService.communityCode) {
            urlPosition = 1;
        }
        if (this.languageService) {
            urlPosition++;
        }
        let pageName = urlArray[urlPosition];
        if (pageName === 'info') {
            if (urlArray[urlPosition + 1] === undefined) {
                return;
            } else {
                pageName = urlArray[urlPosition + 1];
            }
        } else if (pageName === 'blog' && urlArray.length > urlPosition + 1) {
            pageName = urlArray[urlPosition + 1];
        }
        if (pageName === 'do') {
            pageName = urlArray[urlPosition + 2];
        }
        if (urlArray[1] === 'landing') {
            pageName = urlArray[1];
        }

        pageName = pageName || 'landing';

        this.setTitle(pageName);

        this.content
            .valueExists(`meta-description-${pageName}-page`)
            .subscribe((descriptionValueExists) => {
                if (descriptionValueExists) {
                    this.content
                        .value(`meta-description-${pageName}-page`)
                        .subscribe((value) =>
                            value !== ''
                                ? this.meta.updateTag({
                                      name: 'description',
                                      content: value,
                                  })
                                : null,
                        );
                } else {
                    const tag = this.meta.getTag('name=description');
                    if (tag) {
                        this.meta.removeTagElement(tag);
                    }
                }
            });
    }

    public setTitle(pageName: string) {
        if (!this.communityName && this.appendCommunityToTitle) {
            this.communityService.community$.subscribe((community) => {
                this.communityName = community.name;
                this.content.value(`title-${pageName}`).subscribe((value) => {
                    const title = this.generateTitle(value);
                    this.meta.updateTag({ name: 'title', content: title });
                    this.title.setTitle(title);
                });
            });
        } else {
            this.content.value(`title-${pageName}`).subscribe((value) => {
                const title = this.generateTitle(value);
                this.meta.updateTag({ name: 'title', content: title });
                this.title.setTitle(title);
            });
        }
    }

    protected generateTitle(title: string): string {
        return this.communityName
            ? `${title} | ${this.communityName}`
            : `${title}`;
    }

    makeOpenGraphTag(tagType: string): void {
        if (!this.tagTypes[tagType]) {
            this.content.value(`open-graph-${tagType}`).subscribe((value) => {
                this.tagTypes[tagType] = value;
                if (this.tagTypes[tagType] !== `{{open-graph-${tagType}}}`) {
                    this.meta.addTag({
                        property: `og:${tagType}`,
                        content: this.tagTypes[tagType],
                    });
                }
            });
        } else if (this.tagTypes[tagType] !== `{{open-graph-${tagType}}}`) {
            this.meta.addTag({
                property: `og:${tagType}`,
                content: this.tagTypes[tagType],
            });
        }
    }

    generateCanonicalTag(
        urlArray: string[],
        customCanonicalCommunity?: string,
    ): void {
        if (
            isNullOrUndefined(this.canonicalCommunity) &&
            isNullOrUndefined(customCanonicalCommunity)
        ) {
            return;
        }
        this.linkService.removeTag('rel=canonical');

        this.linkService.addTag({
            rel: 'canonical',
            href: this.generateCanonicalHref(
                urlArray,
                this.selectCanonicalCommunity(customCanonicalCommunity),
            ),
        });
    }

    selectCanonicalCommunity(customCanonicalCommunity?: string) {
        return customCanonicalCommunity
            ? customCanonicalCommunity
            : this.canonicalCommunity;
    }

    generateCanonicalHref(
        urlArray: string[],
        selectedCanonicalCommunity: string,
    ): string {
        let subPage: string;
        const pageNumber = this.communityService.communityCode ? 2 : 1;
        const page = urlArray[pageNumber];
        const canonicalCommunity =
            page === 'home' || page === 'register'
                ? this.communityService.communityCode
                : selectedCanonicalCommunity;
        switch (page) {
            case 'info':
            case 'blog':
                if (urlArray[pageNumber + 1] === undefined) {
                    return '';
                }
                subPage = urlArray[pageNumber + 1];
                break;
            case 'do':
            case 'error':
                return '';
        }

        let canonicalHref = [
            document.getElementsByTagName('base')[0].href,
            canonicalCommunity,
            page,
            subPage,
        ]
            .filter((part) => !!part)
            .map((part) => part.replace(new RegExp('^/+|/+$', 'g'), '')) // remove starting and trailing '/'
            .map((part) => part.toLocaleLowerCase())
            .reduce((prev, cur) => `${prev}/${cur}`);

        return (canonicalHref += page === '' ? '/' : '');
    }
}
