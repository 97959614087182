import { Injectable } from '@angular/core';
import { ProcessStepName } from '@common/analytics/model/process-step';
import { RegistrationBase } from '@common/model/registration.base';
import { RegisterFlowLocationService } from '@common/services/register-flow-location.service';
import {
    ProcessStepData,
    ProcessStepServiceBase,
} from '@common/analytics/services/process-step.service.base';

@Injectable()
export class ProcessStepService extends ProcessStepServiceBase {
    data: ProcessStepData = {
        interestedIndividualFlow: {
            person: null,
            product: null,
        },
        registrationFlow: {
            person: ['personal details', 'product details', 'registration completed', 'email confirmed'],
            product: ['product details', 'personal details', 'registration completed', 'email confirmed'],
        },
        decisionFlow: {
            person: ['personal details', 'product details', 'registration completed', 'proposal', 'decided'],
            product: ['product details', 'personal details', 'registration completed', 'proposal', 'decided'],
        },
    };

    constructor(private flowLocationService: RegisterFlowLocationService) {
        super();
    }

    getActiveStep<Registration extends RegistrationBase>(registration: Registration): ProcessStepName {
        switch (this.flowLocationService.activeStep) {
            case 'person':
                return 'personal details';
            case 'product':
                return 'product details';
            case 'email-confirmation':
            case 'offer-introduction':
                if (registration?.subscriptionComplete) return 'registration completed';
                break;
            case 'proposal':
                return 'proposal';
            case 'overview':
                if (registration?.emailConfirmed) return 'email confirmed';
                if (registration?.decisionComplete) return 'decided';
        }

        return null;
    }
}
