import { Inject, Injectable, Optional } from '@angular/core';
import { AnalyticsEvent } from '@common/analytics/events/analytics.event';
// import { WINDOW } from './window.service';
import { AnalyticsState } from '@common/analytics/model/analytics.state';
import { AnalyticsEnricherService } from '@common/analytics/services/analytics-enricher.service';
import { ANALYTICS_LOG_TO_CONSOLE, ANALYTICS_SEND_TO_TAGMANAGER, BUSINESS } from '@common/app/app.config';
import { removeNullishAndEmpty } from '@common/util';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

declare const tagManagerEvent: (...args: any) => any;
declare const newTagManagerEvent: (...args: any) => any;
@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private _state: AnalyticsState;

    private event: BehaviorSubject<AnalyticsEvent> = new BehaviorSubject<AnalyticsEvent>(null);
    public event$ = this.event.asObservable();

    get state(): AnalyticsState {
        return this._state;
    }

    set state(state: AnalyticsState) {
        if (!state) return;
        this._state = state;
    }

    get window(): Record<string, any> {
        return window; //TODO replace with injection token etc.
    }

    constructor(
        //@Inject(WINDOW)
        // private window: Window,
        @Optional()
        @Inject(ANALYTICS_SEND_TO_TAGMANAGER)
        private useTagManager: boolean,
        public enricher: AnalyticsEnricherService,
        @Inject(BUSINESS) private business,
        @Inject(ANALYTICS_LOG_TO_CONSOLE)
        private useConsoleLog = false,
    ) {}

    push(event: AnalyticsEvent): void {
        if (!event?.event) return;

        this.enricher
            .enrichEvent$(event, this.state)
            .pipe(
                take(1),
                filter((x) => !!x),
                map(removeNullishAndEmpty),
            )
            .subscribe((e) => {
                if (this.useTagManager) this.logToTagManager(e);
                if (this.useConsoleLog) console.table(e);
                this.event.next(e);
                this.pushToTestHelpers(e);
            });
    }

    private logToTagManager(e: AnalyticsEvent): void {
        switch (this.business) {
            case 'enbe':
            case 'enjp':
            case 'spuk':
            case 'spbe':
            case 'spjp':
            case 'rebe':
            case 'enus':
            case 'enuk':
            case 'spus':
                newTagManagerEvent(e);
                break;
            default:
                tagManagerEvent(e);
        }
    }

    private pushToTestHelpers(e: AnalyticsEvent) {
        if (this.window.Cypress) {
            this.window.trackAnalyticsEvent ??= (..._args: any[]) => {};
            this.window.trackAnalyticsEvent(e); //this will be caught by a cypress stub in the e2e tests
        }
    }
}
