<ng-template #defaultContentTemplate let-cmsPrefix let-cmsParams="cmsParams">
    <ng-container *ngIf="cmsPrefix && !isMultiverse">
        <div
            [id]="id ?? cmsPrefix"
            [attr.name]="id ?? cmsPrefix"
            class="row alert layer-02 alert-survey my-3 mx-0 d-print-none"
            *ngIf="cmsLink ?? cmsPrefix + '-link' | cmsText | async"
        >
            <span class="col-md-2 col-3 my-auto text-center">
                <img
                    class="img-fluid text-light"
                    [attr.alt]="cmsPrefix + '-icon' | cmsImageAlt | async"
                    [attr.src]="cmsPrefix + '-icon' | cmsImageSrc | async"
                />
            </span>
            <span class="col-md-6 col-9 my-auto" [innerHTML]="cmsPrefix + '-text' | cmsText | async"> </span>
            <span class="offset-md-0 col-md-4 offset-sm-3 col-sm-9 my-auto">
                <a
                    class="btn btn-filled"
                    [href]="cmsLink ?? cmsPrefix + '-link' | cmsText: [cmsParams] | async"
                    target="_blank"
                >
                    {{ cmsPrefix + '-label' | cmsText | async }}
                </a>
            </span>
        </div>
    </ng-container>
    <ng-container *ngIf="cmsPrefix && isMultiverse">
        <div
            [id]="id ?? cmsPrefix"
            [attr.name]="id ?? cmsPrefix"
            class="rounded p-5 layer-02"
            [id]="id ?? cmsPrefix"
            [attr.name]="id ?? cmsPrefix"
        >
            <h4 class="mb-3" [innerHTML]="cmsPrefix + '-titlemv' | cmsText | async"></h4>
            <p class="mb-5" [innerHTML]="cmsPrefix + '-textmv' | cmsText | async"></p>
            <div class="d-grid d-md-block">
                <a
                    class="btn btn-filled"
                    type="button"
                    [href]="cmsLink ?? cmsPrefix + '-link' | cmsText: [cmsParams] | async"
                    target="_blank"
                >
                    {{ cmsPrefix + '-label' | cmsText | async }}
                </a>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-container
    [ngTemplateOutlet]="contentTemplate || defaultContentTemplate"
    [ngTemplateOutletContext]="{ $implicit: cmsPrefix, cmsParams: this.cmsParams }"
>
</ng-container>
