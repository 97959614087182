import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Brand, BrandQuery } from '@lang/uk/model/brand';
import { BrandCommunity } from '@lang/uk/model/brand-community';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { BrandCommunityData } from '../model/brand-community-data';

export abstract class BrandService {
    protected readonly url: string;
    public activeBrand$ = new BehaviorSubject<Brand>(null);
    public activeBrandReplay$ = new ReplaySubject<Brand>(1);
    public activeBrandObservable$: Observable<Brand>;
    protected brandsWithoutBrandedCmsKeys: string[] = [];

    nonBrandUrlParts = ['home', 'landing', 'info', 'faq', 'contact', 'blog', 'error'];

    constructor(
        protected baseUrl: string,
        protected http: HttpClient,
        @Inject(DOCUMENT) protected document: Document,
        protected router: Router,
    ) {
        this.url = this.baseUrl + 'brands';

        this.activeBrandObservable$ = router.events.pipe(
            filter((event) => event instanceof NavigationStart),
            map((event: NavigationStart) => {
                const parts = event.url.split(/[/?&]/).filter((item) => !!item);
                if (parts.length == 0) {
                    return null as string;
                }
                const brandQuery = parts.find((item) => item.startsWith('brand='));
                if (brandQuery) {
                    const brandQueryParts = brandQuery.split('=');
                    if (brandQueryParts.length == 2) {
                        return brandQueryParts[1];
                    }
                    return null as string;
                }
                if (this.nonBrandUrlParts.includes(parts[0])) {
                    return null as string;
                }
                return parts[0];
            }),
            distinctUntilChanged(),
            map((communityCode) => {
                return { communityCode: communityCode, url: this.document.location.href };
            }),
            switchMap((result) => {
                return this.lookup(result);
            }),
        );

        this.activeBrandObservable$.subscribe((result) => {
            this.activeBrand$.next(result);
            this.activeBrandReplay$.next(result);
        });
    }

    public static isSubBrand(brand: Brand, communityCode: string): boolean {
        return brand && brand.parentBrand && brand.code === communityCode;
    }
    public static allowLandingPage(brand: Brand, communityCode: string): boolean {
        return brand && brand.code === communityCode;
    }

    public getActive(): Observable<Brand> {
        return this.activeBrand$.pipe(
            filter((brand) => !!brand),
            distinctUntilChanged((a, b) => a?.code === b?.code),
        );
    }

    public getByCode(brandCode: string): Observable<Brand> {
        return this.http.get<Brand>(`${this.url}/code/${brandCode}`);
    }

    public getCommunities(brandCode: string): Observable<BrandCommunity[]> {
        return this.http.get<{ code: string; name: string }[]>(`${this.url}/code/${brandCode}/communities`);
    }

    public getCommunityParticipation(brandCode: string): Observable<BrandCommunityData[]> {
        return this.http.get<BrandCommunityData[]>(`${this.url}/code/${brandCode}/community-participation`);
    }

    public lookup(brandQuery: BrandQuery): Observable<Brand> {
        return this.http.post<Brand>(`${this.url}/lookup`, brandQuery);
    }

    public determineBrandCodeFromBrand(brand: Brand): string {
        return brand?.parentBrand?.code ?? brand.code;
    }
    public determineBrandCodeFromBrand$(brand$: Observable<Brand> = null): Observable<string> {
        if (!brand$) {
            brand$ = this.getActive();
        }
        return brand$.pipe(map((brand) => brand?.parentBrand?.code ?? brand.code));
    }

    public generateBrandedCmsKey(brand: Brand, cmsKey: string): string {
        const brandCode = this.determineBrandCodeFromBrand(brand);

        const addBrandToCMS = !!brandCode && !this.brandsWithoutBrandedCmsKeys.includes(brandCode);
        if (addBrandToCMS) {
            return `${cmsKey}-${brandCode}`;
        } else {
            return cmsKey;
        }
    }

    public generateBrandedCmsKey$(cmsKey: string, brand$: Observable<Brand> = null): Observable<string> {
        if (!brand$) {
            brand$ = this.getActive();
        }
        return brand$.pipe(map((brand) => this.generateBrandedCmsKey(brand, cmsKey)));
    }
}
