import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { applyFormValue, ControlWithErrors, makeFormModel } from '@common/infrastructure/form-tools';
import { PostAuctionRegistrationMode } from '@common/model/community';
import { ContentService } from '@common/services/content.service';
import {
    DuplicateRegistrationsService,
    FindDuplicate,
} from '@common/services/duplicate-registrations.service';
import { GoogleAnalyticsService } from '@common/services/google-analytics.service';
import { VwoService } from '@common/services/vwo.service';
import { Address, SearchableAddress } from '@enuk/model/address';
import { RegisterStep } from '@enuk/model/register-step.enum';
import { PublicityChannel, Registration } from '@enuk/model/registration';
import { AddressesService } from '@enuk/services/addresses.service';
import { MpasService } from '@enuk/services/mpas.service';
import { RegistrationService } from '@enuk/services/registration.service';
import { BrandService } from '@lang/uk/services/brand.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinct,
    distinctUntilChanged,
    filter,
    flatMap,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { Community, CommunityType } from '.././../../model/community';
import { EnUkCommunityService } from '.././../../services/enuk-community.service';
import { firstOrLastNameValidatorPattern, fixedPhonePattern, mobilePhonePattern } from '../validation';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { ErrorEvent } from '@common/analytics/events/error.event';
@Component({
    selector: 'app-register-person',
    templateUrl: './person.component.html',
})
export class PersonComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
    @Input()
    registration: Registration;

    @Input()
    submitting: boolean;

    @Output()
    submitted = new EventEmitter<Registration>();

    @Output()
    pendingChangesUpdate = new EventEmitter<boolean>();

    cmsSwitchingBenefitParams: { brandName?: string } = {};
    cmsPrivacyParams: { privacy_statement?: string } = {};
    cmsConditionsParams: { terms_and_conditions?: string } = {};

    searchableAddresses: SearchableAddress[];
    entityAddress: Address;

    destroyed$: Subject<boolean> = new Subject<boolean>();
    hasDuplicateRegistrations$: Observable<boolean>;
    form: UntypedFormGroup;
    get contactForm(): UntypedFormGroup {
        return this.form.get('contact') as UntypedFormGroup;
    }

    get showEmailFields(): boolean {
        return this.registrationHasEmail || this.registrationMustHaveEmail;
    }

    get registrationHasEmail(): boolean {
        return !!this.registration.email;
    }

    get registrationMustHaveEmail(): boolean {
        return (
            (this.registration.id && !this.registration.communicationBySnailMail) ||
            (!this.registration.id && !this.newSnailMailRegistrationIsAllowed)
        );
    }

    get newSnailMailRegistrationIsAllowed(): boolean {
        return this.registrationService && this.registrationService.snailMail;
    }

    public submitRequested = false;
    public submitRequested$ = new Subject<boolean>();
    duplicateLinkRequested = false;

    postCodeFetching = false;
    postCodeError = false;
    postCodeNotFoundError = false;

    virtualFields = ['emailConfirmation', 'postcode', 'fixedPhone', 'mobilePhone'];

    memberInput: string;

    community: Community;
    CommunityType: typeof CommunityType = CommunityType;

    get salutation(): ControlWithErrors {
        return this.contactForm.get('salutation');
    }
    get initials(): ControlWithErrors {
        return this.contactForm.get('initials');
    }
    get firstName(): ControlWithErrors {
        return this.contactForm.get('firstName');
    }
    get lastName(): ControlWithErrors {
        return this.contactForm.get('lastName');
    }

    get email(): ControlWithErrors {
        return this.form.get('email');
    }
    get emailConfirmation(): ControlWithErrors {
        return this.form.get('emailConfirmation');
    }
    get fixedPhone(): ControlWithErrors {
        return this.contactForm.get('fixedPhone');
    }
    get mobilePhone(): ControlWithErrors {
        return this.contactForm.get('mobilePhone');
    }
    get postcode(): ControlWithErrors {
        return this.contactForm.get('postcode');
    }
    get address(): ControlWithErrors {
        return this.contactForm.get('address');
    }

    get switchingBenefits(): ControlWithErrors {
        return this.contactForm.get('switchingBenefits');
    }
    get publicityChannel(): ControlWithErrors {
        return this.contactForm.get('publicityChannel');
    }
    get agreesToConditions(): ControlWithErrors {
        return this.contactForm.get('agreesToConditions');
    }
    get agreesToPrivacyPolicy(): ControlWithErrors {
        return this.contactForm.get('agreesToPrivacyPolicy');
    }
    get receiveOfferUpdatesViaSms(): ControlWithErrors {
        return this.contactForm.get('receiveOfferUpdatesViaSms');
    }

    private addressNotListedLabel: string = null;

    private static updateRegistrationWithAddress(
        registration: Registration,
        address: SearchableAddress,
        searchableAddresses: SearchableAddress[],
    ) {
        const theOne = searchableAddresses
            ? searchableAddresses.find((entry) => Address.equalsPostalData(entry.value, address.value))
            : <SearchableAddress>{};
        if (theOne) {
            // Update address related fields
            Object.keys(Address.emptyAddress()).forEach((key) => {
                registration.contact[key] = theOne.value[key];
            });

            // Update meter details if it's the only active one at this address
            const mpasDetails = (theOne.value.mpasDetails || []).filter((md) => md.mpanActive);
            if (mpasDetails.length >= 1) {
                // Use MPAN's region id if available (should be the same for every meter at the same address)
                registration.contact.regionId = mpasDetails[0].regionId || registration.contact.regionId;
                // If there's more than one MPAN the customer will be asked to pick the correct one in the acceptance flow
                registration.contact.mpan = mpasDetails.length === 1 ? mpasDetails[0].mpan : null;
                // As long as all meters have the same hasEco7Meter value we can fill it in
                if (
                    (registration.product.hasEco7Meter === null ||
                        registration.product.hasEco7Meter === undefined) &&
                    mpasDetails.every((md) => md.eco7Meter === mpasDetails[0].eco7Meter)
                ) {
                    registration.product.hasEco7Meter = mpasDetails[0].eco7Meter;
                }
                // MPRN is looked up through postcode and house number so it's the same for all meter results
                registration.contact.mprn = mpasDetails[0].mprn;
            } else if (mpasDetails.length === 0) {
                registration.contact.mpan = null;
                registration.contact.mprn = null;
            }
        }
    }

    constructor(
        private fb: UntypedFormBuilder,
        private addressesService: AddressesService,
        private modalService: NgbModal,
        public communityService: EnUkCommunityService,
        private mpasService: MpasService,
        private route: ActivatedRoute,
        public registrationService: RegistrationService,
        private duplicateRegistrationsService: DuplicateRegistrationsService,
        private contentService: ContentService,
        private analytics: GoogleAnalyticsService,
        private vwoService: VwoService,
        private brandService: BrandService,
        private angularLocation: Location,
        private analyticsService: AnalyticsService,
    ) {
        this.form = this.fb.group({
            email: [
                '',
                {
                    validators: [this.requiredWhenNoSnailMail(), Validators.email, Validators.maxLength(500)],
                    updateOn: 'blur',
                },
            ],
            emailConfirmation: ['', [this.requiredWhenNoSnailMail(), this.sameValueAsEmail()]],
            contact: this.fb.group(
                {
                    salutation: ['', Validators.required],
                    firstName: [
                        '',
                        [
                            Validators.required,
                            Validators.minLength(2),
                            Validators.maxLength(100),
                            Validators.pattern(firstOrLastNameValidatorPattern),
                        ],
                    ],
                    lastName: [
                        '',
                        [
                            Validators.required,
                            Validators.maxLength(100),
                            Validators.pattern(firstOrLastNameValidatorPattern),
                        ],
                    ],
                    postcode: ['', [Validators.required, this.postcodeValidator()]],
                    address: ['', [this.addressValidator()]],
                    fixedPhone: ['', this.phoneValidator(fixedPhonePattern)],
                    mobilePhone: ['', this.phoneValidator(mobilePhonePattern)],
                    agreesToConditions: [false, Validators.requiredTrue],
                    agreesToPrivacyPolicy: [false, Validators.requiredTrue],
                    switchingBenefits: [[]],
                    publicityChannel: '',
                    canBeContactedByLetter: '',
                    canBeContactedByEmail: '',
                    canBeContactedByPhone: '',
                    receiveOfferUpdatesViaSms: '',
                },
                {
                    validators: [this.requireFixedOrMobilePhoneNumber],
                },
            ),
        });

        this.form.valueChanges.subscribe(() => {
            this.pendingChangesUpdate.emit(this.form.dirty);
        });

        // Triggers only when an address is selected and it didn't have its MPAS details fetched yet
        this.address.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                tap((address) => {
                    if (address && address.value === null) {
                        this.onAddressNotListed();
                    }
                }),
                filter((address) => !!(address || {}).value && !address.value.mpasDetails),
                distinctUntilChanged((prevAddress, address) => {
                    return prevAddress && Address.equalsPostalData(prevAddress.value, address.value);
                }),
                switchMap((address: SearchableAddress) =>
                    this.mpasService.getDetails(address.value.mpans, address.value.postcode),
                ),
            )
            .subscribe((mpasDetails) => {
                const selectedAddress = this.address.value;
                // Update searchableAddresses since that's what updateRegistrationWithAddress() will use to update the registration
                const theAddress = this.searchableAddresses
                    ? this.searchableAddresses.find((entry) =>
                          Address.equalsPostalData(entry.value, selectedAddress.value),
                      )
                    : null;
                if (theAddress) {
                    theAddress.value.mpasDetails = mpasDetails;
                    this.address.updateValueAndValidity({ onlySelf: true });
                }
            });

        this.email.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.emailConfirmation.updateValueAndValidity({
                onlySelf: true,
            });
        });

        this.postcode.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                map((query) => (query ? query.replace(/ /g, '') : null)),
                filter((query) => query && this.truthyPostcode(query)),
                distinctUntilChanged(),
                debounceTime(300),
                switchMap((query) => {
                    this.postCodeFetching = true;
                    this.postCodeError = false;
                    this.postCodeNotFoundError = false;

                    return this.addressesService.get(query).pipe(
                        catchError((error) => {
                            this.postCodeFetching = false;
                            if (error instanceof HttpErrorResponse && error.status === 404) {
                                this.postCodeNotFoundError = true;
                            } else {
                                this.postCodeError = true;
                            }

                            this.address.setValue(null);
                            this.address.markAsPristine();
                            this.searchableAddresses = null;
                            return EMPTY;
                        }),
                    );
                }),
            )
            .subscribe((addresses) => {
                this.postCodeFetching = false;

                this.address.setValue(null);
                this.address.markAsPristine();
                if (addresses && addresses.length) {
                    this.searchableAddresses = addresses.map((address) => new SearchableAddress(address));
                    this.searchableAddresses.push({
                        label: this.addressNotListedLabel || 'My address is not listed',
                        value: null,
                    });
                    if (this.entityAddress) {
                        const theOne = addresses.find((address) =>
                            Address.equalsPostalData(address, this.entityAddress),
                        );
                        if (theOne) {
                            this.address.setValue(new SearchableAddress(theOne));
                        }
                    }
                } else {
                    this.searchableAddresses = null;
                }
                if (this.useMembercheck() && this.community.type === CommunityType.Council) {
                    this.checkMembership(this.postcode.value);
                }
            });

        this.hasDuplicateRegistrations$ = combineLatest([
            this.address.valueChanges,
            this.email.valueChanges,
        ]).pipe(
            takeUntil(this.destroyed$),
            startWith([null, null]),
            debounceTime(500),
            filter(
                () =>
                    this.community &&
                    this.community.id &&
                    this.postcode.valid &&
                    this.address.valid &&
                    this.email.valid,
            ),
            distinct((data) => JSON.stringify(data)),
            flatMap(() => {
                const registration = this.getRegistrationForDupeCheck();
                return this.duplicateRegistrationsService
                    .hasPotentialDuplicates(registration)
                    .pipe(catchError(() => EMPTY));
            }),
        );

        this.cmsConditionsParams.terms_and_conditions =
            angularLocation.prepareExternalUrl('terms-and-conditions');
        this.cmsPrivacyParams.privacy_statement = angularLocation.prepareExternalUrl('privacy-statement');
    }
    ngAfterViewInit(): void {
        this.vwoService.push(RegisterStep.Person);
    }

    ngOnInit(): void {
        this.communityService.getActive().subscribe((com) => {
            this.community = com;
            this.route.queryParams.subscribe((params) => {
                if (
                    this.communityService.communityCode === 'unite-the-union' ||
                    this.communityService.communityCode === 'petrolprices'
                ) {
                    const member = params['member'] ? params['member'] : undefined;
                    this.checkMembership(member);
                }
            });
        });

        if (this.useMembercheck() && this.community && this.community.code === 'our-property') {
            this.email.statusChanges.pipe(filter((status) => status === 'VALID')).subscribe((_) => {
                this.communityService
                    .checkMembership(this.community.id, this.email.value)
                    .subscribe((membership) => {
                        this.registration.isCommunityMember = membership;
                        this.registration.communityMemberInfo = this.email.value;
                    });
            });
        }

        this.contentService
            .value('address-not-listed-label')
            .pipe(take(1))
            .subscribe((address_not_listed_label) => (this.addressNotListedLabel = address_not_listed_label));

        this.brandService
            .getActive()
            .pipe(map((brand) => (brand.parentBrand ? brand.parentBrand.name : brand.name)))
            .subscribe((brandName) => {
                this.cmsSwitchingBenefitParams = { brandName };
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
    }

    checkMembership(code?: string): void {
        if (this.useMembercheck()) {
            switch (this.communityService.communityCode) {
                case 'cssc':
                    if (code) {
                        this.communityService
                            .checkMembership(this.community.id, code)
                            .subscribe((membership) => {
                                this.registration.isCommunityMember = membership;
                                this.registration.communityMemberInfo = code;
                            });
                    }
                    break;
                case 'our-property':
                    this.communityService
                        .checkMembership(this.community.id, this.email.value)
                        .subscribe((membership) => {
                            this.registration.isCommunityMember = membership;
                            this.registration.communityMemberInfo = this.email.value;
                        });
                    break;
                case 'unite-the-union':
                    if (code) {
                        this.registration.isCommunityMember = code === 'true' ? true : false;
                        this.registration.communityMemberInfo = code;
                    } else {
                        this.registration.isCommunityMember = false;
                    }
                    break;
                case 'petrolprices':
                    this.communityService.checkMembership(this.community.id, code).subscribe((membership) => {
                        this.registration.isCommunityMember = membership;
                        this.registration.communityMemberInfo = code;
                    });
                    break;
                default:
                    break;
            }

            if (this.community.type === CommunityType.Council) {
                this.communityService.checkMembership(this.community.id, code).subscribe((membership) => {
                    this.registration.isCommunityMember = membership;
                    this.registration.communityMemberInfo = this.postcode.value;
                });
            }
        }
    }

    showSubscriptionBanner(): boolean {
        return false;
    }

    openModal(content: any): void {
        this.modalService.open(content, {}).result.then(
            (_result) => {},
            (_reason) => {},
        );
    }

    sameValueAsEmail(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return this.form && this.email && control.value !== this.email.value
                ? { sameValueAsEmail: { value: control.value } }
                : null;
        };
    }

    private postcodeValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            if (this.isNorthernIrelandPostCode(control.value)) {
                return { isNorthernIreland: { value: control.value } };
            }
            return !this.truthyPostcode(control.value) ? { pattern: { value: control.value } } : null;
        };
    }

    private addressValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const address: SearchableAddress = control.value;
            // address === null       : no address selected
            // address.value === null : 'My address isn't listed' selected
            if (!address) {
                return { required: true };
            } else if (!address.value) {
                return { notListed: true };
            }

            // Verify there's at least one active MPAN at the current address
            if (
                address.value.mpasDetails &&
                address.value.mpasDetails.findIndex((md) => md.mpanActive) === -1
            ) {
                return { mpanInactive: { value: control.value } };
            }

            return null;
        };
    }

    private requireFixedOrMobilePhoneNumber: ValidatorFn = () => {
        const hasFixedPhone = this.form && this.fixedPhone && this.fixedPhone.value;
        const hasMobilePhone = this.form && this.mobilePhone && this.mobilePhone.value;

        return hasFixedPhone || hasMobilePhone ? null : { phoneRequired: true };
    };

    private phoneValidator(pattern: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const formatted = control.value.replace(/[^0-9]/g, '');
            return formatted.match(pattern) === null ? { pattern: { value: control.value } } : null;
        };
    }

    private getRegistrationForDupeCheck(): FindDuplicate {
        const registration = new Registration();
        registration.communityId = this.community.id;
        PersonComponent.updateRegistrationWithAddress(
            registration,
            this.address.value,
            this.searchableAddresses,
        );
        applyFormValue(this.form, registration, this.virtualFields);
        const findDuplicate = new FindDuplicate();
        findDuplicate.registration = registration;
        findDuplicate.registrationId = this.registration.id;
        return findDuplicate;
    }

    private isNorthernIrelandPostCode(postcode: string): boolean {
        const formatted = postcode ? postcode.replace(/ /g, '') : '';
        return formatted.startsWith('BT');
    }

    private truthyPostcode(postcode: string): boolean {
        const formatted = postcode.replace(/ /g, '');
        return (
            formatted.length >= 5 &&
            formatted.length <= 7 &&
            formatted.match(/[0-9][a-zA-Z][a-zA-Z]$/) !== null
        );
    }

    useMembercheck(): boolean {
        if (this.registration.auction) {
            return (
                moment
                    .utc(this.registration.auction.date)
                    .add(9, 'days')
                    .isSameOrBefore(moment.utc(moment.now()), 'day') &&
                moment
                    .utc(this.registration.createdUtc)
                    .isAfter(moment.utc(this.registration.auction.date)) &&
                this.registration.auction.openForNewRegistrations &&
                !this.registration.status &&
                // registrations.status shows the registration hasn't been created yet on the api
                // since the membercheck is only done on post (never on put), we can use this
                // to determine wether or not to use membercheck
                this.community.postAuctionRegistration ===
                    PostAuctionRegistrationMode.MembersAndPreviousParticipants &&
                this.registration.auction.finished === false
            );
        }
        return false;
    }

    ngOnChanges(): void {
        const formModel = makeFormModel(this.form, this.registration, this.virtualFields);
        formModel.emailConfirmation = this.registration.email;
        formModel.contact.fixedPhone = this.registration.contact.phone;
        formModel.contact.mobilePhone = this.registration.contact.mobilePhone;
        this.form.reset(formModel);

        if (this.registration.contact && this.registration.contact.postcode) {
            const theOne = Object.assign(new Address(), this.registration.contact);
            this.entityAddress = theOne;
            this.postcode.setValue(theOne.postcode);
            if (
                this.communityService.communityCode === 'cssc' ||
                this.communityService.communityCode === 'petrolprices'
            ) {
                this.memberInput = this.registration.communityMemberInfo;
            }
        }
    }

    onSubmit(): void {
        const events = ErrorEvent.fromForm(this.form);
        events.forEach((e) => this.analyticsService.push(e));

        this.submitRequested = true;
        this.submitRequested$.next(true);

        if (this.form.valid) {
            this.registration.contact.phone = this.fixedPhone.value
                ? this.fixedPhone.value.replace(/[^0-9]/g, '')
                : '';
            this.registration.contact.mobilePhone = this.mobilePhone.value
                ? this.mobilePhone.value.replace(/[^0-9]/g, '')
                : '';

            if (this.publicityChannel.value === null) {
                this.publicityChannel.setValue(PublicityChannel.Unanswered);
            }
            PersonComponent.updateRegistrationWithAddress(
                this.registration,
                this.address.value,
                this.searchableAddresses,
            );
            applyFormValue(this.form, this.registration, this.virtualFields);
            this.submitted.emit(this.registration);
        }
    }

    public onAddressNotListed(): void {
        this.analytics.event(
            { path: window.location.href },
            {
                category: 'personActions',
                action: 'addressNotListed',
                label: this.postcode.value,
            },
        );
    }

    public searchAddress(needle: string, item: { value: any; label: string }): boolean {
        // Exclude the item with the 'null' value from search (='My address isn't listed')
        return (
            item &&
            item.value &&
            item.label &&
            item.label.toLowerCase().replace(/\W/g, '').indexOf(needle.toLowerCase().replace(/\W/g, '')) > -1
        );
    }

    public sendLinksToDuplicates(): void {
        const registration = this.getRegistrationForDupeCheck();
        this.duplicateRegistrationsService
            .sendLinksToDuplicates(registration)
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => (this.duplicateLinkRequested = true)),
            )
            .subscribe(() => {
                this.registration.markedAsDuplicate = true;
                this.form.disable();
            });
    }

    private requiredWhenNoSnailMail(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.form && this.registrationMustHaveEmail && !control.value ? { required: true } : null;
        };
    }
}
