import { Injectable } from '@angular/core';
import { InfoItem } from '@common/components/info/info.component.base';
import { Community } from '@common/model/community';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, filter, first, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export abstract class InfoItemServiceBase {
    public moreInformationItems$: Observable<InfoItem[]>;

    protected abstract readonly nonCmsInfoPages: string[];

    constructor(protected communityService: CommunityService, protected contentService: ContentService) {
        this.moreInformationItems$ = this.getInfoItems('more-information-items');
    }

    protected createInfoItem(itemKey: string): Observable<InfoItem> {
        return this.contentService.get(`info-item-${itemKey}-url`).pipe(
            first(),
            map((cmsContent) => {
                const infoItemLink = cmsContent ? cmsContent.value : null;
                return new InfoItem(
                    itemKey,
                    this.nonCmsInfoPages.findIndex((item) => item === itemKey) === -1,
                    0,
                    infoItemLink,
                );
            }),
        );
    }

    protected abstract filterInfoItem(infoItemId: string, community: Community): boolean;

    public getInfoItem(itemKey: string): Observable<InfoItem> {
        return this.infoItemExists(itemKey).pipe(
            switchMap((itemExists) => (itemExists ? this.createInfoItem(itemKey) : of(null))),
        );
    }

    public getInfoItemUrlComponents(infoItem: InfoItem): string[] {
        const url: string[] = [];
        if (this.communityService.communityCode) {
            url.push(this.communityService.communityCode);
        }

        if (infoItem.url) {
            url.push(infoItem.url);
        } else {
            url.push('info', infoItem.id);
        }

        return url;
    }

    public getInfoItems(listKey: string): Observable<InfoItem[]> {
        const infoItems$ = this.communityService.communityCode$.pipe(
            switchMap((communityCode) => {
                return this.contentService.get(this.getLocalityKey(listKey, !!communityCode)).pipe(
                    filter((cmsContent) => cmsContent && cmsContent.value),
                    withLatestFrom(communityCode ? this.communityService.community$ : of(null)),
                    map(([cmsContent, community]) =>
                        cmsContent.value.itemKeys?.filter(
                            (key: string) => !this.filterInfoItem(key, community),
                        ),
                    ),
                    concatMap((itemKeys: string[]) =>
                        forkJoin(itemKeys.map((key) => this.createInfoItem(key))),
                    ),
                );
            }),
        );

        return this.contentService.loaded$.pipe(
            first((loaded) => loaded),
            switchMap(() => infoItems$),
            shareReplay(1),
        );
    }

    protected getLocalityKey(baseKey: string, onCommunityPage: boolean): string {
        return baseKey + (onCommunityPage ? '-community' : '-landing');
    }

    public infoItemExists(itemKey: string): Observable<boolean> {
        if (-1 !== this.nonCmsInfoPages.indexOf(itemKey)) {
            return of(true);
        }
        return this.contentService.valueExists(`info-item-${itemKey}-text`);
    }
}
