import { Injectable } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { AuctionHelper } from '@enuk/helpers/auction-helper';
import { Community } from '@enuk/model/community';
import { InfoItemServiceBase } from '@lang/uk/services/info-item.service';

export enum InfoItemIds {
    AboutUs = 'about-us',
    AuctionResults = 'auction-results',
    HowDoesItWork = 'how-does-it-work',
    OfflineInformation = 'offline-information',
    Testimonials = 'customer-reviews',
}

@Injectable()
export class InfoItemService extends InfoItemServiceBase {
    protected nonCmsInfoPages: string[] = [
        InfoItemIds.AboutUs,
        InfoItemIds.HowDoesItWork,
        InfoItemIds.Testimonials,
    ];

    constructor(communityService: CommunityService, contentService: ContentService) {
        super(communityService, contentService);
    }

    protected filterInfoItem(infoItemId: string, community: Community): boolean {
        switch (infoItemId) {
            case InfoItemIds.AuctionResults:
                return !AuctionHelper.showAuctionResultsPage(community);
            case InfoItemIds.OfflineInformation:
                return !community?.showOfflineInformation;
            default:
                return false;
        }
    }
}
