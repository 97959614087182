import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class InMemoryQueryService {

    private subjectKeys: { [id: string]: BehaviorSubject<Event>; } = {};

    publish(recipient: string, name: string, value: any) {
        if (this.subjectKeys[recipient]) {
            this.subjectKeys[recipient].next({ name: name, value: value });
        } else {
            this.subjectKeys[recipient] = new BehaviorSubject<Event>({ name: name, value: value });
        }
    }

    getObservable$(recipient: string): Observable<Event> {
        if (!this.subjectKeys[recipient]) {
            this.subjectKeys[recipient] = new BehaviorSubject<Event>({ name: null, value: null });
        }

        return this.subjectKeys[recipient].asObservable();
    }
}

export class Event {
    name: string;
    value: any;
}
