import { Pipe, PipeTransform } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { ContentService } from '../services/content.service';
import { switchMap } from 'rxjs/operators';

@Pipe({ name: 'cmsText' })
export class CmsTextPipe implements PipeTransform {
    constructor(private contentService: ContentService) {}

    transform(key: string | string[], params?: any[]): Observable<string> {
        if (Array.isArray(key)) {
            const exists$ = [];
            const keys: string[] = key;

            keys.forEach((key) =>
                exists$.push(this.contentService.valueExists(key)),
            );

            return combineLatest(exists$).pipe(
                switchMap((exists: boolean[]) => {
                    const index = exists.findIndex((item) => item);
                    if (this.contentService.showLinksToCms || index == -1) {
                        return of(`{${keys.join(',')}}`);
                    } else {
                        return this.contentService.transform(
                            keys[index],
                            params,
                        );
                    }
                }),
            );
        }

        return this.contentService.transform(key, params);
    }
}
