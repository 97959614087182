import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ControlWithErrors } from '@common/infrastructure/form-tools';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Community, CommunityType } from '../../../../model/community';

@Component({
    selector: 'app-person-can-be-contacted',
    templateUrl: './can-be-contacted.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonCanBeContactedComponent implements OnChanges, OnDestroy {
    @Input() public contactForm: UntypedFormGroup;
    @Input() public community: Community;
    @Input() public submitRequested: Observable<boolean> = null;

    CommunityType: typeof CommunityType = CommunityType;

    private destroyed$ = new Subject<void>();

    get showCanBeContacted(): boolean {
        return (
            this.contactForm !== null &&
            this.community !== null &&
            this.community.type === CommunityType.Council &&
            this.community.hasDataSharingAgreement
        );
    }

    get canBeContacted(): ControlWithErrors {
        return this.contactForm.get('canBeContacted');
    }
    get canBeContactedByLetter(): ControlWithErrors {
        return this.contactForm.get('canBeContactedByLetter');
    }
    get canBeContactedByEmail(): ControlWithErrors {
        return this.contactForm.get('canBeContactedByEmail');
    }
    get canBeContactedByPhone(): ControlWithErrors {
        return this.contactForm.get('canBeContactedByPhone');
    }

    constructor() {}

    public ngOnChanges(changes: SimpleChanges): void {
        // Add convenience (virtual) control that doesn't actually exist on the Registration data model
        if (changes.contactForm?.currentValue) {
            changes.contactForm.currentValue.addControl(
                'canBeContacted',
                new UntypedFormControl(
                    this.canBeContactedByLetter.value ||
                        this.canBeContactedByEmail.value ||
                        this.canBeContactedByPhone.value,
                ),
            );

            this.canBeContacted.valueChanges
                .pipe(takeUntil(this.destroyed$))
                .subscribe((canBeContacted: boolean) => {
                    if (!canBeContacted) {
                        this.canBeContactedByLetter.setValue(false);
                        this.canBeContactedByEmail.setValue(false);
                        this.canBeContactedByPhone.setValue(false);
                    }
                });
        }

        if (changes.submitRequested?.currentValue) {
            const submitRequested$: Observable<boolean> = changes.submitRequested?.currentValue;
            submitRequested$.pipe(takeUntil(this.destroyed$)).subscribe((submitRequested) => {
                if (!submitRequested) return;

                const canBeContacted = this.showCanBeContacted;
                // If they can be contacted the database value should be false; if they cannot we must ensure it's null
                if (canBeContacted) {
                    if (this.canBeContactedByLetter.value === null) {
                        this.canBeContactedByLetter.setValue(false);
                    }
                    if (this.canBeContactedByEmail.value === null) {
                        this.canBeContactedByEmail.setValue(false);
                    }
                    if (this.canBeContactedByPhone.value === null) {
                        this.canBeContactedByPhone.setValue(false);
                    }
                } else {
                    this.canBeContacted.setValue(null);
                    this.canBeContactedByLetter.setValue(null);
                    this.canBeContactedByEmail.setValue(null);
                    this.canBeContactedByPhone.setValue(null);
                }
            });
        }
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
