import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '@common/app/app.config';
import { Observable, of } from 'rxjs';
import { Auction } from '../model/auction';
import { RegistrationBase } from '@common/model/registration.base';
import { Community } from '@common/model/community';

@Injectable({
    providedIn: 'root',
})
export abstract class AuctionServiceBase<T extends Auction> {
    url: string;
    protected constructor (
        protected http: HttpClient,
        @Inject(API_URL) protected baseUrl: string,
    ) {
        this.url = this.baseUrl + 'auctions/';
    }

    public static getAnyAuction(
        registration: RegistrationBase,
        community: Community,
    ): Auction {
        return (
            registration?.auction ??
            registration?.community?.targetAuction ??
            community?.targetAuction
        );
    }

    public getByCode$(auctionCode: string): Observable<T> {
        if (!auctionCode) {
            return of(null);
        }
        const url = this.url + 'code' + '/' + auctionCode;
        return this.http.get<T>(url);
    }
}
