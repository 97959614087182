<div class="card w-100">
    <h5
        class="card-header"
        [innerHTML]="'contact-options-title' | cmsText | async"
        *ngIf="showTitle"
    ></h5>
    <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngIf="showIntroduction">
            <p
                class="mb-0"
                [innerHTML]="'contact-options-introduction' | cmsText | async"
            ></p>
        </li>
        <li class="list-group-item" *ngIf="showWebinarLink && !!webinarLink">
            <ul class="fa-ul">
                <li>
                    <a
                        [href]="webinarLink"
                        [target]="openInNewTab ? '_blank' : null"
                        (click)="analyticsEvent('webinar-opened')"
                    >
                        <i
                            class="fa-li fa fa-info-circle"
                            aria-hidden="true"
                        ></i>
                        <span
                            [innerHTML]="'webinar-link-label' | cmsText | async"
                        ></span>
                    </a>
                </li>
            </ul>
        </li>
        <li class="list-group-item" *ngIf="showFaqLink">
            <ul class="fa-ul">
                <li>
                    <a
                        [routerLink]="faqLink"
                        [queryParams]="faqQueryParams"
                        [target]="openInNewTab ? '_blank' : null"
                        (click)="analyticsEvent('faq-opened')"
                    >
                        <i
                            *ngIf="!useArrow"
                            class="fa-li fa fa-question"
                            aria-hidden="true"
                        ></i>
                        <i
                            *ngIf="useArrow"
                            class="fa-li fa fa-arrow-right"
                            aria-hidden="true"
                        ></i>
                        <span
                            [innerHTML]="'faq-link-label' | cmsText | async"
                        ></span>
                    </a>
                </li>
            </ul>
        </li>
        <li class="list-group-item" *ngIf="showContactUsLink">
            <ul class="fa-ul">
                <li>
                    <a
                        [routerLink]="contactLink"
                        [queryParams]="contactQueryParams"
                        [target]="openInNewTab ? '_blank' : null"
                        (click)="analyticsEvent('contact-opened')"
                    >
                        <i
                            *ngIf="!useArrow"
                            class="fa-li fa fa-envelope"
                            aria-hidden="true"
                        ></i>
                        <i
                            *ngIf="useArrow"
                            class="fa-li fa fa-arrow-right"
                            aria-hidden="true"
                        ></i>
                        <span
                            [innerHTML]="'sidebar-contact' | cmsText | async"
                        ></span>
                    </a>
                </li>
            </ul>
        </li>
        <li class="list-group-item" *ngIf="communityForumLink">
            <ul class="fa-ul">
                <li>
                    <a [href]="communityForumLink" target="_blank">
                        <i class="fa-li fa fa-users" aria-hidden="true"></i>
                        <span
                            [innerHTML]="
                                'info-item-forum-header' | cmsText | async
                            "
                        ></span>
                    </a>
                </li>
            </ul>
        </li>
        <li class="list-group-item" *ngIf="showPhoneLink">
            <ul class="fa-ul">
                <li>
                    <a
                        *ngIf="!contactPhone && !communityContactPhone$"
                        href="tel:{{
                            'contact-phone-number' | cmsText | async
                        }}"
                        class="d-block"
                    >
                        <i class="fa-li fa fa-phone" aria-hidden="true"></i>
                        <span
                            [innerHTML]="contactPhoneCmsKey | cmsText | async"
                        ></span>
                    </a>
                    <a
                        *ngIf="contactPhone2CmsKey"
                        href="tel:{{
                            'contact-phone-number-2' | cmsText | async
                        }}"
                        class="d-block"
                    >
                        <i class="fa-li fa fa-phone" aria-hidden="true"></i>
                        <span
                            [innerHTML]="contactPhone2CmsKey | cmsText | async"
                        ></span>
                    </a>
                    <a
                        *ngIf="communityContactPhone$ && contactPhoneCmsKey"
                        [href]="'tel:' + (communityContactPhone$ | async)"
                        class="d-block"
                    >
                        <i class="fa-li fa fa-phone" aria-hidden="true"></i>
                        <span
                            [innerHTML]="
                                contactPhoneCmsKey
                                    | cmsText
                                    | async
                                    | paramReplacement : contactParams
                            "
                        ></span>
                    </a>
                    <a
                        *ngIf="contactPhone && !contactPhoneCmsKey"
                        [href]="'tel:' + contactPhone"
                        class="d-block"
                    >
                        <i class="fa-li fa fa-phone" aria-hidden="true"></i>
                        <span>{{ contactPhone }}</span>
                    </a>
                    <span
                        [innerHTML]="'contact-phone-label' | cmsText | async"
                    ></span>
                </li>
            </ul>
        </li>
        <li class="list-group-item" *ngIf="chatbotLink">
            <ul class="fa-ul">
                <li>
                    <a [href]="chatbotLink" target="_blank">
                        <i
                            class="fa-li fa fa-commenting"
                            aria-hidden="true"
                        ></i>
                        <span
                            [innerHTML]="'chatbot-link-label' | cmsText | async"
                        ></span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
