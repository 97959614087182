/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ButtonSize {
    Small = 'Sm',
    Medium = 'Md',
    Large = 'Lg',
}

@Component({
    selector: 'app-register-now-button',
    templateUrl: './register-now-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterNowButtonComponent implements OnInit {
    @Input() buttonSize: ButtonSize = ButtonSize.Medium;
    public communityCode$: Observable<string> = null;
    public isCommunityPage$: Observable<boolean> = null;

    public ButtonSize = ButtonSize;

    constructor(private communityService: CommunityService) {}

    ngOnInit(): void {
        this.communityCode$ = this.communityService.communityCode$;
        this.isCommunityPage$ = this.communityService.communityCode$.pipe(
            map((communityCode) => !!communityCode),
        );
    }
}
