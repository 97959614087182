import { Inject, Injectable } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

import { BUSINESS } from '../app/app.config';
import {
    Page,
    Flow,
    Registration,
    Activity,
    DataLayerAuction,
} from '../model/data-layer';
import { AuthService } from './auth.service';
import { CommunityService } from './community.service';

declare var tagManagerEvent: any;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    registration: Registration;
    flowStart: string;
    auction: DataLayerAuction;

    constructor(
        @Inject(BUSINESS) private business: string,
        @Inject(APP_BASE_HREF) private baseHref: string,
        private authService: AuthService,
        private communityService: CommunityService,
    ) {
        this.communityService.community$.subscribe(
            (com) =>
                (this.auction = com.targetAuction
                    ? { id: com.targetAuction.id }
                    : null),
        );
    }

    event(
        page: Page,
        activity: Activity,
        registration?: Registration,
        flow?: Flow,
        auction?: DataLayerAuction,
    ) {
        if (registration) {
            this.registration = registration;
        }
        if (!registration && this.registration) {
            registration = {
                id: this.registration.id,
                status: this.registration.status,
            };
        }
        if (flow) {
            this.flowStart = flow.start;
        }
        if (!flow && this.flowStart) {
            flow = { start: this.flowStart, action: undefined };
        }
        if (auction) {
            this.auction = auction;
        }

        page.path = this.getFullPath(page);

        tagManagerEvent({
            event: 'triggeredEvent',
            page: page,
            activity: activity,
            registration: registration,
            flow: flow,
            auction: this.auction,
            user: this.authService.hasValidToken() ? 'true' : 'false',
        });
    }

    pageView(
        page: Page,
        flow?: Flow,
        registration?: Registration,
        auction?: DataLayerAuction,
    ) {
        page.path = this.getFullPath(page);

        if (registration) {
            this.registration = registration;
        }
        if (!registration && this.registration) {
            registration = {
                id: this.registration.id,
                status: this.registration.status,
            };
        }
        if (flow) {
            this.flowStart = flow.start;
        }
        if (!flow && this.flowStart) {
            flow = { start: this.flowStart };
        }
        if (auction) {
            this.auction = auction;
        }

        tagManagerEvent({
            event: 'pageView',
            page: page,
            flow: flow,
            registration: registration,
            auction: this.auction,
            user: this.authService.hasValidToken() ? 'true' : 'false',
        });
    }

    private getFullPath(page: Page): string {
        return [this.baseHref, page.path]
            .filter((part) => !!part)
            .map((part) => part.replace(new RegExp('^/+|/+$', 'g'), '')) // remove starting and trailing '/'
            .reduce((prev, cur) => `${prev}/${cur}`, '');
    }
}
