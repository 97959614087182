/* eslint-disable @typescript-eslint/naming-convention */
import { getAnyTextFromElement } from '@common/util';
import { AnalyticsEvent, AnalyticsEventTemplate, LinkClickEventName } from './analytics.event';

type Template = AnalyticsEventTemplate<LinkClickEvent>;

export abstract class LinkClickEvent implements AnalyticsEvent {
    event: LinkClickEventName;

    destination_url: string;
    click_text: string;

    constructor(name: LinkClickEventName, template: Template = {}) {
        this.event = name;
        Object.assign(this, template);
    }
}

export class InternalLinkClickEvent extends LinkClickEvent {
    override event = 'internal_link_click' as const;

    constructor(template: Template = {}) {
        super('internal_link_click', template);
    }

    static create(linkElement: HTMLElement, destination: string, customText?: string): LinkClickEvent {
        return new InternalLinkClickEvent({
            destination_url: destination,
            click_text: customText ?? getAnyTextFromElement(linkElement),
        });
    }
}

export class ExternalLinkClickEvent extends LinkClickEvent {
    override event = 'external_link_click' as const;

    constructor(template: Template = {}) {
        super('external_link_click', template);
    }

    static create(linkElement: HTMLElement, customText?: string): LinkClickEvent {
        return new ExternalLinkClickEvent({
            destination_url: linkElement.getAttribute('href'),
            click_text: customText ?? getAnyTextFromElement(linkElement),
        });
    }
}

export class CrossBusinessLinkClickEvent extends LinkClickEvent {
    override event = 'crossbusiness_link_click' as const;

    constructor(template: Template = {}) {
        super('crossbusiness_link_click', template);
    }

    static create(linkElement: HTMLElement, customText?: string): LinkClickEvent {
        return new CrossBusinessLinkClickEvent({
            destination_url: linkElement.getAttribute('href'),
            click_text: customText ?? getAnyTextFromElement(linkElement),
        });
    }
}
