<div *ngIf="contentService.showLinksToCms" [id]="id">{{ '{{' + cmsItemsKey + '}}' }}</div>
<!-- because this is a shared component both V4 and V5 classes are maintained -->
<div class="custom-control custom-checkbox form-check" *ngFor="let option of options$ | async">
    <input
        type="checkbox"
        class="custom-control-input form-check-input"
        [id]="id + option.value"
        [name]="id + option.value"
        [value]="option.value"
        [checked]="isSelected(option.value)"
        (change)="onSelected($event)"
    />
    <label class="custom-control-label form-check-label" [for]="id + option.value">
        <span>{{ option.label | cmsText: [cmsParams] | async }}</span>
    </label>
</div>
