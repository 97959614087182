import { DatePipe } from '@angular/common';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Registration } from '../../../model/registration';
import { VwoService } from '@common/services/vwo.service';
import { RegisterStep } from '../../../model/register-step.enum';
@Component({
    selector: 'app-register-offer-introduction',
    templateUrl: './offer-introduction.component.html',
})
export class RegisterOfferIntroductionComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
    @Input()
    registration: Registration;

    @Input() printRequestEvents: Observable<string>;

    @Input()
    submitting: boolean;

    @Output()
    submitted = new EventEmitter<Registration>();

    @Output()
    navigateToTab = new EventEmitter<string>();

    public cmsParams: any = {};

    public submitRequested = false;

    private datePipe = new DatePipe('en-UK');
    private printSubscription: Subscription;

    constructor(private contentService: ContentService, private vwoService: VwoService) {}

    public ngOnInit(): void {
        if (this.printRequestEvents) {
            this.printSubscription = this.printRequestEvents
                .pipe(filter((x) => x === 'offer-introduction'))
                .subscribe(() => this.print());
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.registration && changes.registration.currentValue) {
            const registration: Registration = changes.registration.currentValue;
            if (registration.proposal && registration.proposal.winningTariffSupplierName) {
                this.cmsParams = {
                    winningSupplier: registration.proposal.winningTariffSupplierName,
                    auctionDate: this.datePipe.transform(registration.auction.date, 'd MMMM'),
                };

                const cmsSupplierName = this.registration.proposal.winningTariffSupplierName
                    .toLowerCase()
                    .replace(/[^A-Za-z0-9\- ]+/g, '')
                    .replace(' ', '-');
                forkJoin([
                    this.contentService
                        .value(`winning-suppler-sales-message-${cmsSupplierName}`)
                        .pipe(take(1)),
                    this.contentService
                        .value(`winning-suppler-exclusive-message-${cmsSupplierName}`)
                        .pipe(take(1)),
                ]).subscribe(([salesMessage, exclusiveMessage]) => {
                    this.cmsParams.winningSupplierSalesMessage = salesMessage;
                    this.cmsParams.winningSupplierExclusiveMessage = exclusiveMessage;
                });
            }
        }
    }

    ngAfterViewInit(): void {
        this.vwoService.push(RegisterStep.OfferIntroduction);
    }

    public ngOnDestroy(): void {
        if (this.printSubscription) {
            this.printSubscription.unsubscribe();
        }
    }

    public onSubmit(): void {
        this.submitRequested = true;
        this.submitted.emit(this.registration);
    }

    public onNavigateToTab(tabName: string): void {
        this.navigateToTab.emit(tabName);
    }

    public print(): void {
        window.print();
    }
}
