import { Injectable } from '@angular/core';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { forkJoin, Observable } from 'rxjs';
import { concatMap, filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { MenuItem } from '../model/menu-item.model';
import { InfoItemServiceBase } from './info-item.service';

@Injectable()
export class MenuItemService {
    protected menuItems$: Observable<MenuItem[]>[] = [];

    constructor(
        protected communityService: CommunityService,
        protected contentService: ContentService,
        protected infoItemService: InfoItemServiceBase,
    ) {}

    public getMenuItemRoute(menuItem: MenuItem): string[] {
        if (!menuItem.link) {
            return null;
        }
        return this.communityService.communityCode
            ? [this.communityService.communityCode, menuItem.link]
            : [menuItem.link];
    }

    public getMenuItems(menuKey: string): Observable<MenuItem[]> {
        if (this.menuItems$[menuKey]) {
            return this.menuItems$[menuKey];
        }
        return (this.menuItems$[menuKey] = this.getMenuItemsInternal(menuKey + '-items'));
    }

    protected createMenuItem(itemKey: string): Observable<MenuItem> {
        const itemLabel$ = this.contentService.get(itemKey + '-label').pipe(first());
        const itemUrl$ = this.contentService.get(itemKey + '-url').pipe(first());

        return forkJoin([itemLabel$, itemUrl$]).pipe(
            map(([cmsItemLabel, cmsItemUrl]) => {
                return {
                    id: itemKey,
                    label: cmsItemLabel?.value ?? itemKey + '-label',
                    link: cmsItemUrl?.value,
                    children$:
                        cmsItemUrl?.value == null
                            ? this.infoItemService.getInfoItems(itemKey + '-items')
                            : null,
                };
            }),
        );
    }

    protected getMenuItemsInternal(listKey: string): Observable<MenuItem[]> {
        const menuItems$ = this.communityService.communityCode$.pipe(
            switchMap((communityCode) =>
                this.contentService.get(this.getLocalityKey(listKey, !!communityCode)).pipe(
                    filter((cmsContent) => cmsContent && cmsContent.value),
                    map((cmsContent) => cmsContent.value.itemKeys?.filter((item: string) => !!item)),
                    concatMap((itemKeys: string[]) =>
                        forkJoin(itemKeys.map((key) => this.createMenuItem(key))),
                    ),
                ),
            ),
        );

        return this.contentService.loaded$.pipe(
            first((loaded) => loaded),
            switchMap(() => menuItems$),
            shareReplay(1),
        );
    }

    protected getLocalityKey(baseKey: string, onCommunityPage: boolean): string {
        return baseKey + (onCommunityPage ? '-community' : '-landing');
    }
}
