/* eslint-disable @typescript-eslint/naming-convention */
import { Community } from '@common/model/community';
import { RegistrationBase } from '@common/model/registration.base';
import { User } from '@common/model/user';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProcessGoalEvent } from '../events/process-goal.event';
import { AnalyticsState } from '../model/analytics.state';
import { getFlowPhase } from '../model/flow-phase';
import { ProposalFlowSource } from '../model/proposal-flow-source';
import { Enricher } from './enricher';

export class ProcessGoalEventEnricher implements Enricher<ProcessGoalEvent<any>> {
    constructor(
        private registration$: Observable<RegistrationBase>,
        private community$: Observable<Community>,
        _user$: Observable<User> = null, // unused for now
    ) {}
    enrich$(event: ProcessGoalEvent<any>, state?: AnalyticsState): Observable<ProcessGoalEvent<any>> {
        return combineLatest([this.registration$, this.community$]).pipe(
            map((dependencies) => ({
                ...event,
                process_flow_start: state?.process_flow_start,
                process_flow_phase: getFlowPhase(...dependencies),
                proposal_flow_source: this.getFlowSource(event, state),
            })),
        );
    }

    private getFlowSource(event: ProcessGoalEvent<any>, state: AnalyticsState): ProposalFlowSource {
        if (!event?.event?.startsWith('proposal')) return undefined;
        return state?.proposal_flow_source ?? 'direct';
    }
}
