<ng-container *ngIf="'variant-1' | vwoVariant | async">
    <app-helpdesk-variant-1></app-helpdesk-variant-1>
</ng-container>

<ng-container *ngIf="'default' | vwoVariant | async">
    <div
        class="container helpdesk-container mt-2 mb-2 p-4"
        *ngIf="(isLoading$ | async) !== true && brandName && communityPhone && brandCode"
    >
        <div class="bg-highlight-three mx-auto p-lg-5 p-3 row">
            <div class="col-sm-12 col-lg-8 helpdesk-info">
                <div class="row w-100">
                    <div class="col-3 col-lg-2 p-0">
                        <img
                            class="float-right"
                            style="max-height: 100px"
                            [attr.src]="'helpdesk-icon' | cmsImageSrc | async"
                        />
                    </div>
                    <div class="col-9 col-lg-10 mb-0">
                        <p
                            *ngIf="inRegisterFlow$ | async"
                            [innerHTML]="
                                'helpdesk-description-blanks'
                                    | cmsText
                                        : [
                                              {
                                                  brandName: brandName,
                                                  brandCode: brandCode,
                                                  communityPhone: communityPhone,
                                                  communityCode: communityCode,
                                                  helpDeskOpeningHours: helpDeskOpeningHours
                                              }
                                          ]
                                    | async
                            "
                        ></p>
                        <p
                            *ngIf="(inRegisterFlow$ | async) !== true"
                            [innerHTML]="
                                'helpdesk-description'
                                    | cmsText
                                        : [
                                              {
                                                  brandName: brandName,
                                                  brandCode: brandCode,
                                                  communityPhone: communityPhone,
                                                  communityCode: communityCode,
                                                  helpDeskOpeningHours: helpDeskOpeningHours
                                              }
                                          ]
                                    | async
                            "
                        ></p>
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-block col-lg-4 py-4 helpdesk-right-column">
                <div class="row">
                    <div class="col-6">
                        <img
                            *ngIf="brandCode !== 'ichoosr'"
                            class="w-95 helpdesk-brand-logo float-right img-fluid"
                            [attr.src]="logoImageUri"
                        />
                    </div>

                    <div
                        *ngIf="facebookLink || twitterLink || instagramLink || youtubeLink"
                        class="col-6 d-flex flex-column"
                    >
                        <h6>
                            <b>Follow us</b>
                        </h6>
                        <ul class="d-flex pl-0 helpdesk-socials" style="list-style-type: none">
                            <li *ngIf="facebookLink" class="col-3 p-0">
                                <a
                                    class="text-primary"
                                    [href]="facebookLink"
                                    target="_blank"
                                    aria-label="Facebook"
                                >
                                    <i class="fa fa-facebook-square text-center"></i
                                    ><span class="d-lg-none ml-3">Facebook</span>
                                </a>
                            </li>
                            <li *ngIf="twitterLink" class="col-3 p-0">
                                <a
                                    class="text-primary"
                                    [href]="twitterLink"
                                    target="_blank"
                                    aria-label="Twitter"
                                >
                                    <i class="fa fa-twitter-square"></i
                                    ><span class="d-lg-none ml-3">Twitter</span>
                                </a>
                            </li>
                            <li *ngIf="instagramLink && showInstagramIcon" class="col-3 p-0">
                                <a
                                    class="text-primary"
                                    [href]="instagramLink"
                                    target="_blank"
                                    aria-label="Instagram"
                                >
                                    <i class="fa fa-instagram"></i
                                    ><span class="d-lg-none ml-3">Instagram</span>
                                </a>
                            </li>
                            <li *ngIf="youtubeLink" class="col-3 p-0">
                                <a
                                    class="text-primary"
                                    [href]="youtubeLink"
                                    target="_blank"
                                    aria-label="YouTube"
                                >
                                    <i class="fa fa-youtube-square"></i
                                    ><span class="d-lg-none ml-3">YouTube</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
