import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[appfocusFirstInvalidField]',
})
export class FocusFirstInvalidFieldDirective {
    constructor(private el: ElementRef) {}

    @HostListener('submit')
    onFormSubmit() {
        //  For custom elements please use div.error-focus.ng-invalid selector
        setTimeout(() => {
            const invalidElements = this.el.nativeElement.querySelectorAll(
                'select.ng-invalid,input.ng-invalid,textarea.ng-invalid,ng-select.ng-invalid,div.error-focus.ng-invalid,input.is-invalid',
            );

            if (invalidElements.length > 0) {
                let firstInvalidElement = invalidElements[0];

                // take first visible element from list
                for (let i = 0; i < invalidElements.length; i++) {
                    if (invalidElements[i].offsetParent !== null) {
                        firstInvalidElement = invalidElements[i];
                        break;
                    }
                }

                firstInvalidElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });
            }
        }, 100);
    }
}
