import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Contracts, Registration } from '../../../../model/registration';

@Component({
    selector: 'app-register-energy-details-overview',
    templateUrl: './energy-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewEnergyDetailsComponent {
    @Input()
    registration: Registration;

    @Input()
    readonly = true;

    @Output()
    edit = new EventEmitter<Registration>();

    Contracts: typeof Contracts = Contracts;

    constructor() {}

    public onEdit(): void {
        this.edit.emit();
    }
}
