import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export const domChanges$ = (
    target: Node = document.body,
    config: MutationObserverInit = { subtree: true, childList: true },
): Observable<MutationRecord[]> => {
    return new Observable<MutationRecord[]>((subscriber) => {
        const observer = new MutationObserver((mut, _obs) => {
            subscriber.next(mut);
        });

        observer.observe(target, config);

        return {
            unsubscribe() {
                subscriber.complete();
                observer.disconnect();
            },
        };
    });
};

export const isInViewport$ = (
    element: Element,
    config: IntersectionObserverInit = { threshold: 0 },
): Observable<boolean> => {
    const isIntersecting = (entry: IntersectionObserverEntry) => {
        return (
            entry.isIntersecting ||
            entry.intersectionRatio > (config.threshold as number)
        );
    };

    return new Observable<boolean>((subscriber) => {
        const subject$ = new Subject<IntersectionObserverEntry>();

        const intersectionObserver = new IntersectionObserver((entries, _) => {
            entries.forEach((entry) => subject$.next(entry));
        }, config);

        subject$
            .pipe(filter(Boolean), map(isIntersecting), distinctUntilChanged())
            .subscribe((b) => subscriber.next(b));

        intersectionObserver.observe(element);

        return {
            unsubscribe() {
                intersectionObserver.disconnect();
                subject$.unsubscribe();
            },
        };
    });
};

export const waitUntil$ = (
    condition: (target: Node) => boolean,
    config: MutationObserverInit = { subtree: true, childList: true },
    target: Node = document.body,
): Observable<void> => {
    return new Observable<void>((subscriber) => {
        domChanges$(target, config).subscribe(() => {
            if (condition(target)) {
                subscriber.next();
                subscriber.complete();
            }
        });
    });
};

export const waitUntil = (
    ...args: Parameters<typeof waitUntil$>
): Promise<void> => waitUntil$(...args).toPromise();
