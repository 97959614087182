<div class="small p-3">
    <app-comparison-table
        [leftTariff]="comparisonTariff"
        [rightTariff]="winningTariff"
        [registration]="registration"
    >
    </app-comparison-table>
</div>

<div class="small px-3">
    <app-content
        key="tariff-details-footer-text"
        [params]="[
            {
                region_name: regions[registration.contact.regionId - 10],
                contact_href: '/enuk/' + communityService.communityCode + '/contact'
            }
        ]"
    >
    </app-content>
</div>
