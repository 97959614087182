<div class="d-flex flex-row justify-content-between" *ngIf="!footer">
    <div *ngFor="let menuItem of menuItems$ | async; trackBy: trackById">
        <ng-container
            *ngTemplateOutlet="menuItem.children$ ? subMenu : menuLink; context: { $implicit: menuItem }"
        >
        </ng-container>
        <ng-template #menuLink let-item>
            <p>
                <a [routerLink]="getMenuItemRoute(menuItem)">{{ item.label }}</a>
            </p>
        </ng-template>
        <ng-template #subMenu let-item>
            <p class="font-weight-bold mb-4">{{ menuItem.label }}</p>
            <ul *ngFor="let childItem of menuItem.children$ | async" class="list-unstyled">
                <li class="mb-5">
                    <a [routerLink]="getInfoItemRoute(childItem)">
                        {{ 'info-item-' + childItem.id + '-header' | cmsText | async }}
                    </a>
                </li>
            </ul>
        </ng-template>
    </div>
</div>

<ng-container *ngIf="footer">
    <div class="fluid-container bg-footer d-lg-block d-none py-7">
        <div class="container">
            <h2 class="text-md-center text-on-color mb-7">Save more when you’re part of a group</h2>
            <div class="d-flex flex-md-row flex-column justify-content-between">
                <div
                    *ngFor="let menuItem of menuItems$ | async; trackBy: trackById"
                    class="d-flex flex-column mb-md-0 mb-5"
                >
                    <ng-container
                        *ngTemplateOutlet="
                            menuItem.children$ ? subMenu : menuLink;
                            context: { $implicit: menuItem }
                        "
                    >
                    </ng-container>
                    <ng-template #menuLink let-item>
                        <p>
                            <a [routerLink]="getMenuItemRoute(menuItem)">{{ item.label }}</a>
                        </p>
                    </ng-template>
                    <ng-template #subMenu let-item>
                        <h6 class="mb-5 text-on-color">{{ menuItem.label }}</h6>
                        <ng-container *ngFor="let childItem of menuItem.children$ | async">
                            <a [routerLink]="getInfoItemRoute(childItem)" class="mb-5 text-on-color">
                                {{ 'info-item-' + childItem.id + '-header' | cmsText | async }}
                            </a>
                        </ng-container>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>
