import { AdditionalRequirementCategory, AdditionalRequirmentKeys } from '../../../../app/model/registration';

export interface SupplierSetting {
    name: string;
    disallowedTitles?: string[];
}

export const defaultSalutations = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr', 'Prof', 'Rev'];
export const requirements = [
    {
        requirementKey: AdditionalRequirmentKeys.SensoryImpairmentBlind,
        requirementLabel: 'Blind',
        category: AdditionalRequirementCategory.SensoryImpairment,
        exclude: [],
        flatOrder: 5,
    },
    {
        requirementKey: AdditionalRequirmentKeys.SensoryImpairmentPartiallySighted,
        requirementLabel: 'Partially sighted',
        category: AdditionalRequirementCategory.SensoryImpairment,
        exclude: [],
        flatOrder: 6,
    },
    {
        requirementKey: AdditionalRequirmentKeys.SensoryImpairmentHearingOrSpeech,
        requirementLabel: 'Hearing/speech difficulties (including deafness)',
        category: AdditionalRequirementCategory.SensoryImpairment,
        exclude: ['Omni Energy'],
        flatOrder: 7,
    },
    {
        requirementKey: AdditionalRequirmentKeys.SensoryImpairmentPoorSenseOfSmell,
        requirementLabel: 'Poor sense of smell/taste',
        category: AdditionalRequirementCategory.SensoryImpairment,
        exclude: [],
        flatOrder: 20,
    },
    {
        requirementKey: AdditionalRequirmentKeys.SensoryImpairmentHearingImpairement,
        requirementLabel: 'Hearing Impairement (inc. Deaf)',
        category: AdditionalRequirementCategory.SensoryImpairment,
        exclude: ['E.ON'],
        flatOrder: 35,
    },
    {
        requirementKey: AdditionalRequirmentKeys.SensoryImpairmentSpeechImpairement,
        requirementLabel: 'Speech impairment',
        category: AdditionalRequirementCategory.SensoryImpairment,
        exclude: ['E.ON'],
        flatOrder: 36,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentDialysis,
        requirementLabel: 'Dialysis, feeding pump and automated medication',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: [],
        flatOrder: 3,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentOxygenConcentrator,
        requirementLabel: 'Oxygen concentrator',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: [],
        flatOrder: 4,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentOxygenUse,
        requirementLabel: 'Oxygen use',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: [],
        flatOrder: 19,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentNebuliserAndApnoeaMonitor,
        requirementLabel: 'Nebuliser and apnoea monitor',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: [],
        flatOrder: 1,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentHearthLungAndVentilator,
        requirementLabel: 'Heart, lung and ventilator',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: [],
        flatOrder: 2,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentStairliftHoistElectricBed,
        requirementLabel: 'Stairlift, hoist, electric bed',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: [],
        flatOrder: 8,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentMDEElectricShowering,
        requirementLabel: 'MDE electric showering',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: [],
        flatOrder: 16,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentMedicineRefrigeration,
        requirementLabel: 'Medicine refrigeration',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: [],
        flatOrder: 18,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentCarelineTelecareSystem,
        requirementLabel: 'Careline/Telecare system',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: [],
        flatOrder: 17,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MedicalEquipmentWaterDependent,
        requirementLabel: 'Water dependent',
        category: AdditionalRequirementCategory.MedicalEquipment,
        exclude: ['E.ON'],
        flatOrder: 37,
    },
    {
        requirementKey: AdditionalRequirmentKeys.PhysiscalMovementImpairementRestrictedHandMovement,
        requirementLabel: 'Restricted hand movement',
        category: AdditionalRequirementCategory.PhysiscalMovementImpairement,
        exclude: [],
        flatOrder: 21,
    },
    {
        requirementKey: AdditionalRequirmentKeys.PhysiscalMovementImpairementChronicSeriousIllness,
        requirementLabel: 'Chronic/serious illness',
        category: AdditionalRequirementCategory.PhysiscalMovementImpairement,
        exclude: [],
        flatOrder: 15,
    },
    {
        requirementKey: AdditionalRequirmentKeys.PhysiscalMovementImpairementPhysicalImpairment,
        requirementLabel: 'Physical impairment',
        category: AdditionalRequirementCategory.PhysiscalMovementImpairement,
        exclude: [],
        flatOrder: 10,
    },
    {
        requirementKey: AdditionalRequirmentKeys.PhysiscalMovementImpairementUnableToAnswerDoor,
        requirementLabel: 'Unable to answer door/restricted movement',
        category: AdditionalRequirementCategory.PhysiscalMovementImpairement,
        exclude: [],
        flatOrder: 13,
    },
    {
        requirementKey: AdditionalRequirmentKeys.PhysiscalMovementImpairementAdditionalPresencePreferred,
        requirementLabel: 'Additional presence preferred',
        category: AdditionalRequirementCategory.PhysiscalMovementImpairement,
        exclude: [],
        flatOrder: 24,
    },
    {
        requirementKey: AdditionalRequirmentKeys.PhysiscalMovementImpairementTempLifeChanges,
        requirementLabel: 'Temp - Life changes',
        category: AdditionalRequirementCategory.PhysiscalMovementImpairement,
        exclude: [],
        flatOrder: 25,
    },
    {
        requirementKey: AdditionalRequirmentKeys.PhysiscalMovementImpairementTempPostHospitalRecovery,
        requirementLabel: 'Temp - Post-hospital recovery',
        category: AdditionalRequirementCategory.PhysiscalMovementImpairement,
        exclude: [],
        flatOrder: 26,
    },
    {
        requirementKey: AdditionalRequirmentKeys.FamilySituationPensionableAge,
        requirementLabel: 'Pensionable age',
        category: AdditionalRequirementCategory.FamilySituation,
        exclude: [],
        flatOrder: 9,
    },
    {
        requirementKey: AdditionalRequirmentKeys.FamilySituationYoungChildren,
        requirementLabel: 'Families with young children 5 and under',
        category: AdditionalRequirementCategory.FamilySituation,
        exclude: [],
        flatOrder: 22,
    },
    {
        requirementKey: AdditionalRequirmentKeys.FamilySituationTempYoungAdultHouseholder,
        requirementLabel: 'Temp - Young adult householder (<18)',
        category: AdditionalRequirementCategory.FamilySituation,
        exclude: [],
        flatOrder: 27,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MentalHealth,
        requirementLabel: 'Mental health',
        category: null,
        exclude: [],
        flatOrder: 23,
    },
    {
        requirementKey: AdditionalRequirmentKeys.MentalHealthDevelopmentalCondition,
        requirementLabel: 'Developmental condition',
        category: null,
        exclude: ['E.ON'],
        flatOrder: 12,
    },
    {
        requirementKey: AdditionalRequirmentKeys.Dementia,
        requirementLabel: 'Dementia(s)',
        category: null,
        exclude: [],
        flatOrder: 14,
    },
    {
        requirementKey: AdditionalRequirmentKeys.CommunicationUnableToCommunicateInEnglish,
        requirementLabel: 'Unable to communicate in English',
        category: null,
        exclude: ['E.ON'],
        flatOrder: 11,
    },
];

export const supplierSettings: SupplierSetting[] = [
    {
        name: 'Shell Energy',
        disallowedTitles: ['Mx', 'Prof', 'Rev'],
    },
];
