export abstract class CmsLineBreaker {
    public static spaceCharacter = '^';
    public static wordPrefix = '<span class=\'d-block\'>';
    public static wordSuffix = '</span>';
    public static regex = /([^>^]*)\^([^<^]*)/g;

    public static insertLineBreaks(text: string): string {
        if (text) {
            const blankSpanRegex = /<span\sclass='d-block'><\/span>/g;
            const replacementText = `${this.wordPrefix}$1${this.wordSuffix}${this.wordPrefix}$2${this.wordSuffix}`;
            const parsedText = text
                .replace(this.regex, replacementText)
                .replace(blankSpanRegex, '');
            return parsedText;
        }
        return text;
    }

    public static containsBreakLineChar(text: string): boolean {
        const match = text.match(this.regex);
        return match && match.length > 0;
    }
}
