<ul class="navbar-nav my-auto" #menu>
    <ng-container *ngFor="let menuItem of menuItems$ | async; trackBy: trackById">
        <ng-container
            *ngTemplateOutlet="
                menuItem.children$ ? (isMobileDevice ? mobileSubMenu : subMenu) : menuLink;
                context: { $implicit: menuItem }
            "
        >
        </ng-container>

        <ng-template #menuLink let-item>
            <li class="nav-item" [id]="'dropdown-' + item.id">
                <a
                    class="nav-link"
                    (click)="onMenuClick()"
                    [routerLinkActive]="'active'"
                    [routerLink]="getMenuItemRoute(item)"
                >
                    {{ item.label }}
                </a>
            </li>
        </ng-template>
        <ng-template #subMenu let-item>
            <ng-container>
                <li
                    class="nav-item dropdown h200"
                    [id]="'dropdown-' + item.id"
                    (mouseenter)="onMenuActive(item)"
                    (mouseleave)="onMenuActive(null)"
                >
                    <a
                        class="nav-link dropdown-toggle"
                        [ngClass]="{
                            'active-dropdown-menu': activeSubMenu === item.id
                        }"
                    >
                        {{ item.label }}
                        <i
                            class="open-indicator fa"
                            [ngClass]="{
                                'fa-angle-down': activeSubMenu !== item.id,
                                'fa-angle-up': activeSubMenu === item.id
                            }"
                            aria-hidden="true"
                        ></i>
                    </a>
                    <div [ngClass]="{ 'active-menu-underline': activeSubMenu === item.id }"></div>
                    <ul class="dropdown-menu" [hidden]="activeSubMenu !== item.id">
                        <li *ngFor="let childItem of item.children$ | async">
                            <a
                                class="dropdown-item"
                                (click)="onMenuClick()"
                                [routerLink]="getInfoItemRoute(childItem)"
                                [id]="childItem.id"
                                routerLinkActive="active-dropdown-item"
                            >
                                {{ 'info-item-' + childItem.id + '-header' | cmsText | async }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>
        </ng-template>
        <ng-template #mobileSubMenu let-item>
            <ng-container>
                <li class="nav-item h200">
                    <div [id]="item.id" ngbDropdown>
                        <a
                            class="nav-link"
                            [routerLink]="null"
                            ngbDropdownToggle
                            (click)="onMenuActive(item)"
                            [ngClass]="{
                                'active-dropdown-menu': activeSubMenu === item.id
                            }"
                            >{{ item.label }}
                            <i
                                class="open-indicator fa"
                                [ngClass]="{
                                    'fa-angle-down': activeSubMenu !== item.id,
                                    'fa-angle-up': activeSubMenu === item.id
                                }"
                                aria-hidden="true"
                            ></i>
                        </a>
                        <div ngbDropdownMenu>
                            <a
                                *ngFor="let childItem of item.children$ | async"
                                ngbDropdownItem
                                [routerLink]="getInfoItemRoute(childItem)"
                                [id]="childItem.id"
                                (click)="onMenuClick()"
                                >{{ 'info-item-' + childItem.id + '-header' | cmsText | async }}</a
                            >
                        </div>
                    </div>
                </li>
            </ng-container>
        </ng-template>
    </ng-container>
</ul>
