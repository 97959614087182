import { Injectable } from '@angular/core';
import { RegistrationBase } from '@common/model/registration.base';
import { propertyChanged } from '@common/util';
import { AnalyticsEventTemplate } from '../events/analytics.event';
import {
    CommonProcessGoalEventParams,
    CompletedRegistrationEvent,
    CreatedRegistrationEvent,
    InterestedRegistrationEvent,
    MinimalRegistrationEvent,
    ProcessGoalEvent,
    ProposalAcceptedEvent,
    ProposalDeclinedEvent,
} from '../events/process-goal.event';

export type Template = Omit<
    AnalyticsEventTemplate<ProcessGoalEvent<any>>,
    keyof CommonProcessGoalEventParams
>;

@Injectable({
    providedIn: 'root',
})
export class ProcessGoalEventFactory<T extends RegistrationBase = RegistrationBase> {
    /** A factory method that can create a process goal event based on the difference between
     * old and new registration data. This method uses protected methods with default implementations
     * that for some BLs should be overridden in a BL-specific service */
    public createRegistrationEvent(
        oldRegistration: T,
        newRegistration: T,
        params: Template = {},
    ):
        | CreatedRegistrationEvent
        | CompletedRegistrationEvent
        | ProposalAcceptedEvent
        | ProposalDeclinedEvent
        | undefined {
        if (this.shouldTriggerCreatedEvent(oldRegistration, newRegistration)) {
            return new CreatedRegistrationEvent(params);
        }

        if (this.shouldTriggerCompletedEvent(oldRegistration, newRegistration)) {
            return new CompletedRegistrationEvent(params);
        }

        if (this.shouldTriggerProposalAcceptedEvent(oldRegistration, newRegistration)) {
            return new ProposalAcceptedEvent(params);
        }

        if (this.shouldTriggerProposalDeclinedEvent(oldRegistration, newRegistration)) {
            return new ProposalDeclinedEvent(params);
        }

        return undefined;
    }

    protected shouldTriggerCreatedEvent(oldRegistration: T, newRegistration: T): boolean {
        const gotAssignedAuction = !oldRegistration.auctionId && !!newRegistration.auctionId;
        return newRegistration.status === 'Created' && !!newRegistration.email && gotAssignedAuction;
    }

    protected shouldTriggerCompletedEvent(oldRegistration: T, newRegistration: T): boolean {
        const registrationChanged = <K extends keyof T>(prop: K, diff: { from?: T[K]; to?: T[K] }) =>
            propertyChanged(oldRegistration, newRegistration, prop, diff);
        const statusChanged = (diff: { from?: string; to?: string }) => registrationChanged('status', diff);

        const completedRegistration =
            statusChanged({ to: 'Completed' }) ||
            (statusChanged({ to: 'Undecided' }) && registrationChanged('subscriptionComplete', { to: true }));

        const hasRevertedDecline =
            registrationChanged('copiedToNextAuction', { from: false, to: true }) &&
            statusChanged({ from: 'Declined', to: 'Undecided' });

        return completedRegistration || hasRevertedDecline;
    }

    protected shouldTriggerProposalAcceptedEvent(oldRegistration: T, newRegistration: T): boolean {
        return (
            newRegistration.proposalMade &&
            newRegistration.proposalAccepted &&
            propertyChanged(oldRegistration, newRegistration, 'status', { to: 'Accepted' })
        );
    }

    protected shouldTriggerProposalDeclinedEvent(oldRegistration: T, newRegistration: T): boolean {
        return (
            newRegistration.proposalMade &&
            !newRegistration.proposalAccepted &&
            propertyChanged(oldRegistration, newRegistration, 'status', { to: 'Declined' })
        );
    }

    // The following is using this service as a sort of facade too

    public createMinimalRegistrationEvent = MinimalRegistrationEvent.create;
    public createInterestedRegistrationEvent = InterestedRegistrationEvent.create;
}
