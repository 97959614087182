<ng-container *ngIf="items$ | async as items">
    <ngb-accordion [closeOthers]="false" [destroyOnHide]="false" #accordion>
        <ng-container *ngFor="let item of items">
            <ngb-panel id="{{ item.id }}">
                <ng-template ngbPanelHeader let-opened="opened">
                    <button
                        ngbPanelToggle
                        class="btn btn-link faq container-fluid text-left"
                    >
                        <div class="d-flex">
                            <i
                                *ngIf="!opened"
                                class="fa fa-chevron-circle-down align-self-center pr-3 pe-3"
                            ></i>
                            <i
                                *ngIf="opened"
                                class="fa fa-chevron-circle-up align-self-center pr-3 pe-3"
                            ></i>
                            <span
                                class="ml-3"
                                [innerHTML]="
                                    item.getContent(contentService)
                                        | async
                                        | faqheader
                                        | paramReplacement: params
                                "
                            ></span>
                        </div>
                    </button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div
                        routeTransformer
                        [innerHTML]="
                            item.getContent(contentService)
                                | async
                                | faqcontent
                                | paramReplacement: params
                        "
                        routeTransformer
                        [routeTransformerArgs]="[]"
                    ></div>
                </ng-template>
            </ngb-panel>
        </ng-container>
    </ngb-accordion>
</ng-container>
