import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../../../_common/services/content.service';
import { FaqComponentBase, FaqCategory } from '../../../../_common/components/faq/faq.component.base';
import { CommunityService } from '../../../../_common/services/community.service';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { WINDOW } from '@common/services/window.service';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent extends FaqComponentBase {
    constructor(
        communityService: CommunityService,
        route: ActivatedRoute,
        content: ContentService,
        analyticsService: AnalyticsService,
        @Inject(WINDOW) window: Window,
    ) {
        super(route, content, communityService, analyticsService, window, [
            new FaqCategory('ten-most-asked-questions'),
            new FaqCategory('new-collective-switching-schemes'),
            new FaqCategory('new-registration'),
            new FaqCategory('new-meters-and-tariffs'),
            new FaqCategory('new-auction'),
            new FaqCategory('new-personal-offer'),
            new FaqCategory('new-support'),
            new FaqCategory('new-all-questions'),
        ]);
    }
}
