import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../model/registration';

@Pipe({
    name: 'formatAddress',
})
export class FormatAddressPipe implements PipeTransform {
    formatPostcode(postcode: string): string {
        if (!postcode) {
            return '';
        }
        const outwardCode = postcode.slice(0, postcode.length - 3);
        const inwardCode = postcode.slice(-3);
        return `${outwardCode} ${inwardCode}`;
    }

    transform(contact: Contact, formatOptions: { separatePostcode?: boolean } = null): string[] {
        if (!contact) {
            return [];
        }

        formatOptions = formatOptions || {};

        const lines =
            !!contact.buildingNumber && !!contact.buildingNumber.trim()
                ? [
                      [contact.subBuilding, ', ', contact.buildingName],
                      [
                          contact.buildingNumber,
                          ' ',
                          contact.dependentThoroughfare,
                          ', ',
                          contact.thoroughfare,
                      ],
                  ]
                : [
                      [contact.subBuilding],
                      [contact.buildingName, ' ', contact.dependentThoroughfare, ', ', contact.thoroughfare],
                  ];
        if (!formatOptions.separatePostcode) {
            lines.push([
                contact.doubleDependentLocality,
                ', ',
                contact.dependentLocality,
                ', ',
                contact.town,
                ' ',
                this.formatPostcode(contact.postcode),
            ]);
        } else {
            lines.push([
                contact.doubleDependentLocality,
                ', ',
                contact.dependentLocality,
                ', ',
                contact.town,
                ' ',
            ]);
            lines.push([this.formatPostcode(contact.postcode)]);
        }

        return lines
            .map((line) => line.filter((x) => !!x).join(''))
            .map((line) =>
                line
                    .replace(' , ', ' ')
                    .replace(', , ', ', ')
                    .replace('  ', ' ')
                    .trim()
                    .replace(/^,/, '')
                    .replace(/,$/, ''),
            )
            .filter((x) => !!x && x !== ',');
    }
}
