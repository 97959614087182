import { Injectable } from '@angular/core';

declare var _cb: any;

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  likeIts1999(page: string): any {
    _cb.push(['addPageview', `/${page}`]);
  }

  constructor() { }
}
