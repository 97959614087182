/* eslint-disable @typescript-eslint/naming-convention */

import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { getInvalidFormControls } from '@common/infrastructure/form-tools';
import { isObject } from '@common/util';
import { AnalyticsEvent, AnalyticsEventTemplate } from './analytics.event';

type Template = AnalyticsEventTemplate<ErrorEvent>;

export class ErrorEvent implements AnalyticsEvent {
    event = 'error' as const;

    question: string;
    field_name: string;
    error_type: string;
    value: string | boolean | number;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }

    static create(template: Template = {}): ErrorEvent {
        return new ErrorEvent(template);
    }

    static fromForm(form: UntypedFormGroup | UntypedFormArray) {
        const invalids = getInvalidFormControls(form);
        return Object.entries(invalids).flatMap(([fieldName, x]) => {
            var question = document.querySelector(`label[for=${fieldName}]`)?.textContent?.trim();

            // Try to retrieve question from fieldnames of subform
            if (!question && x?.value) {
                Object.entries(x.value).flatMap(([fieldName]) => {
                    question = !question
                        ? document.querySelector(`label[for=${fieldName}]`)?.textContent?.trim()
                        : question;
                });
            }

            return Object.entries(x.errors)
                .filter(([_, value]) => value)
                .map(([error, _]) =>
                    ErrorEvent.create({
                        question,
                        field_name: fieldName,
                        error_type: error,
                        value: isObject(x?.value) ? JSON.stringify(x.value) : x?.value,
                    }),
                );
        });
    }
}
