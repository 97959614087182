import { PaymentStatus } from '@common/model/payment';
import { RegistrationBase } from '@common/model/registration.base';

export type OverviewMode =
    | 'registration complete'
    | 'proposal declined'
    | 'proposal accepted'
    | 'proposal introduction'
    | 'confirm email'
    | 'email confirmed'
    | 'interested confirmed'
    | 'payment'
    | 'payment successful'
    | 'rollover'
    | 'payment failed'
    | 'registration cancelled';

/* TODO
        - try to return null|undefined when going back in the flow (and not on overview page anymore)
        - make this customizable per BL? (IF NEEDED, this should be put as protected in enricherbase or so)
    */
export const getOverViewMode = (registration: RegistrationBase): OverviewMode => {
    if (registration?.paymentStatus === PaymentStatus.Failed) {
        return 'payment failed';
    }
    if (registration?.paymentStatus === PaymentStatus.Successful) {
        return 'payment successful';
    }
    if (registration?.proposalAccepted) {
        return 'proposal accepted';
    }
    if (registration?.proposalMade && registration?.decisionComplete && !registration?.proposalAccepted) {
        return 'proposal declined';
    }
    if (registration?.cancelled) {
        return 'registration cancelled';
    }
    if (registration?.copiedToNextAuction) {
        return 'rollover';
    }
    if (registration?.emailConfirmed) {
        return 'email confirmed';
    }
    if (registration?.subscriptionComplete) {
        return 'registration complete';
    }
    if (!registration?.auction && registration?.status === 'Created') {
        return 'interested confirmed';
    }

    return null;
};
