import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MiniFaqComponent } from '@common/components/mini-faq/mini-faq.component';
import { ContentService } from '@common/services/content.service';
import { GoogleAnalyticsService } from '@common/services/google-analytics.service';
import { VwoService } from '@common/services/vwo.service';
import { BrandHelper } from '@enuk/helpers/brand-helper';
import { RegisterStep } from '@enuk/model/register-step.enum';
import { Contracts, PaymentMethod, Registration } from '@enuk/model/registration';
import { EnUkCommunityService } from '@enuk/services/enuk-community.service';
import { RegistrationService } from '@enuk/services/registration.service';
import { StringHelpers } from '@lang/uk/helpers/string-helpers';
import { BrandService } from '@lang/uk/services/brand.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, take } from 'rxjs/operators';

export enum NextSteps {
    None,
    CompleteRegistration,
    ConfirmRegistration,
    WaitForAuction,
    MakeDecision,
    WaitForContactWithSupplier,
    AfterProposalAccepted,
}

@Component({
    selector: 'app-register-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input()
    registration: Registration;

    @Input() printRequestEvents: Observable<string>;

    @Output()
    navigateToTab = new EventEmitter<string>();

    @Output()
    updateRequested = new EventEmitter<Registration>();

    @ViewChild(MiniFaqComponent) miniFaq: MiniFaqComponent;

    public cmsParams: { registrationNumber?: string; contactUrl?: string } = {};

    public get cmsProposalAcceptedSurveyKey(): Observable<string> {
        return this.brandService.getActive().pipe(
            first((brand) => !!brand),
            distinctUntilChanged(),
            map((brand) => `proposal-accepted-survey-${BrandHelper.getShortCode(brand)}-link`),
        );
    }

    public get cmsProposalDeclinedSurveyKey(): Observable<string> {
        return this.communityService.getActive().pipe(
            first((community) => !!community),
            distinctUntilChanged(),
            map((comm) => `proposal-declined-survey-${StringHelpers.convertToKebab(comm.type)}-link`),
        );
    }

    Contracts: typeof Contracts = Contracts;
    PaymentMethod: typeof PaymentMethod = PaymentMethod;
    steps = NextSteps;
    emailConfirmed: boolean;

    private printSubscription: Subscription;

    constructor(
        private registrationService: RegistrationService,
        private analytics: GoogleAnalyticsService,
        private communityService: EnUkCommunityService,
        private route: ActivatedRoute,
        private router: Router,
        public modalService: NgbModal,
        private vwoService: VwoService,
        private brandService: BrandService,
        public contentService: ContentService,
    ) {
        this.route.queryParams.subscribe((params) => {
            this.emailConfirmed = params.confirmEmail;
        });
    }

    ngOnInit(): void {
        if (this.printRequestEvents) {
            this.printSubscription = this.printRequestEvents
                .pipe(filter((x) => x === 'overview'))
                .subscribe(() => this.print());
        }

        this.communityService.communityCode$.pipe(take(1)).subscribe((communityCode) => {
            this.cmsParams.contactUrl = this.router.createUrlTree([communityCode, 'contact']).toString();
        });
    }

    ngAfterViewInit(): void {
        let vwoUrl = RegisterStep.Overview;
        switch (this.nextStep) {
            case NextSteps.AfterProposalAccepted:
                vwoUrl = RegisterStep.OverViewProposalAccepted;
                break;
            case NextSteps.CompleteRegistration:
                vwoUrl = RegisterStep.OverviewCompleteRegistration;
                break;
            case NextSteps.ConfirmRegistration:
                vwoUrl = RegisterStep.OverviewConfirmRegistration;
                break;
            case NextSteps.WaitForAuction:
                vwoUrl = RegisterStep.OverviewWaitForAuction;
                break;
        }
        this.vwoService.push(vwoUrl);
    }

    ngOnDestroy(): void {
        if (this.printSubscription) {
            this.printSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.registration && changes.registration.currentValue) {
            this.cmsParams.registrationNumber = changes.registration.currentValue.number;
        }
    }

    get nextStep(): NextSteps {
        if (this.registration.decisionComplete && !this.registration.proposalAccepted) {
            return NextSteps.None;
        } else if (this.registration.decisionComplete && this.registration.proposalAccepted) {
            return NextSteps.AfterProposalAccepted;
        } else if (!this.registration.subscriptionComplete) {
            return NextSteps.CompleteRegistration;
        } else if (!this.registration.emailConfirmed && !this.registration.communicationBySnailMail) {
            return NextSteps.ConfirmRegistration;
        } else if (!this.registration.proposalMade) {
            return NextSteps.WaitForAuction;
        }
        return NextSteps.None;
    }

    get isRegistrationReadonly(): boolean {
        if (!this.registration) {
            return true;
        }
        return (
            this.registration.decisionComplete ||
            this.registration.cancelled ||
            this.registration.auction?.finished
        );
    }

    get miniFaqCategory(): string {
        if (this.registration) {
            const nextStep = this.nextStep;
            if (NextSteps.WaitForAuction === nextStep) {
                return 'faq-category-mini-pre-auction';
            }

            if (!this.registration.decisionComplete && this.registration.proposalMade) {
                return 'faq-category-mini-post-auction';
            }

            if (this.registration.decisionComplete) {
                return this.registration.proposalAccepted
                    ? 'faq-category-mini-accepted'
                    : 'faq-category-mini-declined';
            }
        }

        return null;
    }

    get showBankDetails(): boolean {
        if (!this.registration) {
            return false;
        }
        return (
            this.registration.decisionComplete &&
            this.registration.proposalAccepted &&
            this.registration.decision.paymentMethod === PaymentMethod.MonthlyDirectDebit &&
            !!this.registration.decision.accountNumber
        );
    }

    get defaultProposalAcceptedText(): string {
        let key = 'status-next-steps-proposal-accepted';
        if (
            this.registration?.proposal?.winningTariffSupplierName === 'Shell Energy' &&
            this.registration?.decision?.paymentMethod === PaymentMethod.PrePayment
        ) {
            key = 'status-next-steps-proposal-accepted-shell-energy-ppm';
        }
        return key;
    }

    get excludedReasonKebab(): string {
        return StringHelpers.convertToKebab(this.registration.excludedReason);
    }

    onNavigateToTab(tabName: string): void {
        this.navigateToTab.emit(tabName);
    }

    save(): void {
        this.updateRequested.emit(this.registration);
    }

    print(): void {
        window.print();
    }

    get dateWhenOneCanRequestLetter(): any {
        if (!this.registration || !this.registration.auction) {
            return '';
        }
        return moment(this.registration.auction.date).add(20, 'days').toDate();
    }

    wantToParticipateInNextAuction(): void {
        this.registrationService.participateInNextAction(this.registration).subscribe((registrationId) => {
            this.analytics.event(
                {
                    path: `/${this.communityService.communityCode}/register/overview`,
                },
                { category: 'overview', action: 'copied to next auction' },
                {
                    id: this.registration.id,
                    status: this.registration.status,
                },
            );
            this.analytics.event(
                {
                    path: `/${this.communityService.communityCode}/register/overview`,
                },
                { category: 'overview', action: 'copied to next auction' },
            );
            if (this.registration.decisionComplete) {
                this.save();
            } else {
                this.brandService.getActive().subscribe((brand) => {
                    const urlTree = this.router.createUrlTree(['register', registrationId]);
                    window.location.href = `${brand.url}${urlTree.toString()}`;
                });
            }
        });
    }
}
