import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class NgbUkDateParserFormatter extends NgbDateParserFormatter {
    datePipe = new DatePipe('en-UK');
    private dateFormatString: string;

    public static createInstance(dateFormatString: string): NgbUkDateParserFormatter {
        const result = new NgbUkDateParserFormatter();
        result.dateFormatString = dateFormatString;
        return result;
    }

    parse(value: string): NgbDateStruct {
        let returnVal: NgbDateStruct;
        if (!value) {
            returnVal = null;
        } else {
            try {
                const dateParts = value.split('/');
                returnVal = {
                    day: parseInt(dateParts[0], 10),
                    month: parseInt(dateParts[1], 10),
                    year: parseInt(dateParts[2], 10),
                };
            } catch (e) {
                returnVal = null;
            }
        }
        return returnVal;
    }

    format(date: NgbDateStruct): string {
        if (date === null) {
            return '';
        }
        try {
            return this.datePipe.transform(
                new Date(date.year, date.month - 1, date.day),
                this.dateFormatString,
            );
        } catch (e) {
            return '';
        }
    }
}
