<footer>
    <div *ngIf="(inRegistrationFlowPage$ | async) !== true">
        <div class="container-fluid py-5 d-none d-lg-block d-print-none">
            <div class="container">
                <app-list-menu cmsMenuKey="footer"></app-list-menu>
            </div>
        </div>
    </div>
    <app-helpdesk></app-helpdesk>
    <div class="container-fluid pt-4 d-print-none">
        <div class="container">
            <div
                class="d-flex justify-content-between align-items-center flex-column flex-md-row border-bottom py-1"
            >
                <h5 class="ms-0 ms-md-3 pb-4 pb-md-0" *ngIf="(utmLoaded$ | async) == true">
                    <a [href]="'footer-corporate-link' | cmsText : [{ brandUtm: brandUtm }] | async">
                        <img
                            [attr.src]="'footer-label-logo' | cmsImageSrc | async"
                            [attr.alt]="'footer-label-logo' | cmsImageAlt | async"
                            class="footerLogo"
                        />
                    </a>
                </h5>
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/', 'terms-and-conditions']" target="_blank">
                            Terms and Conditions
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/', 'privacy-statement']" target="_blank">
                            Privacy Policy
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/', 'cookie-policy']" target="_blank">
                            Cookie Policy</a
                        >
                    </li>
                </ul>
            </div>
            <div
                class="d-flex text-center justify-content-start align-items-center justify-content-md-start flex-column flex-md-row mx-3 mt-3"
            >
                <div class="d-flex"></div>
                <div class="d-flex mt-3 mt-md-0">
                    <p class="small text-muted pb-1">
                        {{ 'footer-copyright-text' | cmsText : [{ year: year }] | async }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>
