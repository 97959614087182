/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable } from '@angular/core';
import { convertToParamMap, ParamMap } from '@angular/router';
import {
    AnalyticsEvent,
    isPageViewEvent,
    isProcessEvent,
    isProcessGoalEvent,
    isProposalInteractionEvent,
} from '@common/analytics/events/analytics.event';
import { AnalyticsState } from '@common/analytics/model/analytics.state';
import { Community } from '@common/model/community';
import { RegistrationBase } from '@common/model/registration.base';
import { AuthService } from '@common/services/auth.service';
import { CommunityServiceBase } from '@common/services/community.service.base';
import { IFetchRegistration } from '@common/services/registration.service.base';
import { Observable, of } from 'rxjs';
import { flatMap, map, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { RouteService } from '@common/infrastructure/params-service';
import { isGuid } from '@common/util';
import { APP_BASE_HREF } from '@angular/common';
import { WINDOW } from '@common/services/window.service';
import { BUSINESS, CMS_PRODUCT } from '@common/app/app.config';
import { ProposalInteractionEventEnricher } from '../enrichers/proposal-interaction-event.enricher';
import { Enricher } from '../enrichers/enricher';
import { PageViewEventEnricher } from '../enrichers/page-view-event.enricher';
import { ProcessGoalEventEnricher } from '../enrichers/process-goal-event.enricher';
import { ProcessEventEnricher } from '../enrichers/process-event.enricher';

declare const CookieConsent: any;
declare const OptanonActiveGroups: string;

export class AnalyticsEnricherProvider {
    constructor(
        public registrationService: IFetchRegistration<RegistrationBase>,
        public communityService: CommunityServiceBase<Community>,
    ) {}
}

interface EnricherDefinition {
    condition: (e: AnalyticsEvent) => boolean;
    enricher: Enricher<AnalyticsEvent>;
}
@Injectable({
    providedIn: 'root',
})
export class AnalyticsEnricherService {
    private community$: Observable<Community>;
    private registration$: Observable<RegistrationBase>;

    private readonly enrichers: EnricherDefinition[];

    constructor(
        private provider: AnalyticsEnricherProvider,
        private authService: AuthService,
        private routeService: RouteService,
        @Inject(APP_BASE_HREF) private baseHref: string,
        @Inject(WINDOW) private window: Window,
        @Inject(CMS_PRODUCT) private product: string,
        @Inject(BUSINESS) private business: string,
    ) {
        this.registration$ = this.initializeRegistration$();
        this.community$ = this.initializeCommunity$();

        const dependencies = [this.registration$, this.community$, this.authService.identity$] as const;

        this.enrichers = [
            {
                condition: isProposalInteractionEvent,
                enricher: new ProposalInteractionEventEnricher(this.product),
            },
            {
                condition: isProcessGoalEvent,
                enricher: new ProcessGoalEventEnricher(...dependencies),
            },
            {
                condition: isProcessEvent,
                enricher: new ProcessEventEnricher(...dependencies),
            },
            {
                condition: isPageViewEvent,
                enricher: new PageViewEventEnricher(
                    this.consentLevel,
                    this.baseHref,
                    this.window.location.href,
                    this.product,
                    this.business,
                    ...dependencies,
                ),
            },
        ];
    }

    enrichEvent$<T extends AnalyticsEvent>(event: T, state: AnalyticsState): Observable<T> {
        const enricher = this.getEnricherFor(event);
        if (!enricher) return of(event);
        return enricher.enrich$(event, state) as Observable<T>;
    }

    private getEnricherFor(event: AnalyticsEvent): Enricher<AnalyticsEvent> {
        return this.enrichers?.find((e) => e.condition(event))?.enricher;
    }

    private get consentLevel(): boolean {
        try {
            return CookieConsent && CookieConsent?.consent && !!CookieConsent?.consent?.statistics;
        } catch {}

        try {
            return OptanonActiveGroups.split(',')
                .filter((v) => v !== '')
                .includes('C0002');
        } catch {}
        return false;
    }

    private get hasRegistrationIdInUrl$(): Observable<string> {
        const paramMap$: Observable<ParamMap> = this.routeService?.paramMap ?? of(convertToParamMap({}));
        return paramMap$.pipe(
            map((params) => params.get('id') || params.get('registration-id')),
            // TODO the check below is a quick fix.
            // It should definitely stay, for added safety, but ideally we should rename every :id to :registration-id to be safe.
            map((id) => (isGuid(id) ? id : null)),
        );
    }

    private initializeCommunity$(): Observable<Community> {
        if (!this.provider?.communityService) return of(null);

        const service = this.provider.communityService;

        //we don't use communityService.community$ to avoid the shareReplay.
        return service.communityCode$.pipe(
            switchMap((code) => (code ? service.getCommunity(code) : of(null))),
            take(1),
        );
    }

    private initializeRegistration$(): Observable<RegistrationBase> {
        if (!this.provider?.registrationService) return of(null);

        const service = this.provider.registrationService;

        // When participant security is enabled we need to wait for authentication to have been exexcuted
        // - otherwise this api call will fail and redirect to unauthorized page.
        // When participant security is not enabled then user$ will resolve instantaneously
        return this.authService.user$.pipe(
            take(1),
            switchMapTo(this.hasRegistrationIdInUrl$),
            flatMap((registrationId) =>
                registrationId ? service.get(registrationId) : service.loadFromSessionStorage(),
            ),
        );
    }
}
