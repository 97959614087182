<section class="container" *ngIf="brandCode$ | async; let brandCode">
    <div class="row">
        <div class="col-12 col-md-6 mx-auto mt-1">
            <img
                class="img-fluid"
                [attr.src]="brandCode + '-brand-logo' | cmsImageSrc | async"
                [attr.alt]="brandCode + '-brand-logo' | cmsImageAlt | async"
            />
        </div>
    </div>
    <h4 class="text-center my-5">{{ 'landing-page-title' | cmsText | async }}</h4>
    <div class="row" *ngIf="brandCode === Brands.Norfolk || brandCode === Brands.BigLondonEnergySwitch">
        <ng-container *ngFor="let community of brandCommunities$ | async; index as i">
            <div class="my-1"></div>
            <div
                class="card col-xl-3 col-md-4 col-sm-6 col-12 text-center p-2 border-0"
                *ngLet="community.code ? community.code : brandCode as communityCode"
            >
                <a [routerLink]="['/', communityCode, 'home']">
                    <img
                        [attr.src]="communityCode + '-community-landing-logo' | cmsImageSrc | async"
                        [attr.alt]="communityCode + '-community-landing-logo' | cmsImageAlt | async"
                        height="100"
                        width="200"
                    />
                    <div class="card-body">
                        <h5>{{ community.name }}</h5>
                    </div>
                </a>
            </div>
        </ng-container>
    </div>

    <div
        class="col-sm-6 mx-auto"
        *ngIf="
            brandCode === Brands.BigCommunitySwitch ||
            brandCode === Brands.Warwickshire ||
            brandCode === Brands.Kent ||
            brandCode === Brands.Essex
        "
    >
        <ng-select
            [items]="brandCommunities$ | async"
            bindValue="code"
            bindLabel="name"
            [searchFn]="searchCouncil"
            [selectOnTab]="true"
            (change)="navigateToPage($event)"
            [placeholder]="'council-dropdown-placeholder' | cmsText | async"
        >
        </ng-select>

        <div class="form-check" *ngIf="user$ | async">
            <input
                type="checkbox"
                class="form-check-input"
                id="paperRegistration"
                [(ngModel)]="paperRegistration"
            />
            <label class="form-check-label" for="paperRegistration">paper registration</label>
        </div>
    </div>
</section>
<section class="container my-5 py-0 py-md-5">
    <div class="row">
        <div class="col-md-6 mt-4 mt-md-0 text-center">
            <img
                class="w-100"
                [attr.src]="'info-the-offer-img1' | cmsImageSrc | async"
                [attr.alt]="'info-the-offer-img1' | cmsImageAlt | async"
            />
        </div>
        <div class="col-md-6 my-auto">
            <h3 class="mb-3 mt-3 mt-md-0" [innerHtml]="'info-the-offer-title1' | cmsText | async"></h3>
            <div class="pe-md-5" [innerHtml]="'info-the-offer-text1' | cmsText | async"></div>
        </div>
    </div>
</section>
<section class="fluid-container text-start text-md-center bg-highlight-one my-5 py-5">
    <div class="container py-0 py-md-5">
        <h3 class="mb-0 mb-md-5"><span [innerHTML]="'how-it-works-title' | cmsText | async"></span></h3>
        <div class="d-flex justify-content-between my-4 row">
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-1-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-1-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-1-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-1-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-2-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-2-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-2-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-2-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-3-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-3-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-3-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-3-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-4-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-4-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-4-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-4-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-5-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-5-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-5-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-5-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="container-fluid my-5">
    <div class="container">
        <app-past-suppliers></app-past-suppliers>
    </div>
</section>
