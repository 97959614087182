import { Component, OnInit } from '@angular/core';

declare const OneTrust: any;

@Component({
    selector: 'app-cookie-policy',
    template: `
        <div class="container">
            <h2 class="mt-5 mb-3">
                {{ 'cookie-policy-title' | cmsText | async }}
            </h2>
            <p [innerHtml]="'cookie-policy-message' | cmsText | async"></p>
            <!-- OneTrust Cookies Settings button start -->
            <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
            <!-- OneTrust Cookies List start -->
            <div id="ot-sdk-cookie-policy"></div>
            <!-- OneTrust Cookies List end -->
        </div>
    `,
})
export class CookiePolicyComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
        OneTrust.initializeCookiePolicyHtml();
    }
}
