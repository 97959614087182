import {
    Component,
    ViewChild,
    ElementRef,
    AfterContentInit,
    AfterContentChecked,
    Input,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VwoStoreService } from '@common/services/vwo-store.service';
@Component({
    selector: 'app-vwo-capturer',
    templateUrl: './vwo-capturer.component.html',
})
export class VwoCapturerComponent
    implements AfterContentInit, AfterContentChecked, OnInit {
    @ViewChild('mainVwoDiv')
    contentWrapper: ElementRef;

    @Input()
    public initialVariantName = 'default';

    @Input()
    public timeoutBeforeUsingDefaultVariant = 0;

    constructor(
        private vwoService: VwoStoreService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        if (this.timeoutBeforeUsingDefaultVariant > 0) {
            setTimeout(() => {
                this.setVwoVariantToDefault();
            }, this.timeoutBeforeUsingDefaultVariant);
        }

        this.route.queryParamMap.subscribe((params) => {
            const vwoVariant = params.get('vwoVariant');
            if (vwoVariant != null) {
                this.forceVwoVariant(vwoVariant);
            }
        });
    }

    ngAfterContentInit(): void {
        this.registerVariationName();
    }

    ngAfterContentChecked(): void {
        this.registerVariationName();
    }

    private forceVwoVariant(variant: string): void {
        const element: HTMLDivElement = this.contentWrapper?.nativeElement?.querySelector(
            '#vwo-variant',
        );

        if (element) {
            element.setAttribute('data-variant-name', variant);
            this.vwoService.variantName$.next(variant);
        }
    }

    private setVwoVariantToDefault(): void {
        const element: HTMLDivElement = this.contentWrapper?.nativeElement?.querySelector(
            '#vwo-variant',
        );

        if (element) {
            const currentVariantName: string = element.getAttribute(
                'data-variant-name',
            );

            if (
                currentVariantName === this.initialVariantName &&
                currentVariantName !== 'default'
            ) {
                this.vwoService.variantName$.next('default');
            }
        }
    }

    private registerVariationName(): void {
        const element: HTMLDivElement = this.contentWrapper?.nativeElement?.querySelector(
            '#vwo-variant',
        );
        const currentVariantName: string = element?.getAttribute(
            'data-variant-name',
        );
        if (currentVariantName && currentVariantName !== 'undefined') {
            this.vwoService.variantName$.next(currentVariantName);
        }
    }
}
