import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appOptional]',
})
export class MarkOptionalDirective implements OnInit {
    @Input() message = '(optional)';
    @Input() labelOnParent = false;

    constructor(private renderer: Renderer2, private el: ElementRef) {}

    ngOnInit(): void {
        let useElement = null;
        if (this.labelOnParent) {
            const parent = this.renderer.parentNode(this.el.nativeElement);
            if (parent != null) {
                useElement = parent.getElementsByTagName('LABEL')[0];
            }
        } else {
            useElement = this.el.nativeElement;
        }

        if (useElement != null && !useElement.getElementsByClassName('optional').length) {
            const node = document.createElement('sup');
            const textNode = document.createTextNode(' ' + this.message);
            node.setAttribute('class', 'optional');
            node.appendChild(textNode);
            useElement.appendChild(node);
        }
    }
}
