<div class="card elevation-3 mb-3" *ngIf="isBackofficeUser">
    <h5 class="card-header bg-primary text-white">
        {{ 'logged-in-as-backoffice-user' | cmsText | async }}
    </h5>
    <div class="card-body">
        <p class="mb-0 nls_protected">
            <i class="fa fa-user text-warning me-2"></i>
            <span>{{ user.email }}</span>
        </p>
    </div>
</div>

<div class="card elevation-3 mb-3" *ngIf="registrationNumber">
    <h5 class="card-header">{{ 'registration-number-card-header' | cmsText | async }}</h5>
    <div class="card-body">
        <p class="mb-0">
            <span *ngIf="isParticipant">
                <i class="fa fa-lock text-warning me-2"></i>
                <app-tooltip-enuk
                    cmsKey="logged-in-as-participant-title"
                    targetCmsKey="registration-number-card-header"
                ></app-tooltip-enuk>
            </span>
            {{ 'registration-number-label' | cmsText | async }}
            <strong id="registrationNumber">{{ registrationNumber }}</strong>
        </p>

        <span *ngIf="isParticipant" class="float-end mt-2">
            <a [routerLink]="[]" (click)="logout()">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
                {{ 'logout-call-to-action' | cmsText | async }}
            </a>
        </span>
    </div>
</div>
