<div class="ms-1 d-print-none" *ngIf="!!selectedStep">
    <h3 class="mt-1 mb-7" [ngClass]="{ 'pb-2': !showStepCounter }">
        <strong>{{ selectedStep.title | cmsText : selectedStep.titleParams | async }}</strong>
        <button
            *ngIf="showPrint"
            #buttonRef
            class="d-print-none float-end btn btn-secondary"
            (click)="print(buttonRef)"
        >
            <i class="fa fa-fw fa-print"></i>Print
        </button>
    </h3>
    <h6 *ngIf="showStepCounter" class="text-muted">Step {{ stepNumber }} of {{ stepCount }} to register</h6>
    <hr class="mt-0 mb-0" />
    <div class="pb-3">
        {{ selectedStep.description | cmsText | async }}
    </div>
</div>
