import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { InfoItem } from '@common/components/info/info.component.base';
import { MenuItem } from '@lang/uk/model/menu-item.model';
import { InfoItemServiceBase } from '@lang/uk/services/info-item.service';
import { MenuItemService } from '@lang/uk/services/menu-item.service';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuComponent implements OnChanges {
    @Input() public cmsMenuKey: string = null;
    @Input() public navbarCollapsed = true;
    @Input() public isMobileDevice = false;
    @Output() public itemClick: EventEmitter<void> = new EventEmitter<void>();
    @Input() public multiverse: boolean = false;

    public activeSubMenu: string = null;
    public menuItems$: Observable<MenuItem[]> = of([]);
    public getMenuItemRoute = (menuItem: MenuItem): string[] =>
        this.menuItemService.getMenuItemRoute(menuItem);

    public getInfoItemRoute = (infoItem: InfoItem): string[] =>
        this.infoItemService.getInfoItemUrlComponents(infoItem);

    public trackById = (_: number, menuItem: MenuItem): string => menuItem.id;

    constructor(private menuItemService: MenuItemService, private infoItemService: InfoItemServiceBase) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.cmsMenuKey) {
            this.menuItems$ = changes.cmsMenuKey.currentValue
                ? this.menuItemService.getMenuItems(changes.cmsMenuKey.currentValue)
                : of([]);
        }
    }

    public onMenuActive(item: MenuItem): void {
        this.activeSubMenu = this.activeSubMenu === item?.id && this.activeSubMenu !== null ? null : item?.id;
    }

    public onMenuClick(): void {
        this.activeSubMenu = null;
        this.itemClick.emit();
    }
}
