import { OnInit, OnDestroy, HostListener, Directive } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { RegistrationBase } from '../../model/registration.base';
import { RegistrationServiceBase } from '../../services/registration.service.base';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Community } from '../../model/community';
import { CommunityService } from '../../services/community.service';
import { ComponentCanDeactivate } from '../../infrastructure/pending-changes.guard';
import { ContentService } from '../../services/content.service';
import { KenalyticsService } from '../../services/kenalytics.service';
import { filter, takeUntil } from 'rxjs/operators';
import { RegisterFlowLocationService } from '@common/services/register-flow-location.service';
import { PendingChangesService } from '@common/services/pending-changes.service';
import { ParticipationAssetsService } from '@common/services/participation-assets.service';
import { copy, removeNullishAndEmpty } from '@common/util';
export class Step {
    id: string;
    header: string;
    icon: string;
}

export enum StandardSteps {
    contact,
    product,
    proposal,
    overview,
}

@Directive()
export abstract class RegisterComponentBase<
    TRegistration extends RegistrationBase,
    TService extends RegistrationServiceBase<TRegistration>,
> implements OnInit, OnDestroy, ComponentCanDeactivate
{
    protected _destroyed$ = new Subject<void>();

    private _prevRegistrationState: TRegistration;
    protected get prevRegistrationState(): TRegistration {
        return this._prevRegistrationState;
    }
    protected set prevRegistrationState(value: TRegistration) {
        this._prevRegistrationState = copy(value); // copy to avoid mutations to and from the original
    }

    get enteredFlowFromLink(): boolean {
        return this.flowLocationService.enteredFlowFromLink;
    }

    get activeStep(): string {
        const activeStep = this.flowLocationService.activeStep;

        if (
            activeStep === 'proposal' &&
            (this.registration.id === null || this.registration.id === '')
        ) {
            return 'undefined';
        }
        return activeStep;
    }

    saving = false;
    submitting = false;
    serverValidations: string[] = [];
    serverError: HttpErrorResponse | undefined;

    participantCount: Observable<number>;
    public get participantMinimum() {
        return this.service.participantMinimum;
    }

    get showParticipantCount() {
        return true;
    }
    get showAcceptantsCount(): boolean {
        switch (this.activeStep) {
            case 'contact':
            case 'product':
                return false;
            case 'proposal':
                return true;
            case 'overview':
                if (this.registration.decisionComplete) {
                    return true;
                }
                return false;
        }
        return false;
    }
    get showContactOptions() {
        return true;
    }
    get showIntroduction() {
        return !this.registration.proposalMade;
    }
    get showProposalInfo() {
        return this.registration.proposalMade;
    }
    get currentStepNumber(): number {
        return this.getStepNumber(this.activeStep);
    }

    get steps() {
        return this.allSteps.filter(
            (step) => step.id !== 'proposal' || this.registration.proposalMade,
        );
    }
    get startedWithStep(): string {
        return this.flowLocationService.startedWithStep;
    }

    community: Community | null;
    get communityCode() {
        return this.communityService.communityCode;
    }

    get isRegistrationReadonly(): boolean {
        if (!this.registration) {
            return true;
        }
        return this.registration.decisionComplete;
    }

    constructor(
        public service: TService,
        protected communityService: CommunityService,
        protected route: ActivatedRoute,
        protected analytics: GoogleAnalyticsService,
        public registration: TRegistration,
        public allSteps: Step[],
        protected contentService: ContentService,
        protected kenalyticsService: KenalyticsService,
        protected flowLocationService: RegisterFlowLocationService,
        private participationAssetsService: ParticipationAssetsService,
        private pendingChangesService: PendingChangesService,
        protected subscriptionCompleteAfterStepIndex: number = 2,
    ) {}

    ngOnInit(): void {
        this.flowLocationService.goToSucceeded$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                window.scrollTo(0, 0);
                this.setParticipantCount();
                this.submitting = false;
            });

        const start = this.route.snapshot.queryParamMap.get('start') || null;
        if (start) {
            this.flowLocationService.startWithStep(start);
            this.prepareSteps();
        }

        const paper = this.route.snapshot.queryParamMap.get('paper') || null;
        if (paper) {
            this.service.snailMail = true;
        }

        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.flowLocationService.enterFlowFromLink();
            this.service
                .get(id)
                .pipe(takeUntil(this._destroyed$))
                .subscribe((r) => this.loadRegistration(r, true));
        } else {
            this.service
                .loadFromSessionStorage()
                .subscribe((storedRegistration) => {
                    const params = storedRegistration
                        ? Object.assign(this.registration, storedRegistration)
                        : this.registration;
                    this.loadRegistration(params, false);
                });
        }

        this.communityService.community$
            .pipe(
                takeUntil(this._destroyed$),
                filter((community) => !!community.code),
            )
            .subscribe((community) => {
                this.community = community;
                if (!this.community.allowCommunicationBySnailMail) {
                    this.service.snailMail = false;
                }
            });
    }

    protected setParticipantCount(): void {
        this.participantCount =
            this.selectCounterAuction() !== null
                ? this.showAcceptantsCount
                    ? this.service.countAcceptants(this.selectCounterAuction())
                    : this.service.countSubscriptions(
                          this.selectCounterAuction(),
                      )
                : this.service.count();
    }

    protected loadRegistration(theOne: any, existing: boolean) {
        this.prevRegistrationState = theOne; //when a new registration is loaded, the prev and current should be the same
        this.registration = theOne;
        this.flowLocationService.registrationLoaded(this.registration);
        this.participationAssetsService.registrationLoaded(this.registration);
        this.communityService.community$
            .pipe(takeUntil(this._destroyed$))
            .subscribe((community) => {
                if (!existing && !this.registration.auction) {
                    this.registration.auction = community.targetAuction;
                }
                if (
                    existing &&
                    this.registration.auction &&
                    (!community.targetAuction ||
                        (community.targetAuction &&
                            this.registration.auction.code !==
                                community.targetAuction.code))
                ) {
                    this.contentService.load(this.registration.auction.code);
                }
            });
        this.goToStep();
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    protected prepareSteps() {
        // Default action for alternative flow
        if (this.startedWithStep === 'product') {
            const target = this.getStepNumber('product');
            if (target > 0) {
                this.allSteps.splice(target, 0, this.allSteps.shift()); // move product page as first step
            }
        }
    }

    protected goToStep() {
        if (!this.startedWithStep) {
            if (this.registration.cancelled) {
                this.goTo('overview');
            } else if (this.registration.proposalMade) {
                this.goTo(
                    this.registration.decisionComplete
                        ? 'overview'
                        : 'proposal',
                );
            } else if (this.registration.subscriptionComplete) {
                this.goTo('overview');
            } else {
                this.goTo('person');
            }
        } else {
            this.goTo(this.startedWithStep);
        }
    }

    goBackTo(step: string): void {
        if (
            !this.isRegistrationReadonly &&
            this.currentStepNumber > this.getStepNumber(step)
        ) {
            this.goTo(step);
        }
    }

    // TODO: remove this method and let children use the service directly
    goTo(step: string): void {
        this.flowLocationService.goTo(step);
    }

    getStepNumber(id): number {
        return this.steps.indexOf(this.steps.find((item) => item.id === id));
    }

    saveAndContinue(registration: TRegistration) {
        const afterSave = () => {
            this.rehydrate(registration, true);
        };
        if (
            this.currentStepNumber === this.subscriptionCompleteAfterStepIndex
        ) {
            this.registration.subscriptionComplete = true;
        }

        this.save(afterSave);
    }

    saveAndRehydrate(registration: TRegistration) {
        const afterSave = () => {
            this.rehydrate(registration);
        };

        this.save(afterSave);
    }

    rehydrate(
        registration: TRegistration,
        changeStep?: boolean,
        afterRehydrate?: (r: TRegistration) => void,
    ) {
        this.service.get(registration.id).subscribe((recalculated) => {
            this.reloadCms(registration, recalculated);
            this.service.saveToSessionStorage(recalculated);
            this.pendingChangesService.pendingChanges = false;

            Object.assign(registration, recalculated);
            if (changeStep) {
                this.rehydrateChangeStep(registration);
            } else {
                this.submitting = false;
            }
            afterRehydrate?.(recalculated);
        });
    }

    rehydrateChangeStep(registration: TRegistration) {
        const steps = this.allSteps.filter(
            (x) => registration.proposalMade || x.id !== 'proposal',
        );
        if (this.currentStepNumber < steps.length - 1) {
            this.goTo(steps[this.getNextStep()].id);
        }
    }

    protected getNextStep(): number {
        return this.currentStepNumber + 1;
    }

    reloadCms(registration: TRegistration, recalculated: TRegistration) {
        if (registration.auction && !recalculated.auction) {
            this.contentService.load(null, true);
        } else if (!registration.auction && recalculated.auction) {
            this.contentService.load(recalculated.auction.id);
        } else if (
            registration.auction &&
            recalculated.auction &&
            registration.auction.id !== recalculated.auction.id
        ) {
            this.contentService.load(recalculated.auction.id);
        }
    }

    selectCounterAuction(): string {
        if (this.registration.auction) {
            return this.registration.auction.id;
        }
        if (this.community && this.community.targetAuction) {
            return this.community.targetAuction.id;
        } else {
            return null;
        }
    }

    save(afterSave: () => void): void {
        this.saving = true;
        this.submitting = true;
        this.serverError = undefined;
        this.serverValidations = [];

        const onError = (error: HttpErrorResponse) => {
            this.saving = false;
            this.submitting = false;
            if (error.status === 400) {
                let validationErrorRoot = error.error;
                if (error.error && error.error.errors) {
                    validationErrorRoot = error.error.errors;
                }

                if (
                    typeof validationErrorRoot === 'string' ||
                    validationErrorRoot instanceof String
                ) {
                    this.serverValidations.push(validationErrorRoot as string);
                } else {
                    for (const property in validationErrorRoot) {
                        if (validationErrorRoot.hasOwnProperty(property)) {
                            this.serverValidations.push(
                                validationErrorRoot[property].join(', '),
                            );
                        }
                    }
                }
                console.log(this.serverValidations);
            } else {
                this.serverError = error;
                console.log(this.serverError);
            }
        };

        if (!this.registration.id) {
            if (this.community) {
                this.doCreate(this.community, afterSave, onError);
            } else {
                this.communityService.community$.subscribe((community) => {
                    this.doCreate(community, afterSave, onError);
                });
            }
        } else {
            this.doUpdate(this.registration, afterSave, onError);
        }
    }

    protected doUpdate(
        registration: TRegistration,
        afterSave: () => void,
        onError: (error: HttpErrorResponse) => void,
    ): void {
        this.service.update(registration).subscribe(() => {
            this.saving = false;
            if (afterSave) {
                afterSave();
            }
        }, onError);
    }

    protected doCreate(
        community: Community,
        afterSave: () => void,
        onError: (error: HttpErrorResponse) => void,
    ): void {
        this.registration.communityId = community.id;
        if (this.service.snailMail && community.allowCommunicationBySnailMail) {
            this.registration.communicationBySnailMail = true;
        }
        this.service.add(this.registration).subscribe((data) => {
            this.registration.id = data;
            this.kenalyticsService.postUtmCodes(this.registration.id);
            this.saving = false;
            if (afterSave) {
                afterSave();
            }
        }, onError);
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.pendingChangesService.pendingChanges;
    }

    // TODO: remove this method and let children use the service directly
    pendingChangesUpdate(changes: boolean) {
        this.pendingChangesService.pendingChanges = changes;
    }

    analyticsEvent(action: string) {
        this.analytics.event(
            {
                path: `/${this.communityService.communityCode}/${this.activeStep}`,
            },
            { category: `${this.activeStep}Actions`, action },
            {
                id: this.registration.id,
                status: this.registration.status,
                hasEmail: this.registration.email ? 'true' : 'false',
            },
        );
    }
}
