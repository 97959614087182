/* eslint-disable @typescript-eslint/naming-convention */

import { AnalyticsEvent, AnalyticsEventTemplate } from './analytics.event';

type Template = AnalyticsEventTemplate<FaqImpressionEvent>;

export class FaqImpressionEvent implements AnalyticsEvent {
    event = 'faq_impression' as const;

    faq_cms_key: string;
    search_term: string;

    constructor(template: Template = {}) {
        Object.assign(this, template);
    }

    static create(template: Template = {}): FaqImpressionEvent {
        return new FaqImpressionEvent(template);
    }
}
