import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { I4pParticipationAssetsService } from '@common/services/participation-assets.service';
import { Community } from '@enuk/model/community';
import { Brands } from '@enuk/model/registration';
import { EnUkCommunityService } from '@enuk/services/enuk-community.service';
import { BrandService } from '@lang/uk/services/brand.service';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'app-info-about-us',
    templateUrl: './about-us.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoAboutUsComponent implements OnInit, OnDestroy {
    public cmsParams: { brand?: string; community?: string; baseUrl?: string } = {};
    public community$: Observable<Community>;
    public parentBrandCode$: Observable<string>;
    protected destroyed$ = new Subject<void>();

    constructor(
        protected brandService: BrandService,
        protected communityService: EnUkCommunityService,
        protected participationAssetsService: I4pParticipationAssetsService,
        protected contentService: ContentService,
        protected changeDetectorRef: ChangeDetectorRef,
        private location: Location,
    ) {}

    public ngOnInit(): void {
        this.community$ = this.communityService.getActive();
        this.parentBrandCode$ = this.brandService
            .getActive()
            .pipe(map((brand) => (brand.parentBrand ? brand.parentBrand.code : brand.code)));

        this.parentBrandCode$
            .pipe(
                takeUntil(this.destroyed$),
                switchMap((brandCode) => this.contentService.value(`${brandCode}-brand-text`).pipe(take(1))),
                withLatestFrom(this.community$),
            )
            .subscribe(([brandName, community]) => {
                this.cmsParams = {
                    brand: brandName,
                    community: community.name,
                    baseUrl: location.origin + this.location.prepareExternalUrl(`${community.code ?? ''}/`),
                };
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public isBcsOrBlesBrand(brandCode: string): boolean {
        return brandCode === Brands.BigCommunitySwitch || brandCode === Brands.BigLondonEnergySwitch;
    }
}
