<ng-container *ngIf="!multiverse">
    <a [routerLink]="routerLink" (click)="clickEvent.emit()">
        <img
            [attr.src]="participationAssetsService.baseBrandLogoUrl$ | async"
            [attr.alt]="participationAssetsService.baseBrandLogoAlt$ | async"
        />
    </a>
    <ng-container
        *ngIf="this.participationAssetsService.communityParticipation$ | async as communityParticipation"
    >
        <div class="second-logo" id="community-logo" *ngIf="showCommunityLogo(communityParticipation)">
            <a [routerLink]="routerLink" (click)="clickEvent.emit()">
                <img
                    [attr.src]="participationAssetsService.communityLogoUrl$ | async"
                    [attr.alt]="participationAssetsService.communityLogoAlt$ | async"
                />
            </a>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="multiverse">
    <a class="navbar-brand" [routerLink]="routerLink" (click)="clickEvent.emit()">
        <img
            class="mt-n4 img-fluid"
            [attr.src]="participationAssetsService.brandLogoUrl$ | async"
            [attr.alt]="participationAssetsService.brandLogoAlt$ | async"
        />
    </a>
    <ng-container
        *ngIf="this.participationAssetsService.communityParticipation$ | async as communityParticipation"
    >
        <a
            [routerLink]="routerLink"
            (click)="clickEvent.emit()"
            class="navbar-brand"
            *ngIf="showCommunityLogo(communityParticipation)"
        >
            <img
                class="img-fluid"
                [attr.src]="participationAssetsService.communityLogoUrl$ | async"
                [attr.alt]="participationAssetsService.communityLogoAlt$ | async"
            />
        </a>
    </ng-container>
</ng-container>
