import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Contracts } from '../model/registration';

export class ValidationHelper {
    protected static requiredWhen(predicate: () => boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return predicate() && !control.value ? { required: true } : null;
        };
    }

    public static requiredForDualContract(getContract: () => Contracts): ValidatorFn {
        return ValidationHelper.requiredWhen(() => getContract() === Contracts.Dual);
    }

    public static requiredForElectricityOrSeparateContract(getContract: () => Contracts): ValidatorFn {
        return ValidationHelper.requiredWhen(() => {
            const currentContract = getContract();
            return currentContract === Contracts.Electricity || currentContract === Contracts.Separate;
        });
    }

    public static requiredForSeparateContract(getContract: () => Contracts): ValidatorFn {
        return ValidationHelper.requiredWhen(() => getContract() === Contracts.Separate);
    }
}
