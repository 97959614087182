import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL, DEFAULT_COMMUNITY } from '@common/app/app.config';
import { RouteService } from '@common/infrastructure/params-service';
import { CommunityService } from '@common/services/community.service';
import { Observable } from 'rxjs';
import { Community } from '../model/community';

@Injectable({
    providedIn: 'root',
})
export class EnUkCommunityService extends CommunityService {
    constructor(
        protected override http: HttpClient,
        @Inject(API_URL) baseUrl: string,
        @Inject(DEFAULT_COMMUNITY) protected override defaultCommunity: string,
        routeService: RouteService,
    ) {
        super(http, baseUrl, defaultCommunity, routeService);
    }

    // Convenience function to allow the use of spyOn while testing (and returns the ENUK-typed Community model)
    public getActive = (): Observable<Community> => this.community$ as Observable<Community>;
}
