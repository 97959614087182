<ng-container *ngIf="fuelType">
    <label [for]="id" class="d-block form-label">
        {{ cmsLabel | cmsText | async }}
    </label>
    <div class="row">
        <div class="col-md-8">
            <ng-select
                [name]="id"
                [id]="id"
                placeholder="{{ cmsPlaceholderLabel | cmsText | async }}"
                [disabled]="!enabled || !suppliers$"
                [ngModel]="selectedSupplier"
                (ngModelChange)="setSelectedSupplier($event)"
                bindLabel="name"
                [items]="suppliers$ | async"
                [searchFn]="searchItem"
                [compareWith]="compareSupplier"
                [selectOnTab]="true"
            >
            </ng-select>
        </div>
    </div>
</ng-container>
