import { Injectable, Inject, InjectionToken, Optional } from '@angular/core';
import { AppConfig } from '@enuk/model/app-config';
import { environment } from '../../environments/environment';

export const APP_CONFIG: InjectionToken<AppConfig> = new InjectionToken<any>('Application Configuration');

@Injectable()
export class AppConfigService {
    constructor(@Optional() @Inject(APP_CONFIG) private appConfig: AppConfig) {
        if (appConfig) {
            environment.production = appConfig.production;
            environment.apiUri = appConfig.apiUri;
            environment.identityUri = appConfig.identityUri;
            environment.customDomainIdentityUris = appConfig.customDomainIdentityUris;
            environment.googleApiKey = appConfig.googleApiKey;
            environment.allowLinksToCms = appConfig.allowLinksToCms;
            environment.analyticsUri = appConfig.analyticsUri;
            environment.canAccessLandingPage = appConfig.canAccessLandingPage;
            environment.brandAsUrlQueryParameter = appConfig.brandAsUrlQueryParameter;
            environment.defaultBrand = appConfig.defaultBrand;
            environment.participantSecurity = appConfig.participantSecurity;
            environment.useProjectsAssets = appConfig.useProjectsAssets;
            environment.aiConnectionString = appConfig.aiConnectionString;

            // Pipelines variable substitution fixes.
            this.fixBoolean(environment, 'production');
            this.fixBoolean(environment, 'allowLinksToCms');
            this.fixBoolean(environment, 'canAccessLandingPage');
            this.fixBoolean(environment, 'brandAsUrlQueryParameter');
            this.fixBoolean(environment, 'participantSecurity');
            this.fixBoolean(environment, 'useProjectsAssets');
        }
    }

    private fixBoolean(parent: any, property: string): void {
        if (typeof parent[property] === 'string') {
            parent[property] = parent[property] === 'true';
        }
    }
}
