export const isObject = (obj: any): boolean => !!obj && obj === Object(obj);

export const isNullOrUndefined = (value: any): boolean =>
    value === undefined || value === null;

export const objectReducer = (prev, [k, v]) => {
    prev[k] = v;
    return prev;
};

export const isGuid = (guid: string): boolean => {
    const guidPattern =
        /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;
    return guidPattern.test(guid);
};

export const copy = <T>(obj: T): T => {
    return JSON.parse(JSON.stringify(obj));
};

export const hasAnyAttribute = (
    element: HTMLElement,
    ...attributes: string[]
): boolean => {
    return attributes.some((a) => element.hasAttribute(a));
};

export const FILE_URL_REGEX =
    /^(?!mailto:)(https?:\/\/)?\/?([^\s/]+\.?[^\s/]+\/)+([^\s/]+\.[a-zA-Z]+)([?&#][^\s/]+)*$/; // regexr.com/6e8ff

export const isPropablyFilePath = (path: string): boolean => {
    // TODO: Fix it later
    // THIS IS UGLY AND TEMPORARY FIX FOR AZURE SHARE FILES ISSUE
    if (path.includes('api/content/fileshare')) {
        return true;
    }
    // *********************************************************

    return FILE_URL_REGEX.test(path);
};

export const getFileName = (path: string): string => {
    // TODO: Fix it later
    // THIS IS UGLY AND TEMPORARY FIX FOR AZURE SHARE FILES ISSUE
    if (path.includes('api/content/fileshare')) {
        let splitted = path.split('/');
        return splitted[splitted.length - 1];
    }
    // *********************************************************

    return FILE_URL_REGEX.exec(path)?.[3] ?? undefined;
};

/** Gets text from an HTML element. This will return either one of the following:
 * - innerText
 * - aria-label
 * - alt
 * - title
 * When not found in the element, it will recursively follow the first child element.
 */
export const getAnyTextFromElement = (el: HTMLElement): string => {
    if (!el) return null;

    const text =
        el.innerText ||
        el.getAttribute('aria-label') ||
        el.getAttribute('alt') ||
        el.getAttribute('title');

    if (!text && el.childElementCount === 1) {
        // we can follow single childs all the way down
        return getAnyTextFromElement(el.firstChild as HTMLElement);
    }
    return text;
};

/**
 * Non-recursively filters out null, undefined and "" values from an object.
 *
 * @returns A new object without null values, undefined values and empty string values
 */
export const removeNullishAndEmpty = <T>(from: T): T => {
    return Object.entries(from)
        .filter(
            ([_, v]) =>
                !isNullOrUndefined(v) &&
                !(typeof v === 'string' && v.length === 0),
        )
        .reduce(objectReducer, {} as T);
};

/** Checks if an object is null, undefined, or empty */
export const isNullOrEmpty = <T extends Record<string, unknown>>(
    obj: T,
): boolean => {
    return !obj || Object.entries(obj).length < 1;
};

/**
 * Finds a target object in an array of objects, by checking if any of the objects matches the keyvalue pairs of the target.
 * This means that an object in the array that has more parameters could also be matched!
 * Optionally, you can also specify a list of keys so only those keys will be checked.
 * Not supplying the `keys` argument will check all keys.
 *
 * @param target The object whose parameters we want to match
 * @param array The array to search in
 * @param keys The keys to check. if not supplied, all keys will be checked
 * @param method Whether to filter the array for matches, or just find the first match
 */
export const findMatchingObject = <T extends Record<string, unknown>>(
    target: T,
    array: T[],
    keys: (keyof T)[] = Object.keys(target),
): T | T[] => {
    return array.find((obj) =>
        Object.entries(target)
            .filter(([k, _]) => keys.includes(k))
            .every(([k, v]) => k in obj && obj[k] === v),
    );
};

/**
 * Determines if a property has changed value between two object states.
 *
 * @example
 * const [prev, cur] = [{ id: 5 }, { id: 6 }];
 * propertyChanged(prev, cur, "id", { from: 5, to: 6 }) //true
 * propertyChanged(prev, cur, "id", { from: 5 }) //true
 * propertyChanged(prev, cur, "id", { to: 6 }) //true
 * propertyChanged(prev, cur, "id") //true
 */
export const propertyChanged = <T, U extends keyof T>(
    previous: T,
    current: T,
    propertyName: U,
    expected?: { from?: T[U]; to?: T[U] },
): boolean => {
    const oldValue = previous?.[propertyName];
    const newValue = current?.[propertyName];

    let result = oldValue !== newValue;

    if (!expected) return result;

    if ('from' in expected) {
        result = result && oldValue === expected.from;
    }

    if ('to' in expected) {
        result = result && newValue === expected.to;
    }

    return result;
};

/**
 * Extract from type `TFrom` those types that are assignable to `TSelect`
 *
 * @description This is a stricter version of {@link Extract `Extract`} for even stronger typing and typo-protection.
 * The type arguments are inversed for intuitive query-like usage.
 */
export type Select<TSelect extends TFrom, TFrom> = Extract<TFrom, TSelect>;

/**
 * Exclude from type `TFrom` those types that are assignable to `TRemove`
 *
 * @description This is a stricter version of {@link Exclude `Exclude`} for even stronger typing and typo-protection.
 * The type arguments are inversed for intuitive query-like usage.
 */
export type Remove<TRemove extends TFrom, TFrom> = Exclude<TFrom, TRemove>;

/** A recursive form of the {@link Partial} type */
export type DeepPartial<T> = T extends Record<string, any>
    ? { [P in keyof T]?: DeepPartial<T[P]> }
    : T;

export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

export function toBoolean(value: string): boolean {
    return value.toUpperCase() === 'TRUE';
}
