import { Component, OnDestroy, OnInit } from '@angular/core';
import { Registration } from '../../model/registration';
import { RegistrationService } from '../../services/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterComponentBase, Step } from '@common/components/register/register.component.base';
import { GoogleAnalyticsService } from '@common/services/google-analytics.service';
import { CommunityService } from '@common/services/community.service';
import { ContentService } from '@common/services/content.service';
import { KenalyticsService } from '@common/services/kenalytics.service';
import { RegisterFlowLocationService } from '@common/services/register-flow-location.service';
import { ParticipationAssetsService } from '@common/services/participation-assets.service';
import { PendingChangesService } from '@common/services/pending-changes.service';
import { Subject } from 'rxjs';
import { StepperHelperService } from '../../services/stepper-helper.service';
import { environment } from 'enuk/environments/environment';
import { AuthService } from '@common/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { InMemoryQueryService } from '@common/services/inmemory.query.service';
import { ProcessStepService } from '../../services/process-step.service';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { ProcessEvent } from '@common/analytics/events/process.event';
import { ProcessGoalEventFactory } from '@common/analytics/services/process-goal-event-factory.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent
    extends RegisterComponentBase<Registration, RegistrationService>
    implements OnInit, OnDestroy
{
    get enabled(): boolean {
        return environment.participantSecurity;
    }

    get registrationIsCommunityTargetAuction(): boolean {
        // Assume community target auction if registration or community haven't loaded yet; this is used to clarify
        // the participant count when the target auction is restricted to members only and isCommunityMember === false
        return (
            !this.registration ||
            !this.registration.auction ||
            !this.community ||
            !this.community.targetAuction ||
            this.registration.auction.id === this.community.targetAuction.id
        );
    }

    override get showAcceptantsCount(): boolean {
        switch (this.activeStep) {
            case 'contact':
            case 'product':
            case 'email-confirmation':
                return false;
            case 'offer-introduction':
            case 'proposal':
                return true;
            case 'overview':
                if (this.registration.decisionComplete) {
                    return true;
                }
                return false;
        }
        return false;
    }

    override get steps(): Step[] {
        let steps = this.allSteps;
        // if the offer was excluded, only one step available (overview)
        if (this.registration.excluded || this.registration.auction?.finished) {
            steps = steps.filter((step) => step.id === 'overview');
            return steps;
        }

        // The email confirmation page is only shown pre-auction when the user's email hasn't been confirmed yet
        // and it replaces all subsequent pages
        if (
            this.registration &&
            !this.registration.communicationBySnailMail &&
            !this.registration.emailConfirmed &&
            !this.registration.proposalMade &&
            !this.enteredFlowFromLink
        ) {
            const emailConfirmationIdx = steps.findIndex((step) => step.id === 'email-confirmation');
            if (emailConfirmationIdx !== -1) {
                steps = steps.filter((_step, idx) => idx <= emailConfirmationIdx);
            }
        } else {
            steps = steps.filter((step) => step.id !== 'email-confirmation');
        }
        // Filter out all pages related to the proposal when there's no personal proposal or no active auction
        if (
            !this.registration ||
            !this.registration.proposalMade ||
            !this.registration.auction ||
            this.registration.auction.finished
        ) {
            steps = steps.filter((step) => step.id !== 'offer-introduction' && step.id !== 'proposal');
        }

        return steps;
    }

    get stepIds(): string[] {
        return this.steps.map((s) => s.id);
    }

    get currentStepVisible(): boolean {
        return this.stepperHelperService.getCurrentStepIsVisible();
    }

    printRequestSubject = new Subject<string>();

    constructor(
        service: RegistrationService,
        protected override communityService: CommunityService,
        route: ActivatedRoute,
        analytics: GoogleAnalyticsService,
        contentService: ContentService,
        flowLocationService: RegisterFlowLocationService,
        participationAssetsService: ParticipationAssetsService,
        pendingChangesService: PendingChangesService,
        kenalyticsService: KenalyticsService,
        private stepperHelperService: StepperHelperService,
        private authService: AuthService,
        private router: Router,
        private queryService: InMemoryQueryService,
        private processStepService: ProcessStepService,
        private analyticsService: AnalyticsService,
        private processGoalEventFactory: ProcessGoalEventFactory,
    ) {
        super(
            service,
            communityService,
            route,
            analytics,
            new Registration(),
            [
                { id: 'person', header: 'person-header', icon: 'fa-user' },
                { id: 'product', header: 'product-header', icon: 'fa-home' },
                {
                    id: 'email-confirmation',
                    header: 'email-confirmation-header',
                    icon: 'fa-envelope',
                },
                {
                    id: 'offer-introduction',
                    header: 'offer-introduction-header',
                    icon: 'fa-bolt',
                },
                { id: 'proposal', header: 'proposal-header', icon: 'fa-gavel' },
                { id: 'overview', header: 'overview-header', icon: 'fa-check' },
            ],
            contentService,
            kenalyticsService,
            flowLocationService,
            participationAssetsService,
            pendingChangesService,
        );

        authService.authEvents$.pipe(takeUntil(this._destroyed$)).subscribe((event) => {
            if (event === 'user_changed') {
                this.router.navigate(['user-changed']);
            }
        });
    }
    override ngOnInit(): void {
        this.flowLocationService.goToSucceeded$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => this.sendProcessEvent());
        super.ngOnInit();
        this.queryService.publish('app-menu', 'showMenuLinks', false);
        this.queryService.publish('app-helpdesk', 'inRegisterFlow', true);
    }
    protected override goToStep(): void {
        if (!this.startedWithStep) {
            if (this.registration.cancelled || this.registration.auction?.finished) {
                this.goTo('overview');
            } else if (this.registration.proposalMade) {
                if (
                    !this.registration.decisionComplete &&
                    !this.registration.cancelled &&
                    !this.registration.auction.finished
                ) {
                    this.goTo('offer-introduction');
                } else {
                    this.goTo('overview');
                }
            } else if (this.registration.subscriptionComplete) {
                if (
                    !this.registration.communicationBySnailMail &&
                    !this.registration.emailConfirmed &&
                    !this.enteredFlowFromLink
                ) {
                    this.goTo('email-confirmation');
                } else {
                    this.goTo('overview');
                }
            } else {
                this.goTo('person');
            }
        } else {
            this.goTo(this.startedWithStep);
        }
    }

    protected override loadRegistration(theOne: Registration, existing: boolean): void {
        if (existing) {
            this.service.saveToSessionStorage(theOne);
        }
        super.loadRegistration(theOne, existing);
    }

    public override rehydrateChangeStep(_registration: Registration): void {
        const steps = this.steps;
        if (this.currentStepNumber < steps.length - 1) {
            this.goTo(steps[this.getNextStep()].id);
        }
    }

    onPrintRequested(stepId: string): void {
        this.printRequestSubject.next(stepId);
    }

    stepIsVisible(stepId: string): boolean {
        return this.stepperHelperService.getStepIsVisible(stepId);
    }

    override ngOnDestroy(): void {
        this.queryService.publish('app-menu', 'showMenuLinks', true);
        this.queryService.publish('app-helpdesk', 'inRegisterFlow', false);
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    override saveAndContinue(registration: Registration): void {
        const afterRehydrate = (r: Registration) => {
            this.sendAnalyticsEvent(r);
        };
        const afterSave = () => {
            this.rehydrate(registration, true, afterRehydrate);
        };
        if (this.currentStepNumber === this.subscriptionCompleteAfterStepIndex) {
            this.registration.subscriptionComplete = true;
        }
        this.save(afterSave);
    }

    private sendAnalyticsEvent(registration: Registration, rollover = undefined): void {
        const event = this.processGoalEventFactory.createRegistrationEvent(
            this.prevRegistrationState,
            registration,
            { rollover },
        );

        if (event?.event) {
            this.analyticsService.push(event);
            this.prevRegistrationState = Registration.clone(registration);
        }
    }

    private sendProcessEvent(): void {
        const activeStep = this.processStepService.getActiveStep(this.registration);
        const allSteps = this.processStepService.getAllSteps(
            this.registration,
            this.community,
            this.analyticsService.state.process_flow_start,
        );

        const events = ProcessEvent.getEventsFromStepName(activeStep, allSteps);
        events?.forEach((e) => this.analyticsService.push(e));
    }
}
