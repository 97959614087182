import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
    public static mprn(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (control.value === undefined || !control.value) {
                return null;
            }
            return this.isMprn(control.value as string) ? null : { mprn: true };
        };
    }

    public static isMprn(mprn: string): boolean {
        const trimmed = mprn.replace(/\s/g, '');
        return /^\d{6,10}$/g.test(trimmed);
    }

    public static mpan(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if (control.value === undefined || !control.value) {
                return null;
            }
            return this.isMpan(control.value as string) ? null : { mpan: true };
        };
    }

    public static isMpan(mpan: string): boolean {
        if (mpan.length !== 13) {
            return false;
        }

        const checkSumDigit = parseInt(mpan.substr(mpan.length - 1), 10);
        const primeNumbers = [3, 5, 7, 13, 17, 19, 23, 29, 31, 37, 41, 43];
        let productTotal = 0;

        for (let index = 0; index < primeNumbers.length; index++) {
            const currentDigit = parseInt(mpan.substr(index, 1), 10);
            productTotal += currentDigit * primeNumbers[index];
        }

        return (productTotal % 11) % 10 === checkSumDigit;
    }
}
