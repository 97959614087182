<app-ceased-trading [supplierCodes]="selectedSupliers"></app-ceased-trading>
<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate appfocusFirstInvalidField>
    <div class="radio">
        <label for="contract" class="d-block form-label">
            {{ 'energy-contract-label' | cmsText | async }}
        </label>
        <div class="row">
            <div class="col-md-8">
                <div class="form-check">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="contractDualFuel"
                        formControlName="contract"
                        value="{{ Contracts.Dual }}"
                    />
                    <label class="form-check-label" for="contractDualFuel">
                        {{ 'energy-contract-dual-label' | cmsText | async }}
                    </label>
                </div>
                <div class="form-check">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="Contractseparate"
                        formControlName="contract"
                        value="{{ Contracts.Separate }}"
                    />
                    <label class="form-check-label" for="Contractseparate">
                        {{ 'energy-contract-separate-label' | cmsText | async }}
                    </label>
                </div>
                <div class="form-check custom-radio">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="contractElectricity"
                        formControlName="contract"
                        value="{{ Contracts.Electricity }}"
                    />
                    <label class="form-check-label" for="contractElectricity">
                        {{ 'energy-contract-electricity-label' | cmsText | async }}
                    </label>
                </div>
                <div *ngIf="contract.invalid && (contract.dirty || submitRequested)" class="text-danger">
                    <span *ngIf="contract.errors.required">
                        {{ 'energy-contract-error-required' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="contract.value === Contracts.Dual">
        <div>
            <app-register-energy-supplier
                formControlName="dualSupplierData"
                id="dualSupplierData"
                cmsLabel="energy-contract-dual-supplier-label"
                [suppliers]="activeSuppliers$"
                [fuelType]="FuelType.ElectricityAndGas"
                [regionId]="registration?.contact?.regionId"
            >
            </app-register-energy-supplier>
            <div
                *ngIf="dualSupplierData.invalid && (dualSupplierData.dirty || submitRequested)"
                class="text-danger"
            >
                <span *ngIf="dualSupplierData.errors.required">
                    {{ 'energy-contract-dual-supplier-error-required' | cmsText | async }}
                </span>
            </div>
        </div>

        <div *ngLet="dualPaymentMethods$ | async as paymentMethods">
            <app-register-energy-payment-method
                formControlName="dualFuelPaymentMethod"
                [id]="'dualFuelPaymentMethod'"
                cmsLabel="energy-contract-dual-payment-method-label"
                [readonly]="!dualSupplierData.value || paymentMethods?.error"
                [isLoading]="paymentMethods?.isLoading"
                [paymentMethods]="paymentMethods?.data"
            >
            </app-register-energy-payment-method>
            <div
                *ngIf="dualFuelPaymentMethod.invalid && (dualFuelPaymentMethod.dirty || submitRequested)"
                class="text-danger"
            >
                <span *ngIf="dualFuelPaymentMethod.errors.required"> Payment method is required. </span>
            </div>
            <div *ngIf="paymentMethods?.error && (form.touched || form.dirty)" class="text-danger">
                {{ 'tariff-error-server' | cmsText | async }}
            </div>
        </div>

        <div *ngLet="dualTariffs$ | async as dualTariffs">
            <app-register-energy-tariff-select
                formControlName="dualFuelTariffName"
                id="dualFuelTariffName"
                cmsLabel="energy-contract-dual-tariff-label"
                cmsPlaceholder="energy-contract-tariff-placeholder-label"
                [readonly]="!dualFuelPaymentMethod.value || dualTariffs?.error"
                [isLoading]="dualTariffs?.isLoading"
                [tariffs]="dualTariffs?.data"
            >
            </app-register-energy-tariff-select>
            <div
                *ngIf="dualFuelTariffName.invalid && (dualFuelTariffName.dirty || submitRequested)"
                class="text-danger"
            >
                <div *ngIf="dualFuelTariffName.errors.required">Dual fuel tariff plan is required.</div>
                <div *ngIf="dualFuelTariffName.errors.server">
                    {{ 'tariff-error-server' | cmsText | async }}
                </div>
            </div>
        </div>

        <div *ngIf="dualFuelTariffRequiresEndDate === true || dualFuelContractEndDate.value">
            <label for="dualFuelContractEndDate" class="d-block form-label">
                {{ 'energy-contract-contract-end-date-label' | cmsText | async }}
            </label>
            <div class="row">
                <div class="col-md-8">
                    <div class="input-group">
                        <input
                            class="form-control"
                            id="dualFuelContractEndDate"
                            formControlName="dualFuelContractEndDate"
                            placeholder="dd/mm/yyyy"
                            dateFormat="dd/mm/yyyy"
                            ngbDatepicker
                            #dp="ngbDatepicker"
                            name="dualFuelContractEndDate"
                            [minDate]="addYearsFromToday(0)"
                            [maxDate]="addYearsFromToday(3)"
                        />
                        <button class="btn btn-secondary" (click)="dp.toggle()" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div>
                    <div
                        *ngIf="
                            dualFuelContractEndDate.invalid &&
                            (dualFuelContractEndDate.dirty || submitRequested)
                        "
                        class="text-danger"
                    >
                        <span *ngIf="dualFuelContractEndDate.errors.required">
                            {{ 'energy-contract-dual-fuel-end-date-error-required' | cmsText | async }}
                        </span>
                        <span *ngIf="dualFuelContractEndDate.errors.isInThePast">
                            {{ 'energy-contract-dual-fuel-end-date-error-in-the-past' | cmsText | async }}
                        </span>
                        <span *ngIf="dualFuelContractEndDate.errors.tooFarInTheFuture">
                            {{
                                'energy-contract-dual-fuel-end-date-error-too-far-in-future' | cmsText | async
                            }}
                        </span>
                        <span *ngIf="dualFuelContractEndDate.errors.ngbDate?.invalid">
                            {{ 'energy-contract-dual-fuel-end-date-error-invalid-format' | cmsText | async }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="contract.value === Contracts.Electricity || contract.value === Contracts.Separate">
        <div>
            <app-register-energy-supplier
                formControlName="electricitySupplierData"
                id="electricitySupplierData"
                cmsLabel="energy-contract-electricity-supplier-label"
                [suppliers]="activeSuppliers$"
                [fuelType]="FuelType.Electricity"
                [regionId]="registration?.contact?.regionId"
            >
            </app-register-energy-supplier>
            <div
                *ngIf="electricitySupplierData.invalid && (electricitySupplierData.dirty || submitRequested)"
                class="text-danger"
            >
                <span *ngIf="electricitySupplierData.errors.required">
                    {{ 'energy-contract-electricity-supplier-error-required' | cmsText | async }}
                </span>
            </div>
        </div>

        <div *ngLet="electricityPaymentMethods$ | async as paymentMethods">
            <app-register-energy-payment-method
                formControlName="electricityPaymentMethod"
                id="electricityPaymentMethod"
                cmsLabel="energy-contract-electricity-payment-method-label"
                [readonly]="!electricitySupplierData.value || paymentMethods?.error"
                [isLoading]="paymentMethods?.isLoading"
                [paymentMethods]="paymentMethods?.data"
            >
            </app-register-energy-payment-method>
            <div
                *ngIf="
                    electricityPaymentMethod.invalid && (electricityPaymentMethod.dirty || submitRequested)
                "
                class="text-danger"
            >
                <span *ngIf="electricityPaymentMethod.errors.required"> Payment method is required. </span>
            </div>
            <div *ngIf="paymentMethods?.error && (form.touched || form.dirty)" class="text-danger">
                {{ 'tariff-error-server' | cmsText | async }}
            </div>
            <div
                *ngIf="form.errors?.bothPrepaymentOrNot && (form.touched || form.dirty || submitRequested)"
                class="cross-validation-error-message text-danger error-focus ng-invalid"
            >
                {{ 'energy-contract-separate-error-mismatched-payment-method' | cmsText | async }}
            </div>
        </div>

        <div *ngLet="electricityTariffs$ | async as electricityTariffs">
            <app-register-energy-tariff-select
                formControlName="electricityTariffName"
                id="electricityTariffName"
                cmsLabel="energy-contract-electricity-tariff-label"
                [readonly]="!electricityPaymentMethod.value || electricityTariffs?.error"
                [isLoading]="electricityTariffs?.isLoading"
                [tariffs]="electricityTariffs?.data"
            >
            </app-register-energy-tariff-select>
            <div
                *ngIf="electricityTariffName.invalid && (electricityTariffName.dirty || submitRequested)"
                class="text-danger"
            >
                <div *ngIf="electricityTariffName.errors.required">Electricity tariff plan is required.</div>
            </div>
            <div *ngIf="electricityTariffs?.error && (form.touched || form.dirty)" class="text-danger">
                {{ 'tariff-error-server' | cmsText | async }}
            </div>
        </div>

        <div *ngIf="electricityTariffRequiresEndDate === true || electricityContractEndDate.value">
            <label for="electricityContractEndDate" class="d-block form-label">
                {{ 'energy-contract-contract-end-date-label' | cmsText | async }}
            </label>
            <div class="row">
                <div class="col-md-8">
                    <div class="input-group">
                        <input
                            class="form-control"
                            id="electricityContractEndDate"
                            formControlName="electricityContractEndDate"
                            placeholder="dd/mm/yyyy"
                            dateFormat="dd/mm/yyyy"
                            ngbDatepicker
                            #dp="ngbDatepicker"
                            name="electricityContractEndDate"
                            [minDate]="addYearsFromToday(0)"
                            [maxDate]="addYearsFromToday(3)"
                        />
                        <button class="btn btn-secondary" (click)="dp.toggle()" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div>
                    <div
                        *ngIf="
                            electricityContractEndDate.invalid &&
                            (electricityContractEndDate.dirty || submitRequested)
                        "
                        class="text-danger"
                    >
                        <span *ngIf="electricityContractEndDate.errors.required">
                            {{ 'energy-contract-electricity-end-date-error-required' | cmsText | async }}
                        </span>
                        <span *ngIf="electricityContractEndDate.errors.isInThePast">
                            {{ 'energy-electricity-fuel-end-date-error-in-the-past' | cmsText | async }}
                        </span>
                        <span *ngIf="electricityContractEndDate.errors.tooFarInTheFuture">
                            {{ 'energy-electricity-fuel-end-date-error-too-far-in-future' | cmsText | async }}
                        </span>
                        <span *ngIf="electricityContractEndDate.errors.ngbDate?.invalid">
                            {{ 'energy-electricity-fuel-end-date-error-invalid-format' | cmsText | async }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="contract.value === Contracts.Separate">
        <div>
            <app-register-energy-supplier
                formControlName="gasSupplierData"
                id="gasSupplierData"
                cmsLabel="energy-contract-gas-supplier-label"
                [suppliers]="activeSuppliers$"
                [fuelType]="FuelType.Gas"
                [regionId]="registration?.contact?.regionId"
            >
            </app-register-energy-supplier>
            <div
                *ngIf="gasSupplierData.invalid && (gasSupplierData.dirty || submitRequested)"
                class="text-danger"
            >
                <span *ngIf="gasSupplierData.errors.required">
                    {{ 'energy-contract-gas-supplier-error-required' | cmsText | async }}
                </span>
            </div>
        </div>

        <div *ngLet="gasPaymentMethods$ | async as paymentMethods">
            <app-register-energy-payment-method
                formControlName="gasPaymentMethod"
                id="gasPaymentMethod"
                cmsLabel="energy-contract-gas-payment-method-label"
                [readonly]="!gasSupplierData.value || paymentMethods?.error"
                [isLoading]="paymentMethods?.isLoading"
                [paymentMethods]="paymentMethods?.data"
            >
            </app-register-energy-payment-method>
            <div
                *ngIf="gasPaymentMethod.invalid && (gasPaymentMethod.dirty || submitRequested)"
                class="text-danger"
            >
                <span *ngIf="gasPaymentMethod.errors.required"> Payment method is required. </span>
            </div>
            <div *ngIf="paymentMethods?.error && (form.touched || form.dirty)" class="text-danger">
                {{ 'tariff-error-server' | cmsText | async }}
            </div>
            <div
                *ngIf="form.errors?.bothPrepaymentOrNot && (form.touched || form.dirty || submitRequested)"
                class="cross-validation-error-message text-danger error-focus ng-invalid"
            >
                {{ 'energy-contract-separate-error-mismatched-payment-method' | cmsText | async }}
            </div>
        </div>

        <div *ngLet="gasTariffs$ | async as gasTariffs">
            <app-register-energy-tariff-select
                formControlName="gasTariffName"
                id="gasTariffName"
                cmsLabel="energy-contract-gas-tariff-label"
                cmsPlaceholder="energy-contract-tariff-placeholder-label"
                [readonly]="!gasPaymentMethod.value || gasTariffs?.error"
                [isLoading]="gasTariffs?.isLoading"
                [tariffs]="gasTariffs?.data"
            >
            </app-register-energy-tariff-select>
            <div
                *ngIf="gasTariffName.invalid && (gasTariffName.dirty || submitRequested)"
                class="text-danger"
            >
                <div *ngIf="gasTariffName.errors.required">gas tariff plan is required.</div>
                <div *ngIf="gasTariffName.errors.server">
                    {{ 'tariff-error-server' | cmsText | async }}
                </div>
            </div>
        </div>

        <div *ngIf="gasTariffRequiresEndDate === true || gasContractEndDate.value">
            <label for="gasContractEndDate" class="d-block form-label">
                {{ 'energy-contract-contract-end-date-label' | cmsText | async }}
            </label>
            <div class="row">
                <div class="col-md-8">
                    <div class="input-group">
                        <input
                            class="form-control"
                            id="gasContractEndDate"
                            formControlName="gasContractEndDate"
                            placeholder="dd/mm/yyyy"
                            dateFormat="dd/mm/yyyy"
                            ngbDatepicker
                            #dp="ngbDatepicker"
                            name="gasContractEndDate"
                            [minDate]="addYearsFromToday(0)"
                            [maxDate]="addYearsFromToday(3)"
                        />
                        <button class="btn btn-secondary" (click)="dp.toggle()" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div>
                    <div
                        *ngIf="gasContractEndDate.invalid && (gasContractEndDate.dirty || submitRequested)"
                        class="text-danger"
                    >
                        <span *ngIf="gasContractEndDate.errors.required">
                            {{ 'energy-contract-gas-end-date-error-required' | cmsText | async }}
                        </span>
                        <span *ngIf="gasContractEndDate.errors.isInThePast">
                            {{ 'energy-gas-fuel-end-date-error-in-the-past' | cmsText | async }}
                        </span>
                        <span *ngIf="gasContractEndDate.errors.tooFarInTheFuture">
                            {{ 'energy-gas-fuel-end-date-error-too-far-in-future' | cmsText | async }}
                        </span>
                        <span *ngIf="gasContractEndDate.errors.ngbDate?.invalid">
                            {{ 'energy-gas-fuel-end-date-error-invalid-format' | cmsText | async }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div role="alert">
        <span class="align-middle">
            {{
                (hasEco7Meter.value === true
                    ? 'energy-has-eco-7-meter-message-yes'
                    : hasEco7Meter.value === false
                    ? 'energy-has-eco-7-meter-message-no'
                    : 'energy-has-eco-7-meter-message-undefined'
                )
                    | cmsText
                    | async
            }}
        </span>
        <button
            type="button"
            class="btn btn-link p-0"
            *ngIf="
                !showEco7MeterRadioButtons &&
                (this.hasEco7Meter.value === true || this.hasEco7Meter.value === false)
            "
            (click)="showEco7MeterRadioButtons = true"
        >
            {{ 'energy-has-eco-7-meter-edit-button' | cmsText | async }}
        </button>
    </div>

    <div
        class="radio"
        *ngIf="
            showEco7MeterRadioButtons ||
            !(this.hasEco7Meter.value === true || this.hasEco7Meter.value === false)
        "
    >
        <label for="contract" class="d-block form-label">
            {{ 'energy-has-eco-7-meter-label' | cmsText | async }}
            <app-tooltip-enuk
                cmsKey="energy-has-eco-7-meter-tooltip-body"
                targetCmsKey="energy-has-eco-7-meter-label"
            ></app-tooltip-enuk>
        </label>
        <div class="row">
            <div class="col-md-8">
                <div class="form-check custom-radio">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="hasEco7MeterYes"
                        formControlName="hasEco7Meter"
                        (click)="showEco7MeterRadioButtons = true"
                        [value]="true"
                    />
                    <label class="form-check-label" for="hasEco7MeterYes">
                        {{ 'yes' | cmsText | async }}
                    </label>
                </div>
                <div class="form-check custom-radio">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="hasEco7MeterNo"
                        formControlName="hasEco7Meter"
                        (click)="showEco7MeterRadioButtons = true"
                        [value]="false"
                    />
                    <label class="form-check-label" for="hasEco7MeterNo">
                        {{ 'no' | cmsText | async }}
                    </label>
                </div>
                <div
                    *ngIf="hasEco7Meter.invalid && (hasEco7Meter.dirty || submitRequested)"
                    class="text-danger"
                >
                    <span *ngIf="hasEco7Meter.errors.required">
                        {{ 'energy-has-eco-7-meter-error-required' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="hasEco7Meter.value === true">
        <div>
            <label for="nightTimeConsumptionPercentage" class="d-block form-label">
                <app-content
                    key="energy-night-time-consumption-percentage-label"
                    [params]="[
                        { averageNightTimeConsumptionPercentage: averageNightTimeConsumptionPercentage }
                    ]"
                >
                </app-content>
                <app-tooltip-enuk
                    cmsKey="energy-night-time-consumption-percentage-tooltip-body"
                    targetCmsKey="energy-night-time-consumption-percentage-label"
                ></app-tooltip-enuk>
            </label>
            <div class="row">
                <div class="col-md-8">
                    <select
                        class="form-control col-sm-6"
                        id="nightTimeConsumptionPercentage"
                        formControlName="nightTimeConsumptionPercentage"
                    >
                        <option *ngFor="let usage of nightTimeConsumptionPercentages" [ngValue]="usage">
                            {{ usage }}%
                            <span *ngIf="usage === averageNightTimeConsumptionPercentage">
                                {{
                                    'energy-night-time-consumption-percentage-uk-average-label'
                                        | cmsText
                                        | async
                                }}
                            </span>
                        </option>
                    </select>
                    <div
                        *ngIf="
                            nightTimeConsumptionPercentage.invalid &&
                            (nightTimeConsumptionPercentage.dirty || submitRequested)
                        "
                        class="text-danger"
                    >
                        <span *ngIf="nightTimeConsumptionPercentage.errors.required">
                            {{ 'energy-night-time-consumption-percentage-error-required' | cmsText | async }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="radio"
        *ngIf="
            contract.value &&
            (gasSupplierData.value || electricitySupplierData.value || dualSupplierData.value)
        "
    >
        <label for="contract" class="d-block form-label">
            {{ 'energy-knows-energy-consumption-label' | cmsText | async }}
            <button type="button" class="btn btn-link p-0" tabindex="-1" (click)="openModal(exampleBills)">
                {{ 'energy-example-bills-link' | cmsText | async }}
            </button>
            <ng-template #exampleBills let-d="dismiss">
                <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="d()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-content key="energy-example-bills-modal"></app-content>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="d()">
                        {{ 'tooltip-close-button' | cmsText | async }}
                    </button>
                </div>
            </ng-template>
        </label>
        <div class="row">
            <div class="col-md-8">
                <div class="form-check custom-radio">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="knowsEnergyConsumptionYes"
                        formControlName="knowsEnergyConsumption"
                        [value]="true"
                    />
                    <label class="form-check-label" for="knowsEnergyConsumptionYes">
                        {{ 'yes' | cmsText | async }}
                    </label>
                </div>
                <div class="form-check custom-radio">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="knowsEnergyConsumptionNo"
                        formControlName="knowsEnergyConsumption"
                        [value]="false"
                    />
                    <label class="form-check-label" for="knowsEnergyConsumptionNo">
                        {{ 'no' | cmsText | async }}
                    </label>
                </div>
                <div
                    *ngIf="
                        knowsEnergyConsumption.invalid && (knowsEnergyConsumption.dirty || submitRequested)
                    "
                    class="text-danger"
                >
                    <span *ngIf="knowsEnergyConsumption.errors.required">
                        {{ 'energy-knows-energy-consumption-error-required' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="knowsEnergyConsumption.value === true">
        <label for="electricityConsumption" *ngIf="contract.value != null" class="form-label">
            {{ 'energy-electricity-consumption-label' | cmsText | async }}
        </label>
        <div class="row">
            <div class="col-md-8">
                <div class="input-group" *ngIf="contract.value != null">
                    <input
                        class="form-control"
                        id="electricityConsumption"
                        formControlName="electricityConsumption"
                        type="text"
                        inputmode="numeric"
                        min="0"
                    />
                    <span class="input-group-text" id="basic-addon-kwh-electricity">
                        {{ 'unit-kilowatt-hour-per-year' | cmsText | async }}
                    </span>
                </div>
                <div
                    *ngIf="
                        electricityConsumption.invalid && (electricityConsumption.dirty || submitRequested)
                    "
                    class="text-danger"
                >
                    <span *ngIf="electricityConsumption.errors.required">
                        {{ 'energy-electricity-consumption-error-required' | cmsText | async }}
                    </span>
                    <span *ngIf="electricityConsumption.errors.max">
                        <app-content
                            key="energy-electricity-consumption-error-max"
                            [params]="[
                                { maxValue: electricityConsumption.errors.max.max | number : '1.0-2' }
                            ]"
                        >
                        </app-content>
                    </span>
                    <span *ngIf="electricityConsumption.errors.min">
                        <app-content
                            key="energy-electricity-consumption-error-min"
                            [params]="[
                                { minValue: electricityConsumption.errors.min.min | number : '1.0-2' }
                            ]"
                        >
                        </app-content>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="knowsEnergyConsumption.value === true">
        <label
            for="gasConsumption"
            class="form-label"
            *ngIf="contract.value === Contracts.Dual || contract.value === Contracts.Separate"
        >
            {{ 'energy-gas-consumption-label' | cmsText | async }}
        </label>
        <div class="row">
            <div class="col-md-8">
                <div *ngIf="contract.value === Contracts.Dual || contract.value === Contracts.Separate">
                    <div class="input-group">
                        <input
                            class="form-control"
                            id="gasConsumption"
                            formControlName="gasConsumption"
                            type="text"
                            inputmode="numeric"
                        />
                        <span class="input-group-text" id="basic-addon-kwh-gas">
                            {{ 'unit-kilowatt-hour-per-year' | cmsText | async }}
                        </span>
                    </div>
                    <div
                        *ngIf="gasConsumption.invalid && (gasConsumption.dirty || submitRequested)"
                        class="text-danger"
                    >
                        <span *ngIf="gasConsumption.errors.required">
                            {{ 'energy-gas-consumption-error-required' | cmsText | async }}
                        </span>
                        <span *ngIf="gasConsumption.errors.max">
                            <app-content
                                key="energy-gas-consumption-error-max"
                                [params]="[{ maxValue: gasConsumption.errors.max.max | number : '1.0-2' }]"
                            >
                            </app-content>
                        </span>
                        <span *ngIf="gasConsumption.errors.min">
                            <app-content
                                key="energy-gas-consumption-error-min"
                                [params]="[{ minValue: gasConsumption.errors.min.min | number : '1.0-2' }]"
                            >
                            </app-content>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="radio" *ngIf="knowsEnergyConsumption.value === false">
        <label for="consumptionProfile" class="d-block form-label">
            {{ 'energy-consumption-profile-label' | cmsText | async }}
            <app-tooltip-enuk
                cmsKey="average-consumption-tooltip-body"
                targetCmsKey="energy-consumption-profile-label"
            ></app-tooltip-enuk>
        </label>
        <div class="row">
            <div class="col-md-8">
                <div class="form-check custom-radio">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="consumptionProfileLow"
                        formControlName="consumptionProfile"
                        value="{{ ConsumptionProfiles.Low }}"
                    />
                    <label class="form-check-label" for="consumptionProfileLow">
                        {{ 'energy-consumption-profile-low-label' | cmsText | async }}
                    </label>
                </div>
                <div class="form-check custom-radio">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="consumptionProfileMedium"
                        formControlName="consumptionProfile"
                        value="{{ ConsumptionProfiles.Medium }}"
                    />
                    <label class="form-check-label form label" for="consumptionProfileMedium">
                        {{ 'energy-consumption-profile-medium-label' | cmsText | async }}
                    </label>
                </div>
                <div class="form-check custom-radio">
                    <input
                        type="radio"
                        class="form-check-input"
                        id="consumptionProfileHigh"
                        formControlName="consumptionProfile"
                        value="{{ ConsumptionProfiles.High }}"
                    />
                    <label class="form-check-label" for="consumptionProfileHigh">
                        {{ 'energy-consumption-profile-high-label' | cmsText | async }}
                    </label>
                </div>
                <div
                    *ngIf="consumptionProfile.invalid && (consumptionProfile.dirty || submitRequested)"
                    class="text-danger"
                >
                    <span *ngIf="consumptionProfile.errors.required">
                        {{ 'energy-consumption-profile-error-required' | cmsText | async }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <hr class="mb-3" />
    <h5 class="mt-3">
        <strong>
            {{ 'energy-additional-information-title' | cmsText | async }}
        </strong>
    </h5>

    <div class="row" [hidden]="true">
        <label for="hasPrepaymentMeter" class="d-block col-sm-8 col-md-9 form-label">
            {{ 'energy-has-pre-payment-meter-label' | cmsText | async }}
        </label>
        <div class="row">
            <div class="col-md-8">
                <div class="d-block">
                    <div class="form-check custom-radio form-check-inline">
                        <input
                            type="radio"
                            class="form-check-input"
                            id="hasPrepaymentMeterYes"
                            formControlName="hasPrepaymentMeter"
                            [value]="true"
                        />
                        <label class="form-check-label" for="hasPrepaymentMeterYes">
                            {{ 'yes' | cmsText | async }}
                        </label>
                    </div>
                    <div class="form-check custom-radio form-check-inline">
                        <input
                            type="radio"
                            class="form-check-input"
                            id="hasPrepaymentMeterNo"
                            formControlName="hasPrepaymentMeter"
                            [value]="false"
                        />
                        <label class="form-check-label" for="hasPrepaymentMeterNo">
                            {{ 'no' | cmsText | async }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <fieldset [hidden]="hasPrepaymentMeter.value === true || registration.communicationBySnailMail === true">
        <label for="onlineBilling" class="form-label">
            {{ 'energy-online-billing-label' | cmsText | async }}
        </label>
        <div class="d-block">
            <div class="form-check custom-radio form-check-inline">
                <input
                    type="radio"
                    class="form-check-input"
                    id="onlineBillingYes"
                    formControlName="onlineBilling"
                    [value]="true"
                />
                <label class="form-check-label" for="onlineBillingYes">
                    {{ 'yes' | cmsText | async }}
                </label>
            </div>
            <div class="form-check custom-radio form-check-inline">
                <input
                    type="radio"
                    class="form-check-input"
                    id="onlineBillingNo"
                    formControlName="onlineBilling"
                    [value]="false"
                />
                <label class="form-check-label" for="onlineBillingNo">
                    {{ 'no' | cmsText | async }}
                </label>
            </div>
        </div>
    </fieldset>

    <label for="warmHomeDiscount" class="form-label">
        {{ 'energy-warm-home-discount-label' | cmsText | async }}
        <app-tooltip-enuk
            cmsKey="energy-warm-home-discount-tooltip-body"
            targetCmsKey="energy-warm-home-discount-label"
        ></app-tooltip-enuk>
    </label>
    <div class="d-block">
        <div class="form-check custom-radio form-check-inline">
            <input
                type="radio"
                class="form-check-input"
                id="warmHomeDiscountYes"
                formControlName="warmHomeDiscount"
                [value]="true"
            />
            <label class="form-check-label from-label" for="warmHomeDiscountYes">
                {{ 'yes' | cmsText | async }}
            </label>
        </div>
        <div class="form-check custom-radio form-check-inline">
            <input
                type="radio"
                class="form-check-input"
                id="warmHomeDiscountNo"
                formControlName="warmHomeDiscount"
                [value]="false"
            />
            <label class="form-check-label" for="warmHomeDiscountNo">
                {{ 'no' | cmsText | async }}
            </label>
        </div>
    </div>

    <label for="priorityServiceRegister" class="form-label">
        {{ 'energy-priority-service-register-label' | cmsText | async }}
        <app-tooltip-enuk
            cmsKey="energy-priority-service-register-tooltip-body"
            targetCmsKey="energy-priority-service-register-label"
        ></app-tooltip-enuk>
    </label>
    <div class="d-block">
        <div class="form-check custom-radio form-check-inline">
            <input
                type="radio"
                class="form-check-input"
                id="priorityServiceRegisterYes"
                formControlName="priorityServiceRegister"
                [value]="true"
            />
            <label class="form-check-label" for="priorityServiceRegisterYes">
                {{ 'yes' | cmsText | async }}
            </label>
        </div>
        <div class="form-check custom-radio form-check-inline">
            <input
                type="radio"
                class="form-check-input"
                id="priorityServiceRegisterNo"
                formControlName="priorityServiceRegister"
                [value]="false"
            />
            <label class="form-check-label" for="priorityServiceRegisterNo">
                {{ 'no' | cmsText | async }}
            </label>
        </div>
    </div>

    <label for="hasChangedSupplierInLast3Years" class="form-label">
        {{ 'energy-has-changed-supplier-in-last-3-years-label' | cmsText | async }}
    </label>
    <div class="d-block">
        <div class="form-check custom-radio form-check-inline">
            <input
                type="radio"
                class="form-check-input"
                id="hasChangedSupplierInLast3YearsYes"
                formControlName="hasChangedSupplierInLast3Years"
                [value]="true"
            />
            <label class="form-check-label" for="hasChangedSupplierInLast3YearsYes">
                {{ 'yes' | cmsText | async }}
            </label>
        </div>
        <div class="form-check custom-radio form-check-inline">
            <input
                type="radio"
                class="form-check-input"
                id="hasChangedSupplierInLast3YearsNo"
                formControlName="hasChangedSupplierInLast3Years"
                [value]="false"
            />
            <label class="form-check-label" for="hasChangedSupplierInLast3YearsNo">
                {{ 'no' | cmsText | async }}
            </label>
        </div>
    </div>
    <hr class="my-3" />
    <div class="row">
        <div class="col text-start">
            <button type="button" class="btn btn-secondary" (click)="onNavigateToTab('person')">
                {{ 'go-back-button' | cmsText | async }}
            </button>
        </div>
        <div class="col text-end">
            <button
                id="nextButton"
                type="submit"
                class="btn btn-primary px-5"
                [disabled]="submitting && form.valid"
            >
                <i *ngIf="submitting && form.valid" class="fa fa-spinner fa-spin me-1"></i>
                {{ 'save-button' | cmsText | async }}
                <i class="fa fa-chevron-right align-middle ms-1"></i>
            </button>
        </div>
    </div>
    <!-- {{ this.registration | json}} -->
</form>
