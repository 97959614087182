import { PaymentMethod } from '../../../model/registration';

export class TarrifComparisonModel {
    typeName: string;
    totalCost: number;

    electricitySupplierName: string;
    electricityTariffName: string;
    electricityPaymentMethod: PaymentMethod;
    electricityDayPriceThreshold: number;
    electricityDayPrice: number;
    electricityDayPrice2: number;
    electricityNightPrice: number;
    electricityStandingCharge: number;
    electricityDiscount: number;
    electricityConsumption: number;
    electricityCost: number;

    gasSupplierName: string;
    gasTariffName: string;
    gasPaymentMethod: PaymentMethod;
    gasPriceThreshold: number;
    gasPrice: number;
    gasPrice2: number;
    gasStandingCharge: number;
    gasDiscount: number;
    gasConsumption: number;
    gasCost: number;
}
