import { Component, OnInit } from '@angular/core';
import { InMemoryQueryService } from '@common/services/inmemory.query.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommunityService } from '../../../../_common/services/community.service';
import { BrandService } from '@lang/uk/services/brand.service';
import { Brand } from '@lang/uk/model/brand';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    year = new Date().getFullYear();
    inRegistrationFlowPage$ = new BehaviorSubject<boolean>(false);
    brandUtm: string;
    utmLoaded$ = new BehaviorSubject<boolean>(false);

    get communityCode(): string {
        return this.communityService.communityCode;
    }

    constructor(
        private communityService: CommunityService,
        private queryService: InMemoryQueryService,
        private brandService: BrandService,
    ) {
        this.brandService
            .getActive()
            .pipe(
                map((brand) =>
                    brand.code === 'big-community-switch'
                        ? 'bigcommunityswitch'
                        : brand.code === 'big-london-energy-switch'
                        ? 'bles'
                        : brand.code,
                ),
            )
            .subscribe((brandCode) => {
                this.brandUtm = brandCode;
                this.utmLoaded$.next(true);
            });
    }

    ngOnInit(): void {
        this.applyQueryServiceParams();
    }

    applyQueryServiceParams(): void {
        this.queryService.getObservable$('app-footer').subscribe((e) => {
            if (e.name === 'inRegisterFlow' && e.value !== null) {
                this.inRegistrationFlowPage$.next(e.value);
            }
        });
    }
}
