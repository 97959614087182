import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { ContentService } from '@common/services/content.service';
import { take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-info-testimonial',
    templateUrl: './testimonial.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTestimonialComponent implements OnChanges {
    @Input() public cmsIndex: number;
    public rating$: Observable<string>; // String so we can show the CMS key
    public get showLinksToCms(): boolean {
        return this.contentService.showLinksToCms;
    }
    public math = Math;

    constructor(private contentService: ContentService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.cmsIndex.currentValue !== changes.cmsIndex.previousValue) {
            const cmsKey = `info-testimonial-${this.cmsIndex}-rating`;
            if (!this.showLinksToCms) {
                this.rating$ = this.contentService.value(cmsKey).pipe(take(1));
            } else {
                this.rating$ = of(`{{${cmsKey}}}`);
            }
        }
    }

    public getShowStar(idxStar: number, ratingStr: string): number {
        const rating = parseFloat(ratingStr);
        if (isNaN(rating)) {
            return null;
        }

        idxStar += 1; // Index is zero-based
        return idxStar <= rating ? 1 : idxStar > Math.ceil(rating) ? 0 : 0.5;
    }
}
