import { OnInit, Directive, HostListener } from '@angular/core';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    ActivatedRoute,
    Event,
} from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { Title } from '@angular/platform-browser';
import { CommunityService } from '../services/community.service';
import { ContentService } from '../services/content.service';
import { SeoService } from '../services/seo.service';
import {
    KenalyticsService,
    UtmQueryParameter,
} from '../services/kenalytics.service';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { PageViewEvent } from '@common/analytics/events/pageview.event';
import { AnalyticsClickService } from '@common/analytics/services/click.service';
@Directive()
export abstract class AppBase implements OnInit {
    // https://stackoverflow.com/a/44372167/4629701
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    protected get useTitleFromIndexPage(): boolean {
        return false;
    }
    protected get useGenericCommunityServiceErrorHandling(): boolean {
        return true;
    }

    constructor(
        protected router: Router,
        protected location: Location,
        protected analytics: GoogleAnalyticsService,
        protected titleService: Title,
        protected communityService: CommunityService,
        protected route: ActivatedRoute,
        protected contentService: ContentService,
        private seoService: SeoService,
        protected kenalyticsService: KenalyticsService,
        private analyticsService: AnalyticsService,
        protected window: Window,
        protected baseHref: string,
        private clickService: AnalyticsClickService,
    ) {
        this.addModalClickEventListener();
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    ngOnInit() {
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });

        this.router.events.subscribe((ev: Event) => {
            if (ev instanceof NavigationStart) {
                this.updateScrollStack(ev);
            } else if (ev instanceof NavigationEnd) {
                const urlArray = ev.urlAfterRedirects.split(/\/|\?|#/);
                this.sendPageView(ev);
                this.doSeo(ev, urlArray);
                this.handleScroll(ev);
                this.onNavigationEnd(ev);
            }
        });

        if (this.useGenericCommunityServiceErrorHandling) {
            this.communityService.community$.subscribe(
                (_community) => {},
                (error) => {
                    if (error.status === 404) {
                        this.router.navigate([
                            this.communityService.communityCode,
                            'error',
                            'community',
                        ]);
                    } else {
                        this.router.navigate([
                            this.communityService.communityCode,
                            'error',
                        ]);
                    }
                },
            );
        }

        this.route.queryParamMap.subscribe((params) => {
            const preview = params.get('preview');
            const showLinksToCms = params.get('showLinksToCms');
            const utmSource = params.get(UtmQueryParameter.Source);

            if (showLinksToCms === 'true') {
                this.contentService.showLinksToCms =
                    this.contentService.cmsAllowLinks;
            }

            if (preview === 'true') {
                this.contentService.showPreview = true;
                this.contentService.load();
            }

            if (utmSource) {
                const campaign = params.get(UtmQueryParameter.Campaign);
                const content = params.get(UtmQueryParameter.Content);
                const medium = params.get(UtmQueryParameter.Medium);
                const term = params.get(UtmQueryParameter.Term);
                this.kenalyticsService.saveToSessionStorage(
                    utmSource,
                    campaign,
                    content,
                    medium,
                    term,
                );
            }
        });
    }

    protected onNavigationEnd(_ev: NavigationEnd): void {}

    private handleScroll(ev: NavigationEnd) {
        if (ev.url === this.lastPoppedUrl) {
            this.lastPoppedUrl = undefined;
            window.scrollTo(0, this.yScrollStack.pop());
        } else {
            window.scrollTo(0, 0);
        }
    }

    private doSeo(ev: NavigationEnd, urlArray: string[]) {
        if (!this.useTitleFromIndexPage || ev.id > 1) {
            this.seoService.generateMetaTagsAndTitle(urlArray);
        }
        this.seoService.generateCanonicalTag(urlArray);
    }

    /** Whether to send analytics for some urls. Can be overridden. */
    protected shouldSendPageView(
        baseUrl: string,
        _queryStrings: string[] = [],
    ): boolean {
        const urlArray = baseUrl.split('/').filter((x) => !!x);
        return urlArray[1] !== 'register' && urlArray[1] !== 'do';
    }

    private sendPageView(ev: NavigationEnd): void {
        const url = ev.urlAfterRedirects;
        const [baseUrl, ...qs] = url.split(/\?|#/);

        if (!this.shouldSendPageView(baseUrl, qs)) return;

        // GA-3
        this.analytics.pageView({
            path: url,
        });

        // GA-4
        const event = PageViewEvent.create({
            path: url.split('?')[0],
        });
        this.analyticsService.push(event);
    }

    private updateScrollStack(ev: NavigationStart) {
        if (ev.url !== this.lastPoppedUrl) {
            this.yScrollStack.push(window.scrollY);
        }
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp(event: MouseEvent): void {
        const click = event.button === 0;
        const middleClick = event.button === 1;
        if (!click && !middleClick) return;

        const clickTarget = event.target as Element;
        if (!clickTarget) return;

        const linkTarget = clickTarget.closest<HTMLElement>('a,button');
        if (!linkTarget) return;

        this.clickService.sendLinkClickEvent(linkTarget);
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        const clickTarget = event.target as Element;
        if (!clickTarget) return;

        const linkTarget = clickTarget.closest<HTMLElement>('a');
        if (!linkTarget) return;

        this.registerBusinessSpecificClickEvent(event, linkTarget);
    }

    private addModalClickEventListener() {
        document.body.addEventListener('mouseup', (e) =>
            this.addModalEventListener(e, (ev) => this.onMouseUp(ev)),
        );

        document.body.addEventListener('click', (e) =>
            this.addModalEventListener(e, (ev) => this.onClick(ev)),
        );
    }

    private addModalEventListener(
        event: MouseEvent,
        callback: (event: MouseEvent) => void,
    ): void {
        const clickTarget = event.target as HTMLElement;
        const clickedOnModal = !!clickTarget?.closest('ngb-modal-window');
        if (clickedOnModal) {
            callback(event);
        }
    }

    protected registerBusinessSpecificClickEvent(
        _event: MouseEvent,
        _linkTarget: HTMLElement,
    ): void {}
}
