import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoItem } from '@common/components/info/info.component.base';
import { Community } from '@common/model/community';
import { ContentService } from '@common/services/content.service';
import { EnUkCommunityService } from '@enuk/services/enuk-community.service';
import { InfoItemIds, InfoItemService } from '@enuk/services/info-item.service';
import { InfoComponentAsyncBase } from '@lang/uk/components/info/info.component.base';
import { ButtonSize } from '@lang/uk/components/register-now/register-now-button.component';
import { BrandService } from '@lang/uk/services/brand.service';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent extends InfoComponentAsyncBase implements OnInit, OnDestroy {
    public infoItems$: Observable<InfoItem[]> = null;
    public cmsParams$: Observable<{ brand?: string; community?: string; baseUrl?: string }> = null;
    public community: Community;
    public InfoItemIds = InfoItemIds;

    public ButtonSize = ButtonSize;

    constructor(
        router: Router,
        route: ActivatedRoute,
        contentService: ContentService,
        infoItemService: InfoItemService,
        private brandService: BrandService,
        private communityService: EnUkCommunityService,
        private location: Location,
    ) {
        super(router, route, contentService, infoItemService);
    }

    public override ngOnInit(): void {
        this.communityService.community$.pipe(take(1)).subscribe((community) => {
            this.community = community;
        });

        const community$ = this.communityService.getActive();
        const brandName$ = this.brandService.getActive().pipe(
            map((brand) => (brand.parentBrand ? brand.parentBrand.code : brand.code)),
            switchMap((brandCode) => this.contentService.value(`${brandCode}-brand-text`).pipe(take(1))),
        );
        this.cmsParams$ = combineLatest([community$, brandName$]).pipe(
            takeUntil(this.destroyed$),
            map(([community, brandName]) => ({
                brand: brandName,
                community: community.name,
                baseUrl: this.location.prepareExternalUrl(`${this.community.code ?? ''}/`),
            })),
        );
        this.infoItems$ = this.infoItemService.moreInformationItems$;

        super.ngOnInit();
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public getNumberedArray(count: number): number[] {
        return Array.from({ length: count }, (_, idx) => ++idx);
    }
}
