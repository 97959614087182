<section class="fluid-container hero-image" [ngStyle]="{ 'background-image': 'url(' + heroImageUrl + ')' }">
    <div class="container d-none d-md-block">
        <div
            class="d-flex flex-column hero-image-container"
            *ngLet="
                'hero-card-message-' + communityService.communityCode | cmsText | async as heroCardMessage
            "
        >
            <ng-container *ngLet="heroCardMessage && '' + heroCardMessage !== 'null' as hasHeroCardMessage">
                <h2
                    id="heroCardMessage"
                    class="hero-title align-self-start mt-auto mb-0 px-4"
                    *ngIf="hasHeroCardMessage"
                >
                    {{ heroCardMessage }}
                </h2>
                <div
                    id="heroRegisterCard"
                    class="card rounded-0 shadow col-xl-5 col-lg-6 col-md-7 border-0 align-self-end"
                    [ngClass]="{
                        'mt-auto': hasHeroCardMessage,
                        'mb-5': hasHeroCardMessage,
                        'my-auto': !hasHeroCardMessage
                    }"
                >
                    <div class="card-body">
                        <h2 class="card-title">
                            <app-content key="hero-card-title"></app-content>
                        </h2>
                        <p>
                            <app-content key="hero-card-text"></app-content>
                        </p>
                        <button
                            class="btn btn-primary px-5 btn-lg btn-block"
                            [routerLink]="'../register'"
                            id="register-button"
                        >
                            <app-content key="register-button-text"></app-content>
                            <i class="fa fa-chevron-right ms-2"></i>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<section class="container mt-n3">
    <div class="card rounded-0 shadow d-block d-md-none col-12 border-0">
        <div class="card-body">
            <h1
                class="card-title h4"
                *ngIf="'hero-card-message-' + communityService.communityCode | cmsText | async"
            >
                {{ 'hero-card-message-' + communityService.communityCode | cmsText | async }}
            </h1>
            <hr *ngIf="'hero-card-message-' + communityService.communityCode | cmsText | async" />
            <h2 class="card-title">
                <app-content key="hero-card-title"></app-content>
            </h2>
            <p>
                <app-content key="hero-card-text"></app-content>
            </p>
            <button
                class="btn btn-primary px-5 btn-lg btn-block"
                [routerLink]="'../register'"
                id="register-button"
            >
                <app-content key="register-button-text"></app-content>
                <i class="fa fa-chevron-right ms-2"></i>
            </button>
        </div>
    </div>
</section>
<section class="container my-5">
    <h3 class="text-center"><app-content key="home-extra-information"></app-content></h3>
    <div class="row align-items-center justify-content-around">
        <div class="col-lg-4 col-md-6 my-auto">
            <h3 class="mb-3">
                <app-content key="why-signup-title"></app-content>
            </h3>
            <ul class="fa-ul ms-0">
                <li class="m-1">
                    <p class="lead">
                        <i class="text-primary fa fa-check"></i>
                        <app-content class="ms-3" key="why-signup-bullet-1-text"></app-content>
                    </p>
                </li>
                <li class="m-1 mt-3">
                    <p class="lead">
                        <i class="text-primary fa fa-check"></i>
                        <app-content class="ms-3" key="why-signup-bullet-2-text"></app-content>
                    </p>
                </li>
                <li class="m-1 mt-3">
                    <p class="lead">
                        <i class="text-primary fa fa-check"></i>
                        <app-content class="ms-3" key="why-signup-bullet-3-text"></app-content>
                    </p>
                </li>
                <li class="m-1 mt-3">
                    <p class="lead">
                        <i class="text-primary fa fa-check"></i>
                        <app-content class="ms-3" key="why-signup-bullet-4-text"></app-content>
                    </p>
                </li>
            </ul>
        </div>
        <div class="col-md-6">
            <ng-container *ngIf="participantCount | async as participantCount">
                <div *ngIf="participantCount > 500" class="row">
                    <div class="col-4 mt-md-0 mt-5 text-md-center text-start">
                        <img
                            class="img-fluid my-auto"
                            [attr.src]="'group-icon-img' | cmsImageSrc | async"
                            [attr.alt]="'group-icon-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-8 mt-md-0 mt-5">
                        <h2>{{ participantCount | number : '1.0-0' : 'en-GB' }} households</h2>
                        <p>
                            <app-content key="participant-count-title"></app-content>
                        </p>
                        <button class="btn btn-primary px-5" [routerLink]="'../register'">
                            <app-content key="register-button-text" id="register-button2"></app-content>
                            <i class="fa fa-chevron-right ms-2"></i>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<section class="container py-0 py-md-5">
    <div class="row">
        <div class="col-md-6 mt-4 mt-md-0 text-center">
            <img
                class="w-100"
                [attr.src]="'info-the-offer-img1' | cmsImageSrc | async"
                [attr.alt]="'info-the-offer-img1' | cmsImageAlt | async"
            />
        </div>
        <div class="col-md-6 my-auto">
            <h3 class="mb-3 mt-3 mt-md-0" [innerHtml]="'info-the-offer-title1' | cmsText | async"></h3>
            <div class="pe-md-5" [innerHtml]="'info-the-offer-text1' | cmsText | async"></div>
            <a
                [routerLink]="'../info/how-does-it-work'"
                class="btn btn-link px-0"
                [innerHtml]="'info-the-offer-link1' | cmsText | async"
            >
            </a>
        </div>
    </div>
</section>
<section class="fluid-container text-start text-md-center bg-highlight-one my-5 py-5">
    <div class="container py-0 py-md-5">
        <h3 class="mb-0 mb-md-5"><span [innerHTML]="'how-it-works-title' | cmsText | async"></span></h3>
        <div class="d-flex justify-content-between my-4 row">
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-1-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-1-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-1-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-1-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-2-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-2-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-2-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-2-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-3-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-3-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-3-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-3-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-4-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-4-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-4-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-4-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 my-3 my-md-0">
                <div class="row">
                    <div class="col-md-9 col-3 mx-auto">
                        <img
                            class="img-fluid rounded-circle bg-white p-1 p-md-2 p-lg-3"
                            [attr.src]="'how-it-works-bullet-5-img' | cmsImageSrc | async"
                            [attr.alt]="'how-it-works-bullet-5-img' | cmsImageAlt | async"
                        />
                    </div>
                    <div class="col-md-12 col-9 my-auto">
                        <h5 class="mt-0 mt-md-3">
                            <app-content key="how-it-works-bullet-5-title"></app-content>
                        </h5>
                        <p class="mt-3">
                            <span [innerHTML]="'how-it-works-bullet-5-text' | cmsText | async"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center">
            <a [routerLink]="'../info/how-does-it-work'" id="moreInfo" class="btn btn-secondary px-5">
                <app-content key="more-info-button-text"></app-content>
            </a>
        </div>
    </div>
</section>
<section class="container-fluid my-5">
    <div class="container">
        <app-past-suppliers></app-past-suppliers>
    </div>
</section>
<section class="fluid-container mt-5">
    <div class="mt-4 py-5 bg-highlight-two text-center">
        <h2 class="col-sm-12">
            <app-content key="sign-up-title"></app-content>
        </h2>
        <div class="col-12 text-center">
            <button class="btn btn-primary px-5 mt-3" [routerLink]="'../register'">
                <app-content key="register-button-text" id="register-button3"></app-content>
                <i class="fa fa-chevron-right ms-2"></i>
            </button>
        </div>
    </div>
</section>
