import { Directive, HostListener, ElementRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Input } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[routeTransformer]',
})
export class RouteTransformerDirective {
    // https://stackoverflow.com/questions/44613069/angular4-routerlink-inside-innerhtml-turned-to-lowercase

    constructor(
        private el: ElementRef,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(APP_BASE_HREF) private baseHref: string,
    ) {}
    @Input()
    routeTransformerArgs: any;

    @HostListener('click', ['$event'])
    public onClick(event) {
        if (event.target.tagName === 'A') {
            const url = event.target.getAttribute('href') as string;
            if (!url) {
                return;
            }
            if (url.startsWith('http')) {
                return;
            }

            const urlParts = url.split('?');
            let firstUrlPart = urlParts[0];
            const absoluteUrl = firstUrlPart.startsWith('/');

            if (this.baseHref) {
                // Fix absolute href that doesn't start with base href
                if (absoluteUrl && !firstUrlPart.startsWith(this.baseHref)) {
                    const href = this.baseHref + firstUrlPart.substring(1);
                    event.target.setAttribute('href', href);
                }

                // Fix relative route that does start with base href
                if (absoluteUrl && firstUrlPart.startsWith(this.baseHref)) {
                    firstUrlPart = firstUrlPart.substring(
                        this.baseHref.length - 1,
                    );
                }
            }

            const target = event.target.getAttribute('target');
            if (target === '_blank') {
                return;
            }

            let args = [firstUrlPart];
            if (this.routeTransformerArgs) {
                args = args.concat(this.routeTransformerArgs);
            }

            let queryParams = null;
            if (urlParts.length === 2) {
                queryParams = {};
                const queryParamArray = urlParts[1].split('&');
                queryParamArray.forEach((paramString) => {
                    const keyValue = paramString.split('=');
                    if (keyValue.length === 2) {
                        queryParams[keyValue[0]] = keyValue[1];
                    }
                });
            }

            this.router.navigate(args, {
                queryParams: queryParams,
            });

            event.preventDefault();
        } else {
            return;
        }
    }
}
