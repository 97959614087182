import { HttpClient, HttpEvent } from '@angular/common/http';
import { ContactFormBase } from '../model/contact-form.base';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from '../app/app.config';

@Injectable()
export class ContactService<TContactForm extends ContactFormBase> {
    protected readonly url: string;

    constructor(
        protected http: HttpClient,
        @Inject(API_URL) protected baseUrl: string,
    ) {
        this.url = this.baseUrl + 'contact';
    }

    send(contactForm: TContactForm): Observable<string> {
        return this.http.post<string>(this.url, contactForm);
    }

    sendFormData(formData: FormData): Observable<HttpEvent<string>> {
        return this.http.post<string>(this.url + '/upload', formData, {
            reportProgress: true,
            observe: 'events',
        });
    }
}
